export const getErrorMessage = (
  error: { event?: string; message: string },
  events: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any
): string => {
  let formError = ''

  if (error?.event === events?.find((event) => event)) {
    return error?.message
  }

  events.forEach((event) => {
    if (form?.touched[event] && form?.errors[event]) {
      formError = form?.errors[event]
    }
  })

  return formError
}
