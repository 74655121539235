import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EmailStatus from '../../components/auth/EmailStatus'

interface IEmailSendedErrorState {
  state: { email: string }
}

const EmailSendedError: React.FC = () => {
  const { state } = useLocation() as IEmailSendedErrorState
  const navigate = useNavigate()

  useEffect(() => {
    if (!state?.email) {
      navigate('/')
    }
  }, [state?.email, navigate])

  return (
    <EmailStatus
      status="ERROR"
      text={['Veja seu e-mail para continuar. Lembre-se de conferir também a caixa de SPAM.']}
    />
  )
}

export default EmailSendedError
