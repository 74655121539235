import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material'
import { ChangeEvent } from 'react'
import styled from 'styled-components'
import PrimaryCard from '../../../../../components/cards/PrimaryCard'
import SecondaryPagination from '../../Pagination/SecondaryPagination'
import TableCellSkeleton from '../../Loading/TableCellSkeleton'
import { useNavigate } from 'react-router-dom'

export type Order = 'asc' | 'desc'

export interface Column {
  id: string
  label: string
  minWidth?: number
  align: 'right' | 'center' | 'left'
  format?: (value: number | string) => string
  hasSort?: boolean
}

interface IBaseRow {
  id: number | string
}

export type Rows<T> = IBaseRow & T

export interface IPrimaryTableProps<T> {
  className?: string
  columns: Array<Column>
  rows: Array<Rows<T>>
  order?: Order
  orderBy?: string
  orderFunc?: (columnId: string) => void
  redirectTo?: string
  isLoading: boolean
  page: number
  rowsPerPage: number
  totalRows: number
  handleChangePage: (newPage: number) => void
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void
}

const PrimaryTable = <T,>({
  className,
  columns,
  rows,
  order,
  orderBy,
  orderFunc,
  isLoading,
  page,
  rowsPerPage,
  redirectTo,
  totalRows,
  handleChangePage,
  handleChangeRowsPerPage
}: IPrimaryTableProps<T>) => {
  const navigate = useNavigate()

  return (
    <div className={className}>
      <PrimaryCard className="primary-table__primary-card">
        <TableContainer
          sx={{
            '&::-webkit-scrollbar': {
              width: 10,
              height: 10
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ddd',
              border: '1px solid white',
              borderRadius: 2
            }
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth
                    }}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {column?.hasSort && orderFunc ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => orderFunc(column.id)}
                        className={`primary-table__header___cell align-${column.align}`}
                        data-testid={`primary-table-header-sort-cell-test-${column.id}`}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'scroll'
              }}
            >
              {isLoading
                ? Array.from({ length: rowsPerPage }, (_, i) => i).map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          <TableCellSkeleton align={column.align} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : //eslint-disable-next-line @typescript-eslint/no-explicit-any
                  rows.map((row: any, rowIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id + '-' + rowIndex}
                      data-testid="primary-table-row-test"
                      onClick={redirectTo ? () => navigate(`/${redirectTo}/${row.id}`) : undefined}
                    >
                      {columns.map((column, columnIndex) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id + '-' + columnIndex} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          ActionsComponent={({ count, rowsPerPage: itemsPerPage, page: currentPage, onPageChange }) => {
            const totalPages = Math.ceil(count / itemsPerPage)

            return (
              <SecondaryPagination
                count={totalPages}
                page={currentPage + 1}
                onPageChange={(newPage) => onPageChange(null, newPage - 1)}
              />
            )
          }}
          nextIconButtonProps={{
            disabled: true
          }}
          labelRowsPerPage="Ítens por página"
          labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
          rowsPerPageOptions={[15, 25, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </PrimaryCard>
    </div>
  )
}

export default styled(PrimaryTable)`
  flex: 1;
  width: 50%;

  .primary-table__primary-card {
    border-radius: 8px;
    padding: 12px 24px;
  }

  .MuiTableContainer-root {
    max-width: 100%;
    max-height: calc(100vh - 24px - 170px - 60px);
    min-height: calc(100vh - 24px - 170px - 60px);
  }

  .MuiInputBase-root {
    margin: 0 !important;
  }

  .MuiTablePagination-select {
    text-align-last: left !important;
    padding: 0 !important;
  }

  .MuiSvgIcon-root {
    color: ${({ theme: { colors } }) => colors?.tertiary};
    margin-right: -7px;
  }

  .MuiToolbar-root {
    display: grid;
    grid-template-columns: 0fr auto 96px 1fr auto;
    padding: 0 !important;
  }

  .MuiToolbar-root > :nth-child(3) {
    border-bottom: 2px solid ${({ theme: { colors } }) => colors?.inputBorder};
    margin: 0 0 0 16px !important;
  }

  .MuiToolbar-root > :nth-child(4) {
    justify-self: start;
    padding-left: 48px;
  }

  .MuiToolbar-root > :last-child {
    justify-self: end;
  }

  .MuiTablePagination-spacer {
    flex: none !important;
  }

  .MuiButtonBase-root {
    margin: 0;
  }

  .MuiTableCell-head,
  .primary-table__header___cell {
    font-weight: bold;
    font-size: 12px;
    padding: 12px 0;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .align-right {
    .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }

  .MuiTableCell-body {
    padding: 12px 0;
    font-size: 13px;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
    cursor: pointer;
  }
  .MuiTableCell-head:last-child {
    padding-right: 20px;
  }
  .MuiTableCell-body:last-child {
    padding-right: 20px;
  }

  .MuiTableRow-root {
    .MuiTableCell-body {
      border-bottom: none;
    }
  }

  .MuiTablePagination-root {
    border-top: 1px solid ${({ theme: { colors } }) => colors?.gray?.lighter};
  }

  @media (max-width: 850px) {
    width: 100%;
    max-height: calc(100vh - 24px);
  }

  @media (max-width: 500px) {
    .MuiPagination-root {
      li {
        padding: 0px !important;
      }
    }
  }
  @media (max-width: 970px) {
    .MuiToolbar-root {
      grid-template-columns: auto auto 1fr;
      grid-template-areas:
        'el1 el1 el1'
        'el2 el3 el4'
        'el5 el5 el5';
      width: 100%;
      align-items: center !important;
      justify-content: center !important;
      grid-row-gap: 24px;
    }

    .MuiToolbar-root > :nth-child(2) {
      grid-area: el2;
      margin: 0;
      justify-self: start;
      font-size: 16px;
      white-space: nowrap;
    }
    .MuiToolbar-root > :nth-child(3) {
      grid-area: el3;
      margin: 0 !important;
      margin-left: 16px !important;
      width: 84px;
      justify-self: start;
    }
    .MuiToolbar-root > :nth-child(4) {
      width: 100%;
      grid-area: el4;
      margin: 0;
      justify-self: end;
      text-align: end;
      padding: 0;
    }

    .MuiToolbar-root > :nth-child(5) {
      justify-self: end;
      grid-area: el5;
    }

    .MuiPagination-ul {
      li {
        padding: 0 6px;
      }
    }
  }
`
