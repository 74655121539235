import { useContext, useEffect } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import { CompanyContext } from '../../states/company/CompanyContext'
import { theme } from '../../styles/theme'
import { cnpjMask } from '../../utils/string'
import defaultBank from '../../assets/images/DefaultBank.png'
import payrollBank from '../../assets/images/PayrollBank.png'
import ListDelete from '../../components/crud/SplitCrud/ListDelete'
import { CompanyBankAccountInfo as ICompanyBankAccountInfo } from '../../service/graphql/schema'
import WithHeader from '../../hocs/WithHeader'
import { SnackbarContext } from '../../apps/common/contexts/SnackBar/SnackBarContext'

interface IProps {
  className?: string
}

const CompanyBankAccountInfo: React.FC<IProps> = ({ className }: IProps) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    companyBankAccountInfos,
    isGettingCompanyBankAccountInfoByCompanyId,
    getCompanyBankAccountInfoByCompanyId,
    defaultCompany,
    isGettingDefaultCompany,
    updateCompanyBankAccountInfo,
    isUpdatingBankAccountInfo,
    updatedBankAccountInfo,
    getBankAccountsPermissions,
    isGettingBankAccountsPermissions,
    bankAccountsPermissions
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyBankAccountInfoByCompanyId(defaultCompany?.businessId)
      getBankAccountsPermissions({ companyId: defaultCompany?.businessId })
    }
  }, [
    defaultCompany?.businessId,
    getCompanyBankAccountInfoByCompanyId,
    updatedBankAccountInfo,
    getBankAccountsPermissions
  ])

  const getTagColorAndStatus = (kind: 'PAYROLL_ACCOUNT' | 'CHECKING_ACCOUNT' | string) => {
    if (kind === 'PAYROLL_ACCOUNT')
      return {
        status: 'Conta Consignada',
        color: theme?.styleGuide?.support?.green
      }

    if (kind === 'CHECKING_ACCOUNT')
      return {
        status: 'LIVRE MOVIMENTAÇÃO',
        color: theme?.styleGuide?.support?.blue
      }
  }

  const handleCheck = (id: string) => {
    const bankAccountInfo = companyBankAccountInfos.find(
      (companyBankAccountInfo) => companyBankAccountInfo?.id === id
    ) as ICompanyBankAccountInfo

    if (bankAccountsPermissions.allowUpdate) {
      if (!bankAccountInfo?.isDefault) {
        updateCompanyBankAccountInfo({
          accountAgency: bankAccountInfo?.accountAgency,
          accountNumber: bankAccountInfo?.accountNumber,
          bankName: bankAccountInfo?.bankName,
          id: bankAccountInfo?.id,
          companyId: defaultCompany?.businessId as string,
          accountDigit: bankAccountInfo?.accountDigit,
          bankCode: bankAccountInfo?.bankCode,
          ispb: bankAccountInfo?.ispb,
          isDefault: !bankAccountInfo?.isDefault
        })
      }
    } else {
      showSnackbar('Você não tem permissão para salvar os dados. Por favor, tente novamente mais tarde.', 'error')
    }
  }

  const isDisabled = (defaultCompany?.statusId as string) !== '1'

  const listData = {
    basePath: '/company-bank-account-info',
    goToFormButtonLabel: 'Adicionar conta de livre movimentação',
    presentation: {
      title: 'Chegou a hora de cadastrar seus dados bancários!',
      description: [
        'Aqui você deve adicionar as contas de livre movimentação da sua empresa e quanto a conta consignada, pode deixar que a gente cuida dela!',
        '=]'
      ]
    },
    list: companyBankAccountInfos?.map((companyBankAccountInfo) => ({
      ...companyBankAccountInfo,
      documentNumber: cnpjMask(companyBankAccountInfo?.documentNumber),
      accountInfos: `Ag: ${companyBankAccountInfo?.accountAgency} / CC: ${companyBankAccountInfo?.accountNumber}${
        companyBankAccountInfo?.accountDigit ? `-${companyBankAccountInfo?.accountDigit}` : ''
      }`,
      tag: getTagColorAndStatus(companyBankAccountInfo?.kind),
      isUnclickable: companyBankAccountInfo?.kind === 'PAYROLL_ACCOUNT' ? true : isDisabled,
      imageSrc: companyBankAccountInfo?.kind === 'PAYROLL_ACCOUNT' ? payrollBank : defaultBank
    })),
    keysInCard: ['companyName', 'documentNumber'],
    cardTitleKeys: ['bankName', 'accountInfos'],
    isLoading:
      isGettingCompanyBankAccountInfoByCompanyId ||
      isGettingDefaultCompany ||
      isUpdatingBankAccountInfo ||
      isGettingBankAccountsPermissions,
    isDisabled: !bankAccountsPermissions.allowCreate,
    checkFunc: handleCheck
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <ListDelete data={listData} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyBankAccountInfo)
