import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { ReactNode } from 'react'
import styled from 'styled-components'
interface IProps extends ButtonProps {
  className?: string
  children: ReactNode
  isLoading?: boolean
}
const PrimaryButton: React.FC<IProps> = ({ className, children, isLoading, ...rest }: IProps) => {
  return (
    <div className={className}>
      <Button
        variant="contained"
        fullWidth
        data-testid="primary-button-test"
        disableElevation
        {...rest}
        disabled={isLoading || rest?.disabled}
      >
        {isLoading && (
          <CircularProgress size={18} className="primary-button-loading" data-testid="test-circular-progress" />
        )}
        {children}
      </Button>
    </div>
  )
}
export default styled(PrimaryButton)`
  width: 100%;
  .MuiButton-root {
    font-size: 16px;
    font-weight: 800;
    padding: 10px 20px;
    border: 2px solid
      ${({ theme, color }) => {
        if (color === 'error') return theme?.styleGuide?.support?.red
        return theme?.colors?.primary
      }};
    .primary-button-loading {
      margin-right: 12px;
    }
    &:hover {
      border: 2px solid
        ${({ theme, color }) => {
          if (color === 'error') return theme?.styleGuide?.support?.red
          return theme?.colors?.primary
        }};
    }
  }
  .Mui-disabled {
    border-color: ${({ theme }) => theme?.colors?.disabled};
    .primary-button-loading {
      color: ${({ theme }) => theme?.colors?.gray?.main};
    }
  }
`
