import { IDocumentType } from '../../components/crud/PrimaryFileSplitCrud/List'
import { CompanyDocument } from '../../service/graphql/schema'
import { theme } from '../../styles/theme'

interface INameStatus {
  name: string
  status: string
  key: string
}

export const getRepeatedDocumentType = (
  documentRequired: { name: string; key: string },
  companyDocuments: CompanyDocument[]
) =>
  companyDocuments?.reduce((acc: INameStatus[], companyDocument) => {
    if (documentRequired?.name?.toLowerCase() === companyDocument?.documentTypeName?.toLowerCase()) {
      acc.push({
        key: documentRequired?.key,
        name: companyDocument?.documentTypeName,
        status: companyDocument?.statusId as string
      })
    }

    return acc
  }, [])

export const getTagByStatuses = (nameStatuses: INameStatus[]): Omit<IDocumentType, 'name' | 'key'> => {
  if (nameStatuses?.find((nameStatus) => nameStatus?.status === '1')) {
    return {
      tagColor: theme?.styleGuide?.support?.yellow,
      tagName: 'Em Análise'
    }
  }
  if (nameStatuses?.find((nameStatus) => nameStatus?.status === '2')) {
    return {
      tagColor: theme?.styleGuide?.support?.green,
      tagName: 'Aprovado'
    }
  }
  if (nameStatuses?.find((nameStatus) => nameStatus?.status === '3')) {
    return {
      tagColor: theme?.styleGuide?.support?.red,
      tagName: 'Reprovado'
    }
  }

  return {
    tagColor: theme?.styleGuide?.fixed?.gray?.dark?.primary,
    tagName: 'Sem Anexo'
  }
}

export const getDocumentsStatus = (
  documentsRequired: { name: string; key: string }[],
  companyDocuments: CompanyDocument[]
) => {
  return documentsRequired?.map((documentRequired) => {
    const documents = getRepeatedDocumentType(documentRequired, companyDocuments)

    if (documents?.length > 0) {
      return {
        name: documents[0]?.name,
        key: documents[0]?.key,
        ...getTagByStatuses(documents)
      }
    } else {
      return {
        name: documentRequired?.name,
        tagColor: theme?.styleGuide?.fixed?.gray?.dark?.primary,
        tagName: 'Sem Anexo',
        key: documentRequired?.key
      }
    }
  })
}

export const getRequiredDocumentsByKey = (
  key: string
): {
  title?: string
  description: string[]
  link?: {
    href: string
    text: string
  }
}[] => {
  switch (key) {
    case 'constitution':
      return [
        {
          title: 'Empresas EIRELI ou LTDA',
          description: ['Contrato Social ou Requerimento de empresário, consolidado com a última alteração contratual.']
        },
        {
          title: 'Empresas S.A.',
          description: ['Estatuto e Ata de Eleição da Diretoria atualizados.']
        }
      ]
    case 'business-address':
      return [
        {
          title: 'Imóvel próprio',
          description: [
            'Conta de água, luz, telefone fixo ou gás, em nome da empresa ou de sócio, emitido nos últimos 90 dias.'
          ]
        },
        {
          title: 'Imóvel alugado',
          description: ['Contrato de locação e comprovante de endereço em nome do proprietário do imóvel.']
        }
      ]
    case 'group-declaration':
      return [
        {
          description: ['A declaração pode ser encontrada no'],
          link: {
            href: 'https://www.gov.br/economia/pt-br/assuntos/drei/juntas-comerciais',
            text: 'site da Junta Comercial de seu estado.'
          }
        }
      ]
    case 'identification':
      return [
        {
          description: [
            'Foto ou cópia do RG e CPF ou CNH vigente (não pode estar vencida), ou documento de órgão de classe (OAB, CREA) dos sócios administradores e procuradores.'
          ]
        }
      ]
    case 'partner-address':
      return [
        {
          description: [
            'Conta de água, luz, telefone fixo ou gás, em nome dos sócios, procuradores (se houver procurador) ou parentes em primeiro grau (mediante comprovação de parentesco) emitido nos últimos 90 dias.'
          ]
        }
      ]
    case 'proxy':
      return [
        {
          description: [
            'Caso a empresa possua procurador, é necessário incluir a procuração que dá poder para essa pessoa.',
            'A procuração precisa estar dentro da data de vigência.'
          ]
        }
      ]
    case 'revenue':
      return [
        {
          title: 'Empresas SIMPLES',
          description: ['Declaração de faturamento (PGDAS-D).']
        },
        {
          title: 'Outras empresas',
          description: ['Faturamento dos últimos 12 meses assinada pelos sócios, com assinatura e carimbo do contador.']
        }
      ]
    case 'extract':
      return [
        {
          title: 'Extrato bancário',
          description: ['Em formato PDF, com os últimos 60 dias, da sua conta de maior movimentação.']
        },
        {
          title: 'Outras empresas',
          description: ['Faturamento dos últimos 12 meses assinada pelos sócios, com assinatura e carimbo do contador.']
        }
      ]
    default:
      return []
  }
}
