export const validateHasUppercase = (text: string): boolean => {
  const hasUppercaseRegex = /.*[A-Z]+.*/g
  return hasUppercaseRegex?.test(text)
}

export const validateHasLowercase = (text: string): boolean => {
  const hasLowercaseRegex = /.*[a-z]+.*/g
  return hasLowercaseRegex?.test(text)
}

export const validateHasNumber = (text: string): boolean => {
  const hasNumberRegex = /.*[0-9]+.*/g
  return hasNumberRegex?.test(text)
}

export const validateMinLength = (text: string, minLength: number): boolean => {
  return text?.length >= minLength
}

export const validateSpecialCharacters = (text: string): boolean => {
  const hasSpecialCharacters = /.*[$&+,:;=?@#|'<>.^*()%!-]+.*/g
  return hasSpecialCharacters?.test(text)
}

export const validateIfPasswordIsLow = (password: string, minLength: number): boolean => {
  if (!validateHasNumber(password)) return true
  if (!validateMinLength(password, minLength)) return true
  if (!validateSpecialCharacters(password)) return true
  if (!validateHasUppercase(password)) return true
  if (!validateHasLowercase(password)) return true
  return false
}
