import { ContentCopy } from '@mui/icons-material'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import SecondaryInput from '../../components/inputs/SecondaryInput'
import SecondarySelectInput from '../../components/inputs/SecondarySelectInput'
import { CompanyContext } from '../../states/company/CompanyContext'
import * as yup from 'yup'
import * as validations from 'validations-br'
import { yupResolver } from '@hookform/resolvers/yup'
import { removeAllLettersAndSpecialCharacters } from '../../utils/string'
import { RegisterCompanyInput } from '../../service/graphql/schema'
import InputFormat from '../../components/inputs/InputFormat'
import { CircularProgress } from '@mui/material'
import { useAuth } from '../../hooks/useAuth'
import { translateGroup } from '../../utils/auth'
import { stateList } from '../../utils/address'
import CrudCreateModal from '../../components/crud/CrudCreateModal'
import { AddressContext } from '../../states/address/AddressContext'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

type IRegisterCompanyForm = Omit<RegisterCompanyInput, 'businessId'>

const schema = yup.object().shape({
  documentNumber: yup
    .string()
    .required('Esse campo precisa ser preenchido')
    .test(
      'is-cnpj',
      'Confira se seu CNPJ está correto',
      (value?: string) => !!(value && validations.validateCNPJ(value))
    )
    .nullable(),
  companyName: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  tradeName: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  email: yup
    .string()
    .required('Esse campo precisa ser preenchido')
    .email('Confira se seu e-mail está correto')
    .nullable(),
  phone: yup
    .string()
    .required('Esse campo precisa ser preenchido')
    .test(
      'is-phone',
      'Confira se seu telefone está correto',
      (value?: string) => !!(value && validations.validatePhone(value))
    )
    .nullable(),
  registerAddressZipCode: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  registerAddressStreet: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  registerAddressNumber: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  registerAddressNeighborhood: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  registerAddressCity: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  registerAddressState: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  workingAddressZipCode: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  workingAddressStreet: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  workingAddressNumber: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  workingAddressNeighborhood: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  workingAddressCity: yup.string().required('Esse campo precisa ser preenchido').nullable(),
  workingAddressState: yup.string().required('Esse campo precisa ser preenchido').nullable()
})

const RegisterCompany: React.FC<IProps> = ({ className }: IProps) => {
  const { getAddressByZipCode } = useContext(AddressContext)

  const { groups } = useAuth()

  const group = translateGroup(groups?.[0])
  const {
    registerCompany,
    defaultCompany,
    isGettingDefaultCompany,
    getCompanyInfos,
    isGettingCompanyInfos,
    companyInfos,
    isRegistratingCompany,
    closeCompanyInfosModal,
    companyInfosModal
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany) {
      getCompanyInfos(defaultCompany?.businessId)
    }
  }, [getCompanyInfos, defaultCompany])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue
  } = useForm<IRegisterCompanyForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const duplicateAddress = () => {
    const addressZipCode = watch('registerAddressZipCode')
    const addressStreet = watch('registerAddressStreet')
    const addressNumber = watch('registerAddressNumber')
    const addressComplement = watch('registerAddressComplement')
    const addressNeighborhood = watch('registerAddressNeighborhood')
    const addressCity = watch('registerAddressCity')
    const addressState = watch('registerAddressState')

    reset({
      workingAddressZipCode: addressZipCode,
      workingAddressStreet: addressStreet,
      workingAddressNumber: addressNumber,
      workingAddressComplement: addressComplement,
      workingAddressNeighborhood: addressNeighborhood,
      workingAddressCity: addressCity,
      registerAddressState: addressState,
      workingAddressState: addressState
    })
  }

  useEffect(() => {
    reset({
      documentNumber: companyInfos?.documentNumber,
      companyName: companyInfos?.companyName,
      tradeName: companyInfos?.tradeName,
      activityCode: companyInfos?.activityCode,
      activity: companyInfos?.activity,
      activityDescription: companyInfos?.activityDescription,
      email: companyInfos?.email,
      phone: companyInfos?.phone,
      website: companyInfos?.website,
      facebook: companyInfos?.facebook,
      instagram: companyInfos?.instagram,
      linkedin: companyInfos?.linkedin,
      registerAddressZipCode: companyInfos?.registerAddressZipCode,
      registerAddressStreet: companyInfos?.registerAddressStreet,
      registerAddressNumber: companyInfos?.registerAddressNumber,
      registerAddressComplement: companyInfos?.registerAddressComplement,
      registerAddressNeighborhood: companyInfos?.registerAddressNeighborhood,
      registerAddressCity: companyInfos?.registerAddressCity,
      registerAddressState: companyInfos?.registerAddressState || '',
      workingAddressZipCode: companyInfos?.workingAddressZipCode,
      workingAddressStreet: companyInfos?.workingAddressStreet,
      workingAddressNumber: companyInfos?.workingAddressNumber,
      workingAddressComplement: companyInfos?.workingAddressComplement,
      workingAddressNeighborhood: companyInfos?.workingAddressNeighborhood,
      workingAddressCity: companyInfos?.workingAddressCity,
      workingAddressState: companyInfos?.workingAddressState || ''
    })
  }, [companyInfos, defaultCompany, reset])

  const disabledFields = (defaultCompany?.statusId as string) !== '1'

  const handleRegisterZipCode = async (event: React.FocusEvent<HTMLInputElement>) => {
    const address = await getAddressByZipCode(event.target.value)
    setValue('registerAddressCity', address.city)
    setValue('registerAddressStreet', address.streetName)
    setValue('registerAddressNeighborhood', address.neighboorhood)
    setValue('registerAddressState', address.state)
  }

  const handleWorkingZipCode = async (event: React.FocusEvent<HTMLInputElement>) => {
    const address = await getAddressByZipCode(event.target.value)
    setValue('workingAddressCity', address.city)
    setValue('workingAddressStreet', address.streetName)
    setValue('workingAddressNeighborhood', address.neighboorhood)
    setValue('workingAddressState', address.state)
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <CrudCreateModal open={companyInfosModal} onClose={closeCompanyInfosModal} />
      {!isGettingCompanyInfos && !isGettingDefaultCompany ? (
        <form
          onSubmit={handleSubmit((data) =>
            registerCompany({
              businessId: defaultCompany?.businessId as string,
              ...data
            })
          )}
        >
          <div className="register-company--wrapper">
            <div className="register-company__group--wrapper">
              <SecondaryInput
                label="CNPJ*"
                errorMessage={errors?.documentNumber?.message}
                InputProps={{
                  inputComponent: InputFormat,
                  inputProps: {
                    format: '##.###.###/####-##'
                  }
                }}
                defaultValue={removeAllLettersAndSpecialCharacters(watch('documentNumber') as string) || ''}
                value={removeAllLettersAndSpecialCharacters(getValues('documentNumber') as string)}
                {...register('documentNumber')}
                data-testid="input-documentNumber-test"
                disabled={disabledFields}
              />
              <SecondaryInput
                label="Razão Social*"
                {...register('companyName')}
                errorMessage={errors?.companyName?.message}
                data-testid="input-companyName-test"
                disabled={disabledFields}
              />
              <SecondaryInput
                label="Nome Fantasia*"
                {...register('tradeName')}
                errorMessage={errors?.tradeName?.message}
                data-testid="input-tradeName-test"
                disabled={disabledFields}
              />
              <SecondaryInput
                label="Cnae"
                {...register('activityCode')}
                errorMessage={errors?.activityCode?.message}
                data-testid="input-activityCode-test"
                disabled={disabledFields}
              />
              <SecondaryInput
                label="Descrição do Cnae"
                {...register('activity')}
                data-testid="input-activity-test"
                errorMessage={errors?.activity?.message}
                disabled={disabledFields}
              />
              <SecondaryInput
                label="Resumo de atividade"
                {...register('activityDescription')}
                data-testid="input-activityDescription-test"
                errorMessage={errors?.activityDescription?.message}
                disabled={disabledFields}
              />
            </div>
          </div>
          <div className="register-company--wrapper">
            {group !== 'Buyer' && (
              <div className="register-company__group___header--wrapper">
                <p className="register-company__group--title">Tipo de empresa</p>
              </div>
            )}
            <div className={`register-company__group--wrapper type ${group === 'Buyer' && 'buyer'}`}>
              {group === 'Buyer' && (
                <SecondarySelectInput
                  options={companyInfos?.buyerTypes?.map((buyerType) => ({
                    value: buyerType?.buyerTypeId,
                    item: buyerType?.buyerTypeName
                  }))}
                  label="Tipo do financiador"
                  {...register('buyerTypeId')}
                  errorMessage={errors?.buyerTypeId?.message}
                  defaultValue={companyInfos?.buyerTypeId ?? ''}
                  data-testid="input-buyerTypeId-test"
                  disabled={disabledFields}
                />
              )}
              <SecondarySelectInput
                options={companyInfos?.companySizes?.map((companySize) => ({
                  value: companySize?.businessSizeId,
                  item: companySize?.businessSizeName
                }))}
                label="Porte da empresa"
                {...register('companySizeId')}
                errorMessage={errors?.companySizeId?.message}
                defaultValue={companyInfos?.companySizeId ?? ''}
                data-testid="input-companySizeId-test"
                disabled={disabledFields}
              />
              <SecondarySelectInput
                options={companyInfos?.taxRules?.map((taxRule) => ({
                  value: taxRule?.taxRuleId,
                  item: taxRule?.taxRuleName
                }))}
                {...register('taxRuleId')}
                errorMessage={errors?.taxRuleId?.message}
                label="Regime tributário"
                defaultValue={companyInfos?.taxRuleId ?? ''}
                data-testid="input-taxRuleId-test"
                disabled={disabledFields}
              />
              <SecondarySelectInput
                options={companyInfos?.constitutions?.map((constitution) => ({
                  value: constitution?.constitutionId,
                  item: constitution?.constitutionName
                }))}
                {...register('constitutionId')}
                errorMessage={errors?.constitutionId?.message}
                label="Constituição"
                defaultValue={companyInfos?.constitutionId ?? ''}
                data-testid="input-constitutionId-test"
                disabled={disabledFields}
              />
            </div>
            <div className="register-company--wrapper">
              <div className="register-company__group___header--wrapper">
                <p className="register-company__group--title">Contato e Redes Sociais</p>
              </div>
              <div className="register-company__group--wrapper contact">
                <SecondaryInput
                  label="E-mail*"
                  {...register('email')}
                  errorMessage={errors?.email?.message}
                  data-testid="input-email-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Telefone*"
                  errorMessage={errors?.phone?.message}
                  InputProps={{
                    inputComponent: InputFormat,
                    inputProps: {
                      format: '(##) #####-####'
                    }
                  }}
                  defaultValue={removeAllLettersAndSpecialCharacters(watch('phone') as string) || ''}
                  value={removeAllLettersAndSpecialCharacters(getValues('phone') as string)}
                  {...register('phone')}
                  data-testid="input-phone-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Site"
                  {...register('website')}
                  errorMessage={errors?.website?.message}
                  data-testid="input-website-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Facebook"
                  {...register('facebook')}
                  errorMessage={errors?.facebook?.message}
                  data-testid="input-facebook-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Instagram"
                  {...register('instagram')}
                  errorMessage={errors?.instagram?.message}
                  data-testid="input-instagram-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Linkedin"
                  {...register('linkedin')}
                  errorMessage={errors?.linkedin?.message}
                  data-testid="input-linkedin-test"
                  disabled={disabledFields}
                />
              </div>
            </div>
            <div className="register-company--wrapper">
              <div className="register-company__group___header--wrapper">
                <p className="register-company__group--title">Endereço de registro</p>
              </div>
              <div className="register-company__group--wrapper address-top">
                <SecondaryInput
                  label="CEP*"
                  errorMessage={errors?.registerAddressZipCode?.message}
                  InputProps={{
                    inputComponent: InputFormat,
                    inputProps: {
                      format: '#####-###'
                    }
                  }}
                  defaultValue={removeAllLettersAndSpecialCharacters(watch('registerAddressZipCode') as string) || ''}
                  value={removeAllLettersAndSpecialCharacters(getValues('registerAddressZipCode') as string)}
                  {...register('registerAddressZipCode')}
                  data-testid="input-registerAddressZipCode-test"
                  disabled={disabledFields}
                  onBlur={handleRegisterZipCode}
                />
                <SecondaryInput
                  label="Logradouro*"
                  {...register('registerAddressStreet')}
                  errorMessage={errors?.registerAddressStreet?.message}
                  data-testid="input-registerAddressStreet-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Número*"
                  {...register('registerAddressNumber')}
                  errorMessage={errors?.registerAddressNumber?.message}
                  data-testid="input-registerAddressNumber-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Complemento"
                  {...register('registerAddressComplement')}
                  errorMessage={errors?.registerAddressComplement?.message}
                  data-testid="input-registerAddressComplement-test"
                  disabled={disabledFields}
                />
              </div>
            </div>
            <div className="register-company--wrapper">
              <div className="register-company__group--wrapper address-bottom">
                <div />
                <SecondaryInput
                  label="Bairro*"
                  {...register('registerAddressNeighborhood')}
                  errorMessage={errors?.registerAddressNeighborhood?.message}
                  data-testid="input-registerAddressNeighborhood-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Cidade*"
                  {...register('registerAddressCity')}
                  errorMessage={errors?.registerAddressCity?.message}
                  data-testid="input-registerAddressCity-test"
                  disabled={disabledFields}
                />
                <SecondarySelectInput
                  options={stateList}
                  label="Estado*"
                  {...register('registerAddressState')}
                  errorMessage={errors?.registerAddressState?.message}
                  data-testid="input-registerAddressState-test"
                  defaultValue={companyInfos?.registerAddressState ?? ''}
                  value={watch('registerAddressState')}
                  disabled={disabledFields}
                />
              </div>
            </div>
            <div className="register-company--wrapper">
              <div className="register-company__group___header--wrapper">
                <p className="register-company__group--title">Endereço de funcionamento</p>
                {!disabledFields && (
                  <SecondaryButton
                    startIcon={<ContentCopy />}
                    onClick={duplicateAddress}
                    data-testid="copy-address-button-test"
                  >
                    Copiar endereço de registro
                  </SecondaryButton>
                )}
              </div>
              <div className="register-company__group--wrapper address-top">
                <SecondaryInput
                  label="CEP*"
                  errorMessage={errors?.workingAddressZipCode?.message}
                  InputProps={{
                    inputComponent: InputFormat,
                    inputProps: {
                      format: '#####-###'
                    }
                  }}
                  defaultValue={removeAllLettersAndSpecialCharacters(watch('workingAddressZipCode') as string) || ''}
                  value={removeAllLettersAndSpecialCharacters(getValues('workingAddressZipCode') as string)}
                  {...register('workingAddressZipCode')}
                  data-testid="input-workingAddressZipCode-test"
                  disabled={disabledFields}
                  onBlur={handleWorkingZipCode}
                />
                <SecondaryInput
                  label="Logradouro*"
                  {...register('workingAddressStreet')}
                  errorMessage={errors?.workingAddressStreet?.message}
                  data-testid="input-workingAddressStreet-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Número*"
                  {...register('workingAddressNumber')}
                  errorMessage={errors?.workingAddressNumber?.message}
                  data-testid="input-workingAddressNumber-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Complemento"
                  {...register('workingAddressComplement')}
                  errorMessage={errors?.workingAddressComplement?.message}
                  data-testid="input-workingAddressComplement-test"
                  disabled={disabledFields}
                />
              </div>
            </div>
            <div className="register-company--wrapper">
              <div className="register-company__group--wrapper address-bottom">
                <div />
                <SecondaryInput
                  label="Bairro*"
                  {...register('workingAddressNeighborhood')}
                  errorMessage={errors?.workingAddressNeighborhood?.message}
                  data-testid="input-workingAddressNeighborhood-test"
                  disabled={disabledFields}
                />
                <SecondaryInput
                  label="Cidade*"
                  {...register('workingAddressCity')}
                  errorMessage={errors?.workingAddressCity?.message}
                  data-testid="input-workingAddressCity-test"
                  disabled={disabledFields}
                />

                <SecondarySelectInput
                  options={stateList}
                  label="Estado*"
                  {...register('workingAddressState')}
                  errorMessage={errors?.workingAddressState?.message}
                  data-testid="input-workingAddressState-test"
                  defaultValue={companyInfos?.workingAddressState ?? ''}
                  value={watch('workingAddressState') || ''}
                  disabled={disabledFields}
                />
              </div>
            </div>
            <div className="register-company__submit-button--wrapper">
              <PrimaryButton
                type="submit"
                data-testid="register-company-submit-test"
                isLoading={isRegistratingCompany}
                disabled={disabledFields}
              >
                Salvar
              </PrimaryButton>
            </div>
          </div>
        </form>
      ) : (
        <div className="register-company__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      )}
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(styled(RegisterCompany)`
  .register-company__loading {
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .register-company--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .register-company__group___header--wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
    }

    .register-company__group--title {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      font-size: 16px;
      font-weight: bold;
      margin: 12px 0;
    }

    .register-company__group--wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 5fr 5fr;
      grid-column-gap: 16px;

      &.type {
        grid-template-columns: 4fr 4fr 4fr;
      }

      &.buyer {
        grid-template-columns: 3fr 3fr 3fr 3fr;
      }

      &.contact {
        grid-template-columns: 4fr 4fr 4fr;
      }

      &.address-top {
        grid-template-columns: 2fr 4fr 2fr 4fr;
      }

      &.address-bottom {
        grid-template-columns: 2fr 3fr 4fr 3fr;
      }
    }

    .register-company__submit-button--wrapper {
      width: 184px;
      margin-top: 48px;
    }
  }

  @media (max-width: 800px) {
    .register-company--wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      .register-company__group___header--wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      .register-company__group--wrapper {
        grid-template-columns: 1fr;

        &.type,
        &.contact,
        &.address-top,
        &.address-bottom {
          grid-template-columns: 1fr;
        }
      }

      .register-company__submit-button--wrapper {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
`)
