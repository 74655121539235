import axios, { AxiosRequestConfig } from 'axios'
import Auth from '../service/auth'
import { API_URL } from '../utils/envs'

export const api = axios.create({
  baseURL: API_URL
})

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (!config?.headers) return config

  const auth = new Auth()
  let accessToken

  try {
    const user = await auth?.currentAuthenticatedUser()

    accessToken = user?.accessToken
  } catch {
    accessToken = undefined
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
    return config
  } else {
    return config
  }
})
