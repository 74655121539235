import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment } from '@mui/material'
import { useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import NormalFlowPageWrapper from '../../components/auth/NormalFlowPageWrapper'
import NormalFlowTitle from '../../components/auth/NormalFlowTitle'
import NormalForm from '../../components/auth/NormalForm'
import NormalFormWrapper from '../../components/auth/NormalFormWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import InputContainer from '../../components/inputs/InputContainer'
import PrimaryInput from '../../components/inputs/PrimaryInput'
import { AuthContext } from '../../states/auth/AuthContext'
import * as yup from 'yup'
import { getErrorMessage } from '../../utils/form'
import { useNavigate } from 'react-router-dom'
import PasswordVerifier from '../../components/auth/PasswordVerifier'
import { usePasswordValidator } from '../../hooks/usePasswordValidator'

interface IProps {
  className?: string
}

const DefinitivePassword: React.FC<IProps> = ({ className }: IProps) => {
  const { authError, isSavingNewPassword, newPassword, temporaryUser } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (!temporaryUser) {
      navigate('/')
    }
  }, [temporaryUser, navigate])

  const formik = useFormik({
    initialValues: {
      password: ''
    },

    validationSchema: yup?.object({
      password: yup?.string().required('Digite uma senha')
    }),
    onSubmit: ({ password }) => newPassword(password)
  })

  const {
    upperCase,
    lowerCase,
    length,
    number,
    specialCharacter,
    all: lowPassword
  } = usePasswordValidator(formik?.values?.password, 8)

  return (
    <NormalFlowPageWrapper className={className}>
      <NormalFormWrapper>
        <NormalFlowTitle backButton title="Escolha sua nova senha" subtitle="Agora, escolha sua senha definitiva." />
        <NormalForm onSubmit={formik.handleSubmit}>
          <InputContainer>
            <PrimaryInput
              value={formik.values.password}
              errorMessage={getErrorMessage(authError, ['newPassword', 'password'], formik)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              label="Nova Senha"
              type="password"
              placeholder="Digite sua nova senha"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLock} />
                  </InputAdornment>
                )
              }}
              data-testid="definifive-password-input"
            />
          </InputContainer>
          <PasswordVerifier
            upperCase={upperCase}
            lowerCase={lowerCase}
            number={number}
            length={length}
            specialCharacter={specialCharacter}
          />
          <PrimaryButton
            data-testid="definifive-password-submit-button"
            color="primary"
            type="submit"
            isLoading={isSavingNewPassword}
            disabled={lowPassword}
          >
            Salvar
          </PrimaryButton>
        </NormalForm>
      </NormalFormWrapper>
    </NormalFlowPageWrapper>
  )
}

export default styled(DefinitivePassword)`
  ${PasswordVerifier} {
    margin-bottom: 16px;
  }
`
