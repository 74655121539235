import { IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from '@mui/material'
import { forwardRef, Ref, useState } from 'react'
import styled from 'styled-components'
import { defineType } from '../../../utils/input'
import PasswordIcon from '../PasswordIcon'

export interface IPrimaryInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  className?: string
  errorMessage?: string
  ref?: Ref<HTMLInputElement>
}

const PrimaryInput = forwardRef<HTMLInputElement, IPrimaryInputProps>(
  ({ className, errorMessage, ...rest }: IPrimaryInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <TextField
        ref={ref}
        InputLabelProps={{
          classes: {
            shrink: 'shrink'
          }
        }}
        variant="outlined"
        error={!!errorMessage}
        helperText={errorMessage}
        className={className}
        fullWidth
        {...rest}
        type={defineType(rest?.type, showPassword)}
        InputProps={{
          endAdornment: rest.type === 'password' && (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <PasswordIcon showPassword={showPassword} errorMessage={errorMessage} />
              </IconButton>
            </InputAdornment>
          ),
          ...rest?.InputProps
        }}
      />
    )
  }
)

PrimaryInput.displayName = 'PrimaryInput'

export default styled(PrimaryInput)`
  fieldset {
    legend {
      margin-left: 8px;
    }
  }

  .shrink {
    margin-left: 14px;
  }

  .MuiInputAdornment-positionStart {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
`
