import React from 'react'
import styled from 'styled-components'

interface IBouncingLoadingBarProps {
  className?: string
}

const BouncingLoadingBar: React.FC<IBouncingLoadingBarProps> = ({ className }: IBouncingLoadingBarProps) => {
  return (
    <div className={className}>
      <div className="bouncing-loading-bar__loader">
        <span className="bouncing-loading-bar__loader__span"></span>
      </div>
    </div>
  )
}

export default styled(BouncingLoadingBar)`
  width: 100%;
  @media (max-width: 500px) {
    width: 80%;
  }

  .bouncing-loading-bar__loader {
    background: ${({ theme }) => theme?.colors?.gray?.secondaryGray};
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 12px;

    .bouncing-loading-bar__loader__span {
      animation: bouncingLoading 4s infinite linear;
      border-radius: 8px;
      height: 100%;
      max-width: 48px;
      width: 100%;
      position: absolute;
      background-color: ${({ theme: { colors } }) => colors?.primary};
    }

    @keyframes bouncingLoading {
      100% {
        left: 0;
      }

      50% {
        left: calc(100% - 48px);
      }

      0% {
        left: 0;
      }
    }
  }
`
