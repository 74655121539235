import { useCallback, useEffect, useMemo, useState } from 'react'
import Auth from '../../service/auth'

interface IUseLinkExpired {
  loading: boolean
  expired: boolean
}

export const useLinkExpired = (email: string): IUseLinkExpired => {
  const auth = useMemo(() => new Auth(), [])

  const [linkExpired, setLinkExpired] = useState({
    loading: true,
    expired: false
  })

  const getUserStatusByEmail = useCallback(async () => {
    try {
      await auth.getUserInfosByEmail(email)
      setLinkExpired({ loading: false, expired: false })
    } catch (error) {
      setLinkExpired({ loading: false, expired: true })
    }
  }, [email, auth])

  useEffect(() => {
    if (email) {
      getUserStatusByEmail()
    }
  }, [email, getUserStatusByEmail])
  return linkExpired
}
