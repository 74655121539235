import { ComponentType, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import LoadingPage from '../../../components/commons/LoadingPage'
import { useAuth } from '../../../hooks/useAuth'
import { AuthContext } from '../../../states/auth/AuthContext'

interface IProps {
  BuyerComponent: ComponentType
  CustomerComponent: ComponentType
}

const CommonRoute = ({ BuyerComponent, CustomerComponent }: IProps) => {
  const { loading, loggedIn, groups } = useAuth()
  const { isLoggingOut } = useContext(AuthContext)

  if (loading || isLoggingOut) return <LoadingPage />

  if (!loggedIn) return <Navigate to="/" />

  if (groups?.[0] === 'Cedente') return <CustomerComponent />

  if (groups?.[0] === 'Financiador') return <BuyerComponent />

  return <Navigate to="/" />
}

export default CommonRoute
