import { ReactNode, useCallback, useReducer } from 'react'
import {
  useGetExtractLazyQuery,
  useGetOwnershipReceiptLazyQuery,
  useGetBusinessBankAccountsLazyQuery,
  GetBusinessBankAccountsInput,
  ExtractInput
} from '../../service/graphql/schema'
import { downloadBase64File } from '../../utils/file'
import { BankingContext, initialState } from './BankingContext'
import BankingReducer from './BankingReducer'
import { handleGraphQLError } from '../../utils/error'

interface IProps {
  children: ReactNode
}

const BankingState: React.FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(BankingReducer, initialState)

  const [getExtractQuery] = useGetExtractLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ getExtract: getExtractCompleted }) => {
      dispatch({
        type: 'GET_EXTRACT_SUCCESS',
        payload: getExtractCompleted
      })
    },
    onError: (error) => {
      dispatch({
        type: 'GET_EXTRACT_ERROR',
        payload: {
          location: 'getExtract',
          message: error?.graphQLErrors[0]?.message
        }
      })
    }
  })

  const getExtract = useCallback(
    (params: ExtractInput) => {
      dispatch({
        type: 'GET_EXTRACT'
      })

      getExtractQuery({
        variables: {
          params
        }
      })
    },
    [getExtractQuery]
  )

  const [getOwnershipReceiptQuery] = useGetOwnershipReceiptLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ getOwnershipReceipt }) => {
      downloadBase64File(getOwnershipReceipt as string, 'Comprovante-de-Titularidade.pdf')
      dispatch({
        type: 'GET_OWNERSHIP_RECEIPT_SUCCESS'
      })
    },
    onError: (error) => {
      dispatch({
        type: 'GET_OWNERSHIP_RECEIPT_ERROR',
        payload: {
          location: 'getOwnershipReceipt',
          message: error?.graphQLErrors[0]?.message
        }
      })
    }
  })

  const downloadReceipt = useCallback(
    (agency: string, account: string) => {
      dispatch({
        type: 'GET_OWNERSHIP_RECEIPT'
      })

      getOwnershipReceiptQuery({
        variables: {
          agency,
          account
        }
      })
    },
    [getOwnershipReceiptQuery]
  )

  const [getBusinessBankAccountsQuery] = useGetBusinessBankAccountsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getBusinessBankAccounts: getBusinessBankAccountsCompleted }) => {
      dispatch({
        type: 'GET_BUSINESS_BANK_ACCOUNTS_SUCCESS',
        payload: getBusinessBankAccountsCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_BUSINESS_BANK_ACCOUNTS_ERROR', 'getBusinessBankAccounts')

      dispatch(action)
    }
  })

  const getBusinessBankAccounts = useCallback(
    (params: GetBusinessBankAccountsInput) => {
      dispatch({
        type: 'GET_BUSINESS_BANK_ACCOUNTS'
      })
      getBusinessBankAccountsQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getBusinessBankAccountsQuery]
  )

  const contextValues = {
    ...state,
    getExtract,
    downloadReceipt,
    getBusinessBankAccounts
  }

  return <BankingContext.Provider value={contextValues}>{children}</BankingContext.Provider>
}

export default BankingState
