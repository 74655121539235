import styled from 'styled-components'
import SecondaryButton from '../../buttons/SecondaryButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useNavigate } from 'react-router-dom'

interface IProps {
  className?: string
  header?: string
  descriptionText?: string[]
  buttonText?: string
  buttonNavigateUrl?: string
  notShowWelcomeMessage?: boolean
  link?: {
    href: string
    text: string
  }
  buttonDisabled?: boolean
}

const CompanyWelcomeForm: React.FC<IProps> = ({
  className,
  header,
  descriptionText,
  buttonText,
  buttonNavigateUrl,
  notShowWelcomeMessage,
  link,
  buttonDisabled
}: IProps) => {
  const navigate = useNavigate()

  return (
    <div className={className}>
      {!notShowWelcomeMessage && (
        <div data-testid="company-welcome-form-description-test">
          <h4 className="company-welcome-form__description___header">{header}</h4>
          {descriptionText?.map((p, index) => <p key={index}>{p}</p>)}
          {link && (
            <a href={link?.href} target="_blank" rel="noreferrer">
              {link?.text}
            </a>
          )}
        </div>
      )}
      {buttonNavigateUrl && buttonText && (
        <SecondaryButton
          onClick={() => navigate(buttonNavigateUrl)}
          className="company-welcome-form__secondary___button"
          data-testid="company-welcome-form-button-test"
          startIcon={<AddCircleOutlineIcon />}
          disabled={buttonDisabled}
        >
          {buttonText}
        </SecondaryButton>
      )}
    </div>
  )
}

export default styled(CompanyWelcomeForm)`
  @media (max-width: 500px) {
    text-align: center;
  }
  .company-welcome-form__description___header {
    color: ${({ theme }) => theme?.colors?.secondaryFont};
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    color: ${({ theme }) => theme?.colors?.tertiaryFont};
    font-size: 14px;
    line-height: 24px;
    max-width: 500px;
  }

  a {
    color: ${({ theme }) => theme?.colors?.primary};
    font-size: 14px;
  }

  .company-welcome-form__secondary___button {
    margin-top: 40px;
  }
`
