import styled from 'styled-components'
import { ReactNode, useContext } from 'react'
import { OriginContext } from '../../../states/origin/OriginContext'

interface IProps {
  className?: string
  children: ReactNode
}

const NormalFlowPageWrapper: React.FC<IProps> = ({ className, children }: IProps) => {
  const { originInfos } = useContext(OriginContext)

  return (
    <section className={className}>
      <div className="normal-flow-page-wrapper__form--wrapper">
        <img src={originInfos?.logoMain} alt={`${originInfos?.storeName} logo`} />
        {children}
      </div>
    </section>
  )
}

export default styled(NormalFlowPageWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 16px;

  .normal-flow-page-wrapper__form--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    gap: 24px;

    img {
      max-height: 56px;
    }
  }
`
