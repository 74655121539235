import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'
import Maintenance from '../../assets/images/Maintenance.png'
import { OriginContext } from '../../states/origin/OriginContext'

interface IProps {
  className?: string
}

const MaintenancePage = ({ className }: IProps) => {
  const { originInfos } = useContext(OriginContext)

  return (
    <div className={className}>
      <header>
        <img className="logo-svg" height={32} alt={`${originInfos?.storeName} Logo`} src={originInfos?.logoMain} />
      </header>
      <Box className="loading-content">
        <img className="maintenance-img" alt="maintenance" src={Maintenance} />
        <CircularProgress className="loading-icon" />
        <div className="maintenance-message">
          Estamos em manutenção.
          <br /> Voltaremos em breve!
        </div>
      </Box>
    </div>
  )
}

export default styled(MaintenancePage)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};

  header {
    width: 100%;
    box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
    min-height: 56px;

    img {
      padding: 12px 0px 0px 12px;
    }
  }

  .maintenance-img {
    height: 120px;
    width: 120px;
  }

  .loading-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);
    justify-content: center;

    .loading-icon {
      margin: 20px 0px;
    }
  }

  .maintenance-message {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`
