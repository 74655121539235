import { createContext } from 'react'
import { IBankingState } from '../../types/banking'

const initialState: IBankingState = {
  downloadReceipt: () => undefined,
  getExtract: () => undefined,
  getBusinessBankAccounts: () => undefined,
  isGettingExtract: false,
  isGettingOwnershipReceipt: false,
  isGettingBusinessBankAccounts: false,
  extract: {
    transactions: [],
    records: 0,
    totalCount: 0
  },
  error: {
    location: '',
    message: ''
  },
  businessBankAccounts: { accounts: [] }
}

const BankingContext = createContext(initialState)

export { BankingContext, initialState }
