import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const Download: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...rest}>
      <path
        id="_223ed637876b0981285cd3161e3297dd"
        data-name="223ed637876b0981285cd3161e3297dd"
        d="M19.1,12.8a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,1-.9.9H4.7a.9.9,0,0,1-.9-.9V13.7a.9.9,0,1,0-1.8,0v3.6A2.7,2.7,0,0,0,4.7,20H17.3A2.7,2.7,0,0,0,20,17.3V13.7A.9.9,0,0,0,19.1,12.8Zm-8.739,1.539a.9.9,0,0,0,.3.189.846.846,0,0,0,.684,0,.9.9,0,0,0,.3-.189l3.6-3.6a.9.9,0,0,0-1.278-1.278L11.9,11.531V2.9a.9.9,0,1,0-1.8,0v8.631L8.039,9.461a.9.9,0,1,0-1.278,1.278Z"
        transform="translate(-2 -2)"
      />
    </svg>
  )
}

export default styled(Download)``
