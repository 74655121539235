import { ReactNode } from 'react'
import styled from 'styled-components'

export interface IInputContainerProps {
  className?: string
  children: ReactNode
}

const InputContainer: React.FC<IInputContainerProps> = ({ className, children }: IInputContainerProps) => {
  return <div className={className}>{children}</div>
}

export default styled(InputContainer)`
  height: 80px;
  width: 100%;
`
