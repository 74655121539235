/* eslint-disable no-useless-escape */
import { IAuthenticatedPayload, IAuthError, INewPasswordResponse, ISignInResponse } from '../../types/auth'
import { errorMessages } from '../../utils/auth/errorMessages'
import { ENVIRONMENT, GRAPHQL_ENDPOINT, URL_AUTH } from '../../utils/envs'
import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider'
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies'
import { generateBase64Token } from '../../utils/crypto'
import axios from 'axios'

export default class Auth {
  async getAuthToken(): Promise<string | IAuthError> {
    const response = await axios?.post(
      GRAPHQL_ENDPOINT,
      {
        query: `
          query getAuthToken {
            getAuthToken
          }
        `
      },
      {
        headers: {
          Authorization: `Basic ${generateBase64Token('', getCookie('ApplicationToken'))}`
        }
      }
    )
    return response.data.data.getAuthToken
  }

  async signIn(username: string, password: string): Promise<ISignInResponse | IAuthError> {
    try {
      const response = await axios?.post(`${URL_AUTH}/auth`, {
        username,
        password
      })

      const token = response?.data?.user?.idToken?.jwtToken

      const userGroup = response?.data?.userAttributes?.groups[0]
      if (userGroup === 'Administrador') {
        setCookie(`UserType_${ENVIRONMENT}`, 'admin', 365)
      } else if (userGroup === 'Financiador') {
        setCookie(`UserType_${ENVIRONMENT}`, 'buyer', 365)
      } else if (userGroup === 'Cedente') {
        setCookie(`UserType_${ENVIRONMENT}`, 'seller', 365)
      } else {
        deleteCookie(`UserType_${ENVIRONMENT}`)
      }

      setCookie('ApplicationToken', token, 365)

      return response?.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
        const errorMessage = errorMessages?.login[error.response.data?.message] || 'Erro de autenticação'
        throw new Error(errorMessage)
      } else {
        throw new Error('Erro ao processar a solicitação')
      }
    }
  }

  async forgotPassword(username: string): Promise<void | IAuthError> {
    return new Promise((resolve, reject) => {
      axios
        ?.post(`${URL_AUTH}/recovery-pass`, {
          username
        })
        ?.then(() => {
          resolve()
        })
        .catch((error) => {
          const message = error?.message as string
          return reject({
            event: 'forgotPassword',
            message: errorMessages?.forgotPassword[message]
          })
        })
    })
  }

  newPassword(data: {
    oldPassword: string
    newPassword: string
    username: string
  }): Promise<INewPasswordResponse | IAuthError> {
    return new Promise((resolve, reject) => {
      axios
        ?.post(`${URL_AUTH}/complete-new-pass`, data)
        ?.then(async () => {
          const authResponse = await axios?.post(`${URL_AUTH}/auth`, {
            username: data.username,
            password: data.newPassword
          })

          const token = authResponse?.data?.user?.idToken?.jwtToken

          setCookie('ApplicationToken', token, 365)

          resolve({
            userAttributes: {
              noSqlId: authResponse?.data?.userAttributes?.noSqlId,
              groups: authResponse?.data?.userAttributes?.groups
            }
          })
        })
        .catch((error) => {
          const message = error?.message as string
          return reject({
            event: 'newPassword',
            message: errorMessages?.newPassword[message]
          })
        })
    })
  }

  getUserInfosByEmail(email: string): Promise<UserType | undefined> {
    return new Promise((resolve, reject) => {
      axios
        ?.get(`${URL_AUTH}/get-user-status/${encodeURIComponent(email)}`)
        .then(({ data }: { data: UserType }) => {
          resolve(data)
        })
        .catch((error) => {
          const message = error?.message as string
          return reject({
            event: 'getUserInfos',
            message: errorMessages?.getUserInfos[message]
          })
        })
    })
  }

  resetPassword(email: string, code: string, password: string): Promise<void | IAuthError> {
    return new Promise((resolve, reject) => {
      axios
        ?.post(`${URL_AUTH}/confirm-recovery-pass`, {
          username: email,
          code,
          password
        })
        ?.then(() => {
          resolve()
        })
        .catch((error) => {
          const message = error?.message as string
          return reject({
            event: 'resetPassword',
            message: errorMessages?.resetPassword[message]
          })
        })
    })
  }

  async currentAuthenticatedUser(): Promise<IAuthenticatedPayload> {
    const applicationToken = getCookie('ApplicationToken')
    const user = (
      await axios.get(`${URL_AUTH}/get-user`, {
        headers: {
          Authorization: `Bearer ${applicationToken}`
        }
      })
    ).data
    const userAttributes = user.UserAttributes.reduce(
      (acc: Record<string, string>, { Name, Value }: { Name: string; Value: string }) => ({ ...acc, [Name]: Value }),
      {}
    )

    return new Promise((resolve, reject) => {
      const rejectPayload = {
        isAuthenticated: false,
        accessToken: undefined,
        accessTokenGraphql: undefined,
        groups: [],
        userId: undefined
      }

      const resolvePayload = (token: string, graphqlToken: string) => ({
        isAuthenticated: true,
        accessToken: token,
        accessTokenGraphql: graphqlToken,
        groups: userAttributes['cognito:groups'],
        userId: userAttributes['custom:sql_id']
      })

      if (!user) reject(rejectPayload)

      if (!applicationToken) return reject(rejectPayload)

      this.getAuthToken()
        .then((newToken) => {
          setCookie('ApplicationTokenGraphql', newToken as string, 365)
          return resolve(resolvePayload(applicationToken, newToken as string))
        })
        .catch(() => reject(rejectPayload))
    })
  }

  async resendCreateUserEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        ?.post(`${URL_AUTH}/resend-create-email`, {
          username: email
        })
        ?.then(() => {
          resolve()
        })
        .catch((error) => {
          const message = error?.message as string
          return reject({
            event: 'resendCreateUserEmail',
            message: errorMessages?.resendCreateUserEmail[message]
          })
        })
    })
  }

  signOut(): Promise<void> {
    return new Promise((resolve) => {
      deleteCookie('ApplicationTokenGraphql')
      deleteCookie('ApplicationToken')
      resolve()
    })
  }
}
