import { createContext } from 'react'
interface ISnackBarContext {
  showSnackbar: (msg: string, sev: 'success' | 'info' | 'warning' | 'error') => void
}

const initialState: ISnackBarContext = {
  showSnackbar: () => undefined
}

export const SnackbarContext = createContext(initialState)
