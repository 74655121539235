import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordIcon = ({ errorMessage, showPassword }: { errorMessage?: string; showPassword: boolean }) => {
  if (showPassword) {
    return <Visibility color={errorMessage ? 'error' : 'primary'} data-testid="visibiliy-icon" />
  }

  return <VisibilityOff color={errorMessage ? 'error' : 'primary'} data-testid="visibiliy-off-icon" />
}

export default PasswordIcon
