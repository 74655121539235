import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface ICreateCompanyStepper {
  position: number
  name: string
  sendTo: string
}

interface IProps {
  className?: string
  steppers: ICreateCompanyStepper[]
  currentPosition: number
}

const CreateCompanyStepper: React.FC<IProps> = ({ className, steppers, currentPosition }: IProps) => {
  return (
    <div className={className}>
      {steppers.map((item) => (
        <Fragment key={item?.position}>
          {item?.position <= currentPosition && (
            <>
              <Link
                to={item?.sendTo}
                className={`create-company-stepper ${item?.position === currentPosition && 'selected'}`}
                data-testid="create-company-stepper-anchor-test"
              >
                {item?.name}
              </Link>
              {item?.position !== currentPosition && <p data-testid="slash-test">/</p>}
            </>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default styled(CreateCompanyStepper)`
  display: flex;
  gap: 8px;

  .create-company-stepper {
    text-decoration: none;

    &.selected {
      font-weight: bold;
    }
  }
`
