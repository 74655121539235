/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBankingState } from '../../types/banking'

interface BankingActions {
  type:
    | 'GET_EXTRACT'
    | 'GET_EXTRACT_SUCCESS'
    | 'GET_EXTRACT_ERROR'
    | 'GET_OWNERSHIP_RECEIPT'
    | 'GET_OWNERSHIP_RECEIPT_SUCCESS'
    | 'GET_OWNERSHIP_RECEIPT_ERROR'
    | 'GET_BUSINESS_BANK_ACCOUNTS'
    | 'GET_BUSINESS_BANK_ACCOUNTS_SUCCESS'
    | 'GET_BUSINESS_BANK_ACCOUNTS_ERROR'

  payload?: any
}

const actionHandler = (
  payload: any
): {
  [key: string]: Partial<IBankingState>
} => ({
  GET_EXTRACT: {
    error: {
      location: '',
      message: ''
    },
    isGettingExtract: true
  },
  GET_EXTRACT_SUCCESS: {
    isGettingExtract: false,
    extract: payload
  },
  GET_EXTRACT_ERROR: {
    isGettingExtract: false,
    error: payload
  },
  GET_OWNERSHIP_RECEIPT: {
    error: {
      location: '',
      message: ''
    },
    isGettingOwnershipReceipt: true
  },
  GET_OWNERSHIP_RECEIPT_SUCCESS: {
    isGettingOwnershipReceipt: false
  },
  GET_OWNERSHIP_RECEIPT_ERROR: {
    isGettingOwnershipReceipt: false,
    error: payload
  },
  GET_BUSINESS_BANK_ACCOUNTS: {
    isGettingBusinessBankAccounts: true
  },

  GET_BUSINESS_BANK_ACCOUNTS_SUCCESS: {
    isGettingBusinessBankAccounts: false,
    businessBankAccounts: payload
  },
  GET_BUSINESS_BANK_ACCOUNTS_ERROR: {
    isGettingBusinessBankAccounts: false,
    error: payload
  }
})

const BankingReducer = (state: IBankingState, action: BankingActions) => {
  const updateProperties = actionHandler(action.payload)[action.type]
  return { ...state, ...updateProperties }
}

export default BankingReducer
