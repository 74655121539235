import { useContext, useEffect } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import List from '../../components/crud/SplitCrud/List'
import { useAuth } from '../../hooks/useAuth'
import { CompanyContext } from '../../states/company/CompanyContext'
import { theme } from '../../styles/theme'
import { translateGroup } from '../../utils/auth'
import { cellphoneMask, phoneMask } from '../../utils/string'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CompanyPartners: React.FC<IProps> = ({ className }: IProps) => {
  const {
    companyPartners,
    defaultCompany,
    isGettingDefaultCompany,
    isGettingCompanyPartnersByCompanyId,
    getCompanyPartnersByCompanyId
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyPartnersByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getCompanyPartnersByCompanyId])

  const getTagColorAndStatus = (partnerType: '2' | '1' | string | number) => {
    if (partnerType === '1')
      return {
        status: 'Sócio',
        color: theme?.styleGuide?.support?.green
      }

    if (partnerType === '2')
      return {
        status: 'Procurador',
        color: theme?.styleGuide?.support?.blue
      }
  }

  const { groups } = useAuth()
  const group = translateGroup(groups?.[0])

  const listData = {
    basePath: '/company-partners',
    goToFormButtonLabel: group === 'Buyer' ? 'Adicionar representante' : 'Adicionar sócio ou procurador',
    presentation: {
      title:
        group === 'Buyer' ? 'Queremos conhecer seus representantes!' : 'Queremos conhecer seus sócios e procuradores!',
      description: [
        group === 'Buyer'
          ? 'Adicione aqui todos os dados necessários sobre os representantes da sua empresa. Eles são importantes para gente também!'
          : 'Adicione aqui todos os dados necessários sobre os sócios e procuradores da sua empresa. Eles são importantes para gente também!'
      ]
    },
    list: companyPartners?.map((companyPartner) => ({
      ...companyPartner,
      tag: getTagColorAndStatus(companyPartner?.typeId?.toString() as string),
      email: companyPartner?.email?.toString() || 'E-mail: -',
      mobile: cellphoneMask(companyPartner?.mobile?.toString()) || 'Celular: -',
      phone: phoneMask(companyPartner?.phone?.toString()) || 'Telefone: -'
    })),
    keysInCard: ['email', 'mobile', 'phone'],
    cardTitleKeys: ['fullName'],
    isLoading: isGettingCompanyPartnersByCompanyId || isGettingDefaultCompany,
    isDisabled: (defaultCompany?.statusId as string) !== '1'
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <List data={listData} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyPartners)
