import { Outlet, Route } from 'react-router-dom'
import CompanyBankAccountInfo from '../../../pages/CompanyBankAccountInfo'
import CompanyBankAccountInfoForm from '../../../pages/CompanyBankAccountInfoForm'
import CompanyCustomersAndSuppliers from '../../../pages/CompanyCustomersAndSuppliers'
import CompanyCustomersAndSuppliersForm from '../../../pages/CompanyCustomersAndSuppliersForm'
import CompanyDocuments from '../../../pages/CompanyDocuments'
import CompanyDocumentsForm from '../../../pages/CompanyDocumentsForm'
import CompanyPartners from '../../../pages/CompanyPartners'
import CompanyPartnersForm from '../../../pages/CompanyPartnersForm'
import CompanyRevenues from '../../../pages/CompanyRevenues'
import CompanyTaxes from '../../../pages/CompanyTaxes'
import CompanyUsers from '../../../pages/CompanyUsers'
import CompanyUsersForm from '../../../pages/CompanyUsersForm'
import CompanyWitnesses from '../../../pages/CompanyWitnesses'
import CompanyWitnessForm from '../../../pages/CompanyWitnessForm'
import CreateCompanyDocumentNumber from '../../../pages/CreateCompanyDocumentNumber'
import CreateCompanyProfile from '../../../pages/CreateCompanyProfile'
import DefaultError from '../../../pages/DefaultError'
import RegisterCompany from '../../../pages/RegisterCompany'
import Company from '../../../states/company/Company'
import CompanyState from '../../../states/company/CompanyState'
import PrivateRoute from '../../Rules/PrivateRoute'

export const PrivateRouterWrapper = () => (
  <PrivateRoute>
    <Outlet />
  </PrivateRoute>
)

export const PrivateRoutes = (
  <Route path="/" element={<PrivateRouterWrapper />}>
    <Route
      path="register-company"
      element={
        <CompanyState>
          <RegisterCompany />
        </CompanyState>
      }
    />
    <Route
      path="company-bank-account-info"
      element={
        <CompanyState>
          <CompanyBankAccountInfo />
        </CompanyState>
      }
    />
    <Route
      path="company-bank-account-info/form"
      element={
        <CompanyState>
          <CompanyBankAccountInfoForm />
        </CompanyState>
      }
    />
    <Route
      path="company-documents"
      element={
        <CompanyState>
          <CompanyDocuments />
        </CompanyState>
      }
    />
    <Route
      path="company-documents/form/:key"
      element={
        <CompanyState>
          <CompanyDocumentsForm />
        </CompanyState>
      }
    />
    <Route
      path="company-partners"
      element={
        <CompanyState>
          <CompanyPartners />
        </CompanyState>
      }
    />
    <Route
      path="company-partners/form"
      element={
        <CompanyState>
          <CompanyPartnersForm />
        </CompanyState>
      }
    />
    <Route
      path="company-partners/form/:id"
      element={
        <CompanyState>
          <CompanyPartnersForm />
        </CompanyState>
      }
    />
    <Route
      path="company-witnesses"
      element={
        <CompanyState>
          <CompanyWitnesses />
        </CompanyState>
      }
    />
    <Route
      path="company-witness/form"
      element={
        <CompanyState>
          <CompanyWitnessForm />
        </CompanyState>
      }
    />
    <Route
      path="company-witness/form/:id"
      element={
        <CompanyState>
          <CompanyWitnessForm />
        </CompanyState>
      }
    />
    <Route
      path="company-taxes"
      element={
        <CompanyState>
          <CompanyTaxes />
        </CompanyState>
      }
    />
    <Route
      path="company-users"
      element={
        <CompanyState>
          <CompanyUsers />
        </CompanyState>
      }
    />
    <Route
      path="company-users/form"
      element={
        <CompanyState>
          <CompanyUsersForm />
        </CompanyState>
      }
    />
    <Route
      path="company-users/form/:id"
      element={
        <CompanyState>
          <CompanyUsersForm />
        </CompanyState>
      }
    />
    <Route
      path="company-customers-and-suppliers"
      element={
        <CompanyState>
          <CompanyCustomersAndSuppliers />
        </CompanyState>
      }
    />
    <Route
      path="company-customers-and-suppliers/form"
      element={
        <CompanyState>
          <CompanyCustomersAndSuppliersForm />
        </CompanyState>
      }
    />
    <Route
      path="company-customers-and-suppliers/form/:id"
      element={
        <CompanyState>
          <CompanyCustomersAndSuppliersForm />
        </CompanyState>
      }
    />
    <Route
      path="company-revenues"
      element={
        <CompanyState>
          <CompanyRevenues />
        </CompanyState>
      }
    />
    <Route path="create-company" element={<Company />}>
      <Route path="document-number" element={<CreateCompanyDocumentNumber />} />
      <Route path="profile" element={<CreateCompanyProfile />} />
    </Route>
    <Route path="error-page" element={<DefaultError />} />
  </Route>
)
