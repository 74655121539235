import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
  align?: 'left' | 'right' | 'center'
}

const TabelCellSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className} data-testid="table-cell-skeleton-test">
      <Skeleton width="60%" height="100%" animation="wave" />
    </div>
  )
}

export default styled(TabelCellSkeleton)`
  display: flex;
  width: 100%;
  justify-content: ${({ align }) => {
    if (align === 'left') {
      return 'flex-start'
    } else if (align === 'center') {
      return 'center'
    } else {
      return 'flex-end'
    }
  }};
`
