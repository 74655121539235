import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { ReactNode, useState } from 'react'
import { SnackbarContext } from './SnackBarContext'

interface IProps {
  children: ReactNode
}

const SnackbarState: React.FC<IProps> = ({ children }: IProps) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('success')

  const showSnackbar = (msg: string, sev: 'success' | 'info' | 'warning' | 'error') => {
    setMessage(msg)
    setSeverity(sev)
    setOpen(true)
  }

  const hideSnackbar = () => {
    setOpen(false)
  }

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={hideSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{
          zIndex: 7000
        }}
      >
        <MuiAlert
          onClose={hideSnackbar}
          severity={severity}
          variant="filled"
          style={{
            minWidth: '312px'
          }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export default SnackbarState
