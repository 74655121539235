import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import ChevronLeft from './ChevronLeft'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const ChevronLeftDouble: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <div className={className} {...rest}>
      <ChevronLeft />
      <ChevronLeft />
    </div>
  )
}

export default styled(ChevronLeftDouble)`
  display: flex;
  align-items: center;
`
