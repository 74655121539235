import * as yup from 'yup'
import { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import { IInput } from '../../components/crud/SplitCrud/CreateUpdateDelete'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'

export const defineType = (inputType: HTMLInputTypeAttribute | undefined, showPassword: boolean) => {
  if (inputType === 'password' && showPassword) {
    return 'text'
  } else if (inputType === 'password' && !showPassword) {
    return inputType
  } else {
    return inputType
  }
}

export const createSchema = (inputs: IInput[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schemaObj: any = {}

  inputs?.forEach((input) => {
    schemaObj[input?.name] = yup.string()

    if (input?.required) {
      schemaObj[input?.name] = schemaObj[input?.name].nullable().required('Esse campo precisa ser preenchido')
    }

    if (input?.notRequired) {
      schemaObj[input?.name] = schemaObj[input?.name]?.nullable()
    }

    if (input?.type === 'currency' || input?.type === 'percentage' || input?.type === 'number') {
      schemaObj[input?.name] = schemaObj[input?.name]?.nullable()
    }

    if (input?.type === 'email') {
      schemaObj[input?.name] = schemaObj[input?.name].email('Confira se seu email está correto').nullable()
    }

    if (input?.test) {
      schemaObj[input?.name] = schemaObj[input?.name]
        .test(input?.test?.type, input?.test?.message, input?.test?.callback)
        .nullable()
    }
  })

  return yup.object().shape(schemaObj)
}

export const handleCheckboxChange = (
  event: ChangeEvent<HTMLInputElement>,
  checked: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<FieldValues, any>
) => {
  if (checked) {
    field.onChange([...field.value, event.target.value])
  } else {
    field.onChange(field.value?.filter((value: string) => value !== event.target.value))
  }
}
