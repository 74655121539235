import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const ChevronLeft: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="8" height="16" viewBox="0 0 8 16" {...rest}>
      <path
        id="Icon_ionic-ios-arrow-back"
        data-name="Icon ionic-ios-arrow-back"
        d="M2.412,8l5.295,6.049a1.257,1.257,0,0,1,0,1.615.918.918,0,0,1-1.416,0l-6-6.854A1.262,1.262,0,0,1,.262,7.235L6.286.333A.919.919,0,0,1,7.7.333a1.257,1.257,0,0,1,0,1.615Z"
      />
    </svg>
  )
}

export default styled(ChevronLeft)`
  fill: ${({ theme: { colors } }) => colors?.primary};
`
