import { createContext } from 'react'
import { IAuthState } from '../../types/auth'

const initialState: IAuthState = {
  login: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  newPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  resendCreateUserEmail: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  isSendingEmailForgotPassword: false,
  isLoggingIn: false,
  isSavingNewPassword: false,
  isResetingPassword: false,
  isResetingCreateUserEmail: false,
  isLoggingOut: false,
  isResentCreateUserEmail: false,
  resetSuccess: false,
  authError: { message: '' },
  temporaryUser: undefined
}

const AuthContext = createContext<IAuthState>(initialState)
export { AuthContext, initialState }
