import { Environment } from './enum'

export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:3000/develop/graphql'
export const WEB_SOCKET_ENDPOINT = process.env.REACT_APP_WEB_SOCKET_ENDPOINT || 'ws://localhost:3001'
export const JWT_SECRET_RESET_PASSWORD =
  process.env.REACT_APP_JWT_SECRET_RESET_PASSWORD || '698a6947-8369-4f71-bac0-0043c7643e46'
export const GOOGLE_RECAPTCHA = process.env.REACT_APP_GOOGLE_RECAPTCHA || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3333'
export const REDIRECT_V1_URLS = process.env.REACT_APP_REDIRECT_V1_URLS || 'http://localhost:3004'
export const V1_URL = process.env.REACT_APP_V1_URL || 'https://api-hmg.antecipafacil.com.br'
export const D4_SIGN_BLOB_URL = process.env.REACT_APP_D4_SIGN_BLOB_URL || 'https://sandbox.d4sign.com.br/desk/viewblob'
export const REDIRECT_V1_URL =
  process.env.REACT_APP_REDIRECT_V1_URL || 'https://frontend-v1-stg.aws.antecipafacil.com.br'
export const REDIRECT_V1_URL_PROD = process.env.REACT_APP_REDIRECT_V1_URL_PROD || 'https://app.antecipafacil.com.br/'
export const ENVIRONMENT = process.env.REACT_APP_ENV || Environment.DEVELOPMENT
export const POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com'
export const POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY
export const URL_AUTH = process.env.REACT_APP_URL_AUTH as string
