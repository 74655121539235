import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import ChevronRight from './ChevronRight'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const ChevronRightDouble: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <div className={className} {...rest}>
      <ChevronRight />
      <ChevronRight />
    </div>
  )
}

export default styled(ChevronRightDouble)`
  display: flex;
  align-items: center;
`
