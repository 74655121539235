import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const Plus: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...rest}>
      <path
        id="Caminho 5315"
        d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9974 5.87908 15.1537 3.84579 13.6539 2.34607C12.1542 0.846352 10.1209 0.00264619 8 0ZM8 14.874C6.64045 14.874 5.31144 14.4708 4.18101 13.7155C3.05059 12.9602 2.16953 11.8866 1.64925 10.6306C1.12898 9.37451 0.992849 7.99237 1.25808 6.65895C1.52332 5.32552 2.17801 4.1007 3.13935 3.13935C4.1007 2.178 5.32553 1.52332 6.65895 1.25808C7.99238 0.992847 9.37451 1.12898 10.6306 1.64925C11.8866 2.16953 12.9602 3.05059 13.7155 4.18101C14.4709 5.31143 14.874 6.64045 14.874 8C14.8714 9.82229 14.1463 11.5692 12.8577 12.8577C11.5692 14.1463 9.82229 14.8714 8 14.874Z"
      />
      <path
        id="Caminho 5316"
        d="M11.6839 7.36611H8.56388V4.24611C8.56388 4.09679 8.50457 3.95359 8.39898 3.84801C8.2934 3.74242 8.1502 3.68311 8.00088 3.68311C7.85156 3.68311 7.70836 3.74242 7.60278 3.84801C7.4972 3.95359 7.43788 4.09679 7.43788 4.24611V7.36611H4.31788C4.16857 7.36611 4.02536 7.42542 3.91978 7.53101C3.8142 7.63659 3.75488 7.77979 3.75488 7.92911C3.75488 8.07842 3.8142 8.22163 3.91978 8.32721C4.02536 8.43279 4.16857 8.49211 4.31788 8.49211H7.43788V11.6121C7.43788 11.7614 7.4972 11.9046 7.60278 12.0102C7.70836 12.1158 7.85156 12.1751 8.00088 12.1751C8.1502 12.1751 8.2934 12.1158 8.39898 12.0102C8.50457 11.9046 8.56388 11.7614 8.56388 11.6121V8.49211H11.6839C11.8332 8.49211 11.9764 8.43279 12.082 8.32721C12.1876 8.22163 12.2469 8.07842 12.2469 7.92911C12.2469 7.77979 12.1876 7.63659 12.082 7.53101C11.9764 7.42542 11.8332 7.36611 11.6839 7.36611Z"
      />
    </svg>
  )
}

export default styled(Plus)``
