import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyContext } from '../../states/company/CompanyContext'
import CreateDelete, { ICreateDeleteData, IDocument } from '../../components/crud/PrimaryFileSplitCrud/CreateDelete'
import { getRequiredDocumentsByKey } from '../../helpers/companyDocuments'
import { formatDateTimeToDDMMYYYYHHMMSS } from '../../utils/datetime'
import WithHeader from '../../hocs/WithHeader'

const CompanyDocumentsForm: React.FC = () => {
  const {
    defaultCompany,
    isGettingCompanyDocumentsByCompanyId,
    isGettingDefaultCompany,
    companyDocuments,
    getCompanyDocumentsByCompanyId,
    deleteCompanyDocument,
    uploadCompanyDocument,
    isUploadingCompanyDocument,
    isDeletingCompanyDocument
  } = useContext(CompanyContext)

  const { key } = useParams()

  useEffect(() => {
    if (defaultCompany?.businessId && key) {
      getCompanyDocumentsByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, key, getCompanyDocumentsByCompanyId])

  const documentInfos = [
    { key: 'constitution', documentTypeId: '1', originTypeId: '1', documentTypeName: 'Documentos de Constituição' },
    {
      key: 'business-address',
      documentTypeId: '4',
      originTypeId: '1',
      documentTypeName: 'Comprovante de endereço da sede'
    },
    {
      key: 'group-declaration',
      documentTypeId: '11',
      originTypeId: '1',
      documentTypeName: 'Declaração simplificada da junta'
    },
    {
      key: 'identification',
      documentTypeId: '15',
      originTypeId: '1',
      documentTypeName: 'Documento de identificação'
    },
    {
      key: 'partner-address',
      documentTypeId: '7',
      originTypeId: '1',
      documentTypeName: 'Comprovante de endereço'
    },
    {
      key: 'proxy',
      documentTypeId: '12',
      originTypeId: '1',
      documentTypeName: 'Documento de Procuração'
    },
    {
      key: 'revenue',
      documentTypeId: '5',
      originTypeId: '1',
      documentTypeName: 'Declaração de faturamento'
    },
    {
      key: 'extract',
      documentTypeId: '13',
      originTypeId: '1',
      documentTypeName: 'Extrato bancário'
    }
  ]

  const documentList = () => {
    const documents = companyDocuments?.filter(
      (companyDocument) =>
        companyDocument?.documentTypeId ===
        documentInfos?.find((documentInfo) => documentInfo?.key === key)?.documentTypeId
    )

    return documents?.reduce((acc: IDocument[], document) => {
      if (document?.statusId !== '6') {
        acc?.push({
          id: document?.id as string,
          fileName: document?.name as string,
          downloadUrl: document?.downloadUrl as string,
          createdAt: formatDateTimeToDDMMYYYYHHMMSS(new Date(document?.lastModifiedDate as string))
        })
      }

      return acc
    }, [])
  }

  const documentTypeId = documentInfos?.find((documentInfo) => documentInfo?.key === key)?.documentTypeId
  const documentTypeName = documentInfos?.find((documentInfo) => documentInfo?.key === key)?.documentTypeName

  const formData: ICreateDeleteData = {
    breadCrumb: 'Dados cadastrais / Documentos',
    title: documentTypeName as string,
    backTo: '/company-documents',
    isLoading: isGettingCompanyDocumentsByCompanyId || isGettingDefaultCompany || isUploadingCompanyDocument,
    documentTypes: getRequiredDocumentsByKey(key as string),
    documentList: documentList(),
    deleteDocument: deleteCompanyDocument,
    isDeletingDocument: isDeletingCompanyDocument,
    uploadDocument: (file: File) => {
      uploadCompanyDocument({
        companyId: defaultCompany?.businessId as string,
        documentTypeId: documentTypeId as string,
        documentTypeName: documentTypeName as string,
        file
      })
    },
    formDisabled: (defaultCompany?.statusId as string) !== '1' && (defaultCompany?.statusId as string) !== '3'
  }

  return <CreateDelete formData={formData} />
}

export default WithHeader()(CompanyDocumentsForm)
