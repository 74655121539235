export const setAppTab = (title: string, favIcon: string): void => {
  const appTitle = document.getElementById('app-title') as HTMLTitleElement
  const appFavIcon = document.getElementById('app-fav-icon') as HTMLLinkElement
  const appShotcutIcon = document.getElementById('app-shortcut-icon') as HTMLLinkElement
  const appAppleTouchIcon = document.getElementById('app-apple-touch-icon') as HTMLLinkElement
  const appDescription = document.getElementById('description') as HTMLMetaElement

  appTitle.innerHTML = title
  appFavIcon.href = favIcon
  appShotcutIcon.href = favIcon
  appAppleTouchIcon.href = favIcon
  appDescription.content = title
}
