import { useContext } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../../states/company/CompanyContext'

interface IProps {
  className?: string
}

const ResponsiblesSection: React.FC<IProps> = ({ className }: IProps) => {
  const { customerData } = useContext(CompanyContext)

  const responsiblesTypeMap = {
    '1': 'Sócio',
    '2': 'Procurador'
  } as const

  return (
    <div data-testid="responsibles-section-test" className={className}>
      <p className="responsibles-section__main-title">Representantes</p>
      {customerData.responsibles && customerData.responsibles.length === 0 ? (
        <div className="responsibles-section__without-responsible-container">Nenhum dado disponível</div>
      ) : (
        <div className="responsibles-section__responsible-container">
          {customerData?.responsibles?.map(
            (responsible, index) =>
              responsible && (
                <div
                  data-testid="responsibles-item-test"
                  className="responsibles-section__responsible-item-wrapper"
                  key={index}
                >
                  <div className="responsibles-section__responsible-ecpf-wrapper">
                    {responsible.hasEcpf && <div>eCPF</div>}
                  </div>
                  <div className="responsibles-section__responsible-data-label-wrapper">
                    <div>{responsiblesTypeMap[responsible.type as keyof typeof responsiblesTypeMap]}</div>
                    <div>CPF</div>
                  </div>
                  <div className="responsibles-section__responsible-data-info-wrapper">
                    <div>{responsible.name} </div>
                    <div>{responsible.document}</div>
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  )
}

export default styled(ResponsiblesSection)`
  text-align: left !important;

  .responsibles-section__main-title {
    padding: 36px 0px 18px;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  .responsibles-section__responsible-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    @media (max-width: 650px) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  .responsibles-section__without-responsible-container {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
  }

  .responsibles-section__responsible-item-wrapper {
    display: flex;
  }
  .responsibles-section__responsible-ecpf-wrapper {
    width: 48px;
    div {
      padding: 2px 4px;
      border-radius: 100px;
      display: inline-block;
      font-size: 8px;
      border: 1px solid;
      border-color: ${({ theme: { styleGuide } }) => styleGuide?.support.blue};
      color: ${({ theme: { styleGuide } }) => styleGuide?.support.blue};
    }
  }
  .responsibles-section__responsible-data-label-wrapper {
    width: 93px;
    div {
      color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
      font-weight: 700 !important;
      font-size: 14px;
    }
  }

  .responsibles-section__responsible-data-info-wrapper {
    div {
      color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
  }

  @media (max-width: 810px) {
    .responsibles-section__responsible-item-wrapper {
      max-width: unset;
      width: 100%;
    }
  }
`
