/* eslint-disable @typescript-eslint/no-explicit-any */

import { IOriginState } from '../../types/origin'

interface OriginActions {
  type: 'GET_ORIGIN_INFOS' | 'GET_ORIGIN_INFOS_SUCCESS' | 'GET_ORIGIN_INFOS_ERROR' | 'SET_THEME'
  payload?: any
}

const actionHandler = (
  payload: any
): {
  [key: string]: Partial<IOriginState>
} => ({
  GET_ORIGIN_INFOS: {
    isGettingOriginInfos: true
  },
  GET_ORIGIN_INFOS_SUCCESS: {
    isGettingOriginInfos: false,
    originInfos: payload
  },
  GET_ORIGIN_INFOS_ERROR: {
    isGettingOriginInfos: false,
    error: payload
  },
  SET_THEME: {
    dynamicTheme: payload
  }
})

const OriginReducer = (state: IOriginState, action: OriginActions) => {
  const updateProperties = actionHandler(action.payload)[action.type]
  return { ...state, ...updateProperties }
}

export default OriginReducer
