import { useContext, useEffect, useMemo, useState } from 'react'
import { CompanyContext } from '../../states/company/CompanyContext'
import CreateUpdateDelete, { IFormData } from '../../components/crud/SplitCrud/CreateUpdateDelete'
import WithHeader from '../../hocs/WithHeader'
import notFav from '../../assets/icons/not-fav.svg'
import fav from '../../assets/icons/fav.svg'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { UpdateBankAccountInfoInput } from '../../service/graphql/schema'

const DefaultAccountButton = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 8px;
  align-self: self-start;
`

const CompanyBankAccountInfoForm: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location as { state: UpdateBankAccountInfoInput }
  const [defaultAccount, setDefaultAccount] = useState(state?.isDefault ?? false)

  const {
    defaultCompany,
    isGettingDefaultCompany,
    registerCompanyBankAccountInfo,
    isRegistratingBankAccountInfo,
    getCompanyBankAccountInfoByCompanyId,
    isGettingCompanyBankAccountInfoByCompanyId,
    companyBankAccountInfos,
    getBankAccounts,
    isGettingBankAccounts,
    bankAccounts,
    getBankAccountsPermissions,
    isGettingBankAccountsPermissions,
    bankAccountsPermissions,
    updateCompanyBankAccountInfo,
    isUpdatingBankAccountInfo,
    deleteCompanyBankAccountInfo,
    isDeletingCompanyBankAccountInfo
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyBankAccountInfoByCompanyId(defaultCompany?.businessId)
      getBankAccountsPermissions({ companyId: defaultCompany?.businessId })
      getBankAccounts()
    }
  }, [defaultCompany?.businessId, getCompanyBankAccountInfoByCompanyId, getBankAccountsPermissions, getBankAccounts])

  useEffect(() => {
    if (companyBankAccountInfos) {
      setDefaultAccount(companyBankAccountInfos.length === 0)
    }
  }, [companyBankAccountInfos])
  useEffect(() => {
    if (state && state?.isDefault) setDefaultAccount(state?.isDefault)
  }, [state])

  const formattedBankAccounts = useMemo(() => {
    return bankAccounts.reduce(
      (
        acc: {
          formattedOptions: Array<{ value: string; item: string }>
          formattedAccounts: Record<
            string,
            { name?: string | null; fullName?: string | null; ispb?: string | null; code?: number | null }
          >
        },
        bankAccount
      ) => {
        if ((bankAccount.name || bankAccount.fullName) && bankAccount.code) {
          acc.formattedOptions.push({
            value: String(bankAccount.code),
            item: bankAccount.code
              ? `${bankAccount.code} - ${bankAccount.name ?? bankAccount.fullName}`
              : String(bankAccount.name) ?? bankAccount.fullName
          })
          acc.formattedAccounts[bankAccount.code] = bankAccount
        }
        return acc
      },
      { formattedOptions: [], formattedAccounts: {} }
    )
  }, [bankAccounts])

  const initialPage = '/company-bank-account-info'
  const formData: IFormData = {
    breadCrumb: 'Dados cadastrais / Dados bancários',
    isLoading:
      isGettingDefaultCompany ||
      isGettingCompanyBankAccountInfoByCompanyId ||
      isGettingBankAccounts ||
      isGettingBankAccountsPermissions,
    title: 'Adicionar conta de livre movimentação',
    subtitle: 'Preencha os campos abaixo para adicionar uma conta de livre movimentação.',
    backTo: initialPage,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editData: (data: any) => {
      const choosedBankAccount = formattedBankAccounts.formattedAccounts[data.bankCode]
      updateCompanyBankAccountInfo({
        ...data,
        id: state.id,
        companyId: defaultCompany?.businessId,
        bankName: choosedBankAccount.name ?? choosedBankAccount.fullName,
        ispb: choosedBankAccount.ispb,
        isDefault: defaultAccount
      })
      navigate(initialPage)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveData: (data: any) => {
      const choosedBankAccount = formattedBankAccounts.formattedAccounts[data.bankCode]
      registerCompanyBankAccountInfo({
        ...data,
        companyId: defaultCompany?.businessId,
        bankName: choosedBankAccount.name ?? choosedBankAccount.fullName,
        ispb: choosedBankAccount.ispb,
        isDefault: defaultAccount
      })
    },
    currentData: state,
    isEditingData: isUpdatingBankAccountInfo,
    formDisabled: state ? !bankAccountsPermissions.allowUpdate : !bankAccountsPermissions.allowCreate,
    isSavingData: isRegistratingBankAccountInfo,
    isDeletingData: isDeletingCompanyBankAccountInfo,
    deleteData: state
      ? () => {
          deleteCompanyBankAccountInfo(state.id, defaultCompany?.businessId as string)
        }
      : undefined,
    groups: [
      {
        rows: [
          {
            inputs: [
              {
                label: 'Banco',
                name: 'bankCode',
                required: true,
                placeholder: 'Buscar seu banco',
                maxLength: 10,
                type: 'autoComplete',
                selectOptions: formattedBankAccounts.formattedOptions.sort((a, b) => {
                  return parseInt(a.value, 10) - parseInt(b.value, 10)
                })
              },
              {
                label: 'Agência sem dígito',
                name: 'accountAgency',
                required: true,
                onlyNumber: true,
                maxLength: 10
              },
              {
                label: 'Número da conta',
                name: 'accountNumber',
                required: true,
                onlyNumber: true,
                maxLength: 10
              },
              {
                label: 'Dígito',
                name: 'accountDigit',
                required: true,
                onlyNumber: true,
                maxLength: 2
              }
            ],
            grid: [5, 2, 2, 1]
          }
        ]
      }
    ]
  }

  return (
    <CreateUpdateDelete
      additionalButton={
        <DefaultAccountButton
          onClick={() => {
            if (companyBankAccountInfos.length > 0 && (!state || !state.isDefault)) setDefaultAccount((prev) => !prev)
          }}
        >
          <p>Conta padrão</p>
          {defaultAccount ? <img src={fav} /> : <img src={notFav} />}
        </DefaultAccountButton>
      }
      formData={formData}
    />
  )
}

export default WithHeader()(CompanyBankAccountInfoForm)
