import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import CreateCompanyStepper from '../CreateCompanyStepper'

interface IProps {
  className?: string
  children: ReactNode
  title?: string
}

const CreateCompanyPageWrapper: React.FC<IProps> = ({ className, children, title }: IProps) => {
  const { pathname } = useLocation()

  const steppers = [
    {
      position: 1,
      name: 'Adicionar empresa',
      sendTo: '/create-company/document-number'
    },
    {
      position: 2,
      name: 'Definir perfil',
      sendTo: '/create-company/profile'
    }
  ]

  const findCurrentPosition = steppers?.find(({ sendTo }) => sendTo === pathname)

  return (
    <div className={className}>
      <header className="create-companies-page-wrapper__header">
        <div className="create-companies-page-wrapper__header___bread-crumb--wrapper">
          <CreateCompanyStepper steppers={steppers} currentPosition={findCurrentPosition?.position as number} />
        </div>
        <h2>{title}</h2>
      </header>
      <section className="create-companies-page-wrapper__body">{children}</section>
    </div>
  )
}

export default styled(CreateCompanyPageWrapper)`
  .create-companies-page-wrapper__header {
    background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
    padding: 24px 72px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h2 {
      color: ${({ theme }) => theme?.colors?.tertiaryFont};
      font-size: 32px;
    }

    .create-companies-page-wrapper__header___bread-crumb--wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .create-companies-page-wrapper__body {
    min-height: calc(100vh - 310px);
    background: ${({ theme }) => theme?.colors?.gray?.smooth};
    padding: 24px 72px;
  }

  @media (max-width: 700px) {
    .create-companies-page-wrapper__header {
      height: 100px;
      padding: 24px 16px;

      h2 {
        font-size: 26px;
      }
    }

    .create-companies-page-wrapper__body {
      min-height: calc(100vh - 258px);
      padding: 24px 16px;
    }
  }
`
