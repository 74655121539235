import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material'
import { forwardRef, Ref, useState } from 'react'
import styled from 'styled-components'
import { defineType } from '../../../utils/input'
import InputFormat from '../InputFormat'
import PasswordIcon from '../PasswordIcon'

export interface ISecondaryInputProps extends Omit<StandardTextFieldProps, 'variant'> {
  className?: string
  errorMessage?: string
  mask?: string
  ref?: Ref<HTMLInputElement>
  endAdornment?: React.ReactNode | string
  variant?: 'standard' | 'outlined'
}

const SecondaryInput = forwardRef<HTMLInputElement, ISecondaryInputProps>(
  ({ className, errorMessage, mask, endAdornment, variant = 'standard', ...rest }: ISecondaryInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <div className={className}>
        <TextField
          ref={ref}
          variant={variant}
          error={!!errorMessage}
          helperText={errorMessage}
          FormHelperTextProps={{
            error: !!errorMessage
          }}
          fullWidth
          {...rest}
          type={defineType(rest?.type, showPassword)}
          InputLabelProps={{
            shrink: true,
            error: !!errorMessage
          }}
          InputProps={{
            error: !!errorMessage,
            endAdornment: endAdornment
              ? endAdornment
              : rest.type === 'password' && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <PasswordIcon showPassword={showPassword} errorMessage={errorMessage} />
                    </IconButton>
                  </InputAdornment>
                ),
            inputComponent: mask ? InputFormat : undefined,
            inputProps: mask
              ? {
                  format: mask
                }
              : rest?.inputProps,
            ...rest?.InputProps
          }}
        />
      </div>
    )
  }
)

SecondaryInput.displayName = 'SecondaryInput'

export default styled(SecondaryInput)`
  min-height: ${({ label }) => (label ? '70px' : '50px')};

  .MuiInputAdornment-positionStart {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }

  .MuiInputAdornment-positionEnd {
    width: 20px;
    margin-right: 12px;
    margin-bottom: 8px;
    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }

  .MuiInput-underline:before {
    border-bottom-width: 2px;
  }

  .MuiInputLabel-root {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
  }

  .MuiInputLabel-standard {
    font-size: 14px;
  }
`
