import { IconButton, InputAdornment } from '@mui/material'
import search from '../../../assets/search.svg'
import styled from 'styled-components'
import SecondaryInput from '../../../../../components/inputs/SecondaryInput'
import { useFormContext } from 'react-hook-form'
import { removeAllNonNumericCharactersFromString } from '../../../../../utils/string'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import SecondaryAutoCompleteInput, { IOption } from '../../../../../components/inputs/SecondaryAutoCompleteInput'
import NumberFormat from 'react-number-format'
import MaskInput from '../../../../../components/inputs/MaskInput'

export interface IFilterFieldProps {
  type: 'search' | 'button' | 'percentage' | 'currency' | string
  name: string
  handleFilter: (value: string) => void
  label?: string
  className?: string
  options?: {
    value: string
    item: string
  }[]
  buttons?: {
    className?: string
    name: string
    icon: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  }[]
}

const FitlerField: React.FC<IFilterFieldProps> = ({
  type,
  handleFilter,
  name,
  label,
  buttons,
  options,
  className
}: IFilterFieldProps) => {
  const { register, getValues, setValue } = useFormContext()
  switch (type) {
    case 'search':
      return (
        <SecondaryInput
          data-testid="search-input-test"
          className={className}
          label={label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleFilter(getValues(name))}
                  edge="end"
                  data-testid="search-input-button-test"
                >
                  <img src={search} />
                </IconButton>
              </InputAdornment>
            )
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleFilter(getValues(name))
          }}
          {...register(name)}
        />
      )
    case 'currency':
      return (
        <NumberFormat
          label={label}
          customInput={SecondaryInput}
          value={getValues(name) || ''}
          onValueChange={(values) => {
            const { value } = values
            setValue('creditLimitValue', value, { shouldValidate: true })
          }}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          {...register(name)}
          data-testid="currency-input-test"
          onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) =>
            handleFilter(removeAllNonNumericCharactersFromString(e.target.value, true))
          }
        />
      )
    case 'button':
      return (
        <div className={className}>
          <div className="filter-field__button___wrapper">
            {buttons?.map((button) => (
              <button
                type="button"
                key={button.name}
                data-testid="button-test"
                className={`filter-field__button ${button.className} ${
                  getValues(name) === button.name && `selected-${button.className}`
                }`}
                onClick={() => {
                  handleFilter(button.name)
                  setValue(name, button.name)
                }}
              >
                {button.icon}
              </button>
            ))}
          </div>
        </div>
      )

    case 'select':
      return (
        <SecondaryAutoCompleteInput
          label={label as string}
          options={options as IOption[]}
          data-testid="input-select-test"
          disableClearable={true}
          onChange={(value) => {
            handleFilter(value as string)
            setValue(name, value)
          }}
          value={
            options?.find((option) => option?.value === getValues(name)) || {
              value: '',
              item: ''
            }
          }
        />
      )

    case 'percentage':
      return (
        <MaskInput
          label={label}
          endAdornment={<div style={{ marginLeft: 5 }}>%</div>}
          InputProps={{
            inputProps: {
              style: { textAlign: 'right' }
            }
          }}
          value={Number(getValues(name))}
          data-testid="percentage-input-test"
          {...register(name)}
          onChange={(value) => setValue(name, String(value))}
          onBlur={(e) => {
            const value = removeAllNonNumericCharactersFromString(e.target.value, true)
            if (value === '0.000') {
              handleFilter('')
            } else {
              handleFilter(removeAllNonNumericCharactersFromString(e.target.value, true))
            }
          }}
        ></MaskInput>
      )
    default:
      return <h1 data-testid="default-test">select a valid type</h1>
  }
}

export default styled(FitlerField)`
  width: 100%;
  .filter-field__button___wrapper {
    display: flex;
    gap: 2px;
    width: 100%;
  }

  .filter-field__button {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
    flex-grow: 1;
    border: none;
    cursor: pointer;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  @media (max-width: 850px) {
    .filter-field__button___wrapper {
      gap: 0px;
      border: 2px solid ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      border-radius: 8px;
    }

    .filter-field__button {
      border-right: 2px solid ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      padding: 6px 0;
      height: auto;

      &:last-child {
        border-right: none;
      }
    }
  }
`
