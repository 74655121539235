import styled from 'styled-components'
import congratulations from '../../../common/assets/congratulations.svg'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import PageWrapper from '../../components/PageWrapper'
import Header from '../../components/Header'
import H5 from '../../../common/components/Typography/H5'
import { transformNumberToPercentageFormat } from '../../../../utils/number'
import { useAuth } from '../../../../hooks/useAuth'
import { AuthContext } from '../../../../states/auth/AuthContext'

interface IProps {
  className?: string
}

export interface IAnalyzedCompanyState {
  state: {
    buyers: number
    averageTax: number
    minTax: number
  }
}

const AnalyzedCompany: React.FC<IProps> = ({ className }: IProps) => {
  const navigate = useNavigate()
  const { state } = useLocation() as IAnalyzedCompanyState
  const { loggedIn } = useAuth()
  const { logout } = useContext(AuthContext)

  const [searchParams] = useSearchParams()

  const companyId = searchParams.get('companyId') as string

  const handleClick = () => {
    if (loggedIn) {
      return logout()
    }

    return navigate(`/onboarding/create-user?companyId=${companyId}`)
  }

  useEffect(() => {
    if (!state) {
      navigate('/onboarding/company-document-number-analysis')
    }
  }, [state, navigate])

  return (
    <PageWrapper className={className}>
      <Header />
      <img src={congratulations} alt="Imagem de uma mulher voando junto com um gráfico de dinheiro subindo" />
      <div className="analyzed-company__title___wrapper">
        <h3 className="analyzed-company__title">Sua empresa foi pré-aprovada!</h3>
        <H5 className="analyzed-company__subtitle">Estamos prontos para te receber.</H5>
      </div>
      <div className="analyzed-company__card-infos">
        <h3>{state?.buyers} Financiadores</h3>
        <p>atendem empresas como a sua, com taxas entre:</p>
        <div className="analyzed-company__card-infos___numbers____wrapper">
          <h3>{transformNumberToPercentageFormat(state?.minTax)}</h3>
          <p>e</p>
          <h3>{transformNumberToPercentageFormat(state?.averageTax)}</h3>
        </div>
      </div>
      <p className="analyzed-company__text-infos">
        {loggedIn
          ? 'Faça login novamente, continue seu cadastro e finalize-o o mais rápido possível para aproveitar nossas vantagens.'
          : 'Continue o seu cadastro e o finalize o mais rápido possível para aproveitar nossas vantagens.'}
      </p>
      <div className="analyzed-company__button___wrapper">
        <PrimaryButton data-testid="analyzed-company-button-test" onClick={handleClick}>
          Continuar cadastro
        </PrimaryButton>
      </div>
    </PageWrapper>
  )
}

export default styled(AnalyzedCompany)`
  .analyzed-company__title___wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
  }

  .analyzed-company__title {
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors?.primary};
    font-size: 24px;
  }

  .analyzed-company__subtitle {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
  }

  .analyzed-company__card-infos {
    padding: 16px 32px;
    margin-top: 32px;
    border: 1px solid ${({ theme: { colors } }) => colors?.primary};
    border-radius: 8px;
    box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    h3 {
      font-size: 20px;
      color: ${({ theme: { colors } }) => colors?.primary};
      font-weight: 700;
    }
    p {
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
      font-size: 14px;
    }

    .analyzed-company__card-infos___numbers____wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .analyzed-company__text-infos {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    max-width: 436px;
    text-align: center;
    line-height: 24px;
    margin-top: 48px;
  }

  .analyzed-company__button___wrapper {
    width: 296px;
    margin-top: 32px;
  }

  @media (max-width: 600px) {
    .analyzed-company__card-infos {
      padding: 16px 16px;
    }

    .analyzed-company__text-infos {
      max-width: 368px;
    }
  }
`
