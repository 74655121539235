import { ReactNode, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import LoadingPage from '../../../components/commons/LoadingPage'
import { useAuth } from '../../../hooks/useAuth'
import { useBuyerThemeColor } from '../../../hooks/useBuyerThemeColor'
import { AuthContext } from '../../../states/auth/AuthContext'

interface IProps {
  children: ReactNode
}

const PrivateRoute: React.FC<IProps> = ({ children }: IProps) => {
  const { loading, loggedIn, groups } = useAuth()
  const { isLoggingOut } = useContext(AuthContext)

  useBuyerThemeColor(groups as ['Cedente' | 'Financiador'])

  if (loading || isLoggingOut) return <LoadingPage />

  if (loggedIn) return <>{children}</>

  return <Navigate to="/" />
}

export default PrivateRoute
