import { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: ReactNode
}

const PrimaryCard = forwardRef<HTMLDivElement, IProps>(({ className, children }: IProps, ref) => {
  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
})

PrimaryCard.displayName = 'PrimaryCard'

export default styled(PrimaryCard)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
  border-radius: 8px;
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  padding: 24px;
  min-height: 300px;
`
