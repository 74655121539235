import { Outlet, Route } from 'react-router-dom'
import CustomersList from '../../../apps/company/pages/CustomersList'
import ExampleBuyer from '../../../pages/ExampleBuyer'
import CompanyState from '../../../states/company/CompanyState'
import RouteByGroup from '../../Rules/RouteByGroup'
import CustomerProfile from '../../../pages/CustomerProfile'
import NegativeRatingReasons from '../../../pages/CustomerProfile/CustomerRating/NegativeRatingReasons'
import CustomerReputationMetabase from '../../../pages/CustomerProfile/CustomerReputation/CustomerReputationMetabase'

export const BuyerRouteWrapper = () => (
  <RouteByGroup allowedGroup="Financiador">
    <Outlet />
  </RouteByGroup>
)

export const BuyerRoutes = (
  <Route path="/" element={<BuyerRouteWrapper />}>
    <Route
      path="customers-list"
      element={
        <CompanyState>
          <CustomersList />
        </CompanyState>
      }
    />
    <Route path="example-buyer" element={<ExampleBuyer />} />
    <Route
      path="customer-profile/:id"
      element={
        <CompanyState>
          <CustomerProfile />
        </CompanyState>
      }
    />
    <Route
      path="negative-rating-reasons"
      element={
        <CompanyState>
          <NegativeRatingReasons />
        </CompanyState>
      }
    />
    <Route
      path="customer-reputation/:id"
      element={
        <CompanyState>
          <CustomerReputationMetabase />
        </CompanyState>
      }
    />
  </Route>
)
