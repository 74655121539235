import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { addOpacityToDynamicColors } from '../../../utils/color'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface IProps {
  className?: string
  items: Array<{
    title: string
    url: string
    id: number | string
  }>
}

const PrimaryTab: React.FC<IProps> = ({ className, items }: IProps) => {
  const navigate = useNavigate()

  const currentTabId = items?.find((tab) => tab.url === window.location.pathname)?.id

  return (
    <div className={className}>
      <Tabs
        data-testid="primary-tab-test"
        value={currentTabId}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        className="tabs__holder"
        sx={{ minHeight: '36px', height: '36px' }}
      >
        {items?.map((t) => (
          <Tab
            data-testid="tab-test"
            sx={{ minHeight: '36px', height: '36px' }}
            onClick={() => {
              navigate(t?.url)
            }}
            label={t.title}
            className="tabs__box"
            key={t?.title}
          />
        ))}
      </Tabs>
    </div>
  )
}

export default styled(PrimaryTab)`
  display: flex;
  margin-bottom: 40px;

  .tabs__box {
    font-size: 20px;
    margin-right: 32px;
    font-weight: 400;
    padding: 4px;
    cursor: pointer;
    user-select: none;
    color: ${({ theme: { colors } }) => colors?.quarternaryFont} !important;

    &:hover {
      background: ${({ theme: { colors } }) => addOpacityToDynamicColors(colors?.primary, 25)};
      border-radius: 4px;
    }

    .tabs__title {
      font-size: 20px;
    }
  }
`
