import styled from 'styled-components'
import PrimaryPagination from '../../../common/components/Pagination/PrimaryPagination'
import { useFormContext, Controller } from 'react-hook-form'
import QuizQuestion from '../../components/QuizQuestion'
import PrimaryRadio from '../../../common/components/Input/PrimaryRadio'
import RadioGroup from '../../../common/components/Input/RadioGroup'
import SecondaryInput from '../../../../components/inputs/SecondaryInput'
import InputFormat from '../../../../components/inputs/InputFormat'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
interface IProps {
  className?: string
}

const QuizSecondStep: React.FC<IProps> = ({ className }: IProps) => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
    setError,
    clearErrors
  } = useFormContext()
  const navigate = useNavigate()
  const { documentNumber } = useParams()

  const [searchParams] = useSearchParams()

  const companyId = searchParams.get('companyId') as string

  const clientTypes = getValues('clientTypes')
  const paymentTypes = getValues('paymentTypes')
  const radialIncome = getValues('radialIncome')

  useEffect(() => {
    if (!clientTypes || !paymentTypes || !radialIncome) {
      navigate(`/onboarding/quiz/step/1/${documentNumber}?companyId=${companyId}`)
    }
  }, [clientTypes, paymentTypes, radialIncome, navigate, documentNumber, companyId])

  const isValid = () => {
    let valid = true

    if (!getValues('howMuchByMonth') || getValues('howMuchByMonth').length < 1) {
      valid = false
      setError('howMuchByMonth', { message: 'Insira um valor' })
    }

    if (!getValues('hasReceipt')) {
      valid = false
      setError('hasReceipt', { message: 'Selecione uma opção' })
    }

    return valid
  }

  return (
    <div className={className}>
      <QuizQuestion title="Quanto você pretende antecipar por mês?" grid={1}>
        <div className="quiz-second-step__input___wrapper">
          <SecondaryInput
            data-testid="how-much-by-month-input-test"
            label="Valor (R$)"
            {...register('howMuchByMonth')}
            onChange={(event) => {
              clearErrors()
              register('howMuchByMonth').onChange(event)
            }}
            errorMessage={errors['howMuchByMonth']?.message as string}
            defaultValue={getValues('howMuchByMonth') ? getValues('howMuchByMonth').replace('R$ ', '') : ''}
            InputProps={{
              inputComponent: InputFormat,
              inputProps: {
                prefix: 'R$ ',
                decimalScale: 2,
                thousandSeparator: '.',
                decimalSeparator: ','
              }
            }}
          />
        </div>
      </QuizQuestion>

      <Controller
        control={control}
        name="hasReceipt"
        render={({ field: { onChange } }) => (
          <RadioGroup
            name="hasReceipt"
            value={getValues('hasReceipt') ? getValues('hasReceipt') : ''}
            onChange={({ target: { value } }) => {
              clearErrors()
              onChange(value)
            }}
          >
            <QuizQuestion
              title="Você tem comprovante de endereço da empresa?"
              grid={4}
              errorMessage={errors['hasReceipt']?.message as string}
            >
              <PrimaryRadio label="Sim" value={true} data-testid="has-receipt-input-test" />
              <div />
              <div />
              <PrimaryRadio label="Não" value={false} />
            </QuizQuestion>
          </RadioGroup>
        )}
      />

      <PrimaryPagination
        totalPages={3}
        currentPage={2}
        handlePreviousPage={(number) =>
          navigate(`/onboarding/quiz/step/${number}/${documentNumber}?companyId=${companyId}`)
        }
        handleNextPage={(number) => {
          if (isValid()) {
            navigate(`/onboarding/quiz/step/${number}/${documentNumber}?companyId=${companyId}`)
          }
        }}
      />
    </div>
  )
}

export default styled(QuizSecondStep)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .quiz-second-step__input___wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    ${SecondaryInput} {
      width: 304px;
    }
  }

  .quiz-question__alternatives___wrapper {
    width: 304px;
  }
`
