import { ReactNode, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import LoadingPage from '../../../components/commons/LoadingPage'
import { useAuth } from '../../../hooks/useAuth'
import { useBuyerThemeColor } from '../../../hooks/useBuyerThemeColor'
import { AuthContext } from '../../../states/auth/AuthContext'

interface IProps {
  children: ReactNode
  allowedGroup: 'Cedente' | 'Financiador'
}

const RouteByGroup: React.FC<IProps> = ({ children, allowedGroup }: IProps) => {
  const { loading, loggedIn, groups } = useAuth()
  const { isLoggingOut } = useContext(AuthContext)

  useBuyerThemeColor(groups as ['Cedente' | 'Financiador'])

  if (loading || isLoggingOut) return <LoadingPage />

  if (!loggedIn) return <Navigate to="/" />

  if (groups?.[0] !== allowedGroup) return <Navigate to="/create-company/document-number" />

  return <>{children}</>
}

export default RouteByGroup
