import styled from 'styled-components'
import SecondaryInput from '../../../../../components/inputs/SecondaryInput'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FilterField, { IFilterFieldProps } from '../FilterField'
import { useSearchParams } from 'react-router-dom'
import { Maybe } from 'graphql/jsutils/Maybe'
import PrimaryButton from '../../../../../components/buttons/PrimaryButton'
import Filter from '../../../assets/Filter'
import { useMobile } from '../../../hooks/useMobile'
import SecondaryButton from '../../../../../components/buttons/SecondaryButton'

export interface IFilter {
  label?: string
  fields: Array<Omit<IFilterFieldProps, 'handleFilter' | 'type'>>
  type: IFilterFieldProps['type']
}

interface IProps {
  className?: string
  filters: Array<IFilter>
  totalItems?: Maybe<number>
}

const PrimaryFilter: React.FC<IProps> = ({ className, filters, totalItems }: IProps) => {
  const isMobile = useMobile(851)
  const [isOpen, setIsOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const newParams: { [key: string]: string } = {}
  searchParams.forEach((value: string, key: string) => {
    const fieldNames = filters.flatMap((item) => item.fields.map((field) => field.name))
    if (fieldNames.find((name) => name === key)) {
      newParams[key] = value
    }
  })

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...newParams
    }
  })
  const { setValue, watch } = methods

  const handleFilter = (field: string, value: string) => {
    setSearchParams({
      ...newParams,
      [field]: value
    })
  }

  const clearForm = () => {
    setSearchParams({})

    Object.keys(watch()).forEach((key) => {
      setValue(key, '')
    })
  }

  return (
    <div className={className}>
      <FormProvider {...methods}>
        {isMobile && !isOpen && (
          <div className="primary-filter__closed___wrapper--mobile">
            <SecondaryButton
              data-testid="open-filter-button-test"
              onClick={() => setIsOpen(true)}
              startIcon={<Filter />}
            >
              Filtros
            </SecondaryButton>
            <h6 className="primary-filter__total" data-testid="total-items-mobile-test">
              {totalItems || 0}
              {totalItems === 1 ? ' Cedente' : ' Cedentes'}
            </h6>
          </div>
        )}
        {(!isMobile || isOpen) && (
          <form className="primary-filter__form" data-testid="form-test">
            {isMobile && <h6 className="primary-filter__filter">Filtros</h6>}
            <h6 className="primary-filter__total" data-testid="total-items-test">
              {totalItems || 0}
              {totalItems === 1 ? ' Cedente' : ' Cedentes'}
            </h6>
            {filters?.map((filterGroup, index) => (
              <div className="primary-filter__field___wrapper" key={index}>
                {filterGroup.label && <p className="primary-filter__field___title">{filterGroup.label}</p>}
                <div className="primary-filter__field__input___wrapper">
                  {filterGroup.fields.map((field) => (
                    <FilterField
                      key={field.name}
                      label={field.label}
                      type={filterGroup.type}
                      name={field.name}
                      buttons={field.buttons}
                      options={field.options}
                      handleFilter={(value) => handleFilter(field.name, value)}
                    />
                  ))}
                </div>
              </div>
            ))}
            <span className="primary-filter__clear-filter" onClick={clearForm} data-testid="clear-filter-test">
              Limpar filtros
            </span>
            {isMobile && isOpen && (
              <div className="primary-filter__footer___wrapper">
                <div className="primary-filter__footer___button____wrapper">
                  <PrimaryButton
                    variant="outlined"
                    onClick={() => setIsOpen(false)}
                    data-testid="close-filter-button-test"
                  >
                    Fechar
                  </PrimaryButton>
                </div>
              </div>
            )}
          </form>
        )}
      </FormProvider>
    </div>
  )
}

export default styled(PrimaryFilter)`
  .primary-filter__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .primary-filter__total,
  .primary-filter__filter {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .primary-filter__filter {
    display: none;
  }

  .primary-filter__field___wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .primary-filter__field___title {
      font-size: 12px;
      font-weight: 700;
      color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
    }

    .primary-filter__field__input___wrapper {
      width: 100%;
      display: flex;
      gap: 12px;

      ${SecondaryInput} {
        width: 100%;
      }
    }
  }

  .MuiInput-root {
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
  }

  .primary-filter__clear-filter {
    color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
    font-size: 14px;

    width: 100%;
    text-align: right;
    cursor: pointer;
  }

  .primary-filter__footer___wrapper {
    display: none;
  }

  @media (max-width: 850px) {
    padding: 16px 0;

    .primary-filter__closed___wrapper--mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
    }

    .primary-filter__form {
      position: absolute;
      z-index: 1100;
      width: calc(100% - 32px);
      padding: 0 16px 16px 16px;
      height: calc(100vh - 136px);
      background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
    }
    .primary-filter__filter {
      display: block;
    }

    .primary-filter__footer___wrapper {
      display: flex;
      align-items: flex-end;
      height: 100%;
      width: 100%;

      .primary-filter__footer___button____wrapper {
        width: 50%;
      }
    }
  }
`
