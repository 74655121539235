import { useState } from 'react'

interface IUseRandomNumberFromArray {
  sorted: number
  sortDifferentNumber: () => void
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRandomNumberFromArray = (list: any[]): IUseRandomNumberFromArray => {
  const [sorted, setSorted] = useState(0)

  const sortNumber = () => {
    return Math.floor(Math.random() * list.length)
  }

  const sortDifferentNumber = () => {
    let index
    do {
      index = sortNumber()
    } while (index === sorted)
    setSorted(index)
  }

  return { sorted, sortDifferentNumber }
}
