import styled from 'styled-components'
import PasswordVerifierItem from '../PasswordVerifierItem'

interface IProps {
  className?: string
  upperCase: boolean
  lowerCase: boolean
  number: boolean
  length: boolean
  specialCharacter: boolean
}

const PasswordVerifier: React.FC<IProps> = ({
  className,
  upperCase,
  lowerCase,
  number,
  length,
  specialCharacter
}: IProps) => {
  return (
    <div className={className}>
      <PasswordVerifierItem text="Letra minúscula" isVerified={lowerCase} />
      <PasswordVerifierItem text="Letra maiúscula" isVerified={upperCase} />
      <PasswordVerifierItem text="Numeral" isVerified={number} />
      <PasswordVerifierItem text="Pelo menos 8 caracteres" isVerified={length} />
      <PasswordVerifierItem text="Caractere especial" isVerified={specialCharacter} />
    </div>
  )
}

export default styled(PasswordVerifier)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`
