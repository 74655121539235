import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import FavoriteIcon from '../../../apps/company/assets/FavoriteIcon'
import NegativeIcon from '../../../apps/company/assets/NegativeIcon'
import PositiveIcon from '../../../apps/company/assets/PositiveIcon'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import SecondaryInput from '../../../components/inputs/SecondaryInput'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect } from 'react'
import { CompanyContext } from '../../../states/company/CompanyContext'
import CompanyRatingSkeleton from '../../../apps/company/components/CompanyRatingSkeleton'
import { removeAllNonNumericCharactersFromString } from '../../../utils/string'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import MaskInput from '../../../components/inputs/MaskInput'
import { useNavigate } from 'react-router-dom'
import { REDIRECT_V1_URL, REDIRECT_V1_URL_PROD, ENVIRONMENT } from '../../../utils/envs'
import { Environment } from '../../../utils/enum'

interface IProps {
  className?: string
  companyId: string
  buyerId: string
}

interface IForm {
  id?: string
  companyRating: 'approved' | 'disapproved' | 'favorite' | string
  creditLimitValue: string
  maximumRate: string
  minimumRate: string
}

const CustomerRating: React.FC<IProps> = ({ className, companyId, buyerId }: IProps) => {
  const {
    companyRating,
    isGettingCompanyRatingByCompanyId,
    isGettingCompanyInfos,
    isMutatingCompanyRatings,
    isUpdatingDefaultCompany,
    defaultCompany,
    isGettingDefaultCompany,
    getCompanyRatingByCompanyId,
    mutateCompanyRatings,
    clearCompanyRatings,
    clearCreateRatingReasons
  } = useContext(CompanyContext)

  const schema = yup.object().shape({
    companyRating: yup.mixed().oneOf(['approved', 'disapproved', 'favorite']),
    creditLimitValue: yup
      .string()
      .test('is-not-disapproved', 'Preencha este campo', (value?: string, object?: { parent: IForm }) => {
        return !!(value && object?.parent?.companyRating !== 'disapproved')
      })
      .test('more-than-0', 'O valor deve ser maior que 0', (value?: string) => {
        return !!(value && Number(value) > 0)
      })
      .nullable(),
    maximumRate: yup
      .string()
      .test('is-not-disapproved', 'Preencha este campo', (value?: string, object?: { parent: IForm }) => {
        return !!(value && object?.parent?.companyRating !== 'disapproved')
      })
      .test('more-than-100', 'Porcentagem maior que 100', (value?: string) => {
        return !!(value && Number(value) <= 100)
      })
      .test(
        'maximum-rate-more-than-minimum-rate',
        'Taxa máxima menor que mínima',
        (value?: string, object?: { parent: IForm }) => {
          const minimumRate = object?.parent?.minimumRate
          return !(minimumRate && value && Number(minimumRate) >= Number(value))
        }
      )
      .nullable(),
    minimumRate: yup
      .string()
      .test('is-not-disapproved', 'Preencha este campo', (value?: string, object?: { parent: IForm }) => {
        return !!(value && object?.parent?.companyRating !== 'disapproved')
      })
      .test('more-than-100', 'Porcentagem maior que 100', (value?: string) => {
        return !!(value && Number(value) <= 100)
      })
      .test('more-than-0', 'O valor deve ser maior que 0', (value?: string) => {
        return !!(value && Number(value) > 0)
      })
      .nullable()
  })
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (companyId && buyerId) {
      reset({
        id: undefined,
        companyRating: '',
        creditLimitValue: undefined,
        maximumRate: undefined,
        minimumRate: undefined
      })
      getCompanyRatingByCompanyId(companyId, buyerId)
    }
  }, [getCompanyRatingByCompanyId, companyId, buyerId, reset])

  useEffect(() => {
    reset({
      id: companyRating.id,
      companyRating: companyRating.companyRating,
      creditLimitValue: companyRating.creditLimitValue as string,
      maximumRate: companyRating.maximumRate as string,
      minimumRate: companyRating.minimumRate as string
    })
  }, [companyRating, reset])

  useEffect(() => {
    clearCompanyRatings()
  }, [clearCompanyRatings])

  const checkIfCompanyRatingExistAndIsDisapproved = (): boolean => {
    return !watch('companyRating') || watch('companyRating') === 'disapproved'
  }

  const isDisabled = checkIfCompanyRatingExistAndIsDisapproved()

  const handleDisapproved = () => {
    setValue('companyRating', 'disapproved')
    mutateCompanyRatings({
      id: companyRating?.id,
      creditLimitValue: '',
      minimumRate: '',
      maximumRate: '0.0',
      companyRating: 'disapproved',
      companyId,
      buyerId: buyerId
    })

    clearCreateRatingReasons()
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (!isUpdatingDefaultCompany && defaultCompany?.statusId !== '4' && defaultCompany?.statusId) {
      window.location.replace(`${ENVIRONMENT === Environment.DEVELOPMENT ? REDIRECT_V1_URL : REDIRECT_V1_URL_PROD}`)
    }
  }, [defaultCompany?.statusId, isUpdatingDefaultCompany, navigate])

  return (
    <div className={className}>
      <div className="container">
        {isGettingCompanyInfos || isGettingCompanyRatingByCompanyId || isGettingDefaultCompany ? (
          <CompanyRatingSkeleton />
        ) : (
          <form
            className="customer-rating__form"
            onSubmit={handleSubmit((data) => {
              mutateCompanyRatings({
                ...data,
                id: data.id,
                creditLimitValue: removeAllNonNumericCharactersFromString(data.creditLimitValue),
                minimumRate: Number(data.minimumRate).toFixed(3),
                maximumRate: Number(data.maximumRate).toFixed(3),
                companyId,
                buyerId: buyerId
              })
            })}
          >
            <h6 className="customer-rating__main-title">Avaliação</h6>
            <div className="customer-rating__rating-button___wrapper">
              <button
                type="button"
                className={`customer-rating__rating-button negative ${
                  watch('companyRating') === 'disapproved' && 'selected-negative'
                }`}
                onClick={handleDisapproved}
                data-testid="company-rating-disapproved-test"
              >
                <NegativeIcon />
              </button>
              <button
                type="button"
                className={`customer-rating__rating-button positive ${
                  watch('companyRating') === 'approved' && 'selected-positive'
                }`}
                onClick={() => setValue('companyRating', 'approved')}
                data-testid="company-rating-approved-test"
              >
                <PositiveIcon />
              </button>
              <button
                type="button"
                className={`customer-rating__rating-button favorite ${
                  watch('companyRating') === 'favorite' && 'selected-favorite'
                }`}
                onClick={() => setValue('companyRating', 'favorite')}
                data-testid="company-rating-favorite-test"
              >
                <FavoriteIcon />
              </button>
            </div>
            <div className="customer-rating__info-group">
              <h6 className="customer-rating__info-title">Limite pré-aprovado</h6>
              <p className="customer-rating__info-subtitle">Valor máximo disponível para este cedente</p>
            </div>
            <div className="customer-rating__input-group">
              <NumberFormat
                isAllowed={({ floatValue }: NumberFormatValues) => {
                  return floatValue === undefined || floatValue <= 9999999999999.99
                }}
                label="Valor (R$)"
                customInput={SecondaryInput}
                value={watch('creditLimitValue')}
                onValueChange={(values) => {
                  const { value } = values
                  setValue('creditLimitValue', value, { shouldValidate: true })
                }}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                disabled={isDisabled}
                errorMessage={!isDisabled ? errors?.creditLimitValue?.message : undefined}
                data-testid="credit-limit-value-test"
              />
            </div>
            <div className="customer-rating__info-group">
              <h6 className="customer-rating__info-title">Taxas</h6>
              <p className="customer-rating__info-subtitle">A menor e maior taxa disponível para este cedente</p>
            </div>
            <div className="customer-rating__input-group">
              <MaskInput
                label="Taxa Mínima"
                endAdornment={<div style={{ marginLeft: 5 }}>%</div>}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'right' }
                  }
                }}
                onChange={(value) => setValue('minimumRate', String(value))}
                value={Number(getValues('minimumRate'))}
                disabled={isDisabled}
                errorMessage={!isDisabled ? errors?.minimumRate?.message : undefined}
                data-testid="minimum-rate-test"
              ></MaskInput>
              <MaskInput
                label="Taxa Máxima"
                endAdornment={<div style={{ marginLeft: 5 }}>%</div>}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'right' }
                  }
                }}
                onChange={(value) => setValue('maximumRate', String(value))}
                value={Number(getValues('maximumRate'))}
                data-testid="maximum-rate-test"
                disabled={isDisabled}
                errorMessage={!isDisabled ? errors?.maximumRate?.message : undefined}
              ></MaskInput>
            </div>
            <div className="customer-rating__button-submit___wrapper">
              <PrimaryButton
                data-testid="submit-test"
                type="submit"
                disabled={isDisabled}
                isLoading={isMutatingCompanyRatings}
                color="secondary"
              >
                Salvar
              </PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default styled(CustomerRating)`
  background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  border-radius: 8px;
  display: grid;
  grid-area: customer_rating;
  width: 100%;

  .container {
    padding: 16px;
  }

  .customer-rating__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .customer-rating__main-title {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .customer-rating__rating-button___wrapper {
    margin-top: 24px;
    display: flex;
    width: 100%;
  }

  .positive {
    ${PositiveIcon} {
      transition: all 300ms;
    }
  }

  .positive:hover,
  .selected-positive {
    ${PositiveIcon} {
      fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.positive};
    }
  }

  .negative {
    ${NegativeIcon} {
      transition: all 300ms;
    }
  }

  .negative:hover,
  .selected-negative {
    ${NegativeIcon} {
      fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.negative};
    }
  }

  .favorite {
    ${FavoriteIcon} {
      transition: all 300ms;
    }
  }

  .favorite:hover,
  .selected-favorite {
    ${FavoriteIcon} {
      fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.favorite};
    }
  }

  .customer-rating__rating-button {
    padding: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
    flex-grow: 1;
    border: 2px solid ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
    cursor: pointer;
    border-right: none;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-right: 2px solid ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
    }

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .customer-rating__info-group {
    margin-top: 24px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .customer-rating__info-title {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .customer-rating__info-subtitle {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
  }

  .customer-rating__input-group {
    display: flex;
    width: 100%;
    gap: 12px;
    ${SecondaryInput} {
      flex: 1;
      width: 50%;
    }
    ${MaskInput} {
      flex: 1;
      width: 50%;
    }
  }
  .customer-rating__button-submit___wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    ${PrimaryButton} {
      button {
        color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
      width: calc(50% - 6px);
    }
  }
`
