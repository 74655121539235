// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorMessages: any = {
  login: {
    'Incorrect username or password.': 'Usuário ou senha inválidos',
    'Password reset required for the user': 'Nova senha requisitada pelo usuário',
    'Temporary password has expired and must be reset by an administrator.':
      'Senha expirada, entre em contato com um administrador'
  },
  forgotPassword: {
    'User does not exist.': 'Email não encontrado na base',
    'User password cannot be reset in the current state.': 'Resete sua senha temporária primeiro'
  },
  newPassword: {
    'Password does not conform to policy: Password not long enough': 'Senha fora das políticas do site'
  },
  resetPassword: {
    'Invalid verification code provided, please try again.': 'Esse não é o código que enviamos para o seu e-mail'
  },
  getUserInfos: {
    'User does not exist.': 'Email não encontrado na base',
    'One or more requested attributes do not exist.': 'Um atributo ou mais não foi encontrado na base'
  },
  resendCreateUserEmail: {
    'Invalid phone number format.': 'Formato de telefone inválido'
  }
}
