import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ellipsisInTheEndOfString } from '../../../utils/string'
import Edit from '../../../assets/icons/Edit'

interface IProps {
  className?: string
  tag?: string
  subtitle?: string
  tagColor?: string
  titles: string[]
  infos: string[]
  editRedirectUrl: string
  isUnclickable?: boolean
  imageSrc?: string
}

const RegisteredAssigneeCard: React.FC<IProps> = ({
  className,
  tag,
  subtitle,
  titles,
  infos,
  editRedirectUrl,
  isUnclickable,
  imageSrc
}: IProps) => {
  const navigate = useNavigate()

  return (
    <button className={className} data-testid="registered-assignee-card-test" onClick={() => navigate(editRedirectUrl)}>
      <div className="registered-assignee-card__left--wrapper">
        {tag && (
          <div className="registered-assignee-card__status--wrapper" data-testid="registered-assignee-card-tag-test">
            <div className="registered-assignee-card__status___dot" />
            <p className="registered-assignee-card__status___tag">{tag?.toUpperCase()}</p>
          </div>
        )}
        <div className="registered-assignee-card__left--infos">
          {imageSrc && (
            <div className="registered-assignee-card__image--wrapper">
              <img className="registered-assignee-card__image" src={imageSrc} />
            </div>
          )}
          <div className="registered-assignee-card__texts--wrapper">
            {titles?.map((title) => (
              <h4 key={title} data-testid="registered-assignee-card-title-test">
                {ellipsisInTheEndOfString(title, 25)}
              </h4>
            ))}
            {subtitle && (
              <h4 data-testid="registered-assignee-card-subtitle-test">{ellipsisInTheEndOfString(subtitle, 29)}</h4>
            )}
            {infos?.map((info, i) => (
              <p key={i} data-testid="registered-assignee-card-info-test">
                {info}
              </p>
            ))}
          </div>
        </div>
      </div>
      {!isUnclickable && (
        <div className="registered-assignee-card__right-side">
          <div className="registered-assignee-card__box--divisor" />
          <Edit />
        </div>
      )}
    </button>
  )
}

export default styled(RegisteredAssigneeCard)`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme?.colors?.borderColor};
  border-radius: 8px;
  padding: 16px;
  width: 384px;
  cursor: ${({ isUnclickable }) => (isUnclickable ? 'auto' : 'pointer')};
  background: transparent;

  .registered-assignee-card__right-side {
    display: flex;
    gap: 16px;
    height: 100%;
    align-items: center;
    .registered-assignee-card__box--divisor {
      width: 1px;
      height: 100%;
      background: ${({ theme }) => theme?.colors?.borderColor};
    }

    ${Edit} {
      path {
        fill: ${({ theme }) => theme?.colors?.primary};
      }
    }
  }

  .registered-assignee-card__left--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    gap: 8px;

    .registered-assignee-card__left--infos {
      display: flex;
      gap: 16px;

      .registered-assignee-card__image--wrapper {
        display: flex;
        height: 100%;

        .registered-assignee-card__image {
          height: 48px;
          width: 48px;
        }
      }

      .registered-assignee-card__texts--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;

        h4 {
          font-size: 14px;
          font-weight: bold;
          color: #383838;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .registered-assignee-card__status--wrapper {
      display: flex;
      letter-spacing: 0.36px;
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;

      .registered-assignee-card__status___dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
        background: ${({ tagColor, theme }) => {
          if (tagColor) {
            return tagColor
          } else {
            return theme?.colors?.primary
          }
        }};
      }

      .registered-assignee-card__status___tag {
        font-size: 12px;
        font-weight: bold;
        color: ${({ tagColor, theme }) => {
          if (tagColor) {
            return tagColor
          } else {
            return theme?.colors?.primary
          }
        }};
      }
    }
  }

  .tag__name {
    font-size: 12px;
    margin-left: 8px;
    margin-top: -1px;
    font-weight: bold;
  }
`
