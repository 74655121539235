import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const ReportsSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className} data-testid="company-reports-skeleton-test">
      <div className="customer-reports__wrapper">
        <h5 className="customer-reports__main-title">Relatórios</h5>
        <div className="customer-reports__list">
          <div className="customer-reports__list-wrapper">
            <Skeleton width="90%" height="56px" animation="wave" className="document-item" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(ReportsSkeleton)`
  .customer-reports__list-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
