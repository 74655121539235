import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import H3 from '../../../common/components/Typography/H3'
import PageWrapper from '../../components/PageWrapper'
import alert from '../../../../assets/icons/alert.svg'

interface IProps {
  className?: string
}

const UserAlreadyExists = ({ className }: IProps) => {
  const navigate = useNavigate()

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="existing-social-number__wrapper">
        <img src={alert} style={{ height: 88, width: 88 }} />
        <H3>CPF já cadastrado!</H3>
        <div className="existing-social-number___info">
          <p>O CPF que você informou já está cadastrado em nossa plataforma.</p>
          <p>
            Por favor, faça o login para acessar <br />
            sua conta.
          </p>
        </div>
        <div className="existing-social-number____button-wrapper">
          <PrimaryButton data-testid="existing-social-number-login-button-test" onClick={() => navigate('/')}>
            Fazer login
          </PrimaryButton>
        </div>
        <div className="existing-social-number_____forgotten-password">
          <p>Esqueceu sua senha?</p>{' '}
          <p data-testid="existing-social-number-password-button-test" onClick={() => navigate('/forgot-password')}>
            Clique aqui
          </p>
        </div>
        <p className="existing-social-number______greeting-message">Nos vemos em breve!</p>
      </div>
    </PageWrapper>
  )
}

export default styled(UserAlreadyExists)`
  .existing-social-number__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 400px;

    @media (max-width: 400px) {
      div {
        p {
          max-width: 360px;
        }
      }
    }

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      padding-top: 32px;
    }

    .existing-social-number___info {
      p {
        text-align: center;
      }

      p:first-child {
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
        font-weight: bold;
        padding: 8px 0px 40px;
      }

      p:nth-child(2) {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        padding-bottom: 32px;
      }
    }

    .existing-social-number____button-wrapper {
      max-width: 296px;
      margin-bottom: 32px;
      width: 100%;
    }

    .existing-social-number_____forgotten-password {
      display: flex;

      p {
        font-size: 14px;
      }

      p:first-child {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        margin-right: 8px;
      }

      p:nth-child(2) {
        color: ${({ theme: { colors } }) => colors?.primary};
        cursor: pointer;
        padding-bottom: 48px;
        text-decoration: underline;
      }
    }
    .existing-social-number______greeting-message {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      font-size: 14px;
      font-weight: bold;
    }
  }
`
