import { Autocomplete } from '@mui/material'
import { forwardRef } from 'react'
import styled from 'styled-components'
import SecondaryInput from '../SecondaryInput'

export interface IOption {
  value: string | number
  item: string
}

export interface ISecondaryAutoCompleteInputProps {
  className?: string
  errorMessage?: string
  label: string
  onChange: (e: string | number) => void
  options: Array<IOption>
  value?: IOption
  disableClearable?: boolean
  disabled?: boolean
  placeholder?: string
}

const SecondaryAutoCompleteInput = forwardRef<HTMLInputElement, ISecondaryAutoCompleteInputProps>(
  (
    {
      className,
      errorMessage,
      options,
      onChange,
      label,
      value: inputValue,
      disableClearable,
      disabled,
      placeholder
    }: ISecondaryAutoCompleteInputProps,
    ref
  ) => {
    return (
      <div className={className}>
        <Autocomplete
          ref={ref}
          disabled={disabled}
          disablePortal
          options={options}
          disableClearable={disableClearable}
          data-testid="auto-complete-test"
          onChange={(_, value) => value && onChange(value?.value)}
          value={inputValue}
          getOptionLabel={(option) => option?.item}
          isOptionEqualToValue={(option: IOption, value: IOption) => option.item === value.item}
          renderInput={(params) => {
            return <SecondaryInput placeholder={placeholder} label={label} errorMessage={errorMessage} {...params} />
          }}
          ListboxProps={{
            style: {
              maxHeight: '200px'
            }
          }}
        />
      </div>
    )
  }
)

SecondaryAutoCompleteInput.displayName = 'SecondaryAutoCompleteInput'

export default styled(SecondaryAutoCompleteInput)`
  height: ${({ label }) => (label ? '70px' : '50px')};
  width: 100%;

  .MuiInputAdornment-positionStart {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }

  .MuiInputAdornment-positionEnd {
    width: 20px;
    margin-right: 12px;
    margin-bottom: 8px;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
`
