import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../states/company/CompanyContext'
import CompanyDescriptionSkeleton from './CompanyDescriptionSkeleton'
import { formatDateToDDMMYYYY } from '../../../utils/datetime'
import VirtualSection from './VirtualSection'
import { formatCurrencyToPtBR } from '../../../utils/currency'
import ResponsiblesSection from './ResponsiblesSection'
import BankAccountsSection from './BankAccountsSection'

interface IProps {
  className?: string
  companyId?: string
}

const CustomerCompanyDescription: React.FC<IProps> = ({ className, companyId }: IProps) => {
  const { getCustomerDataByCompanyId, customerData, isGettingCustomerDataByCompanyId } = useContext(CompanyContext)

  useEffect(() => {
    if (companyId) {
      getCustomerDataByCompanyId({ companyId: companyId })
    }
  }, [companyId, getCustomerDataByCompanyId])

  const birthDate = customerData?.business?.birthDate
    ? formatDateToDDMMYYYY(new Date(customerData?.business?.birthDate))
    : ''

  return (
    <div className={className}>
      <div className="container">
        {isGettingCustomerDataByCompanyId ? (
          <CompanyDescriptionSkeleton />
        ) : (
          <div data-testid="customer-company-description-test" className="company">
            {customerData?.business?.activityDescription ? (
              <div className="company__description">
                <p>{customerData?.business?.activityDescription.toLowerCase()}</p>
              </div>
            ) : (
              ''
            )}
            <div className="company__data">
              <div className="company__data-label">
                <p>Constituição</p>
                <p>Data de fundação</p>
                <p>Faturamento mensal</p>
                <p>Porte da empresa</p>
                <p>CNAE Principal</p>
                <p>Endereço de registro</p>
              </div>
              <div className="company__data-value">
                {customerData?.business?.constitutionName ? (
                  <p>{customerData?.business?.constitutionName}</p>
                ) : (
                  <p>-</p>
                )}
                {customerData?.business?.birthDate ? <p>{birthDate}</p> : <p>-</p>}
                {customerData?.revenues?.average ? (
                  <p>{formatCurrencyToPtBR(customerData?.revenues?.average)}</p>
                ) : (
                  <p>-</p>
                )}
                {customerData?.business?.companySize ? <p>{customerData?.business?.companySize}</p> : <p>-</p>}
                {customerData?.business?.codeActivity ? <p>{customerData?.business?.codeActivity}</p> : <p>-</p>}
                {customerData?.business?.registerAddressStreet ? (
                  <p>
                    {customerData?.business?.registerAddressStreet}, {customerData?.business?.registerAddressZipCode}.{' '}
                    {customerData?.business?.registerAddressNeighborhood}. <br />{' '}
                    {customerData?.business?.registerAddressCity} - {customerData?.business?.registerAddressState}
                  </p>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
            {customerData?.videos?.[0] && <VirtualSection />}
            <ResponsiblesSection />
            <BankAccountsSection />
          </div>
        )}
      </div>
    </div>
  )
}

export default styled(CustomerCompanyDescription)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  border-radius: 8px;
  width: 100%;
  grid-area: company_description;

  .container {
    padding: 16px;
  }

  p {
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
    font-size: 14px;

    @media (max-width: 950px) {
      font-size: 12px;
    }
  }

  .company__description {
    max-width: 740px;
    text-align: left;
    padding-top: 16px;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .company__data {
    display: flex;
    text-align: left;
    padding-top: 36px;
    max-width: 424px;

    p {
      padding-bottom: 16px;
    }

    .company__data-label {
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      p {
        font-weight: bold;
      }
    }

    .company__data-value {
      p:last-child {
        max-width: 220px;
      }
    }
  }
`
