import { ReactNode, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as validations from 'validations-br'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '../../../hooks/useAuth'
import { formatGroupsToAPIformat } from '../../../utils/auth'
import { OnboardingContext } from '../../../apps/onboarding/states/OnboardingContext'
import { ICreateCompanyParams } from '../../../apps/onboarding/types'

interface IProps {
  children: ReactNode
  className?: string
}
const CreateCompanyForm: React.FC<IProps> = ({ children, className }: IProps) => {
  const { createCompanyProposal } = useContext(OnboardingContext)
  const { groups } = useAuth()

  const validationSchema = yup.object().shape({
    documentNumber: yup
      .string()
      .required('Informe o seu CNPJ')
      .test(
        'is-cnpj',
        'Confira se seu CNPJ está correto',
        (value?: string) => !!(value && validations.validateCNPJ(value))
      )
  })

  const methods = useForm<ICreateCompanyParams>({
    mode: 'onChange',
    defaultValues: {
      profile: 'C'
    },
    resolver: yupResolver(validationSchema)
  })

  const { handleSubmit, setValue } = methods

  useEffect(() => {
    if (groups?.length > 0) {
      setValue('profile', formatGroupsToAPIformat(groups?.[0]))
    }
  }, [groups, setValue])

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={handleSubmit((data) => createCompanyProposal(data))}>
        {children}
      </form>
    </FormProvider>
  )
}

export default CreateCompanyForm
