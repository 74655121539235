import { ReactNode } from 'react'
import styled from 'styled-components'

export interface IH3Props {
  className?: string
  children: ReactNode
}

const H3: React.FC<IH3Props> = ({ className, children }: IH3Props) => {
  return <h3 className={className}>{children}</h3>
}

export default styled(H3)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 32px;
`
