import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const PositiveIcon: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg
      id="Componente_49_31_Toggle_State_"
      data-name="Componente 49 – 31 (Toggle State)"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.002"
      height="24"
      viewBox="0 0 24.002 24"
      {...rest}
    >
      <defs>
        <clipPath id="clip-path">
          <path id="Caminho_6100" data-name="Caminho 6100" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Z" fill="none" />
        </clipPath>
      </defs>
      <path
        className={className}
        id="Caminho_6094"
        data-name="Caminho 6094"
        d="M24,12A12,12,0,1,1,12,0,12,12,0,0,1,24,12"
        fill="#A4AEB0"
      />
      <g id="Grupo_10257" data-name="Grupo 10257">
        <g id="Grupo_10256" data-name="Grupo 10256" clipPath="url(#clip-path)">
          <path
            id="Caminho_6095"
            data-name="Caminho 6095"
            d="M8.287,31.2c-.193-2.834-.9-15.017-.937-15.843s-.653-2.1-.84-3.1.782-3.4.006-5.858-.358-2.63.274-2.81c.712-.2,1.93.751,2.524,2.267s.329,4.287,1.917,3.616a17.16,17.16,0,0,1,4.1-1.467c.856-.084,1.554.664,1.494,1.816.888-.073,1.841.56,1.292,2.171a1.486,1.486,0,0,1,.851.79A1.508,1.508,0,0,1,18.7,14.31a2,2,0,0,1-.276,2.511,9.356,9.356,0,0,1-3.639,1.862c-.933.273.619,10.291,1.158,11.8S8.4,32.844,8.287,31.2"
            fill="#fff"
          />
          <path
            id="Caminho_6096"
            data-name="Caminho 6096"
            d="M12.587,32.314A9.1,9.1,0,0,1,8.809,32.1c-.651-.247-.809-.616-.827-.883-.2-2.871-.9-15.089-.937-15.85a6.951,6.951,0,0,0-.415-1.6,11.266,11.266,0,0,1-.42-1.451,5.475,5.475,0,0,1,.1-1.612,9.3,9.3,0,0,0-.088-4.21c-.458-1.45-.545-2.226-.3-2.679A1.15,1.15,0,0,1,6.707,3.3c.935-.269,2.266.853,2.892,2.45A8.927,8.927,0,0,1,10,7.41c.134.766.285,1.635.628,1.822.042.023.172.094.487-.04l.308-.131A15.559,15.559,0,0,1,15.3,7.7a1.549,1.549,0,0,1,1.236.427,2.052,2.052,0,0,1,.593,1.389,1.587,1.587,0,0,1,1.129.582,1.891,1.891,0,0,1,.228,1.727,1.855,1.855,0,0,1,.757.826,1.678,1.678,0,0,1-.189,1.683,2.183,2.183,0,0,1-.416,2.707,9.17,9.17,0,0,1-3.716,1.92c-.515.864.712,9.732,1.314,11.418a.778.778,0,0,1-.241.849,6.794,6.794,0,0,1-3.407,1.084M8.593,31.179c.011.167.277.292.434.352,1.517.578,5.673.109,6.539-.738.123-.12.1-.178.092-.207-.448-1.256-1.983-10.524-1.276-11.909a.53.53,0,0,1,.321-.288A8.776,8.776,0,0,0,18.218,16.6a1.626,1.626,0,0,0,.242-2.086l-.036-.078a.308.308,0,0,1,.029-.3,1.169,1.169,0,0,0,.236-1.217,1.185,1.185,0,0,0-.661-.628.311.311,0,0,1-.184-.153.306.306,0,0,1-.019-.239,1.507,1.507,0,0,0-.05-1.419,1.047,1.047,0,0,0-.927-.348.3.3,0,0,1-.238-.086.3.3,0,0,1-.093-.235,1.582,1.582,0,0,0-.405-1.236.932.932,0,0,0-.754-.26,15.432,15.432,0,0,0-3.7,1.314l-.308.131a1.137,1.137,0,0,1-1.019.013c-.6-.328-.765-1.263-.938-2.254A8.574,8.574,0,0,0,9.029,5.97C8.458,4.51,7.358,3.75,6.875,3.885c-.246.07-.357.116-.413.219a4.047,4.047,0,0,0,.347,2.2,9.824,9.824,0,0,1,.11,4.484,5.391,5.391,0,0,0-.108,1.41,11.1,11.1,0,0,0,.4,1.369,7.173,7.173,0,0,1,.447,1.773c.033.759.74,12.965.936,15.835"
            fill="#383838"
          />
          <path
            id="Caminho_6097"
            data-name="Caminho 6097"
            d="M16.61,10.259a.306.306,0,0,1-.2-.561l.249-.161a.306.306,0,1,1,.333.513l-.249.162a.31.31,0,0,1-.131.047"
            fill="#383838"
          />
          <path
            id="Caminho_6098"
            data-name="Caminho 6098"
            d="M17.779,12.447a.306.306,0,0,1-.149-.588L18,11.71a.306.306,0,0,1,.228.569l-.371.148a.278.278,0,0,1-.079.02"
            fill="#383838"
          />
          <path
            id="Caminho_6099"
            data-name="Caminho 6099"
            d="M18.738,14.614a.261.261,0,0,1-.056,0l-.3-.02a.306.306,0,1,1,.041-.611l.305.021a.306.306,0,0,1,.015.609"
            fill="#383838"
          />
        </g>
      </g>
    </svg>
  )
}

export default styled(PositiveIcon)``
