import { createContext } from 'react'
import { theme } from '../../styles/theme'
import { IOriginState } from '../../types/origin'

const initialState: IOriginState = {
  setTheme: () => undefined,
  isGettingOriginInfos: false,
  dynamicTheme: theme,
  originInfos: undefined,
  error: {
    location: '',
    message: ''
  }
}

const OriginContext = createContext(initialState)

export { OriginContext, initialState }
