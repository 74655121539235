import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const Menu: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
      <path
        id="_729c1366f3de801a96e8aa1f42efce5f"
        data-name="729c1366f3de801a96e8aa1f42efce5f"
        d="M3,6.143A1.079,1.079,0,0,1,4,5H20a1.079,1.079,0,0,1,1,1.143,1.079,1.079,0,0,1-1,1.143H4A1.079,1.079,0,0,1,3,6.143ZM3,13a1.079,1.079,0,0,1,1-1.143H20A1.079,1.079,0,0,1,21,13a1.079,1.079,0,0,1-1,1.143H4A1.079,1.079,0,0,1,3,13Zm1,5.714A1.153,1.153,0,0,0,4,21H20a1.153,1.153,0,0,0,0-2.286Z"
        transform="translate(-3 -5)"
      />
    </svg>
  )
}

export default styled(Menu)``
