import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingPage from '../../../components/commons/LoadingPage'
import { useAuth } from '../../../hooks/useAuth'
import { useResetThemeColor } from '../../../hooks/useResetThemeColor'
import { deleteCookie } from '../../../utils/cookies'

interface IProps {
  children?: ReactNode
}

const AnonymousRoute: React.FC<IProps> = ({ children }: IProps) => {
  const { loading, loggedIn } = useAuth()
  const navigate = useNavigate()

  useResetThemeColor()

  useEffect(() => {
    if (loggedIn) {
      navigate('/create-company/document-number')
    } else if (!loading) {
      deleteCookie('ApplicationTokenGraphql')
      deleteCookie('ApplicationToken')
    }
  }, [loading, loggedIn, navigate])

  if (loading) return <LoadingPage />

  return <>{children}</>
}

export default AnonymousRoute
