import { Outlet } from 'react-router-dom'
import OnboardingState from '../../apps/onboarding/states/OnboardingState'
import CreateCompanyForm from '../../components/companies/CreateCompanyForm'
import CompanyState from './CompanyState'

const Company = () => {
  return (
    <OnboardingState>
      <CompanyState>
        <CreateCompanyForm>
          <Outlet />
        </CreateCompanyForm>
      </CompanyState>
    </OnboardingState>
  )
}

export default Company
