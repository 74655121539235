import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Header from '../../components/Header'
import H3 from '../../../common/components/Typography/H3'
import PreApprovedCompanySuccess from '../../../../assets/images/PreApprovedCompanySuccess.png'
import PageWrapper from '../../components/PageWrapper'
import H5 from '../../../common/components/Typography/H5'
import { useAuth } from '../../../../hooks/useAuth'
import { useContext } from 'react'
import { AuthContext } from '../../../../states/auth/AuthContext'

interface IProps {
  className?: string
}

const PreApprovedCompany = ({ className }: IProps) => {
  const navigate = useNavigate()
  const { loggedIn } = useAuth()

  const [searchParams] = useSearchParams()

  const companyId = searchParams.get('companyId') as string
  const { logout } = useContext(AuthContext)
  const handleClick = () => {
    if (loggedIn) {
      return logout()
    }

    if (!companyId) {
      return navigate('/onboarding/create-user')
    }

    return navigate(`/onboarding/create-user?companyId=${companyId}`)
  }

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="pre-approved-company__wrapper">
        <img alt="refused-company" src={PreApprovedCompanySuccess} />
        <H3>Sua empresa foi pré-aprovada!</H3>
        <div>
          <H5>Estamos prontos para te receber.</H5>
          <p>
            {loggedIn
              ? 'Faça login novamente, continue seu cadastro e finalize-o o mais rápido possível para aproveitar nossas vantagens.'
              : 'Continue o seu cadastro e o finalize o mais rápido possível para aproveitar nossas vantagens.'}
          </p>
        </div>
        <div className="pre-approved-company__button___wrapper">
          <PrimaryButton data-testid="pre-approved-company-button-test" onClick={handleClick}>
            Continuar cadastro
          </PrimaryButton>
        </div>
      </div>
    </PageWrapper>
  )
}

export default styled(PreApprovedCompany)`
  .pre-approved-company__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 386px;

    img {
      height: 180px;
      width: 275px;
    }

    h3 {
      color: ${({ theme: { colors } }) => colors?.primary};
      padding-top: 24px;
      text-align: center;
    }

    div {
      div {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 16px;
        padding: 8px 0px 48px;
        text-align: center;
      }

      p {
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 32px;
        text-align: center;
      }
    }

    .pre-approved-company__button___wrapper {
      width: 296px;
    }
  }

  @media (max-width: 400px) {
    .pre-approved-company__wrapper {
      div {
        p {
          max-width: 360px;
        }
      }
    }
  }
`
