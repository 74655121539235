import { Modal } from '@mui/material'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: ReactNode
  onClose?: () => void
  open: boolean
}

const ModalWrapper: React.FC<IProps> = ({ className, children, onClose, open }: IProps) => {
  return (
    <Modal open={open} onClose={onClose} className={className} data-testid="modal-test">
      <div className="modal">{children}</div>
    </Modal>
  )
}

export default styled(ModalWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  min-height: auto;

  .modal {
    background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
    border-radius: 8px;
    box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
    padding: 24px;
    width: 80%;
    max-width: 500px;
    position: relative;
    overflow: hidden;
  }
`
