import { useContext, useEffect } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import List from '../../components/crud/SplitCrud/List'
import { CompanyContext } from '../../states/company/CompanyContext'
import { theme } from '../../styles/theme'
import { formatDateToDDMMYYYY } from '../../utils/datetime'
import { cellphoneMask } from '../../utils/string'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CompanyUsers: React.FC<IProps> = ({ className }: IProps) => {
  const {
    companyUsers,
    defaultCompany,
    isGettingDefaultCompany,
    isGettingCompanyUsersByCompanyId,
    getCompanyUsersByCompanyId
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyUsersByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getCompanyUsersByCompanyId])

  const getTagColorAndStatus = (userStatus: 'ACTIVATED' | 'DISABLED' | string) => {
    if (userStatus === 'ACTIVATED')
      return {
        status: 'Ativo',
        color: theme?.styleGuide?.support?.green
      }

    if (userStatus === 'DISABLED')
      return {
        status: 'Inativo',
        color: theme?.colors?.tertiaryFont
      }
  }

  const listData = {
    basePath: '/company-users',
    goToFormButtonLabel: 'Adicionar novo usuário',
    presentation: {
      title: 'Se precisar de um time mais completo, esse é o lugar!',
      description: ['Adicione quantos usuários você precisar para ter acesso a plataforma e operar junto com você.']
    },
    list: companyUsers?.map((companyUser) => ({
      ...companyUser,
      mobile: cellphoneMask(companyUser?.mobile as string),
      lastAccess: `Último acesso: ${
        companyUser?.lastAccess ? formatDateToDDMMYYYY(new Date(companyUser?.lastAccess)) : '-----'
      }`,
      tag: getTagColorAndStatus(companyUser?.status as string)
    })),
    keysInCard: ['email', 'mobile', 'lastAccess'],
    cardTitleKeys: ['fullName'],
    isLoading: isGettingDefaultCompany || isGettingCompanyUsersByCompanyId
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <List data={listData} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyUsers)
