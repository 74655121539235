import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { IQuizWrapperDefaultValues } from '../../../../__mocks__/QuizWrapper.mock'
import H3 from '../../../common/components/Typography/H3'
import H5 from '../../../common/components/Typography/H5'
import { OnboardingContext } from '../../states/OnboardingContext'
import { IAnswerQuizInput } from '../../types'
import Header from '../Header'
import PageWrapper from '../PageWrapper'

interface IProps {
  className?: string
  defaultValues?: IQuizWrapperDefaultValues
}

type IQuizForm = Omit<IAnswerQuizInput, 'documentNumber'>

const QuizWrapper: React.FC<IProps> = ({ className, defaultValues }: IProps) => {
  const methods = useForm<IQuizForm>({
    defaultValues
  })
  const { handleSubmit, setError, getValues } = methods
  const { documentNumber } = useParams()
  const [searchParams] = useSearchParams()

  const { answerQuiz } = useContext(OnboardingContext)

  const companyId = searchParams.get('companyId') as string

  const isValid = () => {
    let valid = true

    if (!getValues('howYouMeetUs')) {
      valid = false
      setError('howYouMeetUs', { message: 'Selecione uma opção' })
    }

    return valid
  }

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="quiz-wrapper__title___wrapper">
        <H3 className="quiz-wrapper__title">Queremos te conhecer melhor</H3>
        <H5 className="quiz-wrapper__subtitle">
          Forneça detalhes adicionais para encontrar a melhor taxa de antecipação para sua empresa.
        </H5>
      </div>
      <FormProvider {...methods}>
        <form
          className="quiz-wrapper__form"
          onSubmit={handleSubmit((data) => {
            if (isValid()) {
              answerQuiz({ ...data, documentNumber: documentNumber as string, companyId })
            }
          })}
        >
          <Outlet />
        </form>
      </FormProvider>
    </PageWrapper>
  )
}

export default styled(QuizWrapper)`
  .quiz-wrapper__title___wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 400px;

    .quiz-wrapper__title {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    }

    .quiz-wrapper__subtitle {
      text-align: center;
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
    }
  }
  .quiz-wrapper__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
    width: 384px;
  }

  @media (max-width: 400px) {
    .quiz-wrapper__title___wrapper {
      .quiz-wrapper__subtitle {
        width: 348px;
      }
    }
    .quiz-wrapper__form {
      width: 356px;
    }
  }
`
