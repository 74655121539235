import DesktopNavBar from './DesktopNavBar'
import MobileNavBar from './MobileNavBar'

const NavBar = () => {
  return (
    <div data-testid="with-header-nav-bar-test">
      <DesktopNavBar />
      <MobileNavBar />
    </div>
  )
}

export default NavBar
