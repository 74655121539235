import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyContext } from '../../states/company/CompanyContext'
import * as validations from 'validations-br'
import CreateUpdateDelete, { IFormData } from '../../components/crud/SplitCrud/CreateUpdateDelete'
import WithHeader from '../../hocs/WithHeader'

const CompanyCustomersAndSuppliersForm: React.FC = () => {
  const {
    defaultCompany,
    isGettingDefaultCompany,
    deleteCompanyCustomer,
    deleteCompanySupplier,
    isGettingCompanyCustomersAndSuppliersByCompanyId,
    getCompanyCustomersAndSuppliersByCompanyId,
    companyCustomersAndSuppliers,
    registerCompanyCustomerOrSupplier,
    isRegistratingCompanyCustomerOrSupplier,
    isDeletingCompanyCustomer,
    isDeletingCompanySupplier
  } = useContext(CompanyContext)

  const { id } = useParams()

  useEffect(() => {
    if (defaultCompany?.businessId && id) {
      getCompanyCustomersAndSuppliersByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, id, getCompanyCustomersAndSuppliersByCompanyId])

  const getDeleteData = () => {
    if (companyCustomersAndSuppliers?.find((r) => r?.customerId === id)) {
      return deleteCompanyCustomer(id as string)
    }
    return deleteCompanySupplier(id as string)
  }

  const getIsEditing = () => {
    if (!id) {
      return {
        rows: [
          {
            inputs: [
              {
                name: 'isCustomer',
                required: true,
                type: 'radio',
                options: [
                  {
                    name: 'Cliente',
                    value: '0'
                  },
                  {
                    name: 'Fornecedor',
                    value: '1'
                  }
                ]
              }
            ],
            grid: [1]
          }
        ]
      }
    }

    return { rows: [] }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mutateData = (data: any) =>
    registerCompanyCustomerOrSupplier({
      companyId: defaultCompany?.businessId,
      ...data
    })

  const formData: IFormData = {
    breadCrumb: 'Dados cadastrais / Informações comerciais',
    isLoading: isGettingDefaultCompany || isGettingCompanyCustomersAndSuppliersByCompanyId,
    title: id ? 'Editar cliente ou fornecedor' : 'Adicionar cliente ou fornecedor',
    subtitle: id
      ? 'Faça a edição dos dados e não se esqueça de salvar as modificações.'
      : 'Preencha os campos abaixo para adicionar um cliente ou fornecedor.',
    currentData:
      companyCustomersAndSuppliers?.find((r) => r?.customerId === id) ||
      companyCustomersAndSuppliers?.find((r) => r?.supplierId === id),
    backTo: '/company-customers-and-suppliers',
    saveData: mutateData,
    editData: mutateData,
    deleteData: () => getDeleteData(),
    isSavingData: isRegistratingCompanyCustomerOrSupplier,
    isEditingData: isRegistratingCompanyCustomerOrSupplier,
    isDeletingData: isDeletingCompanyCustomer || isDeletingCompanySupplier,
    formDisabled: (defaultCompany?.statusId as string) !== '1',
    groups: [
      getIsEditing(),
      {
        rows: [
          {
            inputs: [
              {
                label: 'Nome do contato',
                name: 'fullName'
              },
              {
                label: 'Nome da empresa',
                name: 'companyName'
              }
            ],
            grid: [3, 3]
          }
        ]
      },
      {
        rows: [
          {
            inputs: [
              {
                label: 'CNPJ',
                name: 'documentNumber',
                required: true,
                mask: '##.###.###/####-##'
              },
              {
                label: 'E-mail',
                name: 'email',
                type: 'email'
              },
              {
                label: 'Telefone',
                name: 'phone',
                mask: '(##) #####-####',
                test: {
                  type: 'phone',
                  message: 'Confira se seu telefone está correto',
                  callback: (value?: string) => !!(value ? validations.validatePhone(value) : true)
                }
              }
            ],
            grid: [2, 2, 2]
          }
        ]
      }
    ]
  }

  return <CreateUpdateDelete formData={formData} />
}

export default WithHeader()(CompanyCustomersAndSuppliersForm)
