import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const Close: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg
      {...rest}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16.002"
      height="16"
      viewBox="0 0 16.002 16"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(-5.994 -5.996)">
        <path
          id="close"
          d="M15.874,13.995,21.6,8.279a1.338,1.338,0,1,0-1.892-1.892L14,12.116,8.28,6.388A1.338,1.338,0,0,0,6.388,8.279l5.729,5.715L6.388,19.71A1.338,1.338,0,1,0,8.28,21.6L14,15.873,19.711,21.6A1.338,1.338,0,1,0,21.6,19.71Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  )
}

export default styled(Close)``
