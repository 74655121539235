import { Radio, RadioProps, FormControlLabel } from '@mui/material'
import { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

interface IProps extends RadioProps {
  className?: string
  label: ReactNode
}
const PrimaryRadio = forwardRef<HTMLInputElement, IProps>(({ className, label, ...rest }: IProps, ref) => {
  return <FormControlLabel ref={ref} className={className} control={<Radio {...rest} />} label={label} />
})

PrimaryRadio.displayName = 'PrimaryRadio'

export default styled(PrimaryRadio)`
  .MuiTypography-root {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20px;
  }
`
