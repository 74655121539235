/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICompanyState } from '../../types/company'

interface CompanyActions {
  type:
    | 'REGISTER_COMPANY'
    | 'REGISTER_COMPANY_SUCCESS'
    | 'REGISTER_COMPANY_ERROR'
    | 'GET_COMPANY_INFOS'
    | 'GET_COMPANY_INFOS_SUCCESS'
    | 'GET_COMPANY_INFOS_ERROR'
    | 'GET_DEFAULT_COMPANY'
    | 'GET_DEFAULT_COMPANY_SUCCESS'
    | 'GET_DEFAULT_COMPANY_ERROR'
    | 'REGISTER_COMPANY_LEGAL_REPRESENTATIVE'
    | 'REGISTER_COMPANY_LEGAL_REPRESENTATIVE_SUCCESS'
    | 'REGISTER_COMPANY_LEGAL_REPRESENTATIVE_ERROR'
    | 'DELETE_COMPANY_LEGAL_REPRESENTATIVE'
    | 'DELETE_COMPANY_LEGAL_REPRESENTATIVE_SUCCESS'
    | 'DELETE_COMPANY_LEGAL_REPRESENTATIVE_ERROR'
    | 'GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID'
    | 'GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID_ERROR'
    | 'REGISTER_COMPANY_PARTNER'
    | 'REGISTER_COMPANY_PARTNER_SUCCESS'
    | 'REGISTER_COMPANY_PARTNER_ERROR'
    | 'DELETE_COMPANY_PARTNER'
    | 'DELETE_COMPANY_PARTNER_SUCCESS'
    | 'DELETE_COMPANY_PARTNER_ERROR'
    | 'GET_COMPANY_PARTNERS_BY_COMPANY_ID'
    | 'GET_COMPANY_PARTNERS_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_PARTNERS_BY_COMPANY_ID_ERROR'
    | 'DELETE_COMPANY_CUSTOMER'
    | 'DELETE_COMPANY_CUSTOMER_SUCCESS'
    | 'DELETE_COMPANY_CUSTOMER_ERROR'
    | 'DELETE_COMPANY_SUPPLIER'
    | 'DELETE_COMPANY_SUPPLIER_SUCCESS'
    | 'DELETE_COMPANY_SUPPLIER_ERROR'
    | 'REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER'
    | 'REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER_SUCCESS'
    | 'REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER_ERROR'
    | 'GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID'
    | 'GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID_ERROR'
    | 'GET_WITNESSES_BY_COMPANY_ID'
    | 'GET_WITNESSES_BY_COMPANY_ID_SUCCESS'
    | 'GET_WITNESSES_BY_COMPANY_ID_ERROR'
    | 'REGISTER_WITNESS'
    | 'REGISTER_WITNESS_SUCCESS'
    | 'REGISTER_WITNESS_ERROR'
    | 'REGISTER_COMPANY_TAXES'
    | 'REGISTER_COMPANY_TAXES_SUCCESS'
    | 'REGISTER_COMPANY_TAXES_ERROR'
    | 'GET_COMPANY_TAXES_BY_COMPANY_ID'
    | 'GET_COMPANY_TAXES_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_TAXES_BY_COMPANY_ID_ERROR'
    | 'GET_COMPANY_USERS_BY_COMPANY_ID'
    | 'GET_COMPANY_USERS_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_USERS_BY_COMPANY_ID_ERROR'
    | 'REGISTER_COMPANY_USER'
    | 'REGISTER_COMPANY_USER_SUCCESS'
    | 'REGISTER_COMPANY_USER_ERROR'
    | 'UPDATE_COMPANY_USER'
    | 'UPDATE_COMPANY_USER_SUCCESS'
    | 'UPDATE_COMPANY_USER_ERROR'
    | 'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID'
    | 'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_ERROR'
    | 'DELETE_COMPANY_DOCUMENT'
    | 'DELETE_COMPANY_DOCUMENT_SUCCESS'
    | 'DELETE_COMPANY_DOCUMENT_ERROR'
    | 'UPLOAD_COMPANY_DOCUMENT'
    | 'UPLOAD_COMPANY_DOCUMENT_SUCCESS'
    | 'UPLOAD_COMPANY_DOCUMENT_ERROR'
    | 'CLOSE_COMPANY_TAXES_MODAL'
    | 'CLOSE_COMPANY_INFOS_MODAL'
    | 'GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID'
    | 'GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID_ERROR'
    | 'DELETE_COMPANY_BANK_ACCOUNT_INFO'
    | 'DELETE_COMPANY_BANK_ACCOUNT_INFO_SUCCESS'
    | 'DELETE_COMPANY_BANK_ACCOUNT_INFO_ERROR'
    | 'TOGGLE_COMPANY_BANK_ACCOUNT_INFO_MODAL'
    | 'REGISTER_COMPANY_BANK_ACCOUNT_INFO'
    | 'REGISTER_COMPANY_BANK_ACCOUNT_INFO_SUCCESS'
    | 'REGISTER_COMPANY_BANK_ACCOUNT_INFO_ERROR'
    | 'SEND_TO_ANALYSIS'
    | 'SEND_TO_ANALYSIS_SUCCESS'
    | 'SEND_TO_ANALYSIS_ERROR'
    | 'CLOSE_SEND_TO_ANALYSIS_MODAL'
    | 'GET_COMPANY_REVENUE'
    | 'GET_COMPANY_REVENUE_SUCCESS'
    | 'GET_COMPANY_REVENUE_ERROR'
    | 'SEND_COMPANY_REVENUE'
    | 'SEND_COMPANY_REVENUE_SUCCESS'
    | 'SEND_COMPANY_REVENUE_ERROR'
    | 'GET_CONTRACTS_BY_COMPANY_ID'
    | 'GET_CONTRACTS_BY_COMPANY_ID_SUCCESS'
    | 'GET_CONTRACTS_BY_COMPANY_ID_ERROR'
    | 'GET_CONTRACT_DOWNLOAD_URL'
    | 'GET_CONTRACT_DOWNLOAD_URL_SUCCESS'
    | 'GET_CONTRACT_DOWNLOAD_URL_ERROR'
    | 'UPDATE_COMPANY_BANK_ACCOUNT_INFO'
    | 'UPDATE_COMPANY_BANK_ACCOUNT_INFO_SUCCESS'
    | 'UPDATE_COMPANY_BANK_ACCOUNT_INFO_ERROR'
    | 'GET_COMPANIES_FROM_USER'
    | 'GET_COMPANIES_FROM_USER_SUCCESS'
    | 'GET_COMPANIES_FROM_USER_ERROR'
    | 'UPDATE_DEFAULT_COMPANY'
    | 'UPDATE_DEFAULT_COMPANY_SUCCESS'
    | 'UPDATE_DEFAULT_COMPANY_ERROR'
    | 'GET_CUSTOMERS'
    | 'GET_CUSTOMERS_SUCCESS'
    | 'GET_CUSTOMERS_ERROR'
    | 'GET_CUSTOMER_PROFILE'
    | 'GET_CUSTOMER_PROFILE_SUCCESS'
    | 'GET_CUSTOMER_PROFILE_ERROR'
    | 'GET_COMPANY_RATING_BY_COMPANY_ID'
    | 'GET_COMPANY_RATING_BY_COMPANY_ID_SUCCESS'
    | 'GET_COMPANY_RATING_BY_COMPANY_ID_ERROR'
    | 'GET_CUSTOMER_DATA_BY_COMPANY_ID'
    | 'GET_CUSTOMER_DATA_BY_COMPANY_ID_SUCCESS'
    | 'GET_CUSTOMER_DATA_BY_COMPANY_ID_ERROR'
    | 'MUTATE_COMPANY_RATINGS'
    | 'MUTATE_COMPANY_RATINGS_SUCCESS'
    | 'MUTATE_COMPANY_RATINGS_ERROR'
    | 'CLEAR_COMPANY_RATING'
    | 'GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID'
    | 'GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID_SUCCESS'
    | 'IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE'
    | 'IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE_SUCCESS'
    | 'IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE_ERROR'
    | 'GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS'
    | 'GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS_SUCCESS'
    | 'GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS_ERROR'
    | 'CLEAR_ZIP_DOWNLOAD_URL'
    | 'GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP'
    | 'GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP_SUCCESS'
    | 'GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP_ERROR'
    | 'CLEAR_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP'
    | 'CREATE_RATING_REASONS'
    | 'CREATE_RATING_REASONS_SUCCESS'
    | 'CREATE_RATING_REASONS_ERROR'
    | 'CLEAR_CREATE_RATING_REASONS'
    | 'GET_REPUTATION_BY_COMPANY_ID'
    | 'GET_REPUTATION_BY_COMPANY_ID_SUCCESS'
    | 'GET_REPUTATION_BY_COMPANY_ID_ERROR'
    | 'GET_CUSTOMER_REPUTATION_FROM_METABASE'
    | 'GET_CUSTOMER_REPUTATION_FROM_METABASE_SUCCESS'
    | 'GET_CUSTOMER_REPUTATION_FROM_METABASE_ERROR'
    | 'GET_CUSTOMER_ALL_DOCUMENTS_NAMES'
    | 'GET_CUSTOMER_ALL_DOCUMENTS_NAMES_SUCCESS'
    | 'GET_CUSTOMER_ALL_DOCUMENTS_NAMES_ERROR'
    | 'GET_CUSTOMER_GROUP_FILES_BY_URL'
    | 'GET_CUSTOMER_GROUP_FILES_BY_URL_SUCCESS'
    | 'GET_CUSTOMER_GROUP_FILES_BY_URL_ERROR'
    | 'GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL'
    | 'GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL_SUCCESS'
    | 'GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL_ERROR'
    | 'CLEAR_CUSTOMER_GROUP_FILES_URL'
    | 'CLEAR_ALL_FILES_URL'
    | 'GET_BANK_ACCOUNTS'
    | 'GET_BANK_ACCOUNTS_SUCCESS'
    | 'GET_BANK_ACCOUNTS_ERROR'
    | 'GET_BANK_ACCOUNTS_PERMISSIONS'
    | 'GET_BANK_ACCOUNTS_PERMISSIONS_SUCCESS'
    | 'GET_BANK_ACCOUNTS_PERMISSIONS_ERROR'

  payload?: any
}

const actionHandler = (
  payload: any
): {
  [key: string]: Partial<ICompanyState>
} => ({
  REGISTER_COMPANY: {
    isRegistratingCompany: true
  },
  REGISTER_COMPANY_SUCCESS: {
    isRegistratingCompany: false,
    registratedCompany: payload,
    companyInfosModal: true
  },
  CLOSE_COMPANY_INFOS_MODAL: {
    companyInfosModal: false
  },
  REGISTER_COMPANY_ERROR: {
    isRegistratingCompany: false,
    error: payload
  },
  GET_COMPANY_INFOS: {
    isGettingCompanyInfos: true
  },
  GET_COMPANY_INFOS_SUCCESS: {
    isGettingCompanyInfos: false,
    companyInfos: payload
  },
  GET_COMPANY_INFOS_ERROR: {
    isGettingCompanyInfos: false,
    error: payload
  },
  GET_DEFAULT_COMPANY: {
    isGettingDefaultCompany: true
  },
  GET_DEFAULT_COMPANY_SUCCESS: {
    isGettingDefaultCompany: false,
    defaultCompany: payload
  },
  GET_DEFAULT_COMPANY_ERROR: {
    isGettingDefaultCompany: false,
    error: payload
  },
  REGISTER_COMPANY_LEGAL_REPRESENTATIVE: {
    isRegistratingCompanyLegalRepresentatives: true
  },
  REGISTER_COMPANY_LEGAL_REPRESENTATIVE_SUCCESS: {
    isRegistratingCompanyLegalRepresentatives: false,
    registratedCompanyLegalRepresentative: payload
  },
  REGISTER_COMPANY_LEGAL_REPRESENTATIVE_ERROR: {
    isRegistratingCompanyLegalRepresentatives: false,
    error: payload
  },
  DELETE_COMPANY_LEGAL_REPRESENTATIVE: {
    isDeletingCompanyLegalRepresentatives: true
  },
  DELETE_COMPANY_LEGAL_REPRESENTATIVE_SUCCESS: {
    isDeletingCompanyLegalRepresentatives: false
  },
  DELETE_COMPANY_LEGAL_REPRESENTATIVE_ERROR: {
    isDeletingCompanyLegalRepresentatives: false,
    error: payload
  },
  GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID: {
    isGettingCompanyLegalRepresentativesByCompanyId: true
  },
  GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyLegalRepresentativesByCompanyId: false,
    companyLegalRepresentatives: payload
  },
  GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID_ERROR: {
    isGettingCompanyLegalRepresentativesByCompanyId: false,
    error: payload
  },
  REGISTER_COMPANY_PARTNER: {
    isRegistratingCompanyPartner: true
  },
  REGISTER_COMPANY_PARTNER_SUCCESS: {
    isRegistratingCompanyPartner: false,
    registratedCompanyPartner: payload
  },
  REGISTER_COMPANY_PARTNER_ERROR: {
    isRegistratingCompanyPartner: false,
    error: payload
  },
  DELETE_COMPANY_PARTNER: {
    isDeletingCompanyPartner: true
  },
  DELETE_COMPANY_PARTNER_SUCCESS: {
    isDeletingCompanyPartner: false
  },
  DELETE_COMPANY_PARTNER_ERROR: {
    isDeletingCompanyPartner: false,
    error: payload
  },
  GET_COMPANY_PARTNERS_BY_COMPANY_ID: {
    isGettingCompanyPartnersByCompanyId: true
  },
  GET_COMPANY_PARTNERS_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyPartnersByCompanyId: false,
    companyPartners: payload
  },
  GET_COMPANY_PARTNERS_BY_COMPANY_ID_ERROR: {
    isGettingCompanyPartnersByCompanyId: false,
    error: payload
  },
  REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER: {
    isRegistratingCompanyCustomerOrSupplier: true
  },
  REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER_SUCCESS: {
    isRegistratingCompanyCustomerOrSupplier: false,
    registratedCompanyCustomerOrSupplier: payload
  },
  REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER_ERROR: {
    isRegistratingCompanyCustomerOrSupplier: false,
    error: payload
  },
  DELETE_COMPANY_CUSTOMER: {
    isDeletingCompanyCustomer: true
  },
  DELETE_COMPANY_CUSTOMER_SUCCESS: {
    isDeletingCompanyCustomer: false
  },
  DELETE_COMPANY_CUSTOMER_ERROR: {
    isDeletingCompanyCustomer: false,
    error: payload
  },
  DELETE_COMPANY_SUPPLIER: {
    isDeletingCompanySupplier: true
  },
  DELETE_COMPANY_SUPPLIER_SUCCESS: {
    isDeletingCompanySupplier: false
  },
  DELETE_COMPANY_SUPPLIER_ERROR: {
    isDeletingCompanySupplier: false,
    error: payload
  },
  GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID: {
    isGettingCompanyCustomersAndSuppliersByCompanyId: true
  },
  GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyCustomersAndSuppliersByCompanyId: false,
    companyCustomersAndSuppliers: payload
  },
  GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID_ERROR: {
    isGettingCompanyCustomersAndSuppliersByCompanyId: false,
    error: payload
  },
  GET_WITNESSES_BY_COMPANY_ID: {
    isGettingWitnessessByCompanyId: true
  },
  GET_WITNESSES_BY_COMPANY_ID_SUCCESS: {
    isGettingWitnessessByCompanyId: false,
    witnesses: payload
  },
  GET_WITNESSES_BY_COMPANY_ID_ERROR: {
    isGettingWitnessessByCompanyId: false,
    error: payload
  },
  REGISTER_WITNESS: {
    isRegistratingWitness: true
  },
  REGISTER_WITNESS_SUCCESS: {
    isRegistratingWitness: false,
    registratedWitness: payload
  },
  REGISTER_WITNESS_ERROR: {
    isRegistratingWitness: false,
    error: payload
  },
  REGISTER_COMPANY_TAXES: {
    isRegistratingCompanyTaxes: true
  },
  REGISTER_COMPANY_TAXES_SUCCESS: {
    isRegistratingCompanyTaxes: false,
    companyTaxesModal: true,
    registratedCompanyTaxes: payload
  },
  REGISTER_COMPANY_TAXES_ERROR: {
    isRegistratingCompanyTaxes: false,
    error: payload
  },
  CLOSE_COMPANY_TAXES_MODAL: {
    companyTaxesModal: false
  },
  GET_COMPANY_TAXES_BY_COMPANY_ID: {
    isGettingCompanyTaxesByCompanyId: true
  },
  GET_COMPANY_TAXES_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyTaxesByCompanyId: false,
    companyTaxes: payload
  },
  GET_COMPANY_TAXES_BY_COMPANY_ID_ERROR: {
    isGettingCompanyTaxesByCompanyId: false,
    error: payload
  },
  GET_COMPANY_USERS_BY_COMPANY_ID: {
    isGettingCompanyUsersByCompanyId: true
  },
  GET_COMPANY_USERS_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyUsersByCompanyId: false,
    companyUsers: payload
  },
  GET_COMPANY_USERS_BY_COMPANY_ID_ERROR: {
    isGettingCompanyUsersByCompanyId: false,
    error: payload
  },
  REGISTER_COMPANY_USER: {
    isRegistratingCompanyUser: true
  },
  REGISTER_COMPANY_USER_SUCCESS: {
    isRegistratingCompanyUser: false,
    registratedCompanyUser: payload
  },
  REGISTER_COMPANY_USER_ERROR: {
    isRegistratingCompanyUser: false,
    error: payload
  },
  UPDATE_COMPANY_USER: {
    isUpdatingCompanyUser: true
  },
  UPDATE_COMPANY_USER_SUCCESS: {
    isUpdatingCompanyUser: false,
    updatedCompanyUser: payload
  },
  UPDATE_COMPANY_USER_ERROR: {
    isUpdatingCompanyUser: false,
    error: payload
  },
  GET_COMPANY_DOCUMENTS_BY_COMPANY_ID: {
    isGettingCompanyDocumentsByCompanyId: true
  },
  GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyDocumentsByCompanyId: false,
    companyDocuments: payload
  },
  GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_ERROR: {
    isGettingCompanyDocumentsByCompanyId: false,
    error: payload
  },
  DELETE_COMPANY_DOCUMENT: {
    isDeletingCompanyDocument: true
  },
  DELETE_COMPANY_DOCUMENT_SUCCESS: {
    isDeletingCompanyDocument: false
  },
  DELETE_COMPANY_DOCUMENT_ERROR: {
    isDeletingCompanyDocument: false,
    error: payload
  },
  UPLOAD_COMPANY_DOCUMENT: {
    isUploadingCompanyDocument: true
  },
  UPLOAD_COMPANY_DOCUMENT_SUCCESS: {
    isUploadingCompanyDocument: false,
    uploadedCompanyDocument: payload
  },
  UPLOAD_COMPANY_DOCUMENT_ERROR: {
    isUploadingCompanyDocument: false,
    error: payload
  },
  GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID: {
    isGettingCompanyBankAccountInfoByCompanyId: true
  },
  GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyBankAccountInfoByCompanyId: false,
    companyBankAccountInfos: payload
  },
  GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID_ERROR: {
    isGettingCompanyBankAccountInfoByCompanyId: false,
    error: payload
  },
  DELETE_COMPANY_BANK_ACCOUNT_INFO: {
    isDeletingCompanyBankAccountInfo: true
  },
  DELETE_COMPANY_BANK_ACCOUNT_INFO_SUCCESS: {
    isDeletingCompanyBankAccountInfo: false,
    deleteCompanyBankAccountInfoModal: { open: false, id: '' },
    companyBankAccountInfos: payload
  },
  DELETE_COMPANY_BANK_ACCOUNT_INFO_ERROR: {
    isDeletingCompanyBankAccountInfo: false,
    deleteCompanyBankAccountInfoModal: { open: false, id: '' },
    error: payload
  },
  TOGGLE_COMPANY_BANK_ACCOUNT_INFO_MODAL: {
    deleteCompanyBankAccountInfoModal: payload
  },
  REGISTER_COMPANY_BANK_ACCOUNT_INFO: {
    isRegistratingBankAccountInfo: true
  },
  REGISTER_COMPANY_BANK_ACCOUNT_INFO_SUCCESS: {
    isRegistratingBankAccountInfo: false,
    registratedBankAccountInfo: payload
  },
  REGISTER_COMPANY_BANK_ACCOUNT_INFO_ERROR: {
    isRegistratingBankAccountInfo: false,
    error: payload
  },
  SEND_TO_ANALYSIS: {
    isSendingToAnalysis: true
  },
  SEND_TO_ANALYSIS_SUCCESS: {
    isSendingToAnalysis: false,
    sendToAnalysisModal: true,
    sendedToAnalysis: payload
  },
  SEND_TO_ANALYSIS_ERROR: {
    isSendingToAnalysis: false,
    error: payload
  },
  CLOSE_SEND_TO_ANALYSIS_MODAL: {
    sendToAnalysisModal: false
  },
  GET_COMPANY_REVENUE: {
    isGettingCompanyRevenue: true
  },
  GET_COMPANY_REVENUE_SUCCESS: {
    isGettingCompanyRevenue: false,
    companyRevenue: payload
  },
  GET_COMPANY_REVENUE_ERROR: {
    isGettingCompanyRevenue: false,
    error: payload
  },
  SEND_COMPANY_REVENUE: {
    isSendingCompanyRevenue: true
  },
  SEND_COMPANY_REVENUE_SUCCESS: {
    isSendingCompanyRevenue: false,
    companyRevenue: payload
  },
  SEND_COMPANY_REVENUE_ERROR: {
    isSendingCompanyRevenue: false,
    error: payload
  },
  GET_CONTRACTS_BY_COMPANY_ID: {
    isGettingContractsByCompanyId: true
  },
  GET_CONTRACTS_BY_COMPANY_ID_SUCCESS: {
    isGettingContractsByCompanyId: false,
    contracts: payload
  },
  GET_CONTRACTS_BY_COMPANY_ID_ERROR: {
    isGettingContractsByCompanyId: false,
    error: payload
  },
  GET_CONTRACT_DOWNLOAD_URL: {
    isGettingContractDownloadUrl: true
  },
  GET_CONTRACT_DOWNLOAD_URL_SUCCESS: {
    isGettingContractDownloadUrl: false
  },
  GET_CONTRACT_DOWNLOAD_URL_ERROR: {
    isGettingContractDownloadUrl: false,
    error: payload
  },
  UPDATE_COMPANY_BANK_ACCOUNT_INFO: {
    isUpdatingBankAccountInfo: true
  },
  UPDATE_COMPANY_BANK_ACCOUNT_INFO_SUCCESS: {
    isUpdatingBankAccountInfo: false,
    updatedBankAccountInfo: payload
  },
  UPDATE_COMPANY_BANK_ACCOUNT_INFO_ERROR: {
    isUpdatingBankAccountInfo: false,
    error: payload
  },
  GET_COMPANIES_FROM_USER: {
    isGettingCompaniesFromUser: true
  },
  GET_COMPANIES_FROM_USER_SUCCESS: {
    isGettingCompaniesFromUser: false,
    companiesFromUser: payload
  },
  GET_COMPANIES_FROM_USER_ERROR: {
    isGettingCompaniesFromUser: false,
    error: payload
  },
  UPDATE_DEFAULT_COMPANY: {
    isUpdatingDefaultCompany: true
  },
  UPDATE_DEFAULT_COMPANY_SUCCESS: {
    isUpdatingDefaultCompany: false,
    updatedCompany: payload
  },
  UPDATE_DEFAULT_COMPANY_ERROR: {
    isUpdatingDefaultCompany: false,
    error: payload
  },
  GET_CUSTOMERS: {
    isGettingCustomers: true
  },
  GET_CUSTOMERS_SUCCESS: {
    isGettingCustomers: false,
    customers: payload
  },
  GET_CUSTOMERS_ERROR: {
    isGettingCustomers: false,
    error: payload,
    customers: {
      data: [],
      pagination: {
        totalItems: 0,
        totalPages: 0
      }
    }
  },
  GET_CUSTOMER_PROFILE: {
    isGettingCustomerProfile: true
  },
  GET_CUSTOMER_PROFILE_SUCCESS: {
    isGettingCustomerProfile: false,
    customerProfile: payload
  },
  GET_CUSTOMER_PROFILE_ERROR: {
    isGettingCustomerProfile: false,
    error: payload
  },
  GET_COMPANY_RATING_BY_COMPANY_ID: {
    isGettingCompanyRatingByCompanyId: true
  },
  GET_COMPANY_RATING_BY_COMPANY_ID_SUCCESS: {
    isGettingCompanyRatingByCompanyId: false,
    companyRating: payload
  },
  GET_COMPANY_RATING_BY_COMPANY_ID_ERROR: {
    isGettingCompanyRatingByCompanyId: false,
    error: payload
  },
  GET_CUSTOMER_DATA_BY_COMPANY_ID: {
    isGettingCustomerDataByCompanyId: true
  },
  GET_CUSTOMER_DATA_BY_COMPANY_ID_SUCCESS: {
    isGettingCustomerDataByCompanyId: false,
    customerData: payload
  },
  GET_CUSTOMER_DATA_BY_COMPANY_ID_ERROR: {
    isGettingCustomerDataByCompanyId: false,
    error: payload
  },
  MUTATE_COMPANY_RATINGS: {
    isMutatingCompanyRatings: true
  },
  MUTATE_COMPANY_RATINGS_SUCCESS: {
    isMutatingCompanyRatings: false,
    companyRating: payload
  },
  MUTATE_COMPANY_RATINGS_ERROR: {
    isMutatingCompanyRatings: false,
    error: payload
  },
  CLEAR_COMPANY_RATING: {
    companyRating: {
      companyId: '',
      id: '',
      companyRating: '',
      buyerId: ''
    }
  },
  GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID: {
    isGettingCreditAnalysisReportByCompanyId: true
  },
  GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID_SUCCESS: {
    isGettingCreditAnalysisReportByCompanyId: false,
    reports: payload
  },
  GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID_ERROR: {
    isGettingCreditAnalysisReportByCompanyId: false,
    error: payload
  },
  IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE: {
    isGettingInDossieDocumentsGroupedByDocumentType: true
  },
  IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE_SUCCESS: {
    isGettingInDossieDocumentsGroupedByDocumentType: false,
    inDossieDocuments: payload
  },
  IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE_ERROR: {
    isGettingInDossieDocumentsGroupedByDocumentType: false,
    error: payload
  },
  GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS: {
    isGettingZipDownloadUrlFromAllDocuments: true
  },
  GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS_SUCCESS: {
    isGettingZipDownloadUrlFromAllDocuments: false,
    zipDownloadUrlFromAllDocuments: payload
  },
  GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS_ERROR: {
    isGettingZipDownloadUrlFromAllDocuments: false,
    error: payload
  },
  CLEAR_ZIP_DOWNLOAD_URL: {
    zipDownloadUrlFromAllDocuments: ''
  },
  GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP: {
    isGettingZipDownloadUrlByDocumentGroup: true
  },
  GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP_SUCCESS: {
    isGettingZipDownloadUrlByDocumentGroup: false,
    zipDownloadUrlByDocumentGroup: payload
  },
  GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP_ERROR: {
    isGettingZipDownloadUrlByDocumentGroup: false,
    error: payload
  },
  CLEAR_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP: {
    zipDownloadUrlByDocumentGroup: ''
  },
  CREATE_RATING_REASONS: {
    isCreatingRatingReasons: true
  },
  CREATE_RATING_REASONS_SUCCESS: {
    isCreatingRatingReasons: false,
    ratingReasons: payload
  },
  CREATE_RATING_REASONS_ERROR: {
    isCreatingRatingReasons: false,
    error: payload
  },
  CLEAR_CREATE_RATING_REASONS: {
    isCreatingRatingReasons: false,
    ratingReasons: { id: '' }
  },

  GET_REPUTATION_BY_COMPANY_ID: {
    isGettingReputationByCompanyId: true
  },

  GET_REPUTATION_BY_COMPANY_ID_SUCCESS: {
    isGettingReputationByCompanyId: false,
    customerReputation: payload
  },

  GET_REPUTATION_BY_COMPANY_ID_ERROR: {
    isGettingReputationByCompanyId: false,
    error: payload
  },
  GET_CUSTOMER_REPUTATION_FROM_METABASE: {
    isGettingCustomerReputationFromMetabase: true
  },

  GET_CUSTOMER_REPUTATION_FROM_METABASE_SUCCESS: {
    isGettingCustomerReputationFromMetabase: false,
    customerReputationMetabase: payload
  },

  GET_CUSTOMER_REPUTATION_FROM_METABASE_ERROR: {
    isGettingCustomerReputationFromMetabase: false,
    error: payload
  },

  GET_CUSTOMER_ALL_DOCUMENTS_NAMES: {
    isGettingCustomerAllDocumentsNames: true
  },

  GET_CUSTOMER_ALL_DOCUMENTS_NAMES_SUCCESS: {
    isGettingCustomerAllDocumentsNames: false,
    allDocumentsNames: payload
  },

  GET_CUSTOMER_ALL_DOCUMENTS_NAMES_ERROR: {
    isGettingCustomerAllDocumentsNames: false,
    error: payload
  },

  GET_CUSTOMER_GROUP_FILES_BY_URL: {
    isGettingCustomerGroupFilesByURL: true
  },

  GET_CUSTOMER_GROUP_FILES_BY_URL_SUCCESS: {
    isGettingCustomerGroupFilesByURL: false,
    documentsGroupDownload: payload
  },

  GET_CUSTOMER_GROUP_FILES_BY_URL_ERROR: {
    isGettingCustomerGroupFilesByURL: false,
    error: payload
  },

  CLEAR_CUSTOMER_GROUP_FILES_URL: {
    documentsGroupDownload: { url: '' }
  },

  GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL: {
    isGettingCustomerAllDocumentsFilesByURL: true
  },

  GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL_SUCCESS: {
    isGettingCustomerAllDocumentsFilesByURL: false,
    allDocumentsDownload: payload
  },

  GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL_ERROR: {
    isGettingCustomerAllDocumentsFilesByURL: false,
    error: payload
  },

  CLEAR_ALL_FILES_URL: {
    allDocumentsDownload: { allDocumentsUrl: '' }
  },

  GET_BANK_ACCOUNTS: {
    isGettingBankAccounts: true
  },

  GET_BANK_ACCOUNTS_SUCCESS: {
    isGettingBankAccounts: false,
    bankAccounts: payload
  },

  GET_BANK_ACCOUNTS_ERROR: {
    isGettingBankAccounts: false,
    error: payload
  },

  GET_BANK_ACCOUNTS_PERMISSIONS: {
    isGettingBankAccountsPermissions: true
  },

  GET_BANK_ACCOUNTS_PERMISSIONS_SUCCESS: {
    isGettingBankAccountsPermissions: false,
    bankAccountsPermissions: payload
  },

  GET_BANK_ACCOUNTS_PERMISSIONS_ERROR: {
    isGettingBankAccountsPermissions: false,
    error: payload
  }
})

const CompanyReducer = (state: ICompanyState, action: CompanyActions) => {
  const updateProperties = actionHandler(action.payload)[action.type]
  return { ...state, ...updateProperties }
}

export default CompanyReducer
