import { useCallback, useEffect, useMemo, useState } from 'react'
import Auth from '../../service/auth'

interface IUseAuth {
  loggedIn: boolean
  loading: boolean
  groups: Array<'Administrador' | 'Cedente' | 'Financiador'>
  userId?: string
}

export const useAuth = (): IUseAuth => {
  const auth = useMemo(() => new Auth(), [])

  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [groups, setGroups] = useState<Array<'Administrador' | 'Cedente' | 'Financiador'>>([])
  const [userId, setUserId] = useState('')

  const isAuthenticated = useCallback(async () => {
    try {
      setLoading(true)
      const user = await auth?.currentAuthenticatedUser()
      setGroups(user?.groups)
      setUserId(user?.userId as string)
      setLoggedIn(user?.isAuthenticated)
    } catch {
      setLoggedIn(false)
    } finally {
      setLoading(false)
    }
  }, [auth])

  useEffect(() => {
    isAuthenticated()

    return () => {
      setLoggedIn(false)
      setLoading(false)
    }
  }, [isAuthenticated])

  return {
    loggedIn,
    loading,
    groups,
    userId
  }
}
