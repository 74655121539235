import { useContext, useEffect } from 'react'
import { OriginContext } from '../../states/origin/OriginContext'
import { theme } from '../../styles/theme'
import { translateGroup } from '../../utils/auth'

export const useBuyerThemeColor = (groups: Array<'Cedente' | 'Financiador'>): void => {
  const { setTheme, dynamicTheme } = useContext(OriginContext)

  useEffect(() => {
    if (groups?.length > 0 && translateGroup(groups?.[0]) === 'Buyer') {
      if (dynamicTheme?.colors?.primary === theme?.colors?.buyer) return

      setTheme({
        colors: {
          ...theme?.colors,
          primary: theme?.colors?.buyer
        },
        styleGuide: {
          ...theme?.styleGuide,
          dynamic: {
            ...theme?.styleGuide?.dynamic,
            primary: {
              ...theme?.styleGuide?.dynamic?.primary,
              main: theme?.colors?.buyer
            }
          }
        }
      })
    }
  }, [setTheme, groups, dynamicTheme])
}
