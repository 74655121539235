import React, { useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { useRandomNumberFromArray } from '../../hooks/useRandomNumberFromArray'

interface ICarouselProps {
  list: React.ReactNode[]
  timeOut: number
  width: string
  height: string
  minWidth?: string
  minHeight?: string
  maxWidth?: string
  maxHeight?: string
}

const Carousel = ({
  list,
  timeOut,
  width,
  height,
  minWidth = 'auto',
  minHeight = 'auto',
  maxWidth = 'auto',
  maxHeight = 'auto'
}: ICarouselProps) => {
  const { sorted, sortDifferentNumber } = useRandomNumberFromArray(list)

  useEffect(() => {
    const timer = window.setInterval(() => sortDifferentNumber(), timeOut)
    return () => clearInterval(timer)
  })

  return (
    <Container
      timeOut={timeOut}
      sorted={sorted}
      listWidth={list.length}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      minWidth={minWidth}
      minHeight={minHeight}
    >
      <div className="container-wrapper">
        {list.map((item, index) => (
          <Fragment key={index}>
            <Paragraph key={String(index)} sorted={sorted} position={list.indexOf(item)}>
              {item}
            </Paragraph>
          </Fragment>
        ))}
      </div>
    </Container>
  )
}

export default Carousel

const Container = styled.div<{
  timeOut: number
  sorted: number
  listWidth: number
  width: string
  height: string
  minWidth: string
  minHeight: string
  maxWidth: string
  maxHeight: string
}>`
  position: relative;
  ${({ width }) => `width: ${width}`};
  ${({ height }) => `height: ${height}`};
  ${({ maxWidth }) => `max-width: ${maxWidth}`};
  ${({ maxHeight }) => `max-height: ${maxHeight}`};
  ${({ minWidth }) => `min-width: ${minWidth}`};
  ${({ minHeight }) => `min-height: ${minHeight}`};

  .container-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Paragraph = styled.div<{ position: number; sorted: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: ${({ position, sorted }) => (position === sorted ? '1' : '0')};
  background: ${({ theme }) => theme?.colors?.gray?.smooth};
`
