import styled from 'styled-components'
import ModalWrapper from '../../commons/ModalWrapper'
import warning from '../../../assets/icons/warning.svg'
import PrimaryButton from '../../buttons/PrimaryButton'
import { FormEvent } from 'react'

interface IProps {
  className?: string
  open: boolean
  onClose: () => void
  submit: () => void
  isLoading: boolean
}

const CrudDeleteModal: React.FC<IProps> = ({ className, open, onClose, submit, isLoading }: IProps) => {
  const handleSubmit = (e: FormEvent) => {
    e?.preventDefault()
    submit()
  }

  return (
    <ModalWrapper open={open} className={className}>
      <form className="crud-delete-modal__wrapper" onSubmit={handleSubmit} data-testid="crud-delete-modal-test">
        <img src={warning} alt="Ícone de alerta amarelo" />
        <div className="create-delete-modal__text___wrapper">
          <h4>Atenção</h4>
          <p>
            Ao apagar uma informação, ela fica indisponível na plataforma imediatamente e essa operação não poderá ser
            desfeita.
          </p>
          <h5>Você tem certeza que deseja apagar essa informação?</h5>
        </div>
        <div className="create-delete-modal__button___wrapper">
          <PrimaryButton variant="outlined" onClick={onClose} data-testid="create-delete-modal-cancel-button-test">
            Cancelar
          </PrimaryButton>
          <PrimaryButton
            color="error"
            isLoading={isLoading}
            type="submit"
            data-testid="create-delete-modal-submit-button-test"
          >
            Confirmar
          </PrimaryButton>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default styled(CrudDeleteModal)`
  .crud-delete-modal__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .create-delete-modal__text___wrapper {
      margin-top: 16px;
      width: 314px;
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      h4 {
        font-size: 20px;
        font-weight: bold;
      }

      p {
        text-align: center;
        font-size: 14px;
        line-height: 24px;
      }

      h5 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
    }

    .create-delete-modal__button___wrapper {
      margin-top: 48px;
      width: 384px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
    }
  }
`
