import { Check } from '@mui/icons-material'
import styled from 'styled-components'

interface IProps {
  className?: string
  text: string
  isVerified: boolean
}

const PasswordVerifierItem: React.FC<IProps> = ({ className, text }: IProps) => {
  return (
    <div className={className}>
      <Check />
      <p>{text}</p>
    </div>
  )
}

export default styled(PasswordVerifierItem)`
  display: flex;
  gap: 8px;

  path {
    color: ${({ theme, isVerified }) =>
      isVerified ? theme?.styleGuide?.support?.green : theme?.colors?.gray?.lighter};
  }

  p {
    color: ${({ theme, isVerified }) => (isVerified ? theme?.styleGuide?.support?.green : theme?.colors?.gray?.light)};
  }
`
