import { CircularProgress, CircularProgressProps } from '@mui/material'
import styled from 'styled-components'

type Props = CircularProgressProps

const SecondaryLoading: React.FC<Props> = ({ ...rest }: Props) => {
  return <CircularProgress size={32} {...rest} />
}

export default styled(SecondaryLoading)``
