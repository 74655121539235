export interface IMenuItemProps {
  text: string
  isLastItem: boolean
  onItemClick?: () => void
  isActive?: boolean
}

const MenuItem = ({ text, isLastItem }: IMenuItemProps) => {
  const itemClass = isLastItem ? 'last-item' : ''

  return (
    <li className={itemClass}>
      <span id="menu-item-test">{text}</span>
    </li>
  )
}

export default MenuItem
