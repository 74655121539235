import { Skeleton } from '@mui/material'
import posthog from 'posthog-js'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const VirtualSectionSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className} data-testid="virtual-section-skeleton-test">
      <div className="virtual_section_skeleton-wrapper">
        {[...Array(posthog.isFeatureEnabled('downloadCard') ? 8 : 4)].map((_, index) => (
          <Skeleton key={index} animation="wave" />
        ))}
      </div>
    </div>
  )
}

export default styled(VirtualSectionSkeleton)`
  display: flex;
  flex-wrap: wrap;
  max-width: 768px;

  .virtual_section_skeleton-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 500px) {
      flex-direction: column;
    }

    span {
      flex-basis: calc(50% - 16px);
      min-height: 214px;
      max-height: 214px;
    }
  }
`
