import { Tooltip, TooltipProps, tooltipClasses, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'

interface IProps {
  children: React.ReactNode
  content: string
}

const CustomTooltip: React.FC<IProps> = ({ children, content }: IProps) => {
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:700px)')

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <>
      {isMobile ? (
        <StyledMobTooltip onClose={handleTooltipClose} open={open} title={content} arrow>
          <div onClick={handleTooltipOpen}>{children}</div>
        </StyledMobTooltip>
      ) : (
        <StyledWebTooltip title={content} arrow>
          <div>{children}</div>
        </StyledWebTooltip>
      )}
    </>
  )
}

const StyledWebTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '3px !important',
    padding: '4px 8px',
    fontSize: 14
  }
}))
const StyledMobTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '3px !important',
    padding: '4px 8px',
    fontSize: 14
  }
}))

export default CustomTooltip
