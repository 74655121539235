import { Navigate, Route, Routes } from 'react-router-dom'
import AuthState from '../../../states/auth/AuthState'
import AddressState from '../../../states/address/AddressState'
import { OnboardingRouter } from '../../../apps/onboarding/routes'
import { CustomerRoutes } from '../CustomerRoutes'
import { BuyerRoutes } from '../BuyerRoutes'
import { CommonRoutes } from '../CommonRoutes'
import { PrivateRoutes } from '../PrivateRoutes'
import { AnonymousRoutes } from '../AnonymousRoutes'

const Root = () => {
  return (
    <AuthState>
      <AddressState>
        <Routes>
          {/* Rotas que podem ser acessadas por usuários não autenticados */}
          {AnonymousRoutes}

          {/* Rotas que podem ser acessadas por todos os grupos, contudo, o usuário deverá estar autenticado */}
          {PrivateRoutes}

          {/* Rotas com o mesmo path, mas renderizando páginas diferentes de acordo com os grupos */}
          {CommonRoutes}

          {/* Rotas do módulo de Onboarding */}
          {OnboardingRouter}

          {/* Rotas acessadas somente por usuários Cedentes */}
          {CustomerRoutes}

          {/* Rotas acessadas somente por usuários Financiadores */}
          {BuyerRoutes}

          {/* Rota que o usuário será redirecionado caso ele digite uma rota que não exista */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AddressState>
    </AuthState>
  )
}

export default Root
