import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AccountInfos = {
  __typename?: 'AccountInfos'
  accountAgency: Scalars['String']
  accountNumber: Scalars['String']
}

export type Accounts = {
  __typename?: 'Accounts'
  accountDigit: Scalars['String']
  accountNumber: Scalars['String']
  agencyDigit: Scalars['String']
  agencyNumber: Scalars['String']
  availableBalance: Scalars['String']
  bankPartnerDescription: Scalars['String']
  bankPartnerName: Scalars['String']
  bankPartnerNumber: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['String']
}

export type Address = {
  __typename?: 'Address'
  city: Scalars['String']
  neighboorhood: Scalars['String']
  state: Scalars['String']
  streetName: Scalars['String']
  zipCode: Scalars['String']
}

export type AnswerQuizInput = {
  clientTypes: Array<InputMaybe<Scalars['String']>>
  companyId: Scalars['String']
  documentNumber: Scalars['String']
  hasReceipt: Scalars['Boolean']
  howMuchByMonth: Scalars['String']
  howYouMeetUs: Scalars['String']
  paymentTypes: Array<InputMaybe<Scalars['String']>>
  radialIncome: Scalars['String']
}

export type AnswerQuizOutput = {
  __typename?: 'AnswerQuizOutput'
  averageTax?: Maybe<Scalars['Float']>
  buyers?: Maybe<Scalars['Float']>
  companyId: Scalars['String']
  minTax?: Maybe<Scalars['Float']>
  status: Scalars['String']
}

export type BankAccount = {
  __typename?: 'BankAccount'
  accountAgency?: Maybe<Scalars['String']>
  accountDigit?: Maybe<Scalars['String']>
  accountNumber?: Maybe<Scalars['String']>
  bankCode?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isDefault: Scalars['Boolean']
  ispb?: Maybe<Scalars['String']>
}

export type BankAccountInfoInput = {
  accountAgency: Scalars['String']
  accountDigit?: InputMaybe<Scalars['String']>
  accountNumber: Scalars['String']
  bankCode?: InputMaybe<Scalars['String']>
  bankName: Scalars['String']
  companyId: Scalars['String']
  isDefault: Scalars['Boolean']
  ispb?: InputMaybe<Scalars['String']>
}

export type Business = {
  __typename?: 'Business'
  activity?: Maybe<Scalars['String']>
  activityDescription?: Maybe<Scalars['String']>
  birthDate?: Maybe<Scalars['String']>
  codeActivity?: Maybe<Scalars['String']>
  companySize?: Maybe<Scalars['String']>
  constitutionName?: Maybe<Scalars['String']>
  registerAddressCity?: Maybe<Scalars['String']>
  registerAddressComplement?: Maybe<Scalars['String']>
  registerAddressNeighborhood?: Maybe<Scalars['String']>
  registerAddressNumber?: Maybe<Scalars['String']>
  registerAddressState?: Maybe<Scalars['String']>
  registerAddressStreet?: Maybe<Scalars['String']>
  registerAddressZipCode?: Maybe<Scalars['String']>
  registerDate?: Maybe<Scalars['String']>
}

export type BuyerType = {
  __typename?: 'BuyerType'
  buyerTypeId: Scalars['String']
  buyerTypeName: Scalars['String']
}

export type Color = {
  __typename?: 'Color'
  group: Scalars['String']
  primary: Scalars['String']
  secondary: Scalars['String']
}

export type CompanyBankAccountInfo = {
  __typename?: 'CompanyBankAccountInfo'
  accountAgency: Scalars['String']
  accountDigit?: Maybe<Scalars['String']>
  accountNumber: Scalars['String']
  bankCode?: Maybe<Scalars['String']>
  bankName: Scalars['String']
  companyName: Scalars['String']
  documentNumber: Scalars['String']
  id: Scalars['String']
  isDefault?: Maybe<Scalars['Boolean']>
  ispb?: Maybe<Scalars['String']>
  kind: Scalars['String']
}

export type CompanyCustomerOrSupplier = {
  __typename?: 'CompanyCustomerOrSupplier'
  companyId?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  customerId?: Maybe<Scalars['String']>
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  isCustomer?: Maybe<Scalars['String']>
  personTypeName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  statusId?: Maybe<Scalars['String']>
  supplierId?: Maybe<Scalars['String']>
}

export type CompanyCustomerOrSupplierInput = {
  companyId?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  customerId?: InputMaybe<Scalars['String']>
  documentNumber?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  isCustomer?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  statusId?: InputMaybe<Scalars['String']>
  supplierId?: InputMaybe<Scalars['String']>
}

export type CompanyDocument = {
  __typename?: 'CompanyDocument'
  companyId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  docTypeId?: Maybe<Scalars['String']>
  documentTypeId?: Maybe<Scalars['String']>
  documentTypeName?: Maybe<Scalars['String']>
  downloadUrl?: Maybe<Scalars['String']>
  exists?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  inDossie?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  lastModifiedDate?: Maybe<Scalars['String']>
  makeDocumentAvaliable?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  originTypeId?: Maybe<Scalars['String']>
  originTypeName?: Maybe<Scalars['String']>
  pendingId?: Maybe<Scalars['String']>
  registerDate?: Maybe<Scalars['String']>
  removePending?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  statusId?: Maybe<Scalars['String']>
  statusName?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  uploadStatusId?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  validUntil?: Maybe<Scalars['String']>
  validated?: Maybe<Scalars['String']>
}

export type CompanyInfos = {
  __typename?: 'CompanyInfos'
  activity?: Maybe<Scalars['String']>
  activityCode?: Maybe<Scalars['String']>
  activityDescription?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  buyerTypeId?: Maybe<Scalars['String']>
  buyerTypes: Array<BuyerType>
  companyName?: Maybe<Scalars['String']>
  companySizeId?: Maybe<Scalars['String']>
  companySizes: Array<CompanySize>
  constitutionId?: Maybe<Scalars['String']>
  constitutions: Array<Constitution>
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  registerAddressCity?: Maybe<Scalars['String']>
  registerAddressComplement?: Maybe<Scalars['String']>
  registerAddressNeighborhood?: Maybe<Scalars['String']>
  registerAddressNumber?: Maybe<Scalars['String']>
  registerAddressState?: Maybe<Scalars['String']>
  registerAddressStreet?: Maybe<Scalars['String']>
  registerAddressZipCode?: Maybe<Scalars['String']>
  taxRuleId?: Maybe<Scalars['String']>
  taxRules: Array<TaxRule>
  tradeName?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  workingAddressCity?: Maybe<Scalars['String']>
  workingAddressComplement?: Maybe<Scalars['String']>
  workingAddressNeighborhood?: Maybe<Scalars['String']>
  workingAddressNumber?: Maybe<Scalars['String']>
  workingAddressState?: Maybe<Scalars['String']>
  workingAddressStreet?: Maybe<Scalars['String']>
  workingAddressZipCode?: Maybe<Scalars['String']>
}

export type CompanyLegalRepresentative = {
  __typename?: 'CompanyLegalRepresentative'
  birthDate?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  personalDocument?: Maybe<Scalars['String']>
  personalDocumentEmitter?: Maybe<Scalars['String']>
  personalDocumentState?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type CompanyLegalRepresentativeInput = {
  birthDate?: InputMaybe<Scalars['String']>
  companyId?: InputMaybe<Scalars['String']>
  documentNumber?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  mobile?: InputMaybe<Scalars['String']>
  personalDocument?: InputMaybe<Scalars['String']>
  personalDocumentEmitter?: InputMaybe<Scalars['String']>
  personalDocumentState?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

export type CompanyPartner = {
  __typename?: 'CompanyPartner'
  addressCity?: Maybe<Scalars['String']>
  addressComplement?: Maybe<Scalars['String']>
  addressNeighborhood?: Maybe<Scalars['String']>
  addressNumber?: Maybe<Scalars['String']>
  addressState?: Maybe<Scalars['String']>
  addressStreetName?: Maybe<Scalars['String']>
  addressZipCode?: Maybe<Scalars['String']>
  birthDate?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyShareholding?: Maybe<Scalars['Float']>
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  hasEcpf?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  maritalStatusId?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  personalDocument?: Maybe<Scalars['String']>
  personalDocumentEmitter?: Maybe<Scalars['String']>
  personalDocumentState?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  signsForCompany?: Maybe<Scalars['String']>
  typeId?: Maybe<Scalars['String']>
}

export type CompanyPartnerInput = {
  addressCity?: InputMaybe<Scalars['String']>
  addressComplement?: InputMaybe<Scalars['String']>
  addressNeighborhood?: InputMaybe<Scalars['String']>
  addressNumber?: InputMaybe<Scalars['String']>
  addressState?: InputMaybe<Scalars['String']>
  addressStreetName?: InputMaybe<Scalars['String']>
  addressZipCode?: InputMaybe<Scalars['String']>
  birthDate?: InputMaybe<Scalars['String']>
  companyId?: InputMaybe<Scalars['String']>
  companyShareholding?: InputMaybe<Scalars['String']>
  documentNumber?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  hasEcpf?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  maritalStatusId?: InputMaybe<Scalars['String']>
  maritalStatusName?: InputMaybe<Scalars['String']>
  mobile?: InputMaybe<Scalars['String']>
  personalDocument?: InputMaybe<Scalars['String']>
  personalDocumentEmitter?: InputMaybe<Scalars['String']>
  personalDocumentState?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  signsForCompany?: InputMaybe<Scalars['String']>
  typeId?: InputMaybe<Scalars['String']>
}

export type CompanyProposalOutput = {
  __typename?: 'CompanyProposalOutput'
  companyName?: Maybe<Scalars['String']>
  dataStatus?: Maybe<Scalars['String']>
  documentNumber: Scalars['String']
  id: Scalars['String']
  status: Scalars['String']
}

export type CompanyRevenue = {
  __typename?: 'CompanyRevenue'
  amount: Scalars['Int']
  id: Scalars['String']
  kind?: Maybe<Scalars['String']>
  period: Scalars['String']
}

export type CompanySize = {
  __typename?: 'CompanySize'
  businessSizeId: Scalars['String']
  businessSizeName: Scalars['String']
}

export type CompanyTaxes = {
  __typename?: 'CompanyTaxes'
  adValorem?: Maybe<Scalars['String']>
  analysisTax?: Maybe<Scalars['String']>
  chargeTax?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  iofAmount?: Maybe<Scalars['String']>
  iofTerm?: Maybe<Scalars['String']>
  operationTax?: Maybe<Scalars['String']>
  settlementTerm?: Maybe<Scalars['String']>
}

export type CompanyTaxesInput = {
  adValorem?: InputMaybe<Scalars['String']>
  analysisTax?: InputMaybe<Scalars['String']>
  chargeTax?: InputMaybe<Scalars['String']>
  companyId?: InputMaybe<Scalars['String']>
  iofAmount?: InputMaybe<Scalars['String']>
  iofTerm?: InputMaybe<Scalars['String']>
  operationTax?: InputMaybe<Scalars['String']>
  settlementTerm?: InputMaybe<Scalars['String']>
}

export type CompanyUser = {
  __typename?: 'CompanyUser'
  companyId: Scalars['String']
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  id: Scalars['String']
  lastAccess?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type CompanyUserInput = {
  companyId: Scalars['String']
  documentNumber: Scalars['String']
  email: Scalars['String']
  fullName: Scalars['String']
  group: Scalars['String']
  mobile: Scalars['String']
  whiteLabelShortName: Scalars['String']
}

export type Constitution = {
  __typename?: 'Constitution'
  constitutionId: Scalars['String']
  constitutionName: Scalars['String']
}

export type CreateCompanyOutput = {
  __typename?: 'CreateCompanyOutput'
  companyName: Scalars['String']
  documentNumber: Scalars['String']
  id: Scalars['String']
}

export type CreateCompanyProposalInput = {
  documentNumber: Scalars['String']
  partnerId?: InputMaybe<Scalars['String']>
  profile: Scalars['String']
  whiteLabel: Scalars['String']
}

export type CreateCompanyProposalOutput = {
  __typename?: 'CreateCompanyProposalOutput'
  id?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type CreateRatingReasonsInput = {
  creditRatingsId: Scalars['String']
  description?: InputMaybe<Array<Scalars['String']>>
}

export type CreateRatingReasonsOutput = {
  __typename?: 'CreateRatingReasonsOutput'
  id: Scalars['String']
}

export type CreateUserInput = {
  companyId?: InputMaybe<Scalars['String']>
  documentNumber: Scalars['String']
  email: Scalars['String']
  mobile: Scalars['String']
  name: Scalars['String']
  whiteLabel: Scalars['String']
}

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput'
  companyId?: Maybe<Scalars['String']>
  email: Scalars['String']
  id: Scalars['String']
}

export type Customer = {
  __typename?: 'Customer'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type DefaultCompany = {
  __typename?: 'DefaultCompany'
  bankAccountTypeId: Scalars['String']
  businessId: Scalars['String']
  corporateName: Scalars['String']
  countryIdentify: Scalars['String']
  lastUpdateDate: Scalars['String']
  moskitCrmCompanyId?: Maybe<Scalars['String']>
  moskitCrmContactId?: Maybe<Scalars['String']>
  moskitCrmDealId?: Maybe<Scalars['String']>
  moskitCrmResponsibleId?: Maybe<Scalars['String']>
  motiveId: Scalars['String']
  motiveName: Scalars['String']
  name: Scalars['String']
  originId: Scalars['String']
  originType?: Maybe<Scalars['String']>
  originTypeId?: Maybe<Scalars['String']>
  partnerId?: Maybe<Scalars['String']>
  personTypeId: Scalars['String']
  personTypeName: Scalars['String']
  registerDate: Scalars['String']
  statusDate: Scalars['String']
  statusId: Scalars['String']
  statusName: Scalars['String']
  typeId: Scalars['String']
  typeName: Scalars['String']
}

export type DeleteCompanyBankAccountInfo = {
  __typename?: 'DeleteCompanyBankAccountInfo'
  id: Scalars['String']
}

export type DeletedCompanyCustomer = {
  __typename?: 'DeletedCompanyCustomer'
  id: Scalars['String']
}

export type DeletedCompanyDocument = {
  __typename?: 'DeletedCompanyDocument'
  id: Scalars['String']
}

export type DeletedCompanyLegalRepresentative = {
  __typename?: 'DeletedCompanyLegalRepresentative'
  id: Scalars['String']
}

export type DeletedCompanyPartner = {
  __typename?: 'DeletedCompanyPartner'
  id: Scalars['String']
}

export type DeletedCompanySupplier = {
  __typename?: 'DeletedCompanySupplier'
  id: Scalars['String']
}

export type DocumentGroup = {
  __typename?: 'DocumentGroup'
  id: Scalars['Float']
  name: Scalars['String']
}

export type Extract = {
  __typename?: 'Extract'
  records: Scalars['Int']
  totalCount: Scalars['Int']
  transactions: Array<Transaction>
}

export type ExtractInput = {
  accountId: Scalars['String']
  endDate: Scalars['String']
  initDate: Scalars['String']
  limit: Scalars['String']
  offset: Scalars['String']
}

export type GetBankAccountsOutput = {
  __typename?: 'GetBankAccountsOutput'
  code?: Maybe<Scalars['Int']>
  fullName?: Maybe<Scalars['String']>
  ispb?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type GetBankAccountsPermissionsInput = {
  companyId: Scalars['String']
}

export type GetBankAccountsPermissionsOutput = {
  __typename?: 'GetBankAccountsPermissionsOutput'
  allowCreate: Scalars['Boolean']
  allowDelete: Scalars['Boolean']
  allowRead: Scalars['Boolean']
  allowUpdate: Scalars['Boolean']
}

export type GetBusinessBankAccountsInput = {
  businessId: Scalars['String']
}

export type GetBusinessBankAccountsOutput = {
  __typename?: 'GetBusinessBankAccountsOutput'
  accounts: Array<Maybe<Accounts>>
}

export type GetCompanyFromUser = {
  __typename?: 'GetCompanyFromUser'
  cnpj: Scalars['String']
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  name: Scalars['String']
}

export type GetCompanyNotificationOutput = {
  __typename?: 'GetCompanyNotificationOutput'
  notifications: Array<Notification>
  unreadNotifications?: Maybe<Scalars['Int']>
}

export type GetCompanyRatingByCompanyIdOutput = {
  __typename?: 'GetCompanyRatingByCompanyIdOutput'
  buyerId: Scalars['String']
  companyId: Scalars['String']
  companyRating: Scalars['String']
  creditLimitValue?: Maybe<Scalars['String']>
  id: Scalars['String']
  maximumRate?: Maybe<Scalars['String']>
  minimumRate?: Maybe<Scalars['String']>
}

export type GetContractDownloadUrlOutput = {
  __typename?: 'GetContractDownloadUrlOutput'
  name: Scalars['String']
  url: Scalars['String']
}

export type GetContractsByCompanyIdOutput = {
  __typename?: 'GetContractsByCompanyIdOutput'
  contractId: Scalars['String']
  fileName: Scalars['String']
  id: Scalars['String']
  kind: Scalars['String']
  name: Scalars['String']
  status: Scalars['String']
}

export type GetCreditAnalysisReportByCompanyIdInput = {
  companyId: Scalars['String']
}

export type GetCreditAnalysisReportByCompanyIdOutput = {
  __typename?: 'GetCreditAnalysisReportByCompanyIdOutput'
  groupDocumentName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Float']>
  url?: Maybe<Scalars['String']>
}

export type GetCustomerAllDocumentsFilesByUrlInput = {
  companyId: Scalars['String']
  dossieId: Scalars['Float']
  excludedTypesId?: InputMaybe<Scalars['String']>
  typesId?: InputMaybe<Scalars['String']>
}

export type GetCustomerAllDocumentsFilesByUrlOutput = {
  __typename?: 'GetCustomerAllDocumentsFilesByURLOutput'
  allDocumentsUrl: Scalars['String']
}

export type GetCustomerAllDocumentsNamesInput = {
  companyId: Scalars['String']
  dossieId: Scalars['Float']
}

export type GetCustomerAllDocumentsNamesOutput = {
  __typename?: 'GetCustomerAllDocumentsNamesOutput'
  documentsIdsAndNames: Array<DocumentGroup>
}

export type GetCustomerDataByCompanyIdInput = {
  companyId: Scalars['String']
}

export type GetCustomerDataByCompanyIdOutput = {
  __typename?: 'GetCustomerDataByCompanyIdOutput'
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>
  business?: Maybe<Business>
  customers?: Maybe<Array<Maybe<Customer>>>
  responsibles?: Maybe<Array<Maybe<Responsible>>>
  revenues?: Maybe<Revenue>
  videos?: Maybe<Array<Maybe<Video>>>
}

export type GetCustomerGroupFilesByUrlInput = {
  companyId: Scalars['String']
  dossieId: Scalars['Float']
  typesId: Scalars['String']
}

export type GetCustomerGroupFilesByUrlOutput = {
  __typename?: 'GetCustomerGroupFilesByURLOutput'
  url: Scalars['String']
}

export type GetCustomerProfileInput = {
  customerId: Scalars['String']
}

export type GetCustomerProfileOutput = {
  __typename?: 'GetCustomerProfileOutput'
  cnpj?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  id: Scalars['String']
  instagram?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  registerDate: Scalars['String']
  site?: Maybe<Scalars['String']>
  tradeName?: Maybe<Scalars['String']>
}

export type GetCustomerReputationFromMetabaseInput = {
  companyId: Scalars['String']
}

export type GetCustomerReputationFromMetabaseOutput = {
  __typename?: 'GetCustomerReputationFromMetabaseOutput'
  customerReputationURL?: Maybe<Scalars['String']>
}

export type GetCustomersData = {
  __typename?: 'GetCustomersData'
  addressState?: Maybe<Scalars['String']>
  averagePeriod: Scalars['Float']
  cnpj: Scalars['String']
  companyId: Scalars['String']
  companyRating?: Maybe<Scalars['String']>
  creditLimitValue?: Maybe<Scalars['Float']>
  maximumRate?: Maybe<Scalars['String']>
  minimumRate?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  operations: Scalars['Float']
  registerDate: Scalars['String']
}

export type GetCustomersFilters = {
  addressState?: InputMaybe<Scalars['String']>
  companyRating?: InputMaybe<Scalars['String']>
  creditLimitValue?: InputMaybe<Scalars['String']>
  maximumRate?: InputMaybe<Scalars['String']>
  minimumRate?: InputMaybe<Scalars['String']>
  searchField?: InputMaybe<Scalars['String']>
}

export type GetCustomersInput = {
  filters?: InputMaybe<GetCustomersFilters>
  orders?: InputMaybe<OrderInput>
  pagination?: InputMaybe<PaginationInput>
}

export type GetCustomersOutput = {
  __typename?: 'GetCustomersOutput'
  data: Array<GetCustomersData>
  pagination?: Maybe<Pagination>
}

export type GetReputationByCompanyIdInput = {
  companyId: Scalars['String']
}

export type GetReputationByCompanyIdOutput = {
  __typename?: 'GetReputationByCompanyIdOutput'
  mediumTerm?: Maybe<Scalars['Float']>
  totalOperation?: Maybe<Scalars['Float']>
}

export type InDossieDocumentsGroupedByDocumentTypeOutput = {
  __typename?: 'InDossieDocumentsGroupedByDocumentTypeOutput'
  id: Scalars['String']
  name: Scalars['String']
}

export type MutateCompanyRatingsInput = {
  buyerId: Scalars['String']
  companyId: Scalars['String']
  companyRating: Scalars['String']
  creditLimitValue?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  maximumRate?: InputMaybe<Scalars['String']>
  minimumRate?: InputMaybe<Scalars['String']>
}

export type MutateCompanyRatingsOutput = {
  __typename?: 'MutateCompanyRatingsOutput'
  buyerId: Scalars['String']
  companyId: Scalars['String']
  companyRating: Scalars['String']
  creditLimitValue?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  maximumRate?: Maybe<Scalars['String']>
  minimumRate?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  answerQuiz: AnswerQuizOutput
  createCompany: CreateCompanyOutput
  createCompanyProposal: CreateCompanyProposalOutput
  createRatingReasons: CreateRatingReasonsOutput
  createUser: CreateUserOutput
  deleteCompanyBankAccountInfo: DeleteCompanyBankAccountInfo
  deleteCompanyCustomer: DeletedCompanyCustomer
  deleteCompanyDocument: DeletedCompanyDocument
  deleteCompanyLegalRepresentative: DeletedCompanyLegalRepresentative
  deleteCompanyPartner: DeletedCompanyPartner
  deleteCompanySupplier: DeletedCompanySupplier
  mutateCompanyRatings: MutateCompanyRatingsOutput
  registerCompany?: Maybe<RegisterCompany>
  registerCompanyBankAccountInfo: CompanyBankAccountInfo
  registerCompanyCustomerOrSupplier?: Maybe<CompanyCustomerOrSupplier>
  registerCompanyLegalRepresentative?: Maybe<CompanyLegalRepresentative>
  registerCompanyPartner?: Maybe<CompanyPartner>
  registerCompanyTaxes?: Maybe<CompanyTaxes>
  registerCompanyUser?: Maybe<CompanyUser>
  registerWitness?: Maybe<Witness>
  sendCompanyRevenue?: Maybe<TotalCompanyRevenue>
  sendToAnalysis: SendToAnalysis
  syncZeev?: Maybe<Scalars['Boolean']>
  updateCompanyBankAccountInfo: CompanyBankAccountInfo
  updateCompanyUser?: Maybe<UpdateCompanyUser>
  updateDefaultCompany: UpdateDefaultCompanyOutput
}

export type MutationAnswerQuizArgs = {
  params: AnswerQuizInput
}

export type MutationCreateCompanyArgs = {
  companyProposalId: Scalars['String']
}

export type MutationCreateCompanyProposalArgs = {
  params: CreateCompanyProposalInput
}

export type MutationCreateRatingReasonsArgs = {
  params: CreateRatingReasonsInput
}

export type MutationCreateUserArgs = {
  params: CreateUserInput
}

export type MutationDeleteCompanyBankAccountInfoArgs = {
  companyBankAccountInfoId: Scalars['String']
  companyId: Scalars['String']
}

export type MutationDeleteCompanyCustomerArgs = {
  companyCustomerId: Scalars['String']
}

export type MutationDeleteCompanyDocumentArgs = {
  companyDocumentId: Scalars['String']
}

export type MutationDeleteCompanyLegalRepresentativeArgs = {
  companyLegalRepresentativeId: Scalars['String']
}

export type MutationDeleteCompanyPartnerArgs = {
  companyPartnerId: Scalars['String']
}

export type MutationDeleteCompanySupplierArgs = {
  companySupplierId: Scalars['String']
}

export type MutationMutateCompanyRatingsArgs = {
  params: MutateCompanyRatingsInput
}

export type MutationRegisterCompanyArgs = {
  params?: InputMaybe<RegisterCompanyInput>
}

export type MutationRegisterCompanyBankAccountInfoArgs = {
  params?: InputMaybe<BankAccountInfoInput>
}

export type MutationRegisterCompanyCustomerOrSupplierArgs = {
  params: CompanyCustomerOrSupplierInput
}

export type MutationRegisterCompanyLegalRepresentativeArgs = {
  params: CompanyLegalRepresentativeInput
}

export type MutationRegisterCompanyPartnerArgs = {
  params: CompanyPartnerInput
}

export type MutationRegisterCompanyTaxesArgs = {
  params?: InputMaybe<CompanyTaxesInput>
}

export type MutationRegisterCompanyUserArgs = {
  params: CompanyUserInput
}

export type MutationRegisterWitnessArgs = {
  params?: InputMaybe<WitnessInput>
}

export type MutationSendCompanyRevenueArgs = {
  payload?: InputMaybe<SendCompanyRevenueInput>
}

export type MutationSendToAnalysisArgs = {
  companyId: Scalars['String']
}

export type MutationSyncZeevArgs = {
  params: SyncZeevInput
}

export type MutationUpdateCompanyBankAccountInfoArgs = {
  params?: InputMaybe<UpdateBankAccountInfoInput>
}

export type MutationUpdateCompanyUserArgs = {
  params: UpdateCompanyUserInput
}

export type MutationUpdateDefaultCompanyArgs = {
  companyId: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  companySenderId: Scalars['String']
  date: Scalars['String']
  id: Scalars['String']
  redirectPath: Scalars['String']
  text: Scalars['String']
  wasRead: Scalars['Boolean']
}

export type Oi = {
  __typename?: 'Oi'
  oi?: Maybe<Scalars['String']>
}

export type OrderInput = {
  direction?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type Pagination = {
  __typename?: 'Pagination'
  totalItems?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

export type PaginationInput = {
  page: Scalars['Int']
  perPage?: InputMaybe<Scalars['Int']>
}

export type Query = {
  __typename?: 'Query'
  getAddressByZipCode: Address
  getAuthToken: Scalars['String']
  getBankAccounts: Array<GetBankAccountsOutput>
  getBankAccountsPermissions: GetBankAccountsPermissionsOutput
  getBusinessBankAccounts?: Maybe<GetBusinessBankAccountsOutput>
  getCompaniesFromUser: Array<GetCompanyFromUser>
  getCompanyBankAccountInfoByCompanyId?: Maybe<Array<Maybe<CompanyBankAccountInfo>>>
  getCompanyCustomersAndSuppliersByCompanyId?: Maybe<Array<Maybe<CompanyCustomerOrSupplier>>>
  getCompanyDocumentsByCompanyId?: Maybe<Array<Maybe<CompanyDocument>>>
  getCompanyInfos?: Maybe<CompanyInfos>
  getCompanyLegalRepresentativesByCompanyId?: Maybe<Array<Maybe<CompanyLegalRepresentative>>>
  getCompanyNotifications: GetCompanyNotificationOutput
  getCompanyPartnersByCompanyId?: Maybe<Array<Maybe<CompanyPartner>>>
  getCompanyProposalById: CompanyProposalOutput
  getCompanyRatingByCompanyId: GetCompanyRatingByCompanyIdOutput
  getCompanyRevenue?: Maybe<TotalCompanyRevenue>
  getCompanyTaxesByCompanyId?: Maybe<CompanyTaxes>
  getCompanyUsersByCompanyId?: Maybe<Array<Maybe<CompanyUser>>>
  getContractDownloadUrl: GetContractDownloadUrlOutput
  getContractsByCompanyId: Array<GetContractsByCompanyIdOutput>
  getCreditAnalysisReportByCompanyId: Array<Maybe<GetCreditAnalysisReportByCompanyIdOutput>>
  getCustomerAllDocumentsFilesByURL: GetCustomerAllDocumentsFilesByUrlOutput
  getCustomerAllDocumentsNames: GetCustomerAllDocumentsNamesOutput
  getCustomerDataByCompanyId: GetCustomerDataByCompanyIdOutput
  getCustomerGroupFilesByURL: GetCustomerGroupFilesByUrlOutput
  getCustomerProfile: GetCustomerProfileOutput
  getCustomerReputationFromMetabase: GetCustomerReputationFromMetabaseOutput
  getCustomers: GetCustomersOutput
  getDefaultCompany?: Maybe<DefaultCompany>
  getExtract?: Maybe<Extract>
  getOwnershipReceipt?: Maybe<Scalars['String']>
  getReputationByCompanyId: GetReputationByCompanyIdOutput
  getTheme?: Maybe<Theme>
  getWitnessesByCompanyId?: Maybe<Array<Maybe<Witness>>>
  getZipDownloadUrlByDocumentGroup?: Maybe<Scalars['String']>
  getZipDownloadUrlFromAllDocuments: Scalars['String']
  inDossieDocumentsGroupedByDocumentType: Array<Maybe<InDossieDocumentsGroupedByDocumentTypeOutput>>
}

export type QueryGetAddressByZipCodeArgs = {
  zipCode: Scalars['String']
}

export type QueryGetBankAccountsPermissionsArgs = {
  params: GetBankAccountsPermissionsInput
}

export type QueryGetBusinessBankAccountsArgs = {
  params?: InputMaybe<GetBusinessBankAccountsInput>
}

export type QueryGetCompanyBankAccountInfoByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyCustomersAndSuppliersByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyDocumentsByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyInfosArgs = {
  businessId: Scalars['String']
}

export type QueryGetCompanyLegalRepresentativesByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyNotificationsArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyPartnersByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyProposalByIdArgs = {
  companyProposalId: Scalars['String']
}

export type QueryGetCompanyRatingByCompanyIdArgs = {
  buyerId: Scalars['String']
  companyId: Scalars['String']
}

export type QueryGetCompanyRevenueArgs = {
  companyId: Scalars['String']
  kind?: InputMaybe<Scalars['String']>
  periodGt?: InputMaybe<Scalars['String']>
  periodLt?: InputMaybe<Scalars['String']>
}

export type QueryGetCompanyTaxesByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCompanyUsersByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetContractDownloadUrlArgs = {
  contractId: Scalars['String']
}

export type QueryGetContractsByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetCreditAnalysisReportByCompanyIdArgs = {
  params: GetCreditAnalysisReportByCompanyIdInput
}

export type QueryGetCustomerAllDocumentsFilesByUrlArgs = {
  params: GetCustomerAllDocumentsFilesByUrlInput
}

export type QueryGetCustomerAllDocumentsNamesArgs = {
  params: GetCustomerAllDocumentsNamesInput
}

export type QueryGetCustomerDataByCompanyIdArgs = {
  params: GetCustomerDataByCompanyIdInput
}

export type QueryGetCustomerGroupFilesByUrlArgs = {
  params: GetCustomerGroupFilesByUrlInput
}

export type QueryGetCustomerProfileArgs = {
  params: GetCustomerProfileInput
}

export type QueryGetCustomerReputationFromMetabaseArgs = {
  params: GetCustomerReputationFromMetabaseInput
}

export type QueryGetCustomersArgs = {
  params: GetCustomersInput
}

export type QueryGetExtractArgs = {
  params?: InputMaybe<ExtractInput>
}

export type QueryGetOwnershipReceiptArgs = {
  account?: InputMaybe<Scalars['String']>
  agency?: InputMaybe<Scalars['String']>
}

export type QueryGetReputationByCompanyIdArgs = {
  params: GetReputationByCompanyIdInput
}

export type QueryGetThemeArgs = {
  hostname?: InputMaybe<Scalars['String']>
}

export type QueryGetWitnessesByCompanyIdArgs = {
  companyId: Scalars['String']
}

export type QueryGetZipDownloadUrlByDocumentGroupArgs = {
  companyId: Scalars['String']
  documentGroupId: Scalars['Int']
}

export type QueryGetZipDownloadUrlFromAllDocumentsArgs = {
  companyId: Scalars['String']
}

export type QueryInDossieDocumentsGroupedByDocumentTypeArgs = {
  companyId: Scalars['String']
}

export type RegisterCompany = {
  __typename?: 'RegisterCompany'
  activity?: Maybe<Scalars['String']>
  activityCode?: Maybe<Scalars['String']>
  activityDescription?: Maybe<Scalars['String']>
  buyerTypeId?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companySizeId?: Maybe<Scalars['String']>
  constitutionId?: Maybe<Scalars['String']>
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  registerAddressCity?: Maybe<Scalars['String']>
  registerAddressComplement?: Maybe<Scalars['String']>
  registerAddressNeighborhood?: Maybe<Scalars['String']>
  registerAddressNumber?: Maybe<Scalars['String']>
  registerAddressState?: Maybe<Scalars['String']>
  registerAddressStreet?: Maybe<Scalars['String']>
  registerAddressZipCode?: Maybe<Scalars['String']>
  taxRuleId?: Maybe<Scalars['String']>
  tradeName?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
  website?: Maybe<Scalars['String']>
  workingAddressCity?: Maybe<Scalars['String']>
  workingAddressComplement?: Maybe<Scalars['String']>
  workingAddressNeighborhood?: Maybe<Scalars['String']>
  workingAddressNumber?: Maybe<Scalars['String']>
  workingAddressState?: Maybe<Scalars['String']>
  workingAddressStreet?: Maybe<Scalars['String']>
  workingAddressZipCode?: Maybe<Scalars['String']>
}

export type RegisterCompanyInput = {
  activity?: InputMaybe<Scalars['String']>
  activityCode?: InputMaybe<Scalars['String']>
  activityDescription?: InputMaybe<Scalars['String']>
  businessId: Scalars['String']
  buyerTypeId?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  companySizeId?: InputMaybe<Scalars['String']>
  constitutionId?: InputMaybe<Scalars['String']>
  documentNumber?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  facebook?: InputMaybe<Scalars['String']>
  instagram?: InputMaybe<Scalars['String']>
  linkedin?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  registerAddressCity?: InputMaybe<Scalars['String']>
  registerAddressComplement?: InputMaybe<Scalars['String']>
  registerAddressNeighborhood?: InputMaybe<Scalars['String']>
  registerAddressNumber?: InputMaybe<Scalars['String']>
  registerAddressState?: InputMaybe<Scalars['String']>
  registerAddressStreet?: InputMaybe<Scalars['String']>
  registerAddressZipCode?: InputMaybe<Scalars['String']>
  taxRuleId?: InputMaybe<Scalars['String']>
  tradeName?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
  workingAddressCity?: InputMaybe<Scalars['String']>
  workingAddressComplement?: InputMaybe<Scalars['String']>
  workingAddressNeighborhood?: InputMaybe<Scalars['String']>
  workingAddressNumber?: InputMaybe<Scalars['String']>
  workingAddressState?: InputMaybe<Scalars['String']>
  workingAddressStreet?: InputMaybe<Scalars['String']>
  workingAddressZipCode?: InputMaybe<Scalars['String']>
}

export type Responsible = {
  __typename?: 'Responsible'
  document: Scalars['String']
  hasEcpf?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type Revenue = {
  __typename?: 'Revenue'
  average?: Maybe<Scalars['Float']>
}

export type Revenues = {
  amount: Scalars['Int']
  kind?: InputMaybe<Scalars['String']>
  period: Scalars['String']
}

export type SendCompanyRevenueInput = {
  companyId: Scalars['String']
  revenues: Array<Revenues>
}

export type SendToAnalysis = {
  __typename?: 'SendToAnalysis'
  id: Scalars['String']
}

export type SyncZeevInput = {
  companyId: Scalars['String']
}

export type TaxRule = {
  __typename?: 'TaxRule'
  taxRuleId: Scalars['String']
  taxRuleName: Scalars['String']
}

export type Theme = {
  __typename?: 'Theme'
  colors: Array<Color>
  favicon: Scalars['String']
  logoMain: Scalars['String']
  logoSecondary: Scalars['String']
  storeId: Scalars['ID']
  storeName: Scalars['String']
  storeShortName: Scalars['String']
}

export type TotalCompanyRevenue = {
  __typename?: 'TotalCompanyRevenue'
  revenues: Array<CompanyRevenue>
  totalCompanyRevenues: Scalars['Int']
}

export type Transaction = {
  __typename?: 'Transaction'
  balanceAfter: Scalars['String']
  counterpart: Scalars['String']
  createdAt: Scalars['String']
  date: Scalars['String']
  description: Scalars['String']
  id: Scalars['String']
  moveType: Scalars['String']
  updatedAt: Scalars['String']
  value: Scalars['String']
}

export type UpdateBankAccountInfoInput = {
  accountAgency: Scalars['String']
  accountDigit?: InputMaybe<Scalars['String']>
  accountNumber: Scalars['String']
  bankCode?: InputMaybe<Scalars['String']>
  bankName: Scalars['String']
  companyId: Scalars['String']
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  ispb?: InputMaybe<Scalars['String']>
}

export type UpdateCompanyUser = {
  __typename?: 'UpdateCompanyUser'
  companyId: Scalars['String']
  documentNumber: Scalars['String']
  email: Scalars['String']
  fullName: Scalars['String']
  group: Scalars['String']
  id: Scalars['String']
  mobile: Scalars['String']
  status: Scalars['String']
}

export type UpdateCompanyUserInput = {
  companyId: Scalars['String']
  documentNumber: Scalars['String']
  email: Scalars['String']
  fullName: Scalars['String']
  group: Scalars['String']
  id: Scalars['String']
  mobile: Scalars['String']
  status?: InputMaybe<Scalars['String']>
  whiteLabelShortName: Scalars['String']
}

export type UpdateDefaultCompanyOutput = {
  __typename?: 'UpdateDefaultCompanyOutput'
  id: Scalars['String']
}

export type Video = {
  __typename?: 'Video'
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type Witness = {
  __typename?: 'Witness'
  companyId?: Maybe<Scalars['String']>
  documentNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  personalDocument?: Maybe<Scalars['String']>
}

export type WitnessInput = {
  companyId: Scalars['String']
  documentNumber?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  personalDocument?: InputMaybe<Scalars['String']>
}

export type RegisterCompanyMutationVariables = Exact<{
  params?: InputMaybe<RegisterCompanyInput>
}>

export type RegisterCompanyMutation = {
  __typename?: 'Mutation'
  registerCompany?: {
    __typename?: 'RegisterCompany'
    activity?: string | null
    activityCode?: string | null
    activityDescription?: string | null
    registerAddressCity?: string | null
    registerAddressComplement?: string | null
    registerAddressNeighborhood?: string | null
    registerAddressNumber?: string | null
    registerAddressState?: string | null
    registerAddressStreet?: string | null
    registerAddressZipCode?: string | null
    documentNumber?: string | null
    companyName?: string | null
    email?: string | null
    facebook?: string | null
    instagram?: string | null
    linkedin?: string | null
    tradeName?: string | null
    phone?: string | null
    workingAddressCity?: string | null
    workingAddressComplement?: string | null
    workingAddressNeighborhood?: string | null
    workingAddressNumber?: string | null
    workingAddressStreet?: string | null
    workingAddressState?: string | null
    workingAddressZipCode?: string | null
    website?: string | null
    taxRuleId?: string | null
    companySizeId?: string | null
    constitutionId?: string | null
    buyerTypeId?: string | null
    updatedAt: string
  } | null
}

export type RegisterCompanyLegalRepresentativeMutationVariables = Exact<{
  params: CompanyLegalRepresentativeInput
}>

export type RegisterCompanyLegalRepresentativeMutation = {
  __typename?: 'Mutation'
  registerCompanyLegalRepresentative?: {
    __typename?: 'CompanyLegalRepresentative'
    id?: string | null
    birthDate?: string | null
    companyId?: string | null
    documentNumber?: string | null
    email?: string | null
    mobile?: string | null
    fullName?: string | null
    phone?: string | null
    personalDocument?: string | null
    personalDocumentState?: string | null
    personalDocumentEmitter?: string | null
  } | null
}

export type RegisterCompanyCustomerOrSupplierMutationVariables = Exact<{
  params: CompanyCustomerOrSupplierInput
}>

export type RegisterCompanyCustomerOrSupplierMutation = {
  __typename?: 'Mutation'
  registerCompanyCustomerOrSupplier?: {
    __typename?: 'CompanyCustomerOrSupplier'
    isCustomer?: string | null
    customerId?: string | null
    supplierId?: string | null
    email?: string | null
    fullName?: string | null
    companyName?: string | null
    phone?: string | null
    documentNumber?: string | null
    statusId?: string | null
    companyId?: string | null
  } | null
}

export type RegisterCompanyPartnerMutationVariables = Exact<{
  params: CompanyPartnerInput
}>

export type RegisterCompanyPartnerMutation = {
  __typename?: 'Mutation'
  registerCompanyPartner?: {
    __typename?: 'CompanyPartner'
    typeId?: string | null
    id?: string | null
    birthDate?: string | null
    documentNumber?: string | null
    email?: string | null
    fullName?: string | null
    phone?: string | null
    mobile?: string | null
    personalDocument?: string | null
    personalDocumentState?: string | null
    personalDocumentEmitter?: string | null
    companyId?: string | null
    companyShareholding?: number | null
    signsForCompany?: string | null
    maritalStatusId?: string | null
    hasEcpf?: string | null
    addressZipCode?: string | null
    addressStreetName?: string | null
    addressNumber?: string | null
    addressComplement?: string | null
    addressNeighborhood?: string | null
    addressCity?: string | null
    addressState?: string | null
  } | null
}

export type RegisterWitnessMutationVariables = Exact<{
  params?: InputMaybe<WitnessInput>
}>

export type RegisterWitnessMutation = {
  __typename?: 'Mutation'
  registerWitness?: {
    __typename?: 'Witness'
    id?: string | null
    companyId?: string | null
    fullName?: string | null
    documentNumber?: string | null
    personalDocument?: string | null
    email?: string | null
  } | null
}

export type RegisterCompanyTaxesMutationVariables = Exact<{
  params?: InputMaybe<CompanyTaxesInput>
}>

export type RegisterCompanyTaxesMutation = {
  __typename?: 'Mutation'
  registerCompanyTaxes?: {
    __typename?: 'CompanyTaxes'
    companyId?: string | null
    adValorem?: string | null
    iofAmount?: string | null
    iofTerm?: string | null
    chargeTax?: string | null
    operationTax?: string | null
    analysisTax?: string | null
    settlementTerm?: string | null
  } | null
}

export type RegisterCompanyUserMutationVariables = Exact<{
  params: CompanyUserInput
}>

export type RegisterCompanyUserMutation = {
  __typename?: 'Mutation'
  registerCompanyUser?: {
    __typename?: 'CompanyUser'
    id: string
    companyId: string
    status?: string | null
    group?: string | null
    fullName?: string | null
    email?: string | null
    mobile?: string | null
    documentNumber?: string | null
    lastAccess?: string | null
  } | null
}

export type DeleteCompanyLegalRepresentativeMutationVariables = Exact<{
  companyLegalRepresentativeId: Scalars['String']
}>

export type DeleteCompanyLegalRepresentativeMutation = {
  __typename?: 'Mutation'
  deleteCompanyLegalRepresentative: { __typename?: 'DeletedCompanyLegalRepresentative'; id: string }
}

export type DeleteCompanyPartnerMutationVariables = Exact<{
  companyPartnerId: Scalars['String']
}>

export type DeleteCompanyPartnerMutation = {
  __typename?: 'Mutation'
  deleteCompanyPartner: { __typename?: 'DeletedCompanyPartner'; id: string }
}

export type DeleteCompanyCustomerMutationVariables = Exact<{
  companyCustomerId: Scalars['String']
}>

export type DeleteCompanyCustomerMutation = {
  __typename?: 'Mutation'
  deleteCompanyCustomer: { __typename?: 'DeletedCompanyCustomer'; id: string }
}

export type DeleteCompanySupplierMutationVariables = Exact<{
  companySupplierId: Scalars['String']
}>

export type DeleteCompanySupplierMutation = {
  __typename?: 'Mutation'
  deleteCompanySupplier: { __typename?: 'DeletedCompanySupplier'; id: string }
}

export type UpdateCompanyUserMutationVariables = Exact<{
  params: UpdateCompanyUserInput
}>

export type UpdateCompanyUserMutation = {
  __typename?: 'Mutation'
  updateCompanyUser?: {
    __typename?: 'UpdateCompanyUser'
    id: string
    companyId: string
    status: string
    group: string
    fullName: string
    email: string
    mobile: string
    documentNumber: string
  } | null
}

export type DeleteCompanyDocumentMutationVariables = Exact<{
  companyDocumentId: Scalars['String']
}>

export type DeleteCompanyDocumentMutation = {
  __typename?: 'Mutation'
  deleteCompanyDocument: { __typename?: 'DeletedCompanyDocument'; id: string }
}

export type DeleteCompanyBankAccountInfoMutationVariables = Exact<{
  companyBankAccountInfoId: Scalars['String']
  companyId: Scalars['String']
}>

export type DeleteCompanyBankAccountInfoMutation = {
  __typename?: 'Mutation'
  deleteCompanyBankAccountInfo: { __typename?: 'DeleteCompanyBankAccountInfo'; id: string }
}

export type RegisterCompanyBankAccountInfoMutationVariables = Exact<{
  params: BankAccountInfoInput
}>

export type RegisterCompanyBankAccountInfoMutation = {
  __typename?: 'Mutation'
  registerCompanyBankAccountInfo: {
    __typename?: 'CompanyBankAccountInfo'
    id: string
    companyName: string
    documentNumber: string
    accountNumber: string
    accountAgency: string
    kind: string
    bankName: string
    ispb?: string | null
    bankCode?: string | null
    isDefault?: boolean | null
  }
}

export type SendToAnalysisMutationVariables = Exact<{
  companyId: Scalars['String']
}>

export type SendToAnalysisMutation = {
  __typename?: 'Mutation'
  sendToAnalysis: { __typename?: 'SendToAnalysis'; id: string }
}

export type CreateCompanyMutationVariables = Exact<{
  companyProposalId: Scalars['String']
}>

export type CreateCompanyMutation = {
  __typename?: 'Mutation'
  createCompany: { __typename?: 'CreateCompanyOutput'; id: string; companyName: string; documentNumber: string }
}

export type CreateCompanyProposalMutationVariables = Exact<{
  params: CreateCompanyProposalInput
}>

export type CreateCompanyProposalMutation = {
  __typename?: 'Mutation'
  createCompanyProposal: { __typename?: 'CreateCompanyProposalOutput'; id?: string | null; status?: string | null }
}

export type SendCompanyRevenueMutationVariables = Exact<{
  payload: SendCompanyRevenueInput
}>

export type SendCompanyRevenueMutation = {
  __typename?: 'Mutation'
  sendCompanyRevenue?: {
    __typename?: 'TotalCompanyRevenue'
    revenues: Array<{ __typename?: 'CompanyRevenue'; amount: number; period: string; kind?: string | null }>
  } | null
}

export type CreateUserMutationVariables = Exact<{
  params: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: { __typename?: 'CreateUserOutput'; id: string; email: string; companyId?: string | null }
}

export type AnswerQuizMutationVariables = Exact<{
  params: AnswerQuizInput
}>

export type AnswerQuizMutation = {
  __typename?: 'Mutation'
  answerQuiz: {
    __typename?: 'AnswerQuizOutput'
    status: string
    buyers?: number | null
    averageTax?: number | null
    minTax?: number | null
    companyId: string
  }
}

export type UpdateCompanyBankAccountInfoMutationVariables = Exact<{
  params: UpdateBankAccountInfoInput
}>

export type UpdateCompanyBankAccountInfoMutation = {
  __typename?: 'Mutation'
  updateCompanyBankAccountInfo: {
    __typename?: 'CompanyBankAccountInfo'
    id: string
    companyName: string
    documentNumber: string
    accountNumber: string
    accountAgency: string
    kind: string
    bankName: string
    isDefault?: boolean | null
  }
}

export type UpdateDefaultCompanyMutationVariables = Exact<{
  companyId: Scalars['String']
}>

export type UpdateDefaultCompanyMutation = {
  __typename?: 'Mutation'
  updateDefaultCompany: { __typename?: 'UpdateDefaultCompanyOutput'; id: string }
}

export type MutateCompanyRatingsMutationVariables = Exact<{
  params: MutateCompanyRatingsInput
}>

export type MutateCompanyRatingsMutation = {
  __typename?: 'Mutation'
  mutateCompanyRatings: {
    __typename?: 'MutateCompanyRatingsOutput'
    id?: string | null
    creditLimitValue?: string | null
    minimumRate?: string | null
    maximumRate?: string | null
    companyRating: string
    companyId: string
    buyerId: string
  }
}

export type CreateRatingReasonsMutationVariables = Exact<{
  params: CreateRatingReasonsInput
}>

export type CreateRatingReasonsMutation = {
  __typename?: 'Mutation'
  createRatingReasons: { __typename?: 'CreateRatingReasonsOutput'; id: string }
}

export type GetExtractQueryVariables = Exact<{
  params?: InputMaybe<ExtractInput>
}>

export type GetExtractQuery = {
  __typename?: 'Query'
  getExtract?: {
    __typename?: 'Extract'
    totalCount: number
    records: number
    transactions: Array<{
      __typename?: 'Transaction'
      id: string
      moveType: string
      value: string
      description: string
      date: string
      createdAt: string
      updatedAt: string
      counterpart: string
      balanceAfter: string
    }>
  } | null
}

export type GetOwnershipReceiptQueryVariables = Exact<{
  agency?: InputMaybe<Scalars['String']>
  account?: InputMaybe<Scalars['String']>
}>

export type GetOwnershipReceiptQuery = { __typename?: 'Query'; getOwnershipReceipt?: string | null }

export type GetThemeQueryVariables = Exact<{
  hostname?: InputMaybe<Scalars['String']>
}>

export type GetThemeQuery = {
  __typename?: 'Query'
  getTheme?: {
    __typename?: 'Theme'
    storeId: string
    storeName: string
    storeShortName: string
    logoMain: string
    logoSecondary: string
    favicon: string
    colors: Array<{ __typename?: 'Color'; group: string; primary: string; secondary: string }>
  } | null
}

export type GetCompanyInfosQueryVariables = Exact<{
  businessId: Scalars['String']
}>

export type GetCompanyInfosQuery = {
  __typename?: 'Query'
  getCompanyInfos?: {
    __typename?: 'CompanyInfos'
    activity?: string | null
    activityDescription?: string | null
    registerAddressCity?: string | null
    registerAddressComplement?: string | null
    registerAddressNeighborhood?: string | null
    registerAddressNumber?: string | null
    registerAddressState?: string | null
    registerAddressStreet?: string | null
    registerAddressZipCode?: string | null
    businessId?: string | null
    activityCode?: string | null
    documentNumber?: string | null
    companyName?: string | null
    email?: string | null
    facebook?: string | null
    instagram?: string | null
    linkedin?: string | null
    tradeName?: string | null
    phone?: string | null
    workingAddressCity?: string | null
    workingAddressComplement?: string | null
    workingAddressNeighborhood?: string | null
    workingAddressNumber?: string | null
    workingAddressState?: string | null
    workingAddressStreet?: string | null
    workingAddressZipCode?: string | null
    website?: string | null
    taxRuleId?: string | null
    companySizeId?: string | null
    constitutionId?: string | null
    buyerTypeId?: string | null
    companySizes: Array<{ __typename?: 'CompanySize'; businessSizeId: string; businessSizeName: string }>
    taxRules: Array<{ __typename?: 'TaxRule'; taxRuleId: string; taxRuleName: string }>
    constitutions: Array<{ __typename?: 'Constitution'; constitutionId: string; constitutionName: string }>
    buyerTypes: Array<{ __typename?: 'BuyerType'; buyerTypeId: string; buyerTypeName: string }>
  } | null
}

export type GetDefaultCompanyQueryVariables = Exact<{ [key: string]: never }>

export type GetDefaultCompanyQuery = {
  __typename?: 'Query'
  getDefaultCompany?: {
    __typename?: 'DefaultCompany'
    businessId: string
    name: string
    countryIdentify: string
    originId: string
    typeId: string
    statusId: string
    statusName: string
    personTypeId: string
    personTypeName: string
    registerDate: string
    bankAccountTypeId: string
    typeName: string
    corporateName: string
    statusDate: string
    motiveId: string
    motiveName: string
    originType?: string | null
    moskitCrmContactId?: string | null
    moskitCrmCompanyId?: string | null
    moskitCrmDealId?: string | null
    moskitCrmResponsibleId?: string | null
    lastUpdateDate: string
    originTypeId?: string | null
  } | null
}

export type GetCompanyLegalRepresentativesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyLegalRepresentativesByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyLegalRepresentativesByCompanyId?: Array<{
    __typename?: 'CompanyLegalRepresentative'
    id?: string | null
    birthDate?: string | null
    companyId?: string | null
    documentNumber?: string | null
    email?: string | null
    mobile?: string | null
    fullName?: string | null
    phone?: string | null
    personalDocument?: string | null
    personalDocumentState?: string | null
    personalDocumentEmitter?: string | null
  } | null> | null
}

export type GetCompanyPartnersByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyPartnersByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyPartnersByCompanyId?: Array<{
    __typename?: 'CompanyPartner'
    typeId?: string | null
    id?: string | null
    birthDate?: string | null
    documentNumber?: string | null
    email?: string | null
    fullName?: string | null
    phone?: string | null
    mobile?: string | null
    personalDocument?: string | null
    personalDocumentState?: string | null
    personalDocumentEmitter?: string | null
    companyId?: string | null
    companyShareholding?: number | null
    signsForCompany?: string | null
    maritalStatusId?: string | null
    hasEcpf?: string | null
    addressZipCode?: string | null
    addressStreetName?: string | null
    addressNumber?: string | null
    addressComplement?: string | null
    addressNeighborhood?: string | null
    addressCity?: string | null
    addressState?: string | null
  } | null> | null
}

export type GetCompanyCustomersAndSuppliersByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyCustomersAndSuppliersByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyCustomersAndSuppliersByCompanyId?: Array<{
    __typename?: 'CompanyCustomerOrSupplier'
    isCustomer?: string | null
    customerId?: string | null
    supplierId?: string | null
    email?: string | null
    fullName?: string | null
    companyName?: string | null
    phone?: string | null
    documentNumber?: string | null
    statusId?: string | null
    companyId?: string | null
  } | null> | null
}

export type GetWitnessesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetWitnessesByCompanyIdQuery = {
  __typename?: 'Query'
  getWitnessesByCompanyId?: Array<{
    __typename?: 'Witness'
    id?: string | null
    companyId?: string | null
    fullName?: string | null
    documentNumber?: string | null
    personalDocument?: string | null
    email?: string | null
  } | null> | null
}

export type GetCompanyTaxesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyTaxesByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyTaxesByCompanyId?: {
    __typename?: 'CompanyTaxes'
    companyId?: string | null
    adValorem?: string | null
    iofAmount?: string | null
    iofTerm?: string | null
    chargeTax?: string | null
    operationTax?: string | null
    analysisTax?: string | null
    settlementTerm?: string | null
  } | null
}

export type GetCompanyUsersByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyUsersByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyUsersByCompanyId?: Array<{
    __typename?: 'CompanyUser'
    id: string
    companyId: string
    status?: string | null
    group?: string | null
    fullName?: string | null
    email?: string | null
    mobile?: string | null
    documentNumber?: string | null
    lastAccess?: string | null
  } | null> | null
}

export type GetCompanyDocumentsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyDocumentsByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyDocumentsByCompanyId?: Array<{
    __typename?: 'CompanyDocument'
    id?: string | null
    originTypeId?: string | null
    originTypeName?: string | null
    companyId?: string | null
    documentTypeId?: string | null
    documentTypeName?: string | null
    statusId?: string | null
    statusName?: string | null
    name?: string | null
    type?: string | null
    size?: string | null
    lastModifiedDate?: string | null
    key?: string | null
    registerDate?: string | null
    uploadStatusId?: string | null
    url?: string | null
    description?: string | null
    exists?: string | null
    validated?: string | null
    validUntil?: string | null
    docTypeId?: string | null
    makeDocumentAvaliable?: string | null
    pendingId?: string | null
    removePending?: string | null
    inDossie?: string | null
    downloadUrl?: string | null
  } | null> | null
}

export type GetCompanyBankAccountInfoByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyBankAccountInfoByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyBankAccountInfoByCompanyId?: Array<{
    __typename?: 'CompanyBankAccountInfo'
    id: string
    companyName: string
    documentNumber: string
    accountNumber: string
    bankName: string
    accountAgency: string
    bankCode?: string | null
    accountDigit?: string | null
    ispb?: string | null
    kind: string
    isDefault?: boolean | null
  } | null> | null
}

export type GetAuthTokenQueryVariables = Exact<{ [key: string]: never }>

export type GetAuthTokenQuery = { __typename?: 'Query'; getAuthToken: string }

export type GetCompanyProposalByIdQueryVariables = Exact<{
  companyProposalId: Scalars['String']
}>

export type GetCompanyProposalByIdQuery = {
  __typename?: 'Query'
  getCompanyProposalById: {
    __typename?: 'CompanyProposalOutput'
    id: string
    status: string
    dataStatus?: string | null
    documentNumber: string
    companyName?: string | null
  }
}

export type GetAddressByZipCodeQueryVariables = Exact<{
  zipCode: Scalars['String']
}>

export type GetAddressByZipCodeQuery = {
  __typename?: 'Query'
  getAddressByZipCode: {
    __typename?: 'Address'
    city: string
    streetName: string
    neighboorhood: string
    state: string
    zipCode: string
  }
}

export type GetCompanyRevenueQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetCompanyRevenueQuery = {
  __typename?: 'Query'
  getCompanyRevenue?: {
    __typename?: 'TotalCompanyRevenue'
    totalCompanyRevenues: number
    revenues: Array<{ __typename?: 'CompanyRevenue'; id: string; period: string; amount: number; kind?: string | null }>
  } | null
}

export type GetContractDownloadUrlQueryVariables = Exact<{
  contractId: Scalars['String']
}>

export type GetContractDownloadUrlQuery = {
  __typename?: 'Query'
  getContractDownloadUrl: { __typename?: 'GetContractDownloadUrlOutput'; name: string; url: string }
}

export type GetContractsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetContractsByCompanyIdQuery = {
  __typename?: 'Query'
  getContractsByCompanyId: Array<{
    __typename?: 'GetContractsByCompanyIdOutput'
    name: string
    kind: string
    status: string
    fileName: string
    id: string
    contractId: string
  }>
}

export type GetCompaniesFromUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCompaniesFromUserQuery = {
  __typename?: 'Query'
  getCompaniesFromUser: Array<{
    __typename?: 'GetCompanyFromUser'
    id: string
    name: string
    cnpj: string
    isDefault: boolean
  }>
}

export type GetCustomersQueryVariables = Exact<{
  params: GetCustomersInput
}>

export type GetCustomersQuery = {
  __typename?: 'Query'
  getCustomers: {
    __typename?: 'GetCustomersOutput'
    data: Array<{
      __typename?: 'GetCustomersData'
      companyId: string
      companyRating?: string | null
      name?: string | null
      cnpj: string
      addressState?: string | null
      minimumRate?: string | null
      maximumRate?: string | null
      creditLimitValue?: number | null
      operations: number
      averagePeriod: number
      registerDate: string
    }>
    pagination?: { __typename?: 'Pagination'; totalPages?: number | null; totalItems?: number | null } | null
  }
}

export type GetCustomerProfileQueryVariables = Exact<{
  params: GetCustomerProfileInput
}>

export type GetCustomerProfileQuery = {
  __typename?: 'Query'
  getCustomerProfile: {
    __typename?: 'GetCustomerProfileOutput'
    id: string
    tradeName?: string | null
    companyName?: string | null
    cnpj?: string | null
    email?: string | null
    phone?: string | null
    registerDate: string
    instagram?: string | null
    facebook?: string | null
    linkedin?: string | null
    site?: string | null
  }
}

export type GetCompanyRatingByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
  buyerId: Scalars['String']
}>

export type GetCompanyRatingByCompanyIdQuery = {
  __typename?: 'Query'
  getCompanyRatingByCompanyId: {
    __typename?: 'GetCompanyRatingByCompanyIdOutput'
    id: string
    creditLimitValue?: string | null
    minimumRate?: string | null
    maximumRate?: string | null
    companyRating: string
    companyId: string
    buyerId: string
  }
}

export type GetCustomerDataByCompanyIdQueryVariables = Exact<{
  params: GetCustomerDataByCompanyIdInput
}>

export type GetCustomerDataByCompanyIdQuery = {
  __typename?: 'Query'
  getCustomerDataByCompanyId: {
    __typename?: 'GetCustomerDataByCompanyIdOutput'
    business?: {
      __typename?: 'Business'
      activity?: string | null
      codeActivity?: string | null
      companySize?: string | null
      constitutionName?: string | null
      registerAddressCity?: string | null
      registerAddressComplement?: string | null
      registerAddressNeighborhood?: string | null
      registerAddressNumber?: string | null
      registerAddressState?: string | null
      registerAddressStreet?: string | null
      registerAddressZipCode?: string | null
      registerDate?: string | null
      birthDate?: string | null
      activityDescription?: string | null
    } | null
    videos?: Array<{
      __typename?: 'Video'
      id?: string | null
      name?: string | null
      key?: string | null
      url?: string | null
    } | null> | null
    responsibles?: Array<{
      __typename?: 'Responsible'
      hasEcpf?: boolean | null
      id?: string | null
      name?: string | null
      type?: string | null
      document: string
    } | null> | null
    customers?: Array<{ __typename?: 'Customer'; id?: string | null; name?: string | null } | null> | null
    bankAccounts?: Array<{
      __typename?: 'BankAccount'
      accountAgency?: string | null
      accountNumber?: string | null
      accountDigit?: string | null
      bankName?: string | null
      id?: string | null
      isDefault: boolean
    } | null> | null
    revenues?: { __typename?: 'Revenue'; average?: number | null } | null
  }
}

export type GetCreditAnalysisReportByCompanyIdQueryVariables = Exact<{
  params: GetCreditAnalysisReportByCompanyIdInput
}>

export type GetCreditAnalysisReportByCompanyIdQuery = {
  __typename?: 'Query'
  getCreditAnalysisReportByCompanyId: Array<{
    __typename?: 'GetCreditAnalysisReportByCompanyIdOutput'
    id?: number | null
    groupDocumentName?: string | null
    url?: string | null
  } | null>
}

export type InDossieDocumentsGroupedByDocumentTypeQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type InDossieDocumentsGroupedByDocumentTypeQuery = {
  __typename?: 'Query'
  inDossieDocumentsGroupedByDocumentType: Array<{
    __typename?: 'InDossieDocumentsGroupedByDocumentTypeOutput'
    id: string
    name: string
  } | null>
}

export type GetZipDownloadUrlFromAllDocumentsQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetZipDownloadUrlFromAllDocumentsQuery = { __typename?: 'Query'; getZipDownloadUrlFromAllDocuments: string }

export type GetZipDownloadUrlByDocumentGroupQueryVariables = Exact<{
  companyId: Scalars['String']
  documentGroupId: Scalars['Int']
}>

export type GetZipDownloadUrlByDocumentGroupQuery = {
  __typename?: 'Query'
  getZipDownloadUrlByDocumentGroup?: string | null
}

export type GetReputationByCompanyIdQueryVariables = Exact<{
  params: GetReputationByCompanyIdInput
}>

export type GetReputationByCompanyIdQuery = {
  __typename?: 'Query'
  getReputationByCompanyId: {
    __typename?: 'GetReputationByCompanyIdOutput'
    totalOperation?: number | null
    mediumTerm?: number | null
  }
}

export type GetCustomerReputationFromMetabaseQueryVariables = Exact<{
  params: GetCustomerReputationFromMetabaseInput
}>

export type GetCustomerReputationFromMetabaseQuery = {
  __typename?: 'Query'
  getCustomerReputationFromMetabase: {
    __typename?: 'GetCustomerReputationFromMetabaseOutput'
    customerReputationURL?: string | null
  }
}

export type GetCustomerAllDocumentsNamesQueryVariables = Exact<{
  params: GetCustomerAllDocumentsNamesInput
}>

export type GetCustomerAllDocumentsNamesQuery = {
  __typename?: 'Query'
  getCustomerAllDocumentsNames: {
    __typename?: 'GetCustomerAllDocumentsNamesOutput'
    documentsIdsAndNames: Array<{ __typename?: 'DocumentGroup'; id: number; name: string }>
  }
}

export type GetCustomerGroupFilesByUrlQueryVariables = Exact<{
  params: GetCustomerGroupFilesByUrlInput
}>

export type GetCustomerGroupFilesByUrlQuery = {
  __typename?: 'Query'
  getCustomerGroupFilesByURL: { __typename?: 'GetCustomerGroupFilesByURLOutput'; url: string }
}

export type GetCustomerAllDocumentsFilesByUrlQueryVariables = Exact<{
  params: GetCustomerAllDocumentsFilesByUrlInput
}>

export type GetCustomerAllDocumentsFilesByUrlQuery = {
  __typename?: 'Query'
  getCustomerAllDocumentsFilesByURL: { __typename?: 'GetCustomerAllDocumentsFilesByURLOutput'; allDocumentsUrl: string }
}

export type GetBankAccountsQueryVariables = Exact<{ [key: string]: never }>

export type GetBankAccountsQuery = {
  __typename?: 'Query'
  getBankAccounts: Array<{
    __typename?: 'GetBankAccountsOutput'
    ispb?: string | null
    name?: string | null
    code?: number | null
    fullName?: string | null
  }>
}

export type GetBankAccountsPermissionsQueryVariables = Exact<{
  params: GetBankAccountsPermissionsInput
}>

export type GetBankAccountsPermissionsQuery = {
  __typename?: 'Query'
  getBankAccountsPermissions: {
    __typename?: 'GetBankAccountsPermissionsOutput'
    allowCreate: boolean
    allowRead: boolean
    allowUpdate: boolean
    allowDelete: boolean
  }
}

export type GetBusinessBankAccountsQueryVariables = Exact<{
  params: GetBusinessBankAccountsInput
}>

export type GetBusinessBankAccountsQuery = {
  __typename?: 'Query'
  getBusinessBankAccounts?: {
    __typename?: 'GetBusinessBankAccountsOutput'
    accounts: Array<{
      __typename?: 'Accounts'
      availableBalance: string
      bankPartnerName: string
      bankPartnerDescription: string
      bankPartnerNumber: string
      createdAt: string
      id: string
      accountNumber: string
      accountDigit: string
      agencyNumber: string
      agencyDigit: string
    } | null>
  } | null
}

export const RegisterCompanyDocument = gql`
  mutation registerCompany($params: RegisterCompanyInput) {
    registerCompany(params: $params) {
      activity
      activityCode
      activityDescription
      registerAddressCity
      registerAddressComplement
      registerAddressNeighborhood
      registerAddressNumber
      registerAddressState
      registerAddressStreet
      registerAddressZipCode
      documentNumber
      companyName
      email
      facebook
      instagram
      linkedin
      tradeName
      phone
      workingAddressCity
      workingAddressComplement
      workingAddressNeighborhood
      workingAddressNumber
      workingAddressStreet
      workingAddressState
      workingAddressZipCode
      website
      taxRuleId
      companySizeId
      constitutionId
      buyerTypeId
      updatedAt
    }
  }
`
export type RegisterCompanyMutationFn = Apollo.MutationFunction<
  RegisterCompanyMutation,
  RegisterCompanyMutationVariables
>

/**
 * __useRegisterCompanyMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyMutation, { data, loading, error }] = useRegisterCompanyMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterCompanyMutation, RegisterCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterCompanyMutation, RegisterCompanyMutationVariables>(RegisterCompanyDocument, options)
}
export type RegisterCompanyMutationHookResult = ReturnType<typeof useRegisterCompanyMutation>
export type RegisterCompanyMutationResult = Apollo.MutationResult<RegisterCompanyMutation>
export type RegisterCompanyMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyMutation,
  RegisterCompanyMutationVariables
>
export const RegisterCompanyLegalRepresentativeDocument = gql`
  mutation registerCompanyLegalRepresentative($params: CompanyLegalRepresentativeInput!) {
    registerCompanyLegalRepresentative(params: $params) {
      id
      birthDate
      companyId
      documentNumber
      email
      mobile
      fullName
      phone
      personalDocument
      personalDocumentState
      personalDocumentEmitter
    }
  }
`
export type RegisterCompanyLegalRepresentativeMutationFn = Apollo.MutationFunction<
  RegisterCompanyLegalRepresentativeMutation,
  RegisterCompanyLegalRepresentativeMutationVariables
>

/**
 * __useRegisterCompanyLegalRepresentativeMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyLegalRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyLegalRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyLegalRepresentativeMutation, { data, loading, error }] = useRegisterCompanyLegalRepresentativeMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyLegalRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterCompanyLegalRepresentativeMutation,
    RegisterCompanyLegalRepresentativeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterCompanyLegalRepresentativeMutation,
    RegisterCompanyLegalRepresentativeMutationVariables
  >(RegisterCompanyLegalRepresentativeDocument, options)
}
export type RegisterCompanyLegalRepresentativeMutationHookResult = ReturnType<
  typeof useRegisterCompanyLegalRepresentativeMutation
>
export type RegisterCompanyLegalRepresentativeMutationResult =
  Apollo.MutationResult<RegisterCompanyLegalRepresentativeMutation>
export type RegisterCompanyLegalRepresentativeMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyLegalRepresentativeMutation,
  RegisterCompanyLegalRepresentativeMutationVariables
>
export const RegisterCompanyCustomerOrSupplierDocument = gql`
  mutation registerCompanyCustomerOrSupplier($params: CompanyCustomerOrSupplierInput!) {
    registerCompanyCustomerOrSupplier(params: $params) {
      isCustomer
      customerId
      supplierId
      email
      fullName
      companyName
      phone
      documentNumber
      statusId
      companyId
    }
  }
`
export type RegisterCompanyCustomerOrSupplierMutationFn = Apollo.MutationFunction<
  RegisterCompanyCustomerOrSupplierMutation,
  RegisterCompanyCustomerOrSupplierMutationVariables
>

/**
 * __useRegisterCompanyCustomerOrSupplierMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyCustomerOrSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyCustomerOrSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyCustomerOrSupplierMutation, { data, loading, error }] = useRegisterCompanyCustomerOrSupplierMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyCustomerOrSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterCompanyCustomerOrSupplierMutation,
    RegisterCompanyCustomerOrSupplierMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterCompanyCustomerOrSupplierMutation,
    RegisterCompanyCustomerOrSupplierMutationVariables
  >(RegisterCompanyCustomerOrSupplierDocument, options)
}
export type RegisterCompanyCustomerOrSupplierMutationHookResult = ReturnType<
  typeof useRegisterCompanyCustomerOrSupplierMutation
>
export type RegisterCompanyCustomerOrSupplierMutationResult =
  Apollo.MutationResult<RegisterCompanyCustomerOrSupplierMutation>
export type RegisterCompanyCustomerOrSupplierMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyCustomerOrSupplierMutation,
  RegisterCompanyCustomerOrSupplierMutationVariables
>
export const RegisterCompanyPartnerDocument = gql`
  mutation registerCompanyPartner($params: CompanyPartnerInput!) {
    registerCompanyPartner(params: $params) {
      typeId
      id
      birthDate
      documentNumber
      email
      fullName
      phone
      mobile
      personalDocument
      personalDocumentState
      personalDocumentEmitter
      companyId
      companyShareholding
      signsForCompany
      maritalStatusId
      hasEcpf
      addressZipCode
      addressStreetName
      addressNumber
      addressComplement
      addressNeighborhood
      addressCity
      addressState
    }
  }
`
export type RegisterCompanyPartnerMutationFn = Apollo.MutationFunction<
  RegisterCompanyPartnerMutation,
  RegisterCompanyPartnerMutationVariables
>

/**
 * __useRegisterCompanyPartnerMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyPartnerMutation, { data, loading, error }] = useRegisterCompanyPartnerMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterCompanyPartnerMutation, RegisterCompanyPartnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterCompanyPartnerMutation, RegisterCompanyPartnerMutationVariables>(
    RegisterCompanyPartnerDocument,
    options
  )
}
export type RegisterCompanyPartnerMutationHookResult = ReturnType<typeof useRegisterCompanyPartnerMutation>
export type RegisterCompanyPartnerMutationResult = Apollo.MutationResult<RegisterCompanyPartnerMutation>
export type RegisterCompanyPartnerMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyPartnerMutation,
  RegisterCompanyPartnerMutationVariables
>
export const RegisterWitnessDocument = gql`
  mutation registerWitness($params: WitnessInput) {
    registerWitness(params: $params) {
      id
      companyId
      fullName
      documentNumber
      personalDocument
      email
    }
  }
`
export type RegisterWitnessMutationFn = Apollo.MutationFunction<
  RegisterWitnessMutation,
  RegisterWitnessMutationVariables
>

/**
 * __useRegisterWitnessMutation__
 *
 * To run a mutation, you first call `useRegisterWitnessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWitnessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWitnessMutation, { data, loading, error }] = useRegisterWitnessMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterWitnessMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterWitnessMutation, RegisterWitnessMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterWitnessMutation, RegisterWitnessMutationVariables>(RegisterWitnessDocument, options)
}
export type RegisterWitnessMutationHookResult = ReturnType<typeof useRegisterWitnessMutation>
export type RegisterWitnessMutationResult = Apollo.MutationResult<RegisterWitnessMutation>
export type RegisterWitnessMutationOptions = Apollo.BaseMutationOptions<
  RegisterWitnessMutation,
  RegisterWitnessMutationVariables
>
export const RegisterCompanyTaxesDocument = gql`
  mutation registerCompanyTaxes($params: CompanyTaxesInput) {
    registerCompanyTaxes(params: $params) {
      companyId
      adValorem
      iofAmount
      iofTerm
      chargeTax
      operationTax
      analysisTax
      settlementTerm
    }
  }
`
export type RegisterCompanyTaxesMutationFn = Apollo.MutationFunction<
  RegisterCompanyTaxesMutation,
  RegisterCompanyTaxesMutationVariables
>

/**
 * __useRegisterCompanyTaxesMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyTaxesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyTaxesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyTaxesMutation, { data, loading, error }] = useRegisterCompanyTaxesMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyTaxesMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterCompanyTaxesMutation, RegisterCompanyTaxesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterCompanyTaxesMutation, RegisterCompanyTaxesMutationVariables>(
    RegisterCompanyTaxesDocument,
    options
  )
}
export type RegisterCompanyTaxesMutationHookResult = ReturnType<typeof useRegisterCompanyTaxesMutation>
export type RegisterCompanyTaxesMutationResult = Apollo.MutationResult<RegisterCompanyTaxesMutation>
export type RegisterCompanyTaxesMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyTaxesMutation,
  RegisterCompanyTaxesMutationVariables
>
export const RegisterCompanyUserDocument = gql`
  mutation registerCompanyUser($params: CompanyUserInput!) {
    registerCompanyUser(params: $params) {
      id
      companyId
      status
      group
      fullName
      email
      mobile
      documentNumber
      lastAccess
    }
  }
`
export type RegisterCompanyUserMutationFn = Apollo.MutationFunction<
  RegisterCompanyUserMutation,
  RegisterCompanyUserMutationVariables
>

/**
 * __useRegisterCompanyUserMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyUserMutation, { data, loading, error }] = useRegisterCompanyUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterCompanyUserMutation, RegisterCompanyUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterCompanyUserMutation, RegisterCompanyUserMutationVariables>(
    RegisterCompanyUserDocument,
    options
  )
}
export type RegisterCompanyUserMutationHookResult = ReturnType<typeof useRegisterCompanyUserMutation>
export type RegisterCompanyUserMutationResult = Apollo.MutationResult<RegisterCompanyUserMutation>
export type RegisterCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyUserMutation,
  RegisterCompanyUserMutationVariables
>
export const DeleteCompanyLegalRepresentativeDocument = gql`
  mutation deleteCompanyLegalRepresentative($companyLegalRepresentativeId: String!) {
    deleteCompanyLegalRepresentative(companyLegalRepresentativeId: $companyLegalRepresentativeId) {
      id
    }
  }
`
export type DeleteCompanyLegalRepresentativeMutationFn = Apollo.MutationFunction<
  DeleteCompanyLegalRepresentativeMutation,
  DeleteCompanyLegalRepresentativeMutationVariables
>

/**
 * __useDeleteCompanyLegalRepresentativeMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyLegalRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyLegalRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyLegalRepresentativeMutation, { data, loading, error }] = useDeleteCompanyLegalRepresentativeMutation({
 *   variables: {
 *      companyLegalRepresentativeId: // value for 'companyLegalRepresentativeId'
 *   },
 * });
 */
export function useDeleteCompanyLegalRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyLegalRepresentativeMutation,
    DeleteCompanyLegalRepresentativeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCompanyLegalRepresentativeMutation,
    DeleteCompanyLegalRepresentativeMutationVariables
  >(DeleteCompanyLegalRepresentativeDocument, options)
}
export type DeleteCompanyLegalRepresentativeMutationHookResult = ReturnType<
  typeof useDeleteCompanyLegalRepresentativeMutation
>
export type DeleteCompanyLegalRepresentativeMutationResult =
  Apollo.MutationResult<DeleteCompanyLegalRepresentativeMutation>
export type DeleteCompanyLegalRepresentativeMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyLegalRepresentativeMutation,
  DeleteCompanyLegalRepresentativeMutationVariables
>
export const DeleteCompanyPartnerDocument = gql`
  mutation deleteCompanyPartner($companyPartnerId: String!) {
    deleteCompanyPartner(companyPartnerId: $companyPartnerId) {
      id
    }
  }
`
export type DeleteCompanyPartnerMutationFn = Apollo.MutationFunction<
  DeleteCompanyPartnerMutation,
  DeleteCompanyPartnerMutationVariables
>

/**
 * __useDeleteCompanyPartnerMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyPartnerMutation, { data, loading, error }] = useDeleteCompanyPartnerMutation({
 *   variables: {
 *      companyPartnerId: // value for 'companyPartnerId'
 *   },
 * });
 */
export function useDeleteCompanyPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyPartnerMutation, DeleteCompanyPartnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyPartnerMutation, DeleteCompanyPartnerMutationVariables>(
    DeleteCompanyPartnerDocument,
    options
  )
}
export type DeleteCompanyPartnerMutationHookResult = ReturnType<typeof useDeleteCompanyPartnerMutation>
export type DeleteCompanyPartnerMutationResult = Apollo.MutationResult<DeleteCompanyPartnerMutation>
export type DeleteCompanyPartnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyPartnerMutation,
  DeleteCompanyPartnerMutationVariables
>
export const DeleteCompanyCustomerDocument = gql`
  mutation deleteCompanyCustomer($companyCustomerId: String!) {
    deleteCompanyCustomer(companyCustomerId: $companyCustomerId) {
      id
    }
  }
`
export type DeleteCompanyCustomerMutationFn = Apollo.MutationFunction<
  DeleteCompanyCustomerMutation,
  DeleteCompanyCustomerMutationVariables
>

/**
 * __useDeleteCompanyCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyCustomerMutation, { data, loading, error }] = useDeleteCompanyCustomerMutation({
 *   variables: {
 *      companyCustomerId: // value for 'companyCustomerId'
 *   },
 * });
 */
export function useDeleteCompanyCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyCustomerMutation, DeleteCompanyCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyCustomerMutation, DeleteCompanyCustomerMutationVariables>(
    DeleteCompanyCustomerDocument,
    options
  )
}
export type DeleteCompanyCustomerMutationHookResult = ReturnType<typeof useDeleteCompanyCustomerMutation>
export type DeleteCompanyCustomerMutationResult = Apollo.MutationResult<DeleteCompanyCustomerMutation>
export type DeleteCompanyCustomerMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyCustomerMutation,
  DeleteCompanyCustomerMutationVariables
>
export const DeleteCompanySupplierDocument = gql`
  mutation deleteCompanySupplier($companySupplierId: String!) {
    deleteCompanySupplier(companySupplierId: $companySupplierId) {
      id
    }
  }
`
export type DeleteCompanySupplierMutationFn = Apollo.MutationFunction<
  DeleteCompanySupplierMutation,
  DeleteCompanySupplierMutationVariables
>

/**
 * __useDeleteCompanySupplierMutation__
 *
 * To run a mutation, you first call `useDeleteCompanySupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanySupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanySupplierMutation, { data, loading, error }] = useDeleteCompanySupplierMutation({
 *   variables: {
 *      companySupplierId: // value for 'companySupplierId'
 *   },
 * });
 */
export function useDeleteCompanySupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanySupplierMutation, DeleteCompanySupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanySupplierMutation, DeleteCompanySupplierMutationVariables>(
    DeleteCompanySupplierDocument,
    options
  )
}
export type DeleteCompanySupplierMutationHookResult = ReturnType<typeof useDeleteCompanySupplierMutation>
export type DeleteCompanySupplierMutationResult = Apollo.MutationResult<DeleteCompanySupplierMutation>
export type DeleteCompanySupplierMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanySupplierMutation,
  DeleteCompanySupplierMutationVariables
>
export const UpdateCompanyUserDocument = gql`
  mutation updateCompanyUser($params: UpdateCompanyUserInput!) {
    updateCompanyUser(params: $params) {
      id
      companyId
      status
      group
      fullName
      email
      mobile
      documentNumber
    }
  }
`
export type UpdateCompanyUserMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserMutation,
  UpdateCompanyUserMutationVariables
>

/**
 * __useUpdateCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserMutation, { data, loading, error }] = useUpdateCompanyUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>(
    UpdateCompanyUserDocument,
    options
  )
}
export type UpdateCompanyUserMutationHookResult = ReturnType<typeof useUpdateCompanyUserMutation>
export type UpdateCompanyUserMutationResult = Apollo.MutationResult<UpdateCompanyUserMutation>
export type UpdateCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserMutation,
  UpdateCompanyUserMutationVariables
>
export const DeleteCompanyDocumentDocument = gql`
  mutation deleteCompanyDocument($companyDocumentId: String!) {
    deleteCompanyDocument(companyDocumentId: $companyDocumentId) {
      id
    }
  }
`
export type DeleteCompanyDocumentMutationFn = Apollo.MutationFunction<
  DeleteCompanyDocumentMutation,
  DeleteCompanyDocumentMutationVariables
>

/**
 * __useDeleteCompanyDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyDocumentMutation, { data, loading, error }] = useDeleteCompanyDocumentMutation({
 *   variables: {
 *      companyDocumentId: // value for 'companyDocumentId'
 *   },
 * });
 */
export function useDeleteCompanyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyDocumentMutation, DeleteCompanyDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyDocumentMutation, DeleteCompanyDocumentMutationVariables>(
    DeleteCompanyDocumentDocument,
    options
  )
}
export type DeleteCompanyDocumentMutationHookResult = ReturnType<typeof useDeleteCompanyDocumentMutation>
export type DeleteCompanyDocumentMutationResult = Apollo.MutationResult<DeleteCompanyDocumentMutation>
export type DeleteCompanyDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyDocumentMutation,
  DeleteCompanyDocumentMutationVariables
>
export const DeleteCompanyBankAccountInfoDocument = gql`
  mutation deleteCompanyBankAccountInfo($companyBankAccountInfoId: String!, $companyId: String!) {
    deleteCompanyBankAccountInfo(companyBankAccountInfoId: $companyBankAccountInfoId, companyId: $companyId) {
      id
    }
  }
`
export type DeleteCompanyBankAccountInfoMutationFn = Apollo.MutationFunction<
  DeleteCompanyBankAccountInfoMutation,
  DeleteCompanyBankAccountInfoMutationVariables
>

/**
 * __useDeleteCompanyBankAccountInfoMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyBankAccountInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyBankAccountInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyBankAccountInfoMutation, { data, loading, error }] = useDeleteCompanyBankAccountInfoMutation({
 *   variables: {
 *      companyBankAccountInfoId: // value for 'companyBankAccountInfoId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCompanyBankAccountInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyBankAccountInfoMutation,
    DeleteCompanyBankAccountInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyBankAccountInfoMutation, DeleteCompanyBankAccountInfoMutationVariables>(
    DeleteCompanyBankAccountInfoDocument,
    options
  )
}
export type DeleteCompanyBankAccountInfoMutationHookResult = ReturnType<typeof useDeleteCompanyBankAccountInfoMutation>
export type DeleteCompanyBankAccountInfoMutationResult = Apollo.MutationResult<DeleteCompanyBankAccountInfoMutation>
export type DeleteCompanyBankAccountInfoMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyBankAccountInfoMutation,
  DeleteCompanyBankAccountInfoMutationVariables
>
export const RegisterCompanyBankAccountInfoDocument = gql`
  mutation registerCompanyBankAccountInfo($params: BankAccountInfoInput!) {
    registerCompanyBankAccountInfo(params: $params) {
      id
      companyName
      documentNumber
      accountNumber
      accountAgency
      kind
      bankName
      ispb
      bankCode
      isDefault
    }
  }
`
export type RegisterCompanyBankAccountInfoMutationFn = Apollo.MutationFunction<
  RegisterCompanyBankAccountInfoMutation,
  RegisterCompanyBankAccountInfoMutationVariables
>

/**
 * __useRegisterCompanyBankAccountInfoMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyBankAccountInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyBankAccountInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCompanyBankAccountInfoMutation, { data, loading, error }] = useRegisterCompanyBankAccountInfoMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterCompanyBankAccountInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterCompanyBankAccountInfoMutation,
    RegisterCompanyBankAccountInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterCompanyBankAccountInfoMutation, RegisterCompanyBankAccountInfoMutationVariables>(
    RegisterCompanyBankAccountInfoDocument,
    options
  )
}
export type RegisterCompanyBankAccountInfoMutationHookResult = ReturnType<
  typeof useRegisterCompanyBankAccountInfoMutation
>
export type RegisterCompanyBankAccountInfoMutationResult = Apollo.MutationResult<RegisterCompanyBankAccountInfoMutation>
export type RegisterCompanyBankAccountInfoMutationOptions = Apollo.BaseMutationOptions<
  RegisterCompanyBankAccountInfoMutation,
  RegisterCompanyBankAccountInfoMutationVariables
>
export const SendToAnalysisDocument = gql`
  mutation sendToAnalysis($companyId: String!) {
    sendToAnalysis(companyId: $companyId) {
      id
    }
  }
`
export type SendToAnalysisMutationFn = Apollo.MutationFunction<SendToAnalysisMutation, SendToAnalysisMutationVariables>

/**
 * __useSendToAnalysisMutation__
 *
 * To run a mutation, you first call `useSendToAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToAnalysisMutation, { data, loading, error }] = useSendToAnalysisMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSendToAnalysisMutation(
  baseOptions?: Apollo.MutationHookOptions<SendToAnalysisMutation, SendToAnalysisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendToAnalysisMutation, SendToAnalysisMutationVariables>(SendToAnalysisDocument, options)
}
export type SendToAnalysisMutationHookResult = ReturnType<typeof useSendToAnalysisMutation>
export type SendToAnalysisMutationResult = Apollo.MutationResult<SendToAnalysisMutation>
export type SendToAnalysisMutationOptions = Apollo.BaseMutationOptions<
  SendToAnalysisMutation,
  SendToAnalysisMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation createCompany($companyProposalId: String!) {
    createCompany(companyProposalId: $companyProposalId) {
      id
      companyName
      documentNumber
    }
  }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      companyProposalId: // value for 'companyProposalId'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options)
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const CreateCompanyProposalDocument = gql`
  mutation createCompanyProposal($params: CreateCompanyProposalInput!) {
    createCompanyProposal(params: $params) {
      id
      status
    }
  }
`
export type CreateCompanyProposalMutationFn = Apollo.MutationFunction<
  CreateCompanyProposalMutation,
  CreateCompanyProposalMutationVariables
>

/**
 * __useCreateCompanyProposalMutation__
 *
 * To run a mutation, you first call `useCreateCompanyProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyProposalMutation, { data, loading, error }] = useCreateCompanyProposalMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCompanyProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyProposalMutation, CreateCompanyProposalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyProposalMutation, CreateCompanyProposalMutationVariables>(
    CreateCompanyProposalDocument,
    options
  )
}
export type CreateCompanyProposalMutationHookResult = ReturnType<typeof useCreateCompanyProposalMutation>
export type CreateCompanyProposalMutationResult = Apollo.MutationResult<CreateCompanyProposalMutation>
export type CreateCompanyProposalMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyProposalMutation,
  CreateCompanyProposalMutationVariables
>
export const SendCompanyRevenueDocument = gql`
  mutation sendCompanyRevenue($payload: SendCompanyRevenueInput!) {
    sendCompanyRevenue(payload: $payload) {
      revenues {
        amount
        period
        kind
      }
    }
  }
`
export type SendCompanyRevenueMutationFn = Apollo.MutationFunction<
  SendCompanyRevenueMutation,
  SendCompanyRevenueMutationVariables
>

/**
 * __useSendCompanyRevenueMutation__
 *
 * To run a mutation, you first call `useSendCompanyRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompanyRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompanyRevenueMutation, { data, loading, error }] = useSendCompanyRevenueMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSendCompanyRevenueMutation(
  baseOptions?: Apollo.MutationHookOptions<SendCompanyRevenueMutation, SendCompanyRevenueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendCompanyRevenueMutation, SendCompanyRevenueMutationVariables>(
    SendCompanyRevenueDocument,
    options
  )
}
export type SendCompanyRevenueMutationHookResult = ReturnType<typeof useSendCompanyRevenueMutation>
export type SendCompanyRevenueMutationResult = Apollo.MutationResult<SendCompanyRevenueMutation>
export type SendCompanyRevenueMutationOptions = Apollo.BaseMutationOptions<
  SendCompanyRevenueMutation,
  SendCompanyRevenueMutationVariables
>
export const CreateUserDocument = gql`
  mutation createUser($params: CreateUserInput!) {
    createUser(params: $params) {
      id
      email
      companyId
    }
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options)
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>
export const AnswerQuizDocument = gql`
  mutation answerQuiz($params: AnswerQuizInput!) {
    answerQuiz(params: $params) {
      status
      buyers
      averageTax
      minTax
      companyId
    }
  }
`
export type AnswerQuizMutationFn = Apollo.MutationFunction<AnswerQuizMutation, AnswerQuizMutationVariables>

/**
 * __useAnswerQuizMutation__
 *
 * To run a mutation, you first call `useAnswerQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerQuizMutation, { data, loading, error }] = useAnswerQuizMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAnswerQuizMutation(
  baseOptions?: Apollo.MutationHookOptions<AnswerQuizMutation, AnswerQuizMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AnswerQuizMutation, AnswerQuizMutationVariables>(AnswerQuizDocument, options)
}
export type AnswerQuizMutationHookResult = ReturnType<typeof useAnswerQuizMutation>
export type AnswerQuizMutationResult = Apollo.MutationResult<AnswerQuizMutation>
export type AnswerQuizMutationOptions = Apollo.BaseMutationOptions<AnswerQuizMutation, AnswerQuizMutationVariables>
export const UpdateCompanyBankAccountInfoDocument = gql`
  mutation updateCompanyBankAccountInfo($params: UpdateBankAccountInfoInput!) {
    updateCompanyBankAccountInfo(params: $params) {
      id
      companyName
      documentNumber
      accountNumber
      accountAgency
      kind
      bankName
      isDefault
    }
  }
`
export type UpdateCompanyBankAccountInfoMutationFn = Apollo.MutationFunction<
  UpdateCompanyBankAccountInfoMutation,
  UpdateCompanyBankAccountInfoMutationVariables
>

/**
 * __useUpdateCompanyBankAccountInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyBankAccountInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyBankAccountInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyBankAccountInfoMutation, { data, loading, error }] = useUpdateCompanyBankAccountInfoMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCompanyBankAccountInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyBankAccountInfoMutation,
    UpdateCompanyBankAccountInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyBankAccountInfoMutation, UpdateCompanyBankAccountInfoMutationVariables>(
    UpdateCompanyBankAccountInfoDocument,
    options
  )
}
export type UpdateCompanyBankAccountInfoMutationHookResult = ReturnType<typeof useUpdateCompanyBankAccountInfoMutation>
export type UpdateCompanyBankAccountInfoMutationResult = Apollo.MutationResult<UpdateCompanyBankAccountInfoMutation>
export type UpdateCompanyBankAccountInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyBankAccountInfoMutation,
  UpdateCompanyBankAccountInfoMutationVariables
>
export const UpdateDefaultCompanyDocument = gql`
  mutation updateDefaultCompany($companyId: String!) {
    updateDefaultCompany(companyId: $companyId) {
      id
    }
  }
`
export type UpdateDefaultCompanyMutationFn = Apollo.MutationFunction<
  UpdateDefaultCompanyMutation,
  UpdateDefaultCompanyMutationVariables
>

/**
 * __useUpdateDefaultCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultCompanyMutation, { data, loading, error }] = useUpdateDefaultCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateDefaultCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDefaultCompanyMutation, UpdateDefaultCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateDefaultCompanyMutation, UpdateDefaultCompanyMutationVariables>(
    UpdateDefaultCompanyDocument,
    options
  )
}
export type UpdateDefaultCompanyMutationHookResult = ReturnType<typeof useUpdateDefaultCompanyMutation>
export type UpdateDefaultCompanyMutationResult = Apollo.MutationResult<UpdateDefaultCompanyMutation>
export type UpdateDefaultCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateDefaultCompanyMutation,
  UpdateDefaultCompanyMutationVariables
>
export const MutateCompanyRatingsDocument = gql`
  mutation mutateCompanyRatings($params: MutateCompanyRatingsInput!) {
    mutateCompanyRatings(params: $params) {
      id
      creditLimitValue
      minimumRate
      maximumRate
      companyRating
      companyId
      buyerId
    }
  }
`
export type MutateCompanyRatingsMutationFn = Apollo.MutationFunction<
  MutateCompanyRatingsMutation,
  MutateCompanyRatingsMutationVariables
>

/**
 * __useMutateCompanyRatingsMutation__
 *
 * To run a mutation, you first call `useMutateCompanyRatingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateCompanyRatingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateCompanyRatingsMutation, { data, loading, error }] = useMutateCompanyRatingsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMutateCompanyRatingsMutation(
  baseOptions?: Apollo.MutationHookOptions<MutateCompanyRatingsMutation, MutateCompanyRatingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MutateCompanyRatingsMutation, MutateCompanyRatingsMutationVariables>(
    MutateCompanyRatingsDocument,
    options
  )
}
export type MutateCompanyRatingsMutationHookResult = ReturnType<typeof useMutateCompanyRatingsMutation>
export type MutateCompanyRatingsMutationResult = Apollo.MutationResult<MutateCompanyRatingsMutation>
export type MutateCompanyRatingsMutationOptions = Apollo.BaseMutationOptions<
  MutateCompanyRatingsMutation,
  MutateCompanyRatingsMutationVariables
>
export const CreateRatingReasonsDocument = gql`
  mutation createRatingReasons($params: CreateRatingReasonsInput!) {
    createRatingReasons(params: $params) {
      id
    }
  }
`
export type CreateRatingReasonsMutationFn = Apollo.MutationFunction<
  CreateRatingReasonsMutation,
  CreateRatingReasonsMutationVariables
>

/**
 * __useCreateRatingReasonsMutation__
 *
 * To run a mutation, you first call `useCreateRatingReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingReasonsMutation, { data, loading, error }] = useCreateRatingReasonsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateRatingReasonsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRatingReasonsMutation, CreateRatingReasonsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateRatingReasonsMutation, CreateRatingReasonsMutationVariables>(
    CreateRatingReasonsDocument,
    options
  )
}
export type CreateRatingReasonsMutationHookResult = ReturnType<typeof useCreateRatingReasonsMutation>
export type CreateRatingReasonsMutationResult = Apollo.MutationResult<CreateRatingReasonsMutation>
export type CreateRatingReasonsMutationOptions = Apollo.BaseMutationOptions<
  CreateRatingReasonsMutation,
  CreateRatingReasonsMutationVariables
>
export const GetExtractDocument = gql`
  query getExtract($params: ExtractInput) {
    getExtract(params: $params) {
      totalCount
      records
      transactions {
        id
        moveType
        value
        description
        date
        createdAt
        updatedAt
        counterpart
        balanceAfter
      }
    }
  }
`

/**
 * __useGetExtractQuery__
 *
 * To run a query within a React component, call `useGetExtractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtractQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetExtractQuery(baseOptions?: Apollo.QueryHookOptions<GetExtractQuery, GetExtractQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetExtractQuery, GetExtractQueryVariables>(GetExtractDocument, options)
}
export function useGetExtractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExtractQuery, GetExtractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetExtractQuery, GetExtractQueryVariables>(GetExtractDocument, options)
}
export type GetExtractQueryHookResult = ReturnType<typeof useGetExtractQuery>
export type GetExtractLazyQueryHookResult = ReturnType<typeof useGetExtractLazyQuery>
export type GetExtractQueryResult = Apollo.QueryResult<GetExtractQuery, GetExtractQueryVariables>
export const GetOwnershipReceiptDocument = gql`
  query getOwnershipReceipt($agency: String, $account: String) {
    getOwnershipReceipt(agency: $agency, account: $account)
  }
`

/**
 * __useGetOwnershipReceiptQuery__
 *
 * To run a query within a React component, call `useGetOwnershipReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnershipReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnershipReceiptQuery({
 *   variables: {
 *      agency: // value for 'agency'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetOwnershipReceiptQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOwnershipReceiptQuery, GetOwnershipReceiptQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOwnershipReceiptQuery, GetOwnershipReceiptQueryVariables>(
    GetOwnershipReceiptDocument,
    options
  )
}
export function useGetOwnershipReceiptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnershipReceiptQuery, GetOwnershipReceiptQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOwnershipReceiptQuery, GetOwnershipReceiptQueryVariables>(
    GetOwnershipReceiptDocument,
    options
  )
}
export type GetOwnershipReceiptQueryHookResult = ReturnType<typeof useGetOwnershipReceiptQuery>
export type GetOwnershipReceiptLazyQueryHookResult = ReturnType<typeof useGetOwnershipReceiptLazyQuery>
export type GetOwnershipReceiptQueryResult = Apollo.QueryResult<
  GetOwnershipReceiptQuery,
  GetOwnershipReceiptQueryVariables
>
export const GetThemeDocument = gql`
  query getTheme($hostname: String) {
    getTheme(hostname: $hostname) {
      storeId
      storeName
      storeShortName
      logoMain
      logoSecondary
      favicon
      colors {
        group
        primary
        secondary
      }
    }
  }
`

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useGetThemeQuery(baseOptions?: Apollo.QueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, options)
}
export function useGetThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, options)
}
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>
export type GetThemeLazyQueryHookResult = ReturnType<typeof useGetThemeLazyQuery>
export type GetThemeQueryResult = Apollo.QueryResult<GetThemeQuery, GetThemeQueryVariables>
export const GetCompanyInfosDocument = gql`
  query getCompanyInfos($businessId: String!) {
    getCompanyInfos(businessId: $businessId) {
      activity
      activityDescription
      registerAddressCity
      registerAddressComplement
      registerAddressNeighborhood
      registerAddressNumber
      registerAddressState
      registerAddressStreet
      registerAddressZipCode
      businessId
      activityCode
      documentNumber
      companyName
      email
      facebook
      instagram
      linkedin
      tradeName
      phone
      workingAddressCity
      workingAddressComplement
      workingAddressNeighborhood
      workingAddressNumber
      workingAddressState
      workingAddressStreet
      workingAddressZipCode
      website
      taxRuleId
      companySizeId
      constitutionId
      buyerTypeId
      companySizes {
        businessSizeId
        businessSizeName
      }
      taxRules {
        taxRuleId
        taxRuleName
      }
      constitutions {
        constitutionId
        constitutionName
      }
      buyerTypes {
        buyerTypeId
        buyerTypeName
      }
    }
  }
`

/**
 * __useGetCompanyInfosQuery__
 *
 * To run a query within a React component, call `useGetCompanyInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInfosQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetCompanyInfosQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyInfosQuery, GetCompanyInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyInfosQuery, GetCompanyInfosQueryVariables>(GetCompanyInfosDocument, options)
}
export function useGetCompanyInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyInfosQuery, GetCompanyInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyInfosQuery, GetCompanyInfosQueryVariables>(GetCompanyInfosDocument, options)
}
export type GetCompanyInfosQueryHookResult = ReturnType<typeof useGetCompanyInfosQuery>
export type GetCompanyInfosLazyQueryHookResult = ReturnType<typeof useGetCompanyInfosLazyQuery>
export type GetCompanyInfosQueryResult = Apollo.QueryResult<GetCompanyInfosQuery, GetCompanyInfosQueryVariables>
export const GetDefaultCompanyDocument = gql`
  query getDefaultCompany {
    getDefaultCompany {
      businessId
      name
      countryIdentify
      originId
      typeId
      statusId
      statusName
      personTypeId
      personTypeName
      registerDate
      bankAccountTypeId
      typeName
      corporateName
      statusDate
      motiveId
      motiveName
      originType
      moskitCrmContactId
      moskitCrmCompanyId
      moskitCrmDealId
      moskitCrmResponsibleId
      lastUpdateDate
      originTypeId
    }
  }
`

/**
 * __useGetDefaultCompanyQuery__
 *
 * To run a query within a React component, call `useGetDefaultCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDefaultCompanyQuery, GetDefaultCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDefaultCompanyQuery, GetDefaultCompanyQueryVariables>(GetDefaultCompanyDocument, options)
}
export function useGetDefaultCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultCompanyQuery, GetDefaultCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDefaultCompanyQuery, GetDefaultCompanyQueryVariables>(
    GetDefaultCompanyDocument,
    options
  )
}
export type GetDefaultCompanyQueryHookResult = ReturnType<typeof useGetDefaultCompanyQuery>
export type GetDefaultCompanyLazyQueryHookResult = ReturnType<typeof useGetDefaultCompanyLazyQuery>
export type GetDefaultCompanyQueryResult = Apollo.QueryResult<GetDefaultCompanyQuery, GetDefaultCompanyQueryVariables>
export const GetCompanyLegalRepresentativesByCompanyIdDocument = gql`
  query getCompanyLegalRepresentativesByCompanyId($companyId: String!) {
    getCompanyLegalRepresentativesByCompanyId(companyId: $companyId) {
      id
      birthDate
      companyId
      documentNumber
      email
      mobile
      fullName
      phone
      personalDocument
      personalDocumentState
      personalDocumentEmitter
    }
  }
`

/**
 * __useGetCompanyLegalRepresentativesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyLegalRepresentativesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLegalRepresentativesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLegalRepresentativesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyLegalRepresentativesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyLegalRepresentativesByCompanyIdQuery,
    GetCompanyLegalRepresentativesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCompanyLegalRepresentativesByCompanyIdQuery,
    GetCompanyLegalRepresentativesByCompanyIdQueryVariables
  >(GetCompanyLegalRepresentativesByCompanyIdDocument, options)
}
export function useGetCompanyLegalRepresentativesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyLegalRepresentativesByCompanyIdQuery,
    GetCompanyLegalRepresentativesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCompanyLegalRepresentativesByCompanyIdQuery,
    GetCompanyLegalRepresentativesByCompanyIdQueryVariables
  >(GetCompanyLegalRepresentativesByCompanyIdDocument, options)
}
export type GetCompanyLegalRepresentativesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCompanyLegalRepresentativesByCompanyIdQuery
>
export type GetCompanyLegalRepresentativesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLegalRepresentativesByCompanyIdLazyQuery
>
export type GetCompanyLegalRepresentativesByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyLegalRepresentativesByCompanyIdQuery,
  GetCompanyLegalRepresentativesByCompanyIdQueryVariables
>
export const GetCompanyPartnersByCompanyIdDocument = gql`
  query getCompanyPartnersByCompanyId($companyId: String!) {
    getCompanyPartnersByCompanyId(companyId: $companyId) {
      typeId
      id
      birthDate
      documentNumber
      email
      fullName
      phone
      mobile
      personalDocument
      personalDocumentState
      personalDocumentEmitter
      companyId
      companyShareholding
      signsForCompany
      maritalStatusId
      hasEcpf
      addressZipCode
      addressStreetName
      addressNumber
      addressComplement
      addressNeighborhood
      addressCity
      addressState
    }
  }
`

/**
 * __useGetCompanyPartnersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyPartnersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPartnersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPartnersByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyPartnersByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyPartnersByCompanyIdQuery, GetCompanyPartnersByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyPartnersByCompanyIdQuery, GetCompanyPartnersByCompanyIdQueryVariables>(
    GetCompanyPartnersByCompanyIdDocument,
    options
  )
}
export function useGetCompanyPartnersByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPartnersByCompanyIdQuery,
    GetCompanyPartnersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyPartnersByCompanyIdQuery, GetCompanyPartnersByCompanyIdQueryVariables>(
    GetCompanyPartnersByCompanyIdDocument,
    options
  )
}
export type GetCompanyPartnersByCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyPartnersByCompanyIdQuery>
export type GetCompanyPartnersByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPartnersByCompanyIdLazyQuery
>
export type GetCompanyPartnersByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyPartnersByCompanyIdQuery,
  GetCompanyPartnersByCompanyIdQueryVariables
>
export const GetCompanyCustomersAndSuppliersByCompanyIdDocument = gql`
  query getCompanyCustomersAndSuppliersByCompanyId($companyId: String!) {
    getCompanyCustomersAndSuppliersByCompanyId(companyId: $companyId) {
      isCustomer
      customerId
      supplierId
      email
      fullName
      companyName
      phone
      documentNumber
      statusId
      companyId
    }
  }
`

/**
 * __useGetCompanyCustomersAndSuppliersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyCustomersAndSuppliersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCustomersAndSuppliersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCustomersAndSuppliersByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyCustomersAndSuppliersByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyCustomersAndSuppliersByCompanyIdQuery,
    GetCompanyCustomersAndSuppliersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCompanyCustomersAndSuppliersByCompanyIdQuery,
    GetCompanyCustomersAndSuppliersByCompanyIdQueryVariables
  >(GetCompanyCustomersAndSuppliersByCompanyIdDocument, options)
}
export function useGetCompanyCustomersAndSuppliersByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCustomersAndSuppliersByCompanyIdQuery,
    GetCompanyCustomersAndSuppliersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCompanyCustomersAndSuppliersByCompanyIdQuery,
    GetCompanyCustomersAndSuppliersByCompanyIdQueryVariables
  >(GetCompanyCustomersAndSuppliersByCompanyIdDocument, options)
}
export type GetCompanyCustomersAndSuppliersByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCompanyCustomersAndSuppliersByCompanyIdQuery
>
export type GetCompanyCustomersAndSuppliersByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCustomersAndSuppliersByCompanyIdLazyQuery
>
export type GetCompanyCustomersAndSuppliersByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyCustomersAndSuppliersByCompanyIdQuery,
  GetCompanyCustomersAndSuppliersByCompanyIdQueryVariables
>
export const GetWitnessesByCompanyIdDocument = gql`
  query getWitnessesByCompanyId($companyId: String!) {
    getWitnessesByCompanyId(companyId: $companyId) {
      id
      companyId
      fullName
      documentNumber
      personalDocument
      email
    }
  }
`

/**
 * __useGetWitnessesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetWitnessesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWitnessesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWitnessesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWitnessesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetWitnessesByCompanyIdQuery, GetWitnessesByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWitnessesByCompanyIdQuery, GetWitnessesByCompanyIdQueryVariables>(
    GetWitnessesByCompanyIdDocument,
    options
  )
}
export function useGetWitnessesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWitnessesByCompanyIdQuery, GetWitnessesByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWitnessesByCompanyIdQuery, GetWitnessesByCompanyIdQueryVariables>(
    GetWitnessesByCompanyIdDocument,
    options
  )
}
export type GetWitnessesByCompanyIdQueryHookResult = ReturnType<typeof useGetWitnessesByCompanyIdQuery>
export type GetWitnessesByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetWitnessesByCompanyIdLazyQuery>
export type GetWitnessesByCompanyIdQueryResult = Apollo.QueryResult<
  GetWitnessesByCompanyIdQuery,
  GetWitnessesByCompanyIdQueryVariables
>
export const GetCompanyTaxesByCompanyIdDocument = gql`
  query getCompanyTaxesByCompanyId($companyId: String!) {
    getCompanyTaxesByCompanyId(companyId: $companyId) {
      companyId
      adValorem
      iofAmount
      iofTerm
      chargeTax
      operationTax
      analysisTax
      settlementTerm
    }
  }
`

/**
 * __useGetCompanyTaxesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyTaxesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTaxesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTaxesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyTaxesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyTaxesByCompanyIdQuery, GetCompanyTaxesByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyTaxesByCompanyIdQuery, GetCompanyTaxesByCompanyIdQueryVariables>(
    GetCompanyTaxesByCompanyIdDocument,
    options
  )
}
export function useGetCompanyTaxesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyTaxesByCompanyIdQuery, GetCompanyTaxesByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyTaxesByCompanyIdQuery, GetCompanyTaxesByCompanyIdQueryVariables>(
    GetCompanyTaxesByCompanyIdDocument,
    options
  )
}
export type GetCompanyTaxesByCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyTaxesByCompanyIdQuery>
export type GetCompanyTaxesByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCompanyTaxesByCompanyIdLazyQuery>
export type GetCompanyTaxesByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyTaxesByCompanyIdQuery,
  GetCompanyTaxesByCompanyIdQueryVariables
>
export const GetCompanyUsersByCompanyIdDocument = gql`
  query getCompanyUsersByCompanyId($companyId: String!) {
    getCompanyUsersByCompanyId(companyId: $companyId) {
      id
      companyId
      status
      group
      fullName
      email
      mobile
      documentNumber
      lastAccess
    }
  }
`

/**
 * __useGetCompanyUsersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyUsersByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyUsersByCompanyIdQuery, GetCompanyUsersByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyUsersByCompanyIdQuery, GetCompanyUsersByCompanyIdQueryVariables>(
    GetCompanyUsersByCompanyIdDocument,
    options
  )
}
export function useGetCompanyUsersByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsersByCompanyIdQuery, GetCompanyUsersByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyUsersByCompanyIdQuery, GetCompanyUsersByCompanyIdQueryVariables>(
    GetCompanyUsersByCompanyIdDocument,
    options
  )
}
export type GetCompanyUsersByCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyUsersByCompanyIdQuery>
export type GetCompanyUsersByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCompanyUsersByCompanyIdLazyQuery>
export type GetCompanyUsersByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyUsersByCompanyIdQuery,
  GetCompanyUsersByCompanyIdQueryVariables
>
export const GetCompanyDocumentsByCompanyIdDocument = gql`
  query getCompanyDocumentsByCompanyId($companyId: String!) {
    getCompanyDocumentsByCompanyId(companyId: $companyId) {
      id
      originTypeId
      originTypeName
      companyId
      documentTypeId
      documentTypeName
      statusId
      statusName
      name
      type
      size
      lastModifiedDate
      key
      registerDate
      uploadStatusId
      url
      description
      exists
      validated
      validUntil
      docTypeId
      makeDocumentAvaliable
      pendingId
      removePending
      inDossie
      downloadUrl
    }
  }
`

/**
 * __useGetCompanyDocumentsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyDocumentsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDocumentsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDocumentsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyDocumentsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyDocumentsByCompanyIdQuery,
    GetCompanyDocumentsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyDocumentsByCompanyIdQuery, GetCompanyDocumentsByCompanyIdQueryVariables>(
    GetCompanyDocumentsByCompanyIdDocument,
    options
  )
}
export function useGetCompanyDocumentsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDocumentsByCompanyIdQuery,
    GetCompanyDocumentsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyDocumentsByCompanyIdQuery, GetCompanyDocumentsByCompanyIdQueryVariables>(
    GetCompanyDocumentsByCompanyIdDocument,
    options
  )
}
export type GetCompanyDocumentsByCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyDocumentsByCompanyIdQuery>
export type GetCompanyDocumentsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDocumentsByCompanyIdLazyQuery
>
export type GetCompanyDocumentsByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyDocumentsByCompanyIdQuery,
  GetCompanyDocumentsByCompanyIdQueryVariables
>
export const GetCompanyBankAccountInfoByCompanyIdDocument = gql`
  query getCompanyBankAccountInfoByCompanyId($companyId: String!) {
    getCompanyBankAccountInfoByCompanyId(companyId: $companyId) {
      id
      companyName
      documentNumber
      accountNumber
      bankName
      accountAgency
      bankCode
      accountDigit
      ispb
      kind
      isDefault
    }
  }
`

/**
 * __useGetCompanyBankAccountInfoByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyBankAccountInfoByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBankAccountInfoByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBankAccountInfoByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyBankAccountInfoByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyBankAccountInfoByCompanyIdQuery,
    GetCompanyBankAccountInfoByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyBankAccountInfoByCompanyIdQuery, GetCompanyBankAccountInfoByCompanyIdQueryVariables>(
    GetCompanyBankAccountInfoByCompanyIdDocument,
    options
  )
}
export function useGetCompanyBankAccountInfoByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyBankAccountInfoByCompanyIdQuery,
    GetCompanyBankAccountInfoByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCompanyBankAccountInfoByCompanyIdQuery,
    GetCompanyBankAccountInfoByCompanyIdQueryVariables
  >(GetCompanyBankAccountInfoByCompanyIdDocument, options)
}
export type GetCompanyBankAccountInfoByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCompanyBankAccountInfoByCompanyIdQuery
>
export type GetCompanyBankAccountInfoByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyBankAccountInfoByCompanyIdLazyQuery
>
export type GetCompanyBankAccountInfoByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyBankAccountInfoByCompanyIdQuery,
  GetCompanyBankAccountInfoByCompanyIdQueryVariables
>
export const GetAuthTokenDocument = gql`
  query getAuthToken {
    getAuthToken
  }
`

/**
 * __useGetAuthTokenQuery__
 *
 * To run a query within a React component, call `useGetAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAuthTokenQuery, GetAuthTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAuthTokenQuery, GetAuthTokenQueryVariables>(GetAuthTokenDocument, options)
}
export function useGetAuthTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthTokenQuery, GetAuthTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAuthTokenQuery, GetAuthTokenQueryVariables>(GetAuthTokenDocument, options)
}
export type GetAuthTokenQueryHookResult = ReturnType<typeof useGetAuthTokenQuery>
export type GetAuthTokenLazyQueryHookResult = ReturnType<typeof useGetAuthTokenLazyQuery>
export type GetAuthTokenQueryResult = Apollo.QueryResult<GetAuthTokenQuery, GetAuthTokenQueryVariables>
export const GetCompanyProposalByIdDocument = gql`
  query getCompanyProposalById($companyProposalId: String!) {
    getCompanyProposalById(companyProposalId: $companyProposalId) {
      id
      status
      dataStatus
      documentNumber
      companyName
    }
  }
`

/**
 * __useGetCompanyProposalByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyProposalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyProposalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyProposalByIdQuery({
 *   variables: {
 *      companyProposalId: // value for 'companyProposalId'
 *   },
 * });
 */
export function useGetCompanyProposalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyProposalByIdQuery, GetCompanyProposalByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyProposalByIdQuery, GetCompanyProposalByIdQueryVariables>(
    GetCompanyProposalByIdDocument,
    options
  )
}
export function useGetCompanyProposalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyProposalByIdQuery, GetCompanyProposalByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyProposalByIdQuery, GetCompanyProposalByIdQueryVariables>(
    GetCompanyProposalByIdDocument,
    options
  )
}
export type GetCompanyProposalByIdQueryHookResult = ReturnType<typeof useGetCompanyProposalByIdQuery>
export type GetCompanyProposalByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyProposalByIdLazyQuery>
export type GetCompanyProposalByIdQueryResult = Apollo.QueryResult<
  GetCompanyProposalByIdQuery,
  GetCompanyProposalByIdQueryVariables
>
export const GetAddressByZipCodeDocument = gql`
  query getAddressByZipCode($zipCode: String!) {
    getAddressByZipCode(zipCode: $zipCode) {
      city
      streetName
      neighboorhood
      state
      zipCode
    }
  }
`

/**
 * __useGetAddressByZipCodeQuery__
 *
 * To run a query within a React component, call `useGetAddressByZipCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressByZipCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressByZipCodeQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useGetAddressByZipCodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetAddressByZipCodeQuery, GetAddressByZipCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAddressByZipCodeQuery, GetAddressByZipCodeQueryVariables>(
    GetAddressByZipCodeDocument,
    options
  )
}
export function useGetAddressByZipCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAddressByZipCodeQuery, GetAddressByZipCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAddressByZipCodeQuery, GetAddressByZipCodeQueryVariables>(
    GetAddressByZipCodeDocument,
    options
  )
}
export type GetAddressByZipCodeQueryHookResult = ReturnType<typeof useGetAddressByZipCodeQuery>
export type GetAddressByZipCodeLazyQueryHookResult = ReturnType<typeof useGetAddressByZipCodeLazyQuery>
export type GetAddressByZipCodeQueryResult = Apollo.QueryResult<
  GetAddressByZipCodeQuery,
  GetAddressByZipCodeQueryVariables
>
export const GetCompanyRevenueDocument = gql`
  query getCompanyRevenue($companyId: String!) {
    getCompanyRevenue(companyId: $companyId) {
      revenues {
        id
        period
        amount
        kind
      }
      totalCompanyRevenues
    }
  }
`

/**
 * __useGetCompanyRevenueQuery__
 *
 * To run a query within a React component, call `useGetCompanyRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRevenueQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyRevenueQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(GetCompanyRevenueDocument, options)
}
export function useGetCompanyRevenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(
    GetCompanyRevenueDocument,
    options
  )
}
export type GetCompanyRevenueQueryHookResult = ReturnType<typeof useGetCompanyRevenueQuery>
export type GetCompanyRevenueLazyQueryHookResult = ReturnType<typeof useGetCompanyRevenueLazyQuery>
export type GetCompanyRevenueQueryResult = Apollo.QueryResult<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>
export const GetContractDownloadUrlDocument = gql`
  query getContractDownloadUrl($contractId: String!) {
    getContractDownloadUrl(contractId: $contractId) {
      name
      url
    }
  }
`

/**
 * __useGetContractDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetContractDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDownloadUrlQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractDownloadUrlQuery, GetContractDownloadUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractDownloadUrlQuery, GetContractDownloadUrlQueryVariables>(
    GetContractDownloadUrlDocument,
    options
  )
}
export function useGetContractDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractDownloadUrlQuery, GetContractDownloadUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractDownloadUrlQuery, GetContractDownloadUrlQueryVariables>(
    GetContractDownloadUrlDocument,
    options
  )
}
export type GetContractDownloadUrlQueryHookResult = ReturnType<typeof useGetContractDownloadUrlQuery>
export type GetContractDownloadUrlLazyQueryHookResult = ReturnType<typeof useGetContractDownloadUrlLazyQuery>
export type GetContractDownloadUrlQueryResult = Apollo.QueryResult<
  GetContractDownloadUrlQuery,
  GetContractDownloadUrlQueryVariables
>
export const GetContractsByCompanyIdDocument = gql`
  query getContractsByCompanyId($companyId: String!) {
    getContractsByCompanyId(companyId: $companyId) {
      name
      kind
      status
      fileName
      id
      contractId
    }
  }
`

/**
 * __useGetContractsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetContractsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetContractsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractsByCompanyIdQuery, GetContractsByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsByCompanyIdQuery, GetContractsByCompanyIdQueryVariables>(
    GetContractsByCompanyIdDocument,
    options
  )
}
export function useGetContractsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractsByCompanyIdQuery, GetContractsByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractsByCompanyIdQuery, GetContractsByCompanyIdQueryVariables>(
    GetContractsByCompanyIdDocument,
    options
  )
}
export type GetContractsByCompanyIdQueryHookResult = ReturnType<typeof useGetContractsByCompanyIdQuery>
export type GetContractsByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetContractsByCompanyIdLazyQuery>
export type GetContractsByCompanyIdQueryResult = Apollo.QueryResult<
  GetContractsByCompanyIdQuery,
  GetContractsByCompanyIdQueryVariables
>
export const GetCompaniesFromUserDocument = gql`
  query getCompaniesFromUser {
    getCompaniesFromUser {
      id
      name
      cnpj
      isDefault
    }
  }
`

/**
 * __useGetCompaniesFromUserQuery__
 *
 * To run a query within a React component, call `useGetCompaniesFromUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesFromUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesFromUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesFromUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompaniesFromUserQuery, GetCompaniesFromUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompaniesFromUserQuery, GetCompaniesFromUserQueryVariables>(
    GetCompaniesFromUserDocument,
    options
  )
}
export function useGetCompaniesFromUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesFromUserQuery, GetCompaniesFromUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompaniesFromUserQuery, GetCompaniesFromUserQueryVariables>(
    GetCompaniesFromUserDocument,
    options
  )
}
export type GetCompaniesFromUserQueryHookResult = ReturnType<typeof useGetCompaniesFromUserQuery>
export type GetCompaniesFromUserLazyQueryHookResult = ReturnType<typeof useGetCompaniesFromUserLazyQuery>
export type GetCompaniesFromUserQueryResult = Apollo.QueryResult<
  GetCompaniesFromUserQuery,
  GetCompaniesFromUserQueryVariables
>
export const GetCustomersDocument = gql`
  query getCustomers($params: GetCustomersInput!) {
    getCustomers(params: $params) {
      data {
        companyId
        companyRating
        name
        cnpj
        addressState
        minimumRate
        maximumRate
        creditLimitValue
        operations
        averagePeriod
        registerDate
      }
      pagination {
        totalPages
        totalItems
      }
    }
  }
`

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options)
}
export function useGetCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options)
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>
export const GetCustomerProfileDocument = gql`
  query getCustomerProfile($params: GetCustomerProfileInput!) {
    getCustomerProfile(params: $params) {
      id
      tradeName
      companyName
      cnpj
      email
      phone
      registerDate
      instagram
      facebook
      linkedin
      site
    }
  }
`

/**
 * __useGetCustomerProfileQuery__
 *
 * To run a query within a React component, call `useGetCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerProfileQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>(GetCustomerProfileDocument, options)
}
export function useGetCustomerProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>(
    GetCustomerProfileDocument,
    options
  )
}
export type GetCustomerProfileQueryHookResult = ReturnType<typeof useGetCustomerProfileQuery>
export type GetCustomerProfileLazyQueryHookResult = ReturnType<typeof useGetCustomerProfileLazyQuery>
export type GetCustomerProfileQueryResult = Apollo.QueryResult<
  GetCustomerProfileQuery,
  GetCustomerProfileQueryVariables
>
export const GetCompanyRatingByCompanyIdDocument = gql`
  query getCompanyRatingByCompanyId($companyId: String!, $buyerId: String!) {
    getCompanyRatingByCompanyId(companyId: $companyId, buyerId: $buyerId) {
      id
      creditLimitValue
      minimumRate
      maximumRate
      companyRating
      companyId
      buyerId
    }
  }
`

/**
 * __useGetCompanyRatingByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyRatingByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRatingByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRatingByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      buyerId: // value for 'buyerId'
 *   },
 * });
 */
export function useGetCompanyRatingByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyRatingByCompanyIdQuery, GetCompanyRatingByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyRatingByCompanyIdQuery, GetCompanyRatingByCompanyIdQueryVariables>(
    GetCompanyRatingByCompanyIdDocument,
    options
  )
}
export function useGetCompanyRatingByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyRatingByCompanyIdQuery, GetCompanyRatingByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyRatingByCompanyIdQuery, GetCompanyRatingByCompanyIdQueryVariables>(
    GetCompanyRatingByCompanyIdDocument,
    options
  )
}
export type GetCompanyRatingByCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyRatingByCompanyIdQuery>
export type GetCompanyRatingByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCompanyRatingByCompanyIdLazyQuery>
export type GetCompanyRatingByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyRatingByCompanyIdQuery,
  GetCompanyRatingByCompanyIdQueryVariables
>
export const GetCustomerDataByCompanyIdDocument = gql`
  query getCustomerDataByCompanyId($params: GetCustomerDataByCompanyIdInput!) {
    getCustomerDataByCompanyId(params: $params) {
      business {
        activity
        codeActivity
        companySize
        constitutionName
        registerAddressCity
        registerAddressComplement
        registerAddressNeighborhood
        registerAddressNumber
        registerAddressState
        registerAddressStreet
        registerAddressZipCode
        registerDate
        birthDate
        activityDescription
      }
      videos {
        id
        name
        key
        url
      }
      responsibles {
        hasEcpf
        id
        name
        type
        document
      }
      customers {
        id
        name
      }
      bankAccounts {
        accountAgency
        accountNumber
        accountDigit
        bankName
        id
        isDefault
      }
      revenues {
        average
      }
    }
  }
`

/**
 * __useGetCustomerDataByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerDataByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDataByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDataByCompanyIdQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerDataByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerDataByCompanyIdQuery, GetCustomerDataByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerDataByCompanyIdQuery, GetCustomerDataByCompanyIdQueryVariables>(
    GetCustomerDataByCompanyIdDocument,
    options
  )
}
export function useGetCustomerDataByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDataByCompanyIdQuery, GetCustomerDataByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerDataByCompanyIdQuery, GetCustomerDataByCompanyIdQueryVariables>(
    GetCustomerDataByCompanyIdDocument,
    options
  )
}
export type GetCustomerDataByCompanyIdQueryHookResult = ReturnType<typeof useGetCustomerDataByCompanyIdQuery>
export type GetCustomerDataByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCustomerDataByCompanyIdLazyQuery>
export type GetCustomerDataByCompanyIdQueryResult = Apollo.QueryResult<
  GetCustomerDataByCompanyIdQuery,
  GetCustomerDataByCompanyIdQueryVariables
>
export const GetCreditAnalysisReportByCompanyIdDocument = gql`
  query getCreditAnalysisReportByCompanyId($params: GetCreditAnalysisReportByCompanyIdInput!) {
    getCreditAnalysisReportByCompanyId(params: $params) {
      id
      groupDocumentName
      url
    }
  }
`

/**
 * __useGetCreditAnalysisReportByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCreditAnalysisReportByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditAnalysisReportByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditAnalysisReportByCompanyIdQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCreditAnalysisReportByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreditAnalysisReportByCompanyIdQuery,
    GetCreditAnalysisReportByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCreditAnalysisReportByCompanyIdQuery, GetCreditAnalysisReportByCompanyIdQueryVariables>(
    GetCreditAnalysisReportByCompanyIdDocument,
    options
  )
}
export function useGetCreditAnalysisReportByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreditAnalysisReportByCompanyIdQuery,
    GetCreditAnalysisReportByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCreditAnalysisReportByCompanyIdQuery, GetCreditAnalysisReportByCompanyIdQueryVariables>(
    GetCreditAnalysisReportByCompanyIdDocument,
    options
  )
}
export type GetCreditAnalysisReportByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCreditAnalysisReportByCompanyIdQuery
>
export type GetCreditAnalysisReportByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCreditAnalysisReportByCompanyIdLazyQuery
>
export type GetCreditAnalysisReportByCompanyIdQueryResult = Apollo.QueryResult<
  GetCreditAnalysisReportByCompanyIdQuery,
  GetCreditAnalysisReportByCompanyIdQueryVariables
>
export const InDossieDocumentsGroupedByDocumentTypeDocument = gql`
  query inDossieDocumentsGroupedByDocumentType($companyId: String!) {
    inDossieDocumentsGroupedByDocumentType(companyId: $companyId) {
      id
      name
    }
  }
`

/**
 * __useInDossieDocumentsGroupedByDocumentTypeQuery__
 *
 * To run a query within a React component, call `useInDossieDocumentsGroupedByDocumentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInDossieDocumentsGroupedByDocumentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInDossieDocumentsGroupedByDocumentTypeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useInDossieDocumentsGroupedByDocumentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    InDossieDocumentsGroupedByDocumentTypeQuery,
    InDossieDocumentsGroupedByDocumentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InDossieDocumentsGroupedByDocumentTypeQuery,
    InDossieDocumentsGroupedByDocumentTypeQueryVariables
  >(InDossieDocumentsGroupedByDocumentTypeDocument, options)
}
export function useInDossieDocumentsGroupedByDocumentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InDossieDocumentsGroupedByDocumentTypeQuery,
    InDossieDocumentsGroupedByDocumentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InDossieDocumentsGroupedByDocumentTypeQuery,
    InDossieDocumentsGroupedByDocumentTypeQueryVariables
  >(InDossieDocumentsGroupedByDocumentTypeDocument, options)
}
export type InDossieDocumentsGroupedByDocumentTypeQueryHookResult = ReturnType<
  typeof useInDossieDocumentsGroupedByDocumentTypeQuery
>
export type InDossieDocumentsGroupedByDocumentTypeLazyQueryHookResult = ReturnType<
  typeof useInDossieDocumentsGroupedByDocumentTypeLazyQuery
>
export type InDossieDocumentsGroupedByDocumentTypeQueryResult = Apollo.QueryResult<
  InDossieDocumentsGroupedByDocumentTypeQuery,
  InDossieDocumentsGroupedByDocumentTypeQueryVariables
>
export const GetZipDownloadUrlFromAllDocumentsDocument = gql`
  query getZipDownloadUrlFromAllDocuments($companyId: String!) {
    getZipDownloadUrlFromAllDocuments(companyId: $companyId)
  }
`

/**
 * __useGetZipDownloadUrlFromAllDocumentsQuery__
 *
 * To run a query within a React component, call `useGetZipDownloadUrlFromAllDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZipDownloadUrlFromAllDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZipDownloadUrlFromAllDocumentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetZipDownloadUrlFromAllDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetZipDownloadUrlFromAllDocumentsQuery,
    GetZipDownloadUrlFromAllDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetZipDownloadUrlFromAllDocumentsQuery, GetZipDownloadUrlFromAllDocumentsQueryVariables>(
    GetZipDownloadUrlFromAllDocumentsDocument,
    options
  )
}
export function useGetZipDownloadUrlFromAllDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetZipDownloadUrlFromAllDocumentsQuery,
    GetZipDownloadUrlFromAllDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetZipDownloadUrlFromAllDocumentsQuery, GetZipDownloadUrlFromAllDocumentsQueryVariables>(
    GetZipDownloadUrlFromAllDocumentsDocument,
    options
  )
}
export type GetZipDownloadUrlFromAllDocumentsQueryHookResult = ReturnType<
  typeof useGetZipDownloadUrlFromAllDocumentsQuery
>
export type GetZipDownloadUrlFromAllDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetZipDownloadUrlFromAllDocumentsLazyQuery
>
export type GetZipDownloadUrlFromAllDocumentsQueryResult = Apollo.QueryResult<
  GetZipDownloadUrlFromAllDocumentsQuery,
  GetZipDownloadUrlFromAllDocumentsQueryVariables
>
export const GetZipDownloadUrlByDocumentGroupDocument = gql`
  query getZipDownloadUrlByDocumentGroup($companyId: String!, $documentGroupId: Int!) {
    getZipDownloadUrlByDocumentGroup(companyId: $companyId, documentGroupId: $documentGroupId)
  }
`

/**
 * __useGetZipDownloadUrlByDocumentGroupQuery__
 *
 * To run a query within a React component, call `useGetZipDownloadUrlByDocumentGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZipDownloadUrlByDocumentGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZipDownloadUrlByDocumentGroupQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      documentGroupId: // value for 'documentGroupId'
 *   },
 * });
 */
export function useGetZipDownloadUrlByDocumentGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetZipDownloadUrlByDocumentGroupQuery,
    GetZipDownloadUrlByDocumentGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetZipDownloadUrlByDocumentGroupQuery, GetZipDownloadUrlByDocumentGroupQueryVariables>(
    GetZipDownloadUrlByDocumentGroupDocument,
    options
  )
}
export function useGetZipDownloadUrlByDocumentGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetZipDownloadUrlByDocumentGroupQuery,
    GetZipDownloadUrlByDocumentGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetZipDownloadUrlByDocumentGroupQuery, GetZipDownloadUrlByDocumentGroupQueryVariables>(
    GetZipDownloadUrlByDocumentGroupDocument,
    options
  )
}
export type GetZipDownloadUrlByDocumentGroupQueryHookResult = ReturnType<
  typeof useGetZipDownloadUrlByDocumentGroupQuery
>
export type GetZipDownloadUrlByDocumentGroupLazyQueryHookResult = ReturnType<
  typeof useGetZipDownloadUrlByDocumentGroupLazyQuery
>
export type GetZipDownloadUrlByDocumentGroupQueryResult = Apollo.QueryResult<
  GetZipDownloadUrlByDocumentGroupQuery,
  GetZipDownloadUrlByDocumentGroupQueryVariables
>
export const GetReputationByCompanyIdDocument = gql`
  query getReputationByCompanyId($params: GetReputationByCompanyIdInput!) {
    getReputationByCompanyId(params: $params) {
      totalOperation
      mediumTerm
    }
  }
`

/**
 * __useGetReputationByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetReputationByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReputationByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReputationByCompanyIdQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetReputationByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetReputationByCompanyIdQuery, GetReputationByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReputationByCompanyIdQuery, GetReputationByCompanyIdQueryVariables>(
    GetReputationByCompanyIdDocument,
    options
  )
}
export function useGetReputationByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReputationByCompanyIdQuery, GetReputationByCompanyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReputationByCompanyIdQuery, GetReputationByCompanyIdQueryVariables>(
    GetReputationByCompanyIdDocument,
    options
  )
}
export type GetReputationByCompanyIdQueryHookResult = ReturnType<typeof useGetReputationByCompanyIdQuery>
export type GetReputationByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetReputationByCompanyIdLazyQuery>
export type GetReputationByCompanyIdQueryResult = Apollo.QueryResult<
  GetReputationByCompanyIdQuery,
  GetReputationByCompanyIdQueryVariables
>
export const GetCustomerReputationFromMetabaseDocument = gql`
  query getCustomerReputationFromMetabase($params: GetCustomerReputationFromMetabaseInput!) {
    getCustomerReputationFromMetabase(params: $params) {
      customerReputationURL
    }
  }
`

/**
 * __useGetCustomerReputationFromMetabaseQuery__
 *
 * To run a query within a React component, call `useGetCustomerReputationFromMetabaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReputationFromMetabaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReputationFromMetabaseQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerReputationFromMetabaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerReputationFromMetabaseQuery,
    GetCustomerReputationFromMetabaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerReputationFromMetabaseQuery, GetCustomerReputationFromMetabaseQueryVariables>(
    GetCustomerReputationFromMetabaseDocument,
    options
  )
}
export function useGetCustomerReputationFromMetabaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerReputationFromMetabaseQuery,
    GetCustomerReputationFromMetabaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerReputationFromMetabaseQuery, GetCustomerReputationFromMetabaseQueryVariables>(
    GetCustomerReputationFromMetabaseDocument,
    options
  )
}
export type GetCustomerReputationFromMetabaseQueryHookResult = ReturnType<
  typeof useGetCustomerReputationFromMetabaseQuery
>
export type GetCustomerReputationFromMetabaseLazyQueryHookResult = ReturnType<
  typeof useGetCustomerReputationFromMetabaseLazyQuery
>
export type GetCustomerReputationFromMetabaseQueryResult = Apollo.QueryResult<
  GetCustomerReputationFromMetabaseQuery,
  GetCustomerReputationFromMetabaseQueryVariables
>
export const GetCustomerAllDocumentsNamesDocument = gql`
  query getCustomerAllDocumentsNames($params: GetCustomerAllDocumentsNamesInput!) {
    getCustomerAllDocumentsNames(params: $params) {
      documentsIdsAndNames {
        id
        name
      }
    }
  }
`

/**
 * __useGetCustomerAllDocumentsNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomerAllDocumentsNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAllDocumentsNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAllDocumentsNamesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerAllDocumentsNamesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerAllDocumentsNamesQuery, GetCustomerAllDocumentsNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerAllDocumentsNamesQuery, GetCustomerAllDocumentsNamesQueryVariables>(
    GetCustomerAllDocumentsNamesDocument,
    options
  )
}
export function useGetCustomerAllDocumentsNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerAllDocumentsNamesQuery,
    GetCustomerAllDocumentsNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerAllDocumentsNamesQuery, GetCustomerAllDocumentsNamesQueryVariables>(
    GetCustomerAllDocumentsNamesDocument,
    options
  )
}
export type GetCustomerAllDocumentsNamesQueryHookResult = ReturnType<typeof useGetCustomerAllDocumentsNamesQuery>
export type GetCustomerAllDocumentsNamesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAllDocumentsNamesLazyQuery
>
export type GetCustomerAllDocumentsNamesQueryResult = Apollo.QueryResult<
  GetCustomerAllDocumentsNamesQuery,
  GetCustomerAllDocumentsNamesQueryVariables
>
export const GetCustomerGroupFilesByUrlDocument = gql`
  query getCustomerGroupFilesByURL($params: GetCustomerGroupFilesByURLInput!) {
    getCustomerGroupFilesByURL(params: $params) {
      url
    }
  }
`

/**
 * __useGetCustomerGroupFilesByUrlQuery__
 *
 * To run a query within a React component, call `useGetCustomerGroupFilesByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerGroupFilesByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerGroupFilesByUrlQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerGroupFilesByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerGroupFilesByUrlQuery, GetCustomerGroupFilesByUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerGroupFilesByUrlQuery, GetCustomerGroupFilesByUrlQueryVariables>(
    GetCustomerGroupFilesByUrlDocument,
    options
  )
}
export function useGetCustomerGroupFilesByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerGroupFilesByUrlQuery, GetCustomerGroupFilesByUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerGroupFilesByUrlQuery, GetCustomerGroupFilesByUrlQueryVariables>(
    GetCustomerGroupFilesByUrlDocument,
    options
  )
}
export type GetCustomerGroupFilesByUrlQueryHookResult = ReturnType<typeof useGetCustomerGroupFilesByUrlQuery>
export type GetCustomerGroupFilesByUrlLazyQueryHookResult = ReturnType<typeof useGetCustomerGroupFilesByUrlLazyQuery>
export type GetCustomerGroupFilesByUrlQueryResult = Apollo.QueryResult<
  GetCustomerGroupFilesByUrlQuery,
  GetCustomerGroupFilesByUrlQueryVariables
>
export const GetCustomerAllDocumentsFilesByUrlDocument = gql`
  query getCustomerAllDocumentsFilesByURL($params: GetCustomerAllDocumentsFilesByURLInput!) {
    getCustomerAllDocumentsFilesByURL(params: $params) {
      allDocumentsUrl
    }
  }
`

/**
 * __useGetCustomerAllDocumentsFilesByUrlQuery__
 *
 * To run a query within a React component, call `useGetCustomerAllDocumentsFilesByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAllDocumentsFilesByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAllDocumentsFilesByUrlQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerAllDocumentsFilesByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerAllDocumentsFilesByUrlQuery,
    GetCustomerAllDocumentsFilesByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerAllDocumentsFilesByUrlQuery, GetCustomerAllDocumentsFilesByUrlQueryVariables>(
    GetCustomerAllDocumentsFilesByUrlDocument,
    options
  )
}
export function useGetCustomerAllDocumentsFilesByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerAllDocumentsFilesByUrlQuery,
    GetCustomerAllDocumentsFilesByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerAllDocumentsFilesByUrlQuery, GetCustomerAllDocumentsFilesByUrlQueryVariables>(
    GetCustomerAllDocumentsFilesByUrlDocument,
    options
  )
}
export type GetCustomerAllDocumentsFilesByUrlQueryHookResult = ReturnType<
  typeof useGetCustomerAllDocumentsFilesByUrlQuery
>
export type GetCustomerAllDocumentsFilesByUrlLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAllDocumentsFilesByUrlLazyQuery
>
export type GetCustomerAllDocumentsFilesByUrlQueryResult = Apollo.QueryResult<
  GetCustomerAllDocumentsFilesByUrlQuery,
  GetCustomerAllDocumentsFilesByUrlQueryVariables
>
export const GetBankAccountsDocument = gql`
  query getBankAccounts {
    getBankAccounts {
      ispb
      name
      code
      fullName
    }
  }
`

/**
 * __useGetBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBankAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBankAccountsQuery, GetBankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>(GetBankAccountsDocument, options)
}
export function useGetBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankAccountsQuery, GetBankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>(GetBankAccountsDocument, options)
}
export type GetBankAccountsQueryHookResult = ReturnType<typeof useGetBankAccountsQuery>
export type GetBankAccountsLazyQueryHookResult = ReturnType<typeof useGetBankAccountsLazyQuery>
export type GetBankAccountsQueryResult = Apollo.QueryResult<GetBankAccountsQuery, GetBankAccountsQueryVariables>
export const GetBankAccountsPermissionsDocument = gql`
  query GetBankAccountsPermissions($params: GetBankAccountsPermissionsInput!) {
    getBankAccountsPermissions(params: $params) {
      allowCreate
      allowRead
      allowUpdate
      allowDelete
    }
  }
`

/**
 * __useGetBankAccountsPermissionsQuery__
 *
 * To run a query within a React component, call `useGetBankAccountsPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountsPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountsPermissionsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetBankAccountsPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBankAccountsPermissionsQuery, GetBankAccountsPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBankAccountsPermissionsQuery, GetBankAccountsPermissionsQueryVariables>(
    GetBankAccountsPermissionsDocument,
    options
  )
}
export function useGetBankAccountsPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankAccountsPermissionsQuery, GetBankAccountsPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBankAccountsPermissionsQuery, GetBankAccountsPermissionsQueryVariables>(
    GetBankAccountsPermissionsDocument,
    options
  )
}
export type GetBankAccountsPermissionsQueryHookResult = ReturnType<typeof useGetBankAccountsPermissionsQuery>
export type GetBankAccountsPermissionsLazyQueryHookResult = ReturnType<typeof useGetBankAccountsPermissionsLazyQuery>
export type GetBankAccountsPermissionsQueryResult = Apollo.QueryResult<
  GetBankAccountsPermissionsQuery,
  GetBankAccountsPermissionsQueryVariables
>
export const GetBusinessBankAccountsDocument = gql`
  query GetBusinessBankAccounts($params: GetBusinessBankAccountsInput!) {
    getBusinessBankAccounts(params: $params) {
      accounts {
        availableBalance
        bankPartnerName
        bankPartnerDescription
        bankPartnerNumber
        createdAt
        id
        accountNumber
        accountDigit
        agencyNumber
        agencyDigit
      }
    }
  }
`

/**
 * __useGetBusinessBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetBusinessBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessBankAccountsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetBusinessBankAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBusinessBankAccountsQuery, GetBusinessBankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBusinessBankAccountsQuery, GetBusinessBankAccountsQueryVariables>(
    GetBusinessBankAccountsDocument,
    options
  )
}
export function useGetBusinessBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessBankAccountsQuery, GetBusinessBankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBusinessBankAccountsQuery, GetBusinessBankAccountsQueryVariables>(
    GetBusinessBankAccountsDocument,
    options
  )
}
export type GetBusinessBankAccountsQueryHookResult = ReturnType<typeof useGetBusinessBankAccountsQuery>
export type GetBusinessBankAccountsLazyQueryHookResult = ReturnType<typeof useGetBusinessBankAccountsLazyQuery>
export type GetBusinessBankAccountsQueryResult = Apollo.QueryResult<
  GetBusinessBankAccountsQuery,
  GetBusinessBankAccountsQueryVariables
>
