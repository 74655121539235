import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EmailStatus from '../../components/auth/EmailStatus'
import { AuthContext } from '../../states/auth/AuthContext'

interface IEmailSendedSuccessState {
  state: { email: string }
}

const EmailSendedSuccess: React.FC = () => {
  const { state } = useLocation() as IEmailSendedSuccessState
  const navigate = useNavigate()
  const { forgotPassword, isSendingEmailForgotPassword } = useContext(AuthContext)

  useEffect(() => {
    if (!state?.email) {
      navigate('/')
    }
  }, [state?.email, navigate])

  return (
    <EmailStatus
      status="SUCCESS"
      isLoading={isSendingEmailForgotPassword}
      resendFunction={() => forgotPassword(state?.email, true)}
      text={['Veja seu e-mail para continuar. Lembre-se de conferir também a caixa de SPAM.']}
    />
  )
}

export default EmailSendedSuccess
