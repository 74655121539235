import { Grid, useMediaQuery, Typography, Button, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import WithHeader from '../../hocs/WithHeader'
import { useAuth } from '../../hooks/useAuth'

const NoOffer = () => {
  const isMobile = useMediaQuery('(max-width:510px)')
  const largeScreen = useMediaQuery('(min-width:959px)')
  const breakpointLg = useMediaQuery('(min-width:1500px)')
  const XXL = useMediaQuery('(min-width:2900px)')
  const navigate = useNavigate()

  const { loggedIn } = useAuth()

  const handleClick = () => {
    if (loggedIn) {
      return navigate('/create-company/document-number')
    }

    return navigate('/onboarding/company-document-number-analysis')
  }

  return (
    <Grid
      container
      mt={largeScreen ? 25 : 10}
      alignSelf="center"
      alignItems="center"
      justifyContent="space-evenly"
      flexDirection={largeScreen ? 'row' : 'column'}
      data-testid="no-offer-container"
    >
      <Grid
        item
        alignContent="center"
        justifyContent="center"
        paddingLeft={largeScreen ? 12 : 0}
        xs={12}
        lg={6}
        xl={3}
        md={6}
        sm={12}
        data-testid="no-offer-wrapper"
      >
        <img
          style={{
            maxWidth: largeScreen ? '450px' : '266px',
            marginLeft: XXL ? 200 : 0
          }}
          data-testid="no-offer-image"
          alt="money-celebration"
          src="https://af-logo.s3.sa-east-1.amazonaws.com/SemOfertaTinyImg.png"
        />
      </Grid>
      <Grid item xs={12} lg={5} xl={4} md={6} sm={12}>
        <Grid
          data-testid="no-offer-text-wrapper"
          container
          ml={breakpointLg ? 10 : 0}
          mt={largeScreen ? 0 : 4}
          spacing={2}
          flexDirection="column"
        >
          <Grid item padding={2} sm={12} xl={12} md={12} xs={12}>
            <Typography
              textAlign={largeScreen ? 'left' : 'center'}
              variant={isMobile ? 'h5' : 'h4'}
              fontWeight={600}
              color="gray.dark"
              data-testid="no-offer-text"
            >
              Poxa, ainda não
              <br />
              podemos te atender
            </Typography>
          </Grid>
          <Grid item alignSelf={largeScreen ? 'left' : 'center'} padding={2} sm={10} xl={9} lg={10} md={12} xs={12}>
            <Typography
              textAlign={largeScreen ? 'left' : 'center'}
              color="gray.light"
              fontWeight={500}
              variant={largeScreen ? 'body1' : 'body2'}
            >
              Infelizmente, você ainda não pode utilizar a plataforma. Veja abaixo alguns dos possíveis motivos e tente
              novamente no futuro.
            </Typography>
          </Grid>
          <Grid alignSelf={largeScreen ? 'left' : 'center'} item xs={10} lg={4} sm={6} md={6} xl={4}>
            <Button
              onClick={handleClick}
              variant="contained"
              color="primary"
              fullWidth
              data-testid="no-offer-button-test"
              sx={{ height: 50, width: 250, textTransform: 'none' }}
            >
              Voltar ao Cadastro
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid alignSelf="center" item xs={11} md={7} xl={6}>
        <Box
          mt={6}
          sx={{
            background: '#F8F8F8',
            paddingTop: 2,
            paddingBottom: 4,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: '10px'
          }}
        >
          <Grid container>
            <Grid item container alignItems={largeScreen ? '' : 'center'}>
              <Grid item xs={1}>
                <img
                  src="https://af-logo.s3.sa-east-1.amazonaws.com/orangeExclamation.svg"
                  alt="orange-warning"
                  style={{ width: 30 }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography ml={largeScreen ? 0 : 4} fontWeight={600} variant={isMobile ? 'h6' : 'h5'}>
                  Infelizmente não podemos continuar
                </Typography>
              </Grid>
            </Grid>
            <Grid item container mt={1} spacing={4}>
              <Grid item container xs={12} lg={6} flexDirection="row">
                <Grid item xs={1}>
                  <div
                    style={{
                      backgroundColor: '#EA841F',
                      width: '9px',
                      height: '15px',
                      marginTop: XXL ? 6 : 20
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="gray.dark" variant={isMobile ? 'body2' : 'body1'}>
                    A empresa ainda é muito jovem! Não realizamos antecipação para empresas com menos de 12 meses de
                    constituição.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} flexDirection="row">
                <Grid item xs={1}>
                  <div
                    style={{
                      backgroundColor: '#EA841F',
                      width: '9px',
                      height: '15px',
                      marginTop: XXL ? 6 : 20
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="gray.dark" variant={isMobile ? 'body2' : 'body1'}>
                    A empresa é MEI, Microempreendedor Individual. Infelizmente, não conseguimos lhe atender no momento.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} flexDirection="row">
                <Grid item xs={1}>
                  <div
                    style={{
                      backgroundColor: '#EA841F',
                      width: '9px',
                      height: '15px',
                      marginTop: XXL ? 6 : 20
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ mt: 1.8 }} color="gray.dark" variant={isMobile ? 'body2' : 'body1'}>
                    Empresa com baixo faturamento mensal.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} flexDirection="row">
                <Grid item xs={1}>
                  <div
                    style={{
                      backgroundColor: '#EA841F',
                      width: '9px',
                      height: '15px',
                      marginTop: XXL ? 6 : 20
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="gray.dark" variant={isMobile ? 'body2' : 'body1'}>
                    Não temos o produto para antecipar de Recebíveis de Cartão de Crédito.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} flexDirection="row">
                <Grid item xs={1}>
                  <div
                    style={{
                      backgroundColor: '#EA841F',
                      width: '9px',
                      height: '15px',
                      marginTop: XXL ? 6 : 20
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="gray.dark" variant={isMobile ? 'body2' : 'body1'}>
                    Não fazemos pré faturamento ou antecipação de contratos. Precisamos do aceite do produto ou serviço
                    entregue.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} flexDirection="row">
                <Grid item xs={1}>
                  <div
                    style={{
                      backgroundColor: '#EA841F',
                      width: '9px',
                      height: '15px',
                      marginTop: XXL ? 6 : 20
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="gray.dark" variant={isMobile ? 'body2' : 'body1'}>
                    Não fazemos empréstimos. Fazemos antecipação de Notas Fiscais de produtos ou serviços.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WithHeader()(NoOffer)
