import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper'
import RegistrationError from '../../../../assets/images/RegistrationError.png'
import Header from '../../components/Header'
import H3 from '../../../common/components/Typography/H3'
import { useAuth } from '../../../../hooks/useAuth'

interface IProps {
  className?: string
}

const OnboardingError = ({ className }: IProps) => {
  const { loggedIn } = useAuth()
  const navigate = useNavigate()

  const handleClick = () => {
    if (loggedIn) {
      return navigate('/create-company/document-number')
    }

    return navigate('/onboarding/company-document-number-analysis')
  }

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="onboarding-error__container">
        <img src={RegistrationError} style={{ width: 275 }} />
        <H3>Oops…</H3>
        <div className="onboarding-error___message">
          <p>Houve um problema ao processar seus dados.</p>
          <p className="onboarding-error____info--message">Por favor, tente enviar novamente em alguns instantes.</p>
        </div>

        <PrimaryButton data-testid="onboarding-error-button-test" onClick={handleClick}>
          Tentar novamente
        </PrimaryButton>
        <div className="onboarding-error_____help--message">
          <p>Precisa de ajuda?</p>
          <p>Clique aqui</p>
        </div>
      </div>
    </PageWrapper>
  )
}

export default styled(OnboardingError)`
  .onboarding-error__container {
    height: 100%;
    max-width: 420px;
    text-align: center;

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    }

    .onboarding-error___message {
      padding-top: 8px;

      p {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
      }

      strong {
        font-weight: bold;
      }

      .onboarding-error____info--message {
        padding: 16px 42px 0px;
      }
    }

    button {
      margin: 48px 0px 88px;
      max-width: 296px;
      width: 100%;

      @media (max-width: 500px) {
        margin: 36px 0px 44px;
      }
    }

    .onboarding-error_____help--message {
      p {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 14px;
      }

      p:nth-child(2) {
        color: ${({ theme: { colors } }) => colors?.primary};
        text-decoration: underline;
        padding-top: 8px;
      }
    }
  }
`
