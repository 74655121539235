/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Grid,
  Container,
  Theme,
  Typography,
  useMediaQuery,
  Button,
  InputAdornment,
  CircularProgress
} from '@mui/material'
import { useContext, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'
import { useFormik } from 'formik'
import { useLinkExpired } from '../../hooks/useLinkExpired'
import * as Yup from 'yup'
import PrimaryInput from '../../components/inputs/PrimaryInput'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../../states/auth/AuthContext'
import InputContainer from '../../components/inputs/InputContainer'
import { getErrorMessage } from '../../utils/form'
import PasswordVerifier from '../../components/auth/PasswordVerifier'
import { usePasswordValidator } from '../../hooks/usePasswordValidator'
import { OriginContext } from '../../states/origin/OriginContext'

const PasswordCode: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))
  const navigate = useNavigate()
  const state = useParams()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { decodedToken } = useJwt<any>(state.token as string)
  const linkExpired = useLinkExpired(decodedToken?.email)
  const { originInfos } = useContext(OriginContext)

  const { resetPassword, isResetingPassword, resetSuccess, authError } = useContext(AuthContext)

  const formik = useFormik({
    initialValues: {
      code: '',
      password: ''
    },

    validationSchema: Yup.object({
      code: Yup.string().required('Digite um código válido'),
      password: Yup?.string().required('Digite uma senha')
    }),

    onSubmit: async () => {
      resetPassword(decodedToken?.email, formik?.values?.code, formik?.values?.password)
    }
  })

  const {
    upperCase,
    lowerCase,
    length,
    number,
    specialCharacter,
    all: lowPassword
  } = usePasswordValidator(formik?.values?.password, 8)

  useEffect(() => {
    if (!state.token) {
      navigate('/')
    }
  }, [state.token, navigate])

  return (
    <Grid container mt={4} direction="column" alignItems="center" justifyItems="center">
      <Grid item justifySelf="center" xs>
        <img
          style={{ maxWidth: 200, marginBottom: 32 }}
          src={originInfos?.logoMain}
          alt={`${originInfos?.storeName} logo`}
          data-testid="password-code-image-test"
        />
      </Grid>
      {linkExpired?.loading ? (
        <CircularProgress size={80} />
      ) : (
        <Grid item xs={12}>
          {resetSuccess ? (
            <Container
              maxWidth="xs"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 3px 9px #0000001A',
                borderRadius: '10px',
                pb: 4
              }}
            >
              <Box mt={4}>
                <Grid container direction="column">
                  <Grid item container direction="column">
                    <Grid item container alignItems="center">
                      <Grid item xs={1}>
                        <CheckCircleIcon
                          style={{
                            fontSize: 32,
                            fill: '#04B74E',
                            marginTop: 4
                          }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography fontWeight={600} color="#04B74E" align="center" fontSize={40} ml={2}>
                          Nova senha salva!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={4}>
                    <Typography pl={6} pr={6} fontSize={16} align="center" color="gray.lighter">
                      Agora, acesse a plataforma clicando no botão abaixo.
                    </Typography>
                  </Grid>
                  <Grid item mt={4}>
                    <Button
                      data-testid="go-to-login-after-password-reset"
                      onClick={() => navigate('/')}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        height: 55,
                        fontSize: isMobile ? 17 : 21,
                        textTransform: 'none'
                      }}
                    >
                      Acessar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          ) : (
            <Container
              maxWidth="xs"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 3px 9px #0000001A',
                borderRadius: '10px',
                pb: 4
              }}
            >
              {linkExpired?.expired ? (
                <Box mt={4}>
                  <Grid container direction="column">
                    <Grid item container direction="column">
                      <Grid item container alignItems="center">
                        <Grid item xs={1}>
                          <img
                            src="https://af-logo.s3.sa-east-1.amazonaws.com/orangeExclamation.svg"
                            alt="orange-warning"
                            style={{ width: 24, marginTop: 8 }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography fontWeight={600} color="#EA841F" align="center" fontSize={34}>
                            Ops, código já utilizado.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item mt={4}>
                      <Typography pl={2} pr={2} fontSize={16} align="center" color="gray.lighter">
                        Uma nova senha já foi criada usando esse código. Agora, basta fazer o login para acessar a
                        plataforma.
                      </Typography>
                    </Grid>
                    <Grid item mt={4}>
                      <PrimaryButton
                        data-testid="go-to-login-after-password-reset"
                        onClick={() => navigate('/')}
                        color="primary"
                      >
                        Login
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <Box mt={8}>
                    <Typography
                      variant={!isMobile ? 'h4' : 'h5'}
                      align="center"
                      color="gray.darker"
                      fontWeight="800"
                      data-testid="register-user-page-title-test"
                    >
                      Crie uma nova senha
                    </Typography>
                    <Typography variant={'subtitle1'} align="center" fontSize={20} color={'gray.light'}>
                      Para começar, informe o código que enviamos no seu e-mail.
                    </Typography>
                  </Box>

                  <Container maxWidth={false} sx={{ maxWidth: 480, mt: 2 }}>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <InputContainer>
                            <PrimaryInput
                              value={formik.values.code}
                              errorMessage={getErrorMessage(authError, ['resetPassword'], formik)}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="code"
                              placeholder="Código recebido por e-mail"
                              data-testid="recover-password-code-input"
                            />
                          </InputContainer>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                  <Grid container>
                    <Box
                      mt={2}
                      sx={{
                        opacity: formik.values.code?.length === 6 ? 1 : 0.3
                      }}
                    >
                      <Typography variant={'subtitle1'} align="center" fontSize={18} color={'gray.light'}>
                        Agora escolha sua nova senha. Fique atento (a) aos requisitos mínimos de segurança exigidos.
                      </Typography>
                    </Box>

                    <Container maxWidth={false} sx={{ maxWidth: 480, mt: 2 }}>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                              opacity: formik.values.code?.length === 6 ? 1 : 0.3
                            }}
                          >
                            <PrimaryInput
                              value={formik.values.password}
                              disabled={formik.values.code?.length !== 6}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="password"
                              type={'password'}
                              placeholder="Digite a nova senha"
                              data-testid="recover-password-code-input"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faLock} />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            direction="column"
                            xs={12}
                            md={12}
                            sx={{
                              opacity: formik.values.code?.length === 6 ? 1 : 0.3
                            }}
                          >
                            <PasswordVerifier
                              upperCase={upperCase}
                              lowerCase={lowerCase}
                              number={number}
                              length={length}
                              specialCharacter={specialCharacter}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <PrimaryButton
                              data-testid="register-user-submit-button"
                              type="submit"
                              disabled={!formik.isValid || lowPassword}
                              isLoading={isResetingPassword}
                            >
                              Salvar
                            </PrimaryButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Container>
                  </Grid>
                </>
              )}
            </Container>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default PasswordCode
