import { ApolloProvider } from '@apollo/client'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import GlobalStyles from './styles/global'
import { ThemeProvider } from 'styled-components'
import Root from './routes/Clusters/Root'
import { BrowserRouter } from 'react-router-dom'
import { client } from './config/Apollo'
import Origin from './components/commons/Origin'
import OriginState from './states/origin/OriginState'
import SnackbarState from './apps/common/contexts/SnackBar'

export default function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <OriginState>
          <Origin>
            {({ theme, scTheme }) => (
              <ThemeProvider theme={scTheme}>
                <MuiThemeProvider theme={theme}>
                  <SnackbarState>
                    <Root />
                  </SnackbarState>
                  <GlobalStyles />
                </MuiThemeProvider>
              </ThemeProvider>
            )}
          </Origin>
        </OriginState>
      </ApolloProvider>
    </BrowserRouter>
  )
}
