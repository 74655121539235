import { createTheme, Theme as MuiTheme } from '@mui/material'
import { ReactNode, useContext } from 'react'
import { Theme } from '../../../types/override/styled'
import { OriginContext } from '../../../states/origin/OriginContext'
import LoadingPage from '../LoadingPage'

interface IProps {
  children: ({ theme }: { theme: MuiTheme; scTheme: Theme }) => ReactNode
}

const Origin: React.FC<IProps> = ({ children }: IProps) => {
  const { dynamicTheme, isGettingOriginInfos } = useContext(OriginContext)

  const theme = createTheme({
    shape: { borderRadius: 10 },
    palette: {
      primary: {
        light: dynamicTheme?.styleGuide?.dynamic?.primary?.main,
        main: dynamicTheme?.styleGuide?.dynamic?.primary?.main,
        dark: dynamicTheme?.styleGuide?.dynamic?.primary?.main,
        contrastText: dynamicTheme?.styleGuide?.fixed?.white?.light?.primary
      },
      secondary: {
        main: dynamicTheme?.styleGuide?.fixed?.white?.light?.primary,
        dark: dynamicTheme?.colors?.secondaryDark,
        contrastText: dynamicTheme?.styleGuide?.fixed?.white?.light?.primary
      },
      gray: {
        main: dynamicTheme?.colors?.gray?.main,
        light: dynamicTheme?.colors?.gray?.light,
        lighter: dynamicTheme?.colors?.gray?.lighter,
        smooth: dynamicTheme?.colors?.gray?.smooth,
        dark: dynamicTheme?.colors?.gray?.dark,
        darker: dynamicTheme?.colors?.gray?.darker
      },
      error: {
        main: dynamicTheme?.styleGuide?.support?.red
      },
      success: {
        main: dynamicTheme?.styleGuide?.support?.green
      }
    },
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      button: {
        textTransform: 'none'
      },
      allVariants: {
        color: dynamicTheme?.colors?.tertiary
      }
    }
  })

  const values = {
    theme,
    scTheme: dynamicTheme
  }

  if (isGettingOriginInfos) return <LoadingPage />

  return <>{children(values)}</>
}

export default Origin
