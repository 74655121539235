import styled from 'styled-components'
import PrimaryPagination from '../../../common/components/Pagination/PrimaryPagination'
import { useFormContext, Controller } from 'react-hook-form'
import QuizQuestion from '../../components/QuizQuestion'
import PrimaryRadio from '../../../common/components/Input/PrimaryRadio'
import RadioGroup from '../../../common/components/Input/RadioGroup'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { OnboardingContext } from '../../states/OnboardingContext'

interface IProps {
  className?: string
}

const QuizThirdStep: React.FC<IProps> = ({ className }: IProps) => {
  const {
    control,
    getValues,
    formState: { errors }
  } = useFormContext()
  const navigate = useNavigate()
  const { documentNumber } = useParams()

  const [searchParams] = useSearchParams()
  const { isAnsweringQuiz } = useContext(OnboardingContext)

  const companyId = searchParams.get('companyId') as string

  const clientTypes = getValues('clientTypes')
  const paymentTypes = getValues('paymentTypes')
  const radialIncome = getValues('radialIncome')
  const howMuchByMonth = getValues('howMuchByMonth')
  const hasReceipt = getValues('hasReceipt')

  useEffect(() => {
    if (!clientTypes || !paymentTypes || !radialIncome || !howMuchByMonth || !hasReceipt) {
      navigate(`/onboarding/quiz/step/1/${documentNumber}?companyId=${companyId}`)
    }
  }, [clientTypes, paymentTypes, radialIncome, howMuchByMonth, documentNumber, navigate, hasReceipt, companyId])

  return (
    <div className={className}>
      <Controller
        control={control}
        name="howYouMeetUs"
        render={({ field: { onChange } }) => (
          <RadioGroup
            value={getValues('howYouMeetUs') ? getValues('howYouMeetUs') : ''}
            name="howYouMeetUs"
            onChange={({ target: { value } }) => onChange(value)}
          >
            <QuizQuestion
              title="Como você conheceu a nossa plataforma?"
              errorMessage={errors['howYouMeetUs']?.message as string}
            >
              <PrimaryRadio label="Pesquisa no Google" value="Pesquisa no Google" />
              <PrimaryRadio label="Evento" value="Evento" />
              <PrimaryRadio label="Facebook" value="Facebook" />
              <PrimaryRadio label="Instagram" value="Instagram" />
              <PrimaryRadio label="Indicação" value="Indicação" data-testid="has-receipt-input-test" />
              <PrimaryRadio label="Outro" value="Outro" />
            </QuizQuestion>
          </RadioGroup>
        )}
      />

      <PrimaryPagination
        loading={isAnsweringQuiz}
        totalPages={3}
        currentPage={3}
        nextButtonTextWhenIsLastPage="Enviar"
        submit
        handlePreviousPage={(number) =>
          navigate(`/onboarding/quiz/step/${number}/${documentNumber}?companyId=${companyId}`)
        }
      />
    </div>
  )
}

export default styled(QuizThirdStep)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .quiz-question__alternatives___wrapper {
    width: 304px;
  }
`
