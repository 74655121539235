import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import styled from 'styled-components'
import { HTMLAttributes } from 'react'
import MenuList from '../MenuList'

export interface SwipeableMenuProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  handleClick: (path: string, redirectToV1?: boolean, blocked?: boolean) => void
  selectedTab?: string
  className?: string
}

const SwipeableMenu = ({ isOpen, onClose, onOpen, handleClick, selectedTab, className }: SwipeableMenuProps) => {
  return (
    <SwipeableDrawer
      BackdropProps={{ 'data-testid': 'swipeable-test' } as HTMLAttributes<HTMLDivElement>}
      data-testid="swipeable-menu-test"
      PaperProps={{
        sx: {
          borderRadius: '0px 0px 8px 8px'
        }
      }}
      className={className}
      anchor="top"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <MenuList handleClick={handleClick} selectedTab={selectedTab} />
    </SwipeableDrawer>
  )
}

export default styled(SwipeableMenu)`
  top: 8px !important;
  transform: translateY(98px);

  @media (min-width: 601px) {
    display: none;
  }
`
