import { useContext } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import styled from 'styled-components'
import CompaniesList from './CompaniesList'
import { CompanyContext } from '../../../../../states/company/CompanyContext'
import { cnpjMask, ellipsisInTheEndOfString } from '../../../../../utils/string'
import ArrowDown from '../../../assets/ArrowDown'
import Close from '../../../assets/Close'

interface CompaniesDropdownProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  className?: string
}

export interface CompaniesFromUserProps {
  id: string
  name: string
  cnpj: string
  isDefault: boolean
}

const CompaniesDropdown = ({ isOpen, onClose, onOpen, className }: CompaniesDropdownProps) => {
  const { defaultCompany } = useContext(CompanyContext)

  return (
    <div data-testid="companies-dropdown-test" className={className}>
      <div
        data-testid="companies-dropdown-icon-test"
        className="companies_list-icon"
        onClick={isOpen ? onClose : onOpen}
      >
        {isOpen ? (
          <Close data-testid="companies-dropdown-close-icon-test" width={16} />
        ) : (
          <ArrowDown data-testid="companies-dropdown-arrow-down-icon-test" width={16} />
        )}
      </div>
      <div className="default_company">
        <span data-testid="default-company-name-test" className="default__company-name">
          {ellipsisInTheEndOfString(defaultCompany?.corporateName as string, 30)}
        </span>
        <span data-testid="default-company-name-test" className="default__company-name mobile">
          {ellipsisInTheEndOfString(defaultCompany?.corporateName as string, 25)}
        </span>
        <span className="default__company-cnpj">{cnpjMask(defaultCompany?.countryIdentify as string)}</span>
      </div>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            width: 400,
            top: 56,
            left: 210,
            borderRadius: '0px 0px 8px 8px',
            textAlign: 'left',
            '@media (max-width: 610px)': {
              left: 0,
              width: '100%',
              zIndex: 4000
            }
          }
        }}
        ModalProps={{
          style: { zIndex: 4000 }
        }}
        anchor="top"
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        <CompaniesList onClose={onClose} />
      </SwipeableDrawer>
    </div>
  )
}

export default styled(CompaniesDropdown)`
  display: flex;
  align-items: center;

  .default_company {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 12px 0px 16px;

    span {
      color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
      font-size: 14px;
    }

    .default__company-name {
      display: none;
      @media (min-width: 551px) {
        display: block;
      }
    }

    .mobile {
      display: none;
      @media (max-width: 550px) {
        display: block;
      }
    }

    .default__company-cnpj {
      @media (max-width: 550px) {
        display: none;
      }
    }
  }

  .companies_list-icon {
    border-left: 2px solid ${({ theme }) => theme?.styleGuide?.dynamic?.primary?.main};
    padding-left: 16px;
    cursor: pointer;
    ${ArrowDown} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    ${Close} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    @media (max-width: 550px) {
      border-left: unset;
      margin-left: unset;
      padding-left: 0;
    }
  }
`
