export const formatGroupsToAPIformat = (
  group: 'Cedente' | 'Administrador' | 'Financiador' | 'Parceiro'
): 'C' | 'F' | 'P' | 'A' => {
  switch (group) {
    case 'Parceiro':
      return 'P'
    case 'Cedente':
      return 'C'
    case 'Financiador':
      return 'F'
    case 'Administrador':
      return 'A'
  }
}

export const translateGroup = (
  group: 'Administrador' | 'Cedente' | 'Financiador' | 'Parceiro'
): 'Backoffice' | 'Buyer' | 'Customer' | 'Partner' => {
  switch (group) {
    case 'Administrador':
      return 'Backoffice'
    case 'Cedente':
      return 'Customer'
    case 'Financiador':
      return 'Buyer'
    case 'Parceiro':
      return 'Partner'
  }
}
