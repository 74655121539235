import { Skeleton } from '@mui/material'
import VirtualSectionSkeleton from '../VirtualSection/VirtualSectionSkeleton'
import ResponsibleSectionSkeleton from '../ResponsiblesSection/ResponsibleSectionSkeleton'
import BankAccountsSectionSkeleton from '../BankAccountsSection/BankAccountsSectionSkeleton'

const CompanyDescriptionSkeleton: React.FC = () => {
  return (
    <div data-testid="company-description-skeleton-test">
      {[...Array(7)].map((_, index) => (
        <Skeleton width="100%" key={index} animation="wave" />
      ))}
      <VirtualSectionSkeleton />
      <ResponsibleSectionSkeleton />
      <BankAccountsSectionSkeleton />
    </div>
  )
}

export default CompanyDescriptionSkeleton
