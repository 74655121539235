import styled from 'styled-components'

interface IProps {
  className?: string
}

const Upload: React.FC<IProps> = ({ className }: IProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="17.602" viewBox="0 0 22 17.602">
    <path
      id="_2f063a8dc25f2ec13d7e4f32819acb5d"
      data-name="2f063a8dc25f2ec13d7e4f32819acb5d"
      d="M20.08,8.645A7.707,7.707,0,0,0,5.37,10.726,4.4,4.4,0,0,0,6.4,19.413a1.1,1.1,0,0,0,0-2.2,2.2,2.2,0,1,1,0-4.4,1.1,1.1,0,0,0,1.1-1.1A5.505,5.505,0,0,1,18.219,9.933a1.1,1.1,0,0,0,.859.738,3.3,3.3,0,0,1,.264,6.43,1.1,1.1,0,0,0,.551,2.136A5.505,5.505,0,0,0,20.08,8.645Zm-6.287,2.279a1.138,1.138,0,0,0-1.563,0l-3.3,3.3a1.106,1.106,0,0,0,1.563,1.563l1.42-1.431v6.155a1.1,1.1,0,0,0,2.2,0V14.359l1.42,1.431A1.106,1.106,0,1,0,17.1,14.227Z"
      transform="translate(-1.991 -4.013)"
    />
  </svg>
)

export default styled(Upload)``
