import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const NegativeIcon: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg
      id="Componente_48_12"
      data-name="Componente 48 – 12"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.002"
      height="24"
      viewBox="0 0 24.002 24"
      {...rest}
    >
      <defs>
        <clipPath id="clip-path-negative">
          <path
            id="Caminho_6093"
            data-name="Caminho 6093"
            d="M11.02,8.376a12,12,0,0,0,1.558,23.895h0a12.26,12.26,0,0,0,1.591-.1A12,12,0,1,0,11.02,8.376"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        className={className}
        id="Caminho_6087"
        data-name="Caminho 6087"
        d="M.7,21.847A12,12,0,1,1,14.171,32.166,12,12,0,0,1,.7,21.847"
        transform="translate(-0.594 -8.271)"
        fill="#A4AEB0"
      />
      <g id="Grupo_10252" data-name="Grupo 10252" transform="translate(-0.594 -8.271)">
        <g id="Grupo_10251" data-name="Grupo 10251" clipPath="url(#clip-path-negative)">
          <path
            id="Caminho_6088"
            data-name="Caminho 6088"
            d="M.357,3.982C1.629,6.522,7.2,17.376,7.592,18.106s.5,2.143.848,3.095,2.4,2.532,2.979,5.044,1.027,2.447,1.663,2.282c.716-.187,1.281-1.627,1.023-3.234s-1.892-3.861-.184-4.088a17.175,17.175,0,0,0,4.274-.816,1.5,1.5,0,0,0,.366-2.322c.8-.388,1.3-1.417.011-2.527a1.485,1.485,0,0,0,.332-1.112,1.5,1.5,0,0,0-1-1.181,1.994,1.994,0,0,0-1.512-2.023,9.33,9.33,0,0,0-4.08.242c-.942.239-4.69-9.18-4.993-10.754s-7.7,1.8-6.961,3.27"
            fill="#fff"
          />
          <path
            id="Caminho_6089"
            data-name="Caminho 6089"
            d="M6.981.045a.775.775,0,0,1,.638.609c.338,1.758,3.782,10.02,4.665,10.5a9.176,9.176,0,0,1,4.175-.233,2.183,2.183,0,0,1,1.732,2.121A1.683,1.683,0,0,1,19.209,14.4a1.847,1.847,0,0,1-.234,1.1,1.89,1.89,0,0,1,.681,1.6,1.581,1.581,0,0,1-.678,1.074,2.052,2.052,0,0,1,.195,1.5,1.551,1.551,0,0,1-.848.995,15.54,15.54,0,0,1-4.032.8l-.331.044c-.339.045-.416.171-.44.213-.2.335.11,1.16.383,1.889a8.9,8.9,0,0,1,.5,1.634c.272,1.694-.3,3.336-1.248,3.579a1.148,1.148,0,0,1-.938-.049c-.44-.266-.758-.979-1.1-2.46A9.291,9.291,0,0,0,9.06,22.642a5.458,5.458,0,0,1-.907-1.337,11.5,11.5,0,0,1-.375-1.462,6.924,6.924,0,0,0-.456-1.593C6.964,17.578,1.373,6.693.084,4.119a1.058,1.058,0,0,1,.264-1.18A9.121,9.121,0,0,1,3.495.84,6.8,6.8,0,0,1,6.981.045m-6.35,3.8C1.919,6.417,7.505,17.291,7.862,17.962a7.133,7.133,0,0,1,.515,1.754A11.059,11.059,0,0,0,8.728,21.1a5.369,5.369,0,0,0,.809,1.161,9.817,9.817,0,0,1,2.181,3.918c.28,1.217.556,1.915.82,2.075.1.061.22.044.467-.02.485-.13,1.046-1.342.8-2.89a8.57,8.57,0,0,0-.471-1.516c-.353-.941-.687-1.83-.336-2.418a1.138,1.138,0,0,1,.885-.506l.332-.044a15.45,15.45,0,0,0,3.854-.745.931.931,0,0,0,.517-.607,1.587,1.587,0,0,0-.278-1.27.3.3,0,0,1-.04-.249.3.3,0,0,1,.162-.195,1.045,1.045,0,0,0,.621-.771,1.5,1.5,0,0,0-.677-1.247.314.314,0,0,1-.1-.216.31.31,0,0,1,.081-.226,1.181,1.181,0,0,0,.25-.875c-.036-.394-.3-.7-.821-.93a.305.305,0,0,1-.178-.245l-.009-.085a1.626,1.626,0,0,0-1.267-1.674,8.756,8.756,0,0,0-3.937.241.533.533,0,0,1-.423-.086C10.652,10.843,7.27,2.08,7.018.77,7.012.741,7,.68,6.834.639,5.658.348,1.84,2.053.826,3.321c-.1.132-.27.374-.195.524"
            fill="#383838"
          />
          <path
            id="Caminho_6090"
            data-name="Caminho 6090"
            d="M18.157,17.8a.306.306,0,0,0,.111.585l.3.013a.306.306,0,0,0,.027-.611l-.3-.013a.3.3,0,0,0-.137.026"
            fill="#383838"
          />
          <path
            id="Caminho_6091"
            data-name="Caminho 6091"
            d="M18.053,15.321a.306.306,0,0,0,.171.582l.394-.06a.306.306,0,0,0-.092-.605l-.395.06a.3.3,0,0,0-.078.023"
            fill="#383838"
          />
          <path
            id="Caminho_6092"
            data-name="Caminho 6092"
            d="M17.78,12.967a.262.262,0,0,0-.049.027l-.252.173a.306.306,0,0,0,.345.505l.252-.173a.306.306,0,0,0-.3-.532"
            fill="#383838"
          />
        </g>
      </g>
    </svg>
  )
}

export default styled(NegativeIcon)``
