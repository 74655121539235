import styled from 'styled-components'
import { CompanyContext } from '../../../states/company/CompanyContext'
import { useContext, useEffect, useMemo } from 'react'
import { cnpjMask, phoneMask } from '../../../utils/string'
import 'moment/locale/pt-br'
import Moment from 'moment'
import CustomerMainInformationSkeleton from './CustomerMainInformationSkeleton'
import newsLetter from '../../../apps/common/assets/news-letter.svg'
import facebook from '../../../apps/common/assets/facebook.svg'
import instagram from '../../../apps/common/assets/instagram.svg'
import linkedin from '../../../apps/common/assets/linkedin.svg'
interface IProps {
  className?: string
  customerId: string
}

const CustomerMainInformation: React.FC<IProps> = ({ className, customerId }: IProps) => {
  const { getCustomerProfile, customerProfile, isGettingCustomerProfile } = useContext(CompanyContext)

  useEffect(() => {
    if (customerId) {
      getCustomerProfile({ customerId: customerId })
    }
  }, [getCustomerProfile, customerId])

  const registerDate = Moment(customerProfile?.registerDate).format('DD/MM/YYYY')

  const formatLink = (link?: string | null) => (link && !/^https?:\/\//i.test(link) ? 'https://' + link : link)

  const links = useMemo(() => {
    return [
      {
        image: newsLetter,
        path: formatLink(customerProfile.site)
      },
      {
        image: facebook,
        path: formatLink(customerProfile.facebook)
      },
      {
        image: instagram,
        path: formatLink(customerProfile.instagram)
      },
      {
        image: linkedin,
        path: formatLink(customerProfile.linkedin)
      }
    ]
  }, [customerProfile])

  return (
    <div className={className}>
      {isGettingCustomerProfile || customerProfile?.companyName === '' ? (
        <CustomerMainInformationSkeleton />
      ) : (
        <div data-testid="customer-profile-main-information-test" className="customer-info">
          <p>{customerProfile?.tradeName}</p>
          <p>{customerProfile?.companyName}</p>
          <p>{cnpjMask(customerProfile?.cnpj as string)}</p>
          <p>
            {customerProfile?.email}{' '}
            {customerProfile?.phone ? <span>| {phoneMask(customerProfile?.phone.toString())}</span> : ''}
          </p>
          <p>Cliente desde {registerDate}</p>

          <div className="customer-info__social-media">
            {links.map((link, index) => {
              return link.path ? (
                <a key={index} target="_blank" rel="noreferrer" href={link.path}>
                  <img src={link.image} />
                </a>
              ) : (
                <img key={index} className="customer-info__social-media__disabled" src={link.image} />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default styled(CustomerMainInformation)`
  align-items: center;
  display: flex;
  min-height: 200px;
  .customer-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 638px;

    p {
      color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
      font-size: 24px;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    p:first-child {
      color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.dark?.primary};
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 14px;
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }

    p:nth-last-child(1) {
      font-size: 14px;
      padding: 6px 0px;
    }

    p:nth-last-child(2) {
      font-size: 12px;
    }

    p:nth-last-child(3) {
      font-size: 14px;
      padding: 6px 0px 6px;
      @media (max-width: 500px) {
        font-size: 12px;
        padding: 4px 0px 8px;
      }
    }

    p:nth-last-child(5) {
      padding-bottom: 6px;
      @media (max-width: 500px) {
        padding-bottom: 8px;
      }
    }

    .customer-info__social-media {
      margin-top: 26px;
      display: flex;
      gap: 32px;

      a {
        cursor: pointer;
      }
    }
    .customer-info__social-media__disabled {
      filter: grayscale(100%);
    }

    span {
      display: inline-block;
      font-size: 14px;
      padding: 4px;
    }
  }
`
