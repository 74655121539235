import styled from 'styled-components'
import PageWrapper from '../../components/PageWrapper'
import success from '../../../common/assets/success.svg'
import H5 from '../../../common/components/Typography/H5'
import Body from '../../../common/components/Typography/Body'
import { useContext, useEffect } from 'react'
import { OriginContext } from '../../../../states/origin/OriginContext'
import { AuthContext } from '../../../../states/auth/AuthContext'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import PrimaryLoading from '../../../common/components/Loading/PrimaryLoading'

interface IProps {
  className?: string
}

interface ICreatedUserState {
  state: {
    email: string
  }
}

const CreatedUser: React.FC<IProps> = ({ className }: IProps) => {
  const { originInfos } = useContext(OriginContext)
  const { resendCreateUserEmail, isResetingCreateUserEmail, authError, isResentCreateUserEmail } =
    useContext(AuthContext)
  const { state } = useLocation() as ICreatedUserState
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const companyId = searchParams.get('companyId') as string

  useEffect(() => {
    if (!state?.email) {
      navigate(companyId ? `/onboarding/create-user?companyId=${companyId}` : '/onboarding/create-user')
    }
  }, [state?.email, navigate, companyId])

  return (
    <PageWrapper className={className}>
      <header className="created-user__header">
        <img src={success} />
        <h1 className="created-user__header___title">É um prazer te receber aqui</h1>
        <H5 className="created-user__header___subtitle">
          O próximo passo do cadastro da <br /> sua empresa, é no seu e-mail.
        </H5>
      </header>
      <div className="created-user__body">
        <Body className="created-user__body___text--top">
          Enviamos um e-mail pra você com as <br />
          instruções para prosseguir na criação da sua senha.
        </Body>
        <Body className="created-user__body___text--bottom">Lembre-se de checar sua caixa de Spam.</Body>
      </div>
      <div className="created-user__good-bye">
        <Body className="created-user__good-bye___info">Nos vemos em breve!</Body>
        <img alt={`${originInfos?.storeName} Logo`} src={originInfos?.logoMain} />
      </div>
      {authError?.event !== 'resendCreateUserEmail' && (
        <footer className="created-user__footer">
          {isResentCreateUserEmail && !isResetingCreateUserEmail && (
            <p data-testid="created-user-success" className="created-user__footer___info--success">
              E-mail enviado com sucesso!
            </p>
          )}
          {isResetingCreateUserEmail ? (
            <PrimaryLoading data-testid="created-user-loading-test" />
          ) : (
            <div className="created-user__footer___info____wrapper">
              <Body className="created-user__footer___info">Não recebeu o e-mail?</Body>
              <p
                className="created-user__footer___send-email-again"
                data-testid="created-user-resend-email-test"
                onClick={() => resendCreateUserEmail(state?.email)}
              >
                Enviar e-mail novamente
              </p>
            </div>
          )}
        </footer>
      )}
    </PageWrapper>
  )
}

export default styled(CreatedUser)`
  .created-user__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    img {
      height: 88px;
      width: 88px;
      margin-bottom: 32px;
    }

    .created-user__header___title {
      color: ${({ theme: { colors } }) => colors?.primary};
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
      font-weight: bold;
    }

    .created-user__header___subtitle {
      font-weight: bold;
      margin-top: 8px;
      width: 328px;
      text-align: center;
    }
  }

  .created-user__body {
    margin-top: 40px;
    width: 344px;
    text-align: center;

    .created-user__body___text--bottom {
      margin-top: 24px;
    }
  }

  .created-user__good-bye {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .created-user__good-bye___info {
      font-weight: bold;
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    }
    img {
      margin-top: 24px;
      max-height: 48px;
    }
  }

  .created-user__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    gap: 16px;

    .created-user__footer___info--success {
      color: ${({ theme: { styleGuide } }) => styleGuide?.support?.green};
      font-size: 14px;
      font-weight: bold;
    }

    .created-user__footer___send-email-again {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
      text-decoration: underline;
      color: ${({ theme: { colors } }) => colors?.primary};
      cursor: pointer;
    }

    .created-user__footer___info____wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
`
