/* eslint-disable @typescript-eslint/no-explicit-any */

import { IOnboardingState } from '../types'

interface OnboardingActions {
  type:
    | 'CREATE_USER'
    | 'CREATE_USER_SUCCESS'
    | 'CREATE_USER_ERROR'
    | 'CREATE_COMPANY_PROPOSAL'
    | 'CREATE_COMPANY_PROPOSAL_SUCCESS'
    | 'CREATE_COMPANY_PROPOSAL_ERROR'
    | 'ANSWER_QUIZ'
    | 'ANSWER_QUIZ_SUCCESS'
    | 'ANSWER_QUIZ_ERROR'
    | 'GET_COMPANY_PROPOSAL_BY_ID'
    | 'GET_COMPANY_PROPOSAL_BY_ID_SUCCESS'
    | 'GET_COMPANY_PROPOSAL_BY_ID_ERROR'

  payload?: any
}

const actionHandler = (
  payload: any
): {
  [key: string]: Partial<IOnboardingState>
} => ({
  CREATE_USER: { isCreatingUser: true },
  CREATE_USER_SUCCESS: { isCreatingUser: false, createdUser: payload },
  CREATE_USER_ERROR: { isCreatingUser: false, error: payload },
  CREATE_COMPANY_PROPOSAL: {
    isCreatingCompanyProposal: true
  },
  CREATE_COMPANY_PROPOSAL_SUCCESS: {
    isCreatingCompanyProposal: false,
    createdCompanyProposal: payload
  },
  CREATE_COMPANY_PROPOSAL_ERROR: {
    isCreatingCompanyProposal: false,
    error: payload
  },
  ANSWER_QUIZ: {
    isAnsweringQuiz: true
  },
  ANSWER_QUIZ_SUCCESS: {
    isAnsweringQuiz: false,
    answeredQuiz: payload
  },
  ANSWER_QUIZ_ERROR: {
    isAnsweringQuiz: false,
    error: payload
  },
  GET_COMPANY_PROPOSAL_BY_ID: {
    isGettingCompanyProposalById: true
  },
  GET_COMPANY_PROPOSAL_BY_ID_SUCCESS: {
    isGettingCompanyProposalById: false
  },
  GET_COMPANY_PROPOSAL_BY_ID_ERROR: {
    isGettingCompanyProposalById: false,
    error: payload
  }
})

const OnboardingReducer = (state: IOnboardingState, action: OnboardingActions) => {
  const updateProperties = actionHandler(action.payload)[action.type]
  return { ...state, ...updateProperties }
}

export default OnboardingReducer
