import { ReactNode } from 'react'
import styled from 'styled-components'
import PrimaryCard from '../../cards/PrimaryCard'

interface IProps {
  className?: string
  title: string
  children: ReactNode
}

const CompanyForm: React.FC<IProps> = ({ className, title, children }: IProps) => {
  return (
    <div className={className}>
      <h3 className="company-form-wrapper__title">{title}</h3>
      <PrimaryCard>{children}</PrimaryCard>
    </div>
  )
}

export default styled(CompanyForm)`
  padding: 24px;
  display: flex;
  min-height: calc(100vh - 152px);
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme: { colors } }) => colors?.secondaryBackground};

  .company-form-wrapper__title {
    font-size: 24px;
    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
  }
`
