import { useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'
import CompanyItem from './CompanyItem'
import { CompanyContext } from '../../../../../../states/company/CompanyContext'
import { cnpjMask } from '../../../../../../utils/string'
import { CompaniesFromUserProps } from '..'
import Add from '../../../../assets/Add'
import { useNavigate } from 'react-router-dom'

interface ICompaniesListProps {
  className?: string
  onClose: () => void
}

const CompaniesList = ({ className, onClose }: ICompaniesListProps) => {
  const { getCompaniesFromUser, companiesFromUser, isGettingCompaniesFromUser, updateDefaultCompany } =
    useContext(CompanyContext)

  useEffect(() => {
    if (companiesFromUser.length === 0) {
      getCompaniesFromUser()
    }
  }, [getCompaniesFromUser, companiesFromUser])

  const handleLastItemClass = (index: number) => {
    if (index === companiesFromUser.length - 2) {
      return 'last-item'
    }
    return ''
  }

  const handleCompanyClick = (company: CompaniesFromUserProps) => {
    onClose()
    navigate('/create-company/document-number')
    updateDefaultCompany(company.id)
  }

  const navigate = useNavigate()

  return (
    <div data-testid="companies-list-test" className={className}>
      <Box>
        {isGettingCompaniesFromUser ? (
          <Box data-testid="companies-list-loading-test" className="loading-content">
            <CircularProgress className="loading-content" data-testid="test-circular-progress" />
          </Box>
        ) : (
          <List ata-testid="companies-list-map-test" className="companies-list-container">
            {companiesFromUser?.map((company, index) => (
              <div data-testid="handle-list-test" onClick={() => handleCompanyClick(company)} key={index}>
                <CompanyItem
                  companyName={company.name}
                  companyCnpj={cnpjMask(company.cnpj)}
                  isLastItem={index === companiesFromUser.length - 2}
                  className={handleLastItemClass(index)}
                />
              </div>
            ))}
            <div onClick={() => navigate('/create-company/document-number')} className="add_company-container">
              <Add width={16} />
              <span>Adicionar empresa</span>
            </div>
          </List>
        )}
      </Box>
    </div>
  )
}

export default styled(CompaniesList)`
  z-index: 5000;

  @media (max-width: 550px) {
    margin-top: 8px;
  }

  .loading-content {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }

  .companies-list-container {
  }

  ul {
    padding: 0px;
    max-height: 400px;

    li {
      padding: 16px 48px;
      position: relative;

      &:hover {
        background-color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.lighter?.primary};
      }

      span {
        cursor: pointer;
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        display: inline-block;
        font-size: 16px;
      }

      &::before {
        background-color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.lighter?.primary};
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 16px;
        position: absolute;
        right: 16px;
      }

      &.last-item::before {
        display: none;
      }

      &.last-item {
        border-bottom: 1px solid ${({ theme }) => theme?.styleGuide?.fixed?.gray?.lighter?.primary};
      }
    }
  }

  .add_company-container {
    display: flex;
    justify-content: center;
    padding: 16px 0px 16px 16px;
    cursor: pointer;

    ${Add} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    span {
      color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      font-size: 16px;
      text-decoration: none !important;
      padding-left: 16px;
    }
  }
`
