import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import H3 from '../../../common/components/Typography/H3'
import ExistentCompany from '../../../../assets/images/ExistentCompany.png'
import PageWrapper from '../../components/PageWrapper'
import { useAuth } from '../../../../hooks/useAuth'
import { getRedirectUrl } from '../../../../utils/string'
import SecondaryLoading from '../../../common/components/Loading/SecondaryLoading'

interface IProps {
  className?: string
}

const ExistingCompany = ({ className }: IProps) => {
  const { loggedIn, groups, loading } = useAuth()
  const navigate = useNavigate()
  const redirectUrl = getRedirectUrl() as string

  const handleClickTopButton = () => {
    if (loggedIn) {
      return navigate('/create-company/document-number')
    }
    return navigate('/onboarding/company-document-number-analysis')
  }

  const handleClickBottomButton = () => {
    if (loggedIn) {
      if (groups?.length > 0) {
        return window.location.replace(redirectUrl)
      }

      return navigate('/create-company/document-number')
    }

    return navigate('/')
  }

  return (
    <PageWrapper className={className}>
      <Header />
      {!loading ? (
        <div className="existing-company__container">
          <img alt="refused-company" src={ExistentCompany} />
          <H3>Empresa já cadastrada</H3>
          <div>
            <p>Desculpe, mas essa empresa já está cadastrada.</p>
            {loggedIn ? (
              <p>Você pode tentar novamente com outra empresa.</p>
            ) : (
              <p>Você pode simular outra empresa ou fazer login para acessar a nossa plataforma.</p>
            )}
          </div>
          <div className="bottom_container">
            <PrimaryButton
              className="bottom__container-simulate-button"
              data-testid="existing-company-button-test"
              onClick={handleClickTopButton}
            >
              {loggedIn ? 'Tentar novamente' : 'Simular outra empresa'}
            </PrimaryButton>
            <PrimaryButton
              variant="outlined"
              className="bottom___container-bottom-button"
              data-testid="existing-company-bottom-button-test"
              onClick={handleClickBottomButton}
            >
              {loggedIn ? 'Fechar' : 'Login'}
            </PrimaryButton>
            {!loggedIn && (
              <div className="bottom___container-information">
                <p>Precisa de ajuda para acessar a plataforma?</p>
                <p data-testid="existing-company-info-button-test" onClick={() => navigate('/')}>
                  Clique aqui
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="existing-company__loading--wrapper">
          <SecondaryLoading data-testid="existing-company-loading-test" />
        </div>
      )}
    </PageWrapper>
  )
}

export default styled(ExistingCompany)`
  .existing-company__loading--wrapper {
    height: calc(100vh - 112px - 96px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .existing-company__container {
    height: 100%;
    max-width: 400px;
    text-align: center;

    img {
      height: 180px;
      width: 275px;
    }

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      padding-top: 23px;
    }

    div {
      p {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 16px;
      }

      p:first-child {
        padding: 8px 0px 16px;
      }

      strong {
        font-weight: bold;
      }
    }

    .bottom_container {
      .bottom__container-simulate-button {
        button {
          margin-top: 48px;
          max-width: 296px;
        }
      }

      .bottom___container-bottom-button {
        button {
          background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
          color: ${({ theme: { colors } }) => colors?.primary};
          margin-top: 24px;
          max-width: 296px;
        }
      }

      .bottom___container-information {
        p:nth-child(1) {
          color: ${({ theme: { colors } }) => colors?.quarternaryFont};
          font-size: 14px;
          padding: 40px 0px 8px;
        }

        p:nth-child(2) {
          color: ${({ theme: { colors } }) => colors?.primary};
          cursor: pointer;
          font-size: 14px;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .existing-company__container {
      div {
        p {
          max-width: 360px;
        }
      }
    }
  }
`
