import styled from 'styled-components'
import NormalFlowPageWrapper from '../../components/auth/NormalFlowPageWrapper'
import NormalForm from '../../components/auth/NormalForm'
import NormalFormWrapper from '../../components/auth/NormalFormWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import PrimaryInput from '../../components/inputs/PrimaryInput'
import ReCAPTCHA from 'react-google-recaptcha'
import { useContext } from 'react'
import { GOOGLE_RECAPTCHA } from '../../utils/envs'
import InputContainer from '../../components/inputs/InputContainer'
import { InputAdornment } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import NormalFlowTitle from '../../components/auth/NormalFlowTitle'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AuthContext } from '../../states/auth/AuthContext'
import { getErrorMessage } from '../../utils/form'

interface IProps {
  className?: string
}

const ForgotPassword: React.FC<IProps> = ({ className }: IProps) => {
  const { forgotPassword, isSendingEmailForgotPassword, authError } = useContext(AuthContext)

  const formik = useFormik({
    initialValues: {
      email: ''
    },

    validationSchema: yup?.object({
      email: yup?.string().required('Digite um email'),
      recaptcha: yup?.boolean()?.required()
    }),
    onSubmit: ({ email }) => forgotPassword(email, false)
  })

  return (
    <NormalFlowPageWrapper className={className}>
      <NormalFormWrapper>
        <NormalFlowTitle
          backButton
          title="Problemas com o login?"
          subtitle="Digite seu e-mail para redefinir a sua senha."
        />
        <NormalForm onSubmit={formik.handleSubmit}>
          <InputContainer>
            <PrimaryInput
              value={formik.values.email}
              errorMessage={getErrorMessage(authError, ['forgotPassword', 'email'], formik)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              label="E-mail"
              type="email"
              placeholder="Digite o e-mail de cadastro"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputAdornment>
                )
              }}
              data-testid="forgot-password-email-input"
            />
          </InputContainer>
          {GOOGLE_RECAPTCHA && (
            <ReCAPTCHA
              className="forgot-password--recaptcha"
              onChange={() => formik?.setFieldValue('recaptcha', true)}
              sitekey={GOOGLE_RECAPTCHA}
              onExpired={() => formik?.setFieldValue('recaptcha', true)}
              onErrored={() => formik?.setFieldValue('recaptcha', true)}
            />
          )}
          <PrimaryButton
            data-testid="forgot-password-submit-button"
            color="primary"
            type="submit"
            isLoading={isSendingEmailForgotPassword}
            disabled={!formik.isValid || Object.keys(formik?.touched).length === 0}
          >
            Recuperar senha
          </PrimaryButton>
        </NormalForm>
      </NormalFormWrapper>
    </NormalFlowPageWrapper>
  )
}

export default styled(ForgotPassword)`
  .forgot-password--recaptcha {
    margin: 12px 0 36px 0;
  }
`
