import { useContext, useEffect } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import List, { IPrimaryFileSplitCrudData } from '../../components/crud/PrimaryFileSplitCrud/List'
import { getDocumentsStatus } from '../../helpers/companyDocuments'
import { useAuth } from '../../hooks/useAuth'
import { CompanyContext } from '../../states/company/CompanyContext'
import { translateGroup } from '../../utils/auth'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CompanyDocuments: React.FC<IProps> = ({ className }: IProps) => {
  const { groups } = useAuth()

  const {
    companyDocuments,
    isGettingCompanyDocumentsByCompanyId,
    getCompanyDocumentsByCompanyId,
    defaultCompany,
    isGettingDefaultCompany,
    sendToAnalysis,
    isSendingToAnalysis,
    closeSendToAnalysisModal,
    sendToAnalysisModal
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyDocumentsByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getCompanyDocumentsByCompanyId])

  const documentsLeftBuyer = [
    { name: 'Documentos de constituição', key: 'constitution' },
    { name: 'Comprovante de endereço da sede', key: 'business-address' },
    { name: 'Declaração simplificada da junta', key: 'group-declaration' }
  ]
  const documentsRightBuyer = [
    { name: 'Documento de identificação', key: 'identification' },
    { name: 'Comprovante de endereço', key: 'partner-address' },
    { name: 'Documento de procuração', key: 'proxy' }
  ]

  const documentsLeftCustomer = [
    { name: 'Documentos de constituição', key: 'constitution' },
    { name: 'Declaração de faturamento', key: 'revenue' },
    { name: 'Comprovante de endereço da sede', key: 'business-address' },
    { name: 'Extrato Bancário', key: 'extract' },
    { name: 'Declaração simplificada da junta', key: 'group-declaration' }
  ]
  const documentsRightCustomer = [
    { name: 'Documento de identificação', key: 'identification' },
    { name: 'Comprovante de endereço', key: 'partner-address' },
    { name: 'Documento de procuração', key: 'proxy' }
  ]

  const listDataBuyer: IPrimaryFileSplitCrudData = {
    isLoading: isGettingCompanyDocumentsByCompanyId || isGettingDefaultCompany,
    basePath: '/company-documents',
    presentation: {
      title: 'Chegou a hora do envio dos seus documentos!',
      description: [
        'Eles serão validados para garantir que todos os nossos usuários possam participar da nossa plataforma com segurança.'
      ]
    },
    listDocumentGroup: [
      {
        title: 'Da empresa',
        documentTypes: getDocumentsStatus(documentsLeftBuyer, companyDocuments)
      },
      {
        title: 'Dos sócios e procuradores',
        documentTypes: getDocumentsStatus(documentsRightBuyer, companyDocuments)
      }
    ],
    submit: () => sendToAnalysis(defaultCompany?.businessId as string),
    isSubmitting: isSendingToAnalysis,
    closeCreateModal: closeSendToAnalysisModal,
    openModal: sendToAnalysisModal,
    isDisabled: (defaultCompany?.statusId as string) !== '1' && (defaultCompany?.statusId as string) !== '3'
  }

  const listDataCustomer: IPrimaryFileSplitCrudData = {
    isLoading: isGettingCompanyDocumentsByCompanyId || isGettingDefaultCompany,
    basePath: '/company-documents',
    presentation: {
      title: 'Chegou a hora do envio dos seus documentos!',
      description: [
        'Eles serão validados para garantir que todos os nossos usuários possam participar da nossa plataforma com segurança.'
      ]
    },
    submit: () => sendToAnalysis(defaultCompany?.businessId as string),
    listDocumentGroup: [
      {
        title: 'Da empresa',
        documentTypes: getDocumentsStatus(documentsLeftCustomer, companyDocuments)
      },
      {
        title: 'Dos sócios e procuradores',
        documentTypes: getDocumentsStatus(documentsRightCustomer, companyDocuments)
      }
    ],
    isSubmitting: isSendingToAnalysis,
    closeCreateModal: closeSendToAnalysisModal,
    openModal: sendToAnalysisModal,
    isDisabled: (defaultCompany?.statusId as string) !== '1' && (defaultCompany?.statusId as string) !== '3'
  }

  const getCorrectPayloadByGroup = () => {
    const group = translateGroup(groups?.[0])

    if (group === 'Buyer') {
      return listDataBuyer
    } else if (group === 'Customer') {
      return listDataCustomer
    }
    return listDataCustomer
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <List data={getCorrectPayloadByGroup()} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyDocuments)
