import { createContext } from 'react'
import { IAddressState } from '../../types/address'

const initialState: IAddressState = {
  getAddressByZipCode: () => Promise.resolve({ city: '', streetName: '', neighboorhood: '', state: '', zipCode: '' }),
  isGettingAddressByZipCode: false,
  error: { location: '', message: '' }
}

const AddressContext = createContext(initialState)

export { AddressContext, initialState }
