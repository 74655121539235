import { Maybe } from 'graphql/jsutils/Maybe'
import { ChangeEvent, useState, useEffect, useContext, DetailedHTMLProps, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import WithHeader from '../../../../hocs/WithHeader'
import { CompanyContext } from '../../../../states/company/CompanyContext'
import { stateList } from '../../../../utils/address'
import { formatCurrencyToPtBR } from '../../../../utils/currency'
import { formatDateToDDMMYYYY } from '../../../../utils/datetime'
import { cnpjMask, ellipsisInTheEndOfString } from '../../../../utils/string'
import PrimaryFilter, { IFilter } from '../../../common/components/Filter/PrimaryFilter'
import PrimaryTable, { Column, Order, Rows } from '../../../common/components/Table/PrimaryTable'
import FavoriteIcon from '../../assets/FavoriteIcon'
import NegativeIcon from '../../assets/NegativeIcon'
import PositiveIcon from '../../assets/PositiveIcon'
import CompanyRatingCell from '../../components/CompanyRatingCell'
import { REDIRECT_V1_URL, REDIRECT_V1_URL_PROD, ENVIRONMENT } from '../../../../utils/envs'
import { Environment } from '../../../../utils/enum'

interface IRowData {
  companyRating: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  name: string
  cnpj: string
  addressState: string
  minimumRate?: Maybe<string>
  maximumRate?: Maybe<string>
  creditLimitValue?: Maybe<number>
  operations: number
  averagePeriod: number
  registerDate: string
}

interface IProps {
  className?: string
}

interface ICustomerFilters {
  searchField?: string
  companyRating?: string | 'approved' | 'disapproved' | 'favorite'
  minimumRate?: string
  maximumRate?: string
  creditLimitValue?: string
}

const filterFields: IFilter[] = [
  {
    type: 'search',
    fields: [
      {
        label: 'Buscar',
        name: 'searchField'
      }
    ]
  },
  {
    label: 'Classificação',
    type: 'button',
    fields: [
      {
        name: 'companyRating',

        buttons: [
          { name: 'disapproved', icon: <NegativeIcon />, className: 'negative' },
          { name: 'approved', icon: <PositiveIcon />, className: 'positive' },
          { name: 'favorite', icon: <FavoriteIcon />, className: 'favorite' }
        ]
      }
    ]
  },
  {
    type: 'select',
    fields: [{ name: 'addressState', label: 'Localização', options: [{ value: '', item: '' }, ...stateList] }]
  },
  {
    label: 'Taxas',
    type: 'percentage',
    fields: [
      { name: 'minimumRate', label: 'Mínima' },
      { name: 'maximumRate', label: 'Máxima' }
    ]
  },
  {
    label: 'Limite pré-aprovado',
    type: 'currency',
    fields: [{ name: 'creditLimitValue', label: 'Máximo' }]
  }
]

const filterFieldsKeys = filterFields.flatMap((item) => item.fields.map((field) => field.name))

const CustomerList: React.FC<IProps> = ({ className }: IProps) => {
  const { getCustomers, isGettingCustomers, customers, defaultCompany, isUpdatingDefaultCompany } =
    useContext(CompanyContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const orderBySearchParams = searchParams.get('orderBy')
  const orderSearchParams = searchParams.get('order')
  const pageSearchParams = searchParams.get('page') ? parseInt(searchParams.get('page') as string) - 1 : null
  const rowsPerPageSearchParams = searchParams.get('rowsPerPage')
    ? parseInt(searchParams.get('rowsPerPage') as string)
    : null

  const [order, setOrder] = useState<Order | string>(orderSearchParams || 'desc')
  const [orderBy, setOrderBy] = useState<string>(orderBySearchParams || 'registerDate')
  const [page, setPage] = useState(pageSearchParams || 0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageSearchParams || 15)
  const [rows, setRows] = useState<Rows<IRowData>[]>([])

  useEffect(() => {
    if (!isUpdatingDefaultCompany && defaultCompany?.statusId !== '4' && defaultCompany?.statusId) {
      window.location.replace(`${ENVIRONMENT === Environment.DEVELOPMENT ? REDIRECT_V1_URL : REDIRECT_V1_URL_PROD}`)
    }
  }, [defaultCompany?.statusId, isUpdatingDefaultCompany])

  const newParams: { [key: string]: string } = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams])

  const filterParams: ICustomerFilters = useMemo(() => {
    const filterParamsAux = {} as ICustomerFilters
    searchParams.forEach((value: string, key: string) => {
      if (filterFieldsKeys.find((name) => name === key)) {
        filterParamsAux[key as keyof ICustomerFilters] = value
      }
    })
    return filterParamsAux
  }, [searchParams])

  useEffect(() => {
    if (!isUpdatingDefaultCompany) {
      getCustomers({
        orders: {
          orderBy,
          direction: order
        },
        pagination: {
          page: page + 1,
          perPage: rowsPerPage
        },
        filters: filterParams
      })
    }
  }, [getCustomers, orderBy, order, filterParams, rowsPerPage, page, defaultCompany, isUpdatingDefaultCompany])

  useEffect(() => {
    setRows(
      customers.data.map((customer) => ({
        id: customer?.companyId,
        companyRating: (
          <div className="customer-list__company-rating___wrapper">
            <CompanyRatingCell companyRating={customer.companyRating} />
          </div>
        ),
        name: ellipsisInTheEndOfString(customer?.name as string, 40),
        cnpj: customer?.cnpj,
        addressState: customer?.addressState as string,
        minimumRate: customer?.minimumRate,
        maximumRate: customer?.maximumRate,
        creditLimitValue: customer?.creditLimitValue,
        operations: customer?.operations,
        averagePeriod: customer?.averagePeriod,
        registerDate: customer?.registerDate
      }))
    )
  }, [customers])

  useEffect(() => {
    if (customers.data.length === 0 && page !== 0) {
      setPage(0)
      setSearchParams({
        ...newParams,
        page: '1'
      })
    }
  }, [customers, page, newParams, setSearchParams])

  const columns: Column[] = [
    { id: 'companyRating', label: 'Classificação', minWidth: 110, align: 'left', hasSort: true },
    { id: 'name', label: 'Empresa', minWidth: 350, hasSort: true, align: 'left' },
    {
      id: 'cnpj',
      label: 'CNPJ',
      minWidth: 140,
      align: 'left',
      format: (value) => cnpjMask(value as string),
      hasSort: true
    },
    {
      id: 'addressState',
      label: 'Estado',
      minWidth: 70,
      align: 'center',
      hasSort: true
    },
    {
      id: 'minimumRate',
      label: 'Taxa Mín. (%)',
      minWidth: 130,
      align: 'right',
      hasSort: true
    },
    {
      id: 'maximumRate',
      label: 'Taxa Max. (%)',
      minWidth: 130,
      align: 'right',
      hasSort: true
    },
    {
      id: 'registerDate',
      label: 'Data de criação',
      minWidth: 130,
      align: 'right',
      format: (value) => formatDateToDDMMYYYY(new Date(value as string)),
      hasSort: true
    },
    {
      id: 'creditLimitValue',
      label: 'Valor pré-aprovado',
      minWidth: 160,
      align: 'right',
      format: (value) => formatCurrencyToPtBR(value as number),
      hasSort: true
    },
    {
      id: 'operations',
      label: 'Operações',
      minWidth: 100,
      align: 'right'
    },
    {
      id: 'averagePeriod',
      label: 'Prazo Médio',
      minWidth: 100,
      align: 'right'
    }
  ]

  const orderFunc = (columnId: string) => {
    setPage(0)
    if (columnId !== orderBy) {
      setOrder('asc')
      setOrderBy(columnId)
      setSearchParams({
        ...newParams,
        orderBy: columnId,
        order: 'asc',
        page: '1'
      })
    } else {
      if (order === 'asc') {
        setOrder('desc')
        setOrderBy(columnId)
        setSearchParams({
          ...newParams,
          orderBy: columnId,
          order: 'desc',
          page: '1'
        })
      } else {
        setOrder('asc')
        setOrderBy(columnId)
        setSearchParams({
          ...newParams,
          orderBy: columnId,
          order: 'asc',
          page: '1'
        })
      }
    }
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    setSearchParams({
      ...newParams,
      page: (newPage + 1).toString()
    })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    setSearchParams({
      ...newParams,
      rowsPerPage: event.target.value,
      page: '1'
    })
  }

  return (
    <div className={className}>
      <PrimaryFilter filters={filterFields} totalItems={customers.pagination?.totalItems} />
      <PrimaryTable
        columns={columns}
        rows={rows}
        totalRows={rows.length === 0 ? 0 : (customers.pagination?.totalItems as number)}
        orderFunc={orderFunc}
        order={order as Order}
        orderBy={orderBy}
        isLoading={isGettingCustomers}
        page={rows.length === 0 ? 0 : page}
        redirectTo="customer-profile"
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}
export default WithHeader()(styled(CustomerList)`
  min-height: calc(100vh - 170px);
  padding: 24px;
  background: ${({ theme: { colors } }) => colors?.secondaryBackground};
  display: flex;
  gap: 16px;

  ${PrimaryFilter} {
    width: 192px !important;
  }

  .customer-list__company-rating___wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;

    ${PositiveIcon} {
      fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.positive};
    }

    ${NegativeIcon} {
      fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.negative};
    }

    ${FavoriteIcon} {
      fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.favorite};
    }
  }

  .filter-field__button___wrapper {
    .positive {
      ${PositiveIcon} {
        transition: all 300ms;
      }
    }

    .positive:hover,
    .selected-positive {
      ${PositiveIcon} {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.positive};
      }
    }

    .negative {
      ${NegativeIcon} {
        transition: all 300ms;
      }
    }

    .negative:hover,
    .selected-negative {
      ${NegativeIcon} {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.negative};
      }
    }

    .favorite {
      ${FavoriteIcon} {
        transition: all 300ms;
      }
    }

    .favorite:hover,
    .selected-favorite {
      ${FavoriteIcon} {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.support?.favorite};
      }
    }
  }

  @media (max-width: 850px) {
    position: relative;
    padding: 0;
    width: 100vw;
    height: calc(100vh - 104px);
    gap: 0;

    .MuiTableContainer-root {
      max-height: calc(100vh - 308px);
      min-height: calc(100vh - 308px);
    }

    flex-direction: column;
    background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};

    ${PrimaryFilter} {
      width: 100% !important;
    }

    .filter-field__button___wrapper {
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
`)
