import { CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import styled from 'styled-components'
import PrimaryButton from '../../../buttons/PrimaryButton'
import CompanyWelcomeForm from '../../../companies/CompanyWelcomeForm'
import Moment from 'moment'
import 'moment/locale/pt-br'
import { formatCurrencyToPtBR } from '../../../../utils/currency'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { CompanyContext } from '../../../../states/company/CompanyContext'
import CrudCreateModal from '../../CrudCreateModal'

interface IProps {
  className?: string
}

export interface IInputs {
  basePath: string
  presentation: {
    title: string
    description: string[]
  }
  isLoading?: boolean
  submit?: () => void
  isSubmitting?: boolean
  isDisabled?: boolean
  openModal?: boolean
  closeCreateModal?: () => void
}

interface IProps {
  className?: string
  data: IInputs
}
const Input: React.FC<IProps> = ({ className, data }: IProps) => {
  const { companyRevenue, defaultCompany, getCompanyRevenue, sendCompanyRevenue } = useContext(CompanyContext)
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({})
  const [object, setObject] = useState<{ [key: string]: string }>()

  const apiValues = companyRevenue

  useEffect(() => {
    const monthValues = apiValues.revenues.map((item) => {
      return {
        [item.period]: item.amount
      }
    })
    const finalObj = {}

    for (const value of monthValues) {
      Object.assign(finalObj, value)
    }

    setObject(finalObj)
  }, [apiValues.revenues])

  useEffect(() => {
    if (object) {
      setInputValues(object)
    }
  }, [object])

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyRevenue(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getCompanyRevenue])

  const getFloatPrice = (price: string) => {
    const digitsOnPrice = price.replace(/[^0-9]/g, '')
    return (Number(digitsOnPrice) / 100).toString()
  }

  const currentDate = Moment()
  const initialDate = new Date('2022-01-01')
  const diffDates = Moment(currentDate).diff(initialDate, 'months')

  const sendRevenues = async () => {
    const arrayOfObjects = Object.entries(inputValues).map(([key, value]) => ({
      period: key,
      amount: Number(value),
      kind: 'monthly'
    }))

    await sendCompanyRevenue({ companyId: defaultCompany?.businessId || '', revenues: arrayOfObjects })
    if (defaultCompany?.businessId) {
      getCompanyRevenue(defaultCompany?.businessId)
    }
  }
  const renderMonthInputs = () => {
    const inputs = []
    for (let i = 0; i < diffDates; i++) {
      const thisDateLabel = Moment(currentDate).locale('pt-Br').subtract(i, 'months').format('MMMM - YYYY')
      const thisDate = Moment(currentDate).locale('pt-Br').subtract(i, 'months').format('YYYY-MM')
      inputs.push(
        <NumberFormat
          key={i}
          data-testid="month-textfield-test"
          id={thisDate}
          label={thisDateLabel}
          customInput={TextField}
          fixedDecimalScale
          thousandSeparator="."
          decimalScale={2}
          decimalSeparator=","
          InputProps={{
            sx: { height: 36, fontSize: 14 },
            startAdornment: (
              <InputAdornment position="start" color="primary">
                <Typography fontSize={14} fontWeight={400} color="grey">
                  R$
                </Typography>
              </InputAdornment>
            )
          }}
          variant={'standard'}
          inputProps={{ maxLength: 15 }}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setInputValues((prev) => {
              return { ...prev, [e.target.id]: getFloatPrice(e.target.value) }
            })
          }
          value={inputValues[thisDate] || ''}
        />
      )
    }

    return inputs.map((input) => input)
  }

  return (
    <div className={className}>
      {data?.isLoading ? (
        <div className="list_loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <>
          {data?.closeCreateModal && data?.openModal && (
            <CrudCreateModal open={!!data?.openModal} onClose={data?.closeCreateModal} />
          )}
          <CompanyWelcomeForm header={data?.presentation?.title} descriptionText={data?.presentation?.description} />
          <div className="month_wrapper">{renderMonthInputs()}</div>
          <div className="input_bottom--container">
            <div className="input__bottom___amount--wrapper">
              <h4>Faturamento total:</h4>
              <h4>{formatCurrencyToPtBR(companyRevenue?.totalCompanyRevenues || 0)}</h4>
            </div>
            <div className="input_button--wrapper">
              <PrimaryButton onClick={sendRevenues} isLoading={!!data?.isSubmitting} disabled={!!data?.isDisabled}>
                Salvar
              </PrimaryButton>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default styled(Input)`
  min-height: calc(100vh - 240px);

  .list_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 240px);
  }

  .month_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: flex-start;
    margin-top: 50px;
    text-transform: capitalize;
    width: 100%;
    input {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      cursor: pointer;
      font-size: 14px;
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    label {
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
    }
    @media (max-width: 500px) {
      gap: 20px;
      justify-content: center;
      margin-top: 10px;
    }
  }

  .input_bottom--container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media (max-width: 500px) {
      flex-direction: column;
    }

    .input__bottom___amount--wrapper {
      color: ${({ theme }) => theme?.colors?.tertiaryFont};
      display: flex;

      h4:first-child {
        font-weight: bold;
        margin-right: 16px;
      }
    }

    .input_button--wrapper {
      width: 184px;
      @media (max-width: 500px) {
        margin-top: 12px;
        width: 100px;
      }
    }
  }
`
