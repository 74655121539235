import { useContext, useEffect } from 'react'
import { OriginContext } from '../../states/origin/OriginContext'
import { theme } from '../../styles/theme'

export const useResetThemeColor = (): void => {
  const { setTheme, originInfos, dynamicTheme } = useContext(OriginContext)

  useEffect(() => {
    const primaryColorFromOrigin = originInfos?.colors.find(({ group }) => group === 'Default')?.primary

    if (primaryColorFromOrigin) {
      if (dynamicTheme?.colors?.primary === primaryColorFromOrigin) return
      setTheme({
        colors: {
          ...theme?.colors,
          primary: primaryColorFromOrigin
        },
        styleGuide: {
          ...theme?.styleGuide,
          dynamic: {
            ...theme?.styleGuide?.dynamic,
            primary: {
              ...theme?.styleGuide?.dynamic?.primary,
              main: primaryColorFromOrigin
            }
          }
        }
      })
    }
  }, [setTheme, originInfos, dynamicTheme?.colors?.primary])
}
