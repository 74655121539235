import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const LoadingPage: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className}>
      <CircularProgress size={32} data-testid="test-circular-progress" />
    </div>
  )
}

export default styled(LoadingPage)`
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
`
