import { faCity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { OnboardingContext } from '../../apps/onboarding/states/OnboardingContext'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import CreateCompanyPageWrapper from '../../components/companies/CreateCompanyPageWrapper'
import InputFormat from '../../components/inputs/InputFormat'
import PrimaryInput from '../../components/inputs/PrimaryInput'
import WithHeader from '../../hocs/WithHeader'
import { useAuth } from '../../hooks/useAuth'
import { getRedirectUrl } from '../../utils/string'

interface IProps {
  className?: string
}

const CreateCompanyDocumentNumber: React.FC<IProps> = ({ className }: IProps) => {
  const redirectUrl = getRedirectUrl() as string
  const navigate = useNavigate()
  const { groups } = useAuth()
  const { isCreatingCompanyProposal, error } = useContext(OnboardingContext)
  const {
    register,
    formState: { errors, isValid }
  } = useFormContext()

  const goToNextStep = () => {
    navigate('/create-company/profile')
  }

  return (
    <CreateCompanyPageWrapper className={className}>
      <div className="create-company-document-number__input--wrapper">
        <h6 className="create-company-document-number__input--label">Qual é o CNPJ</h6>
        <div className="create-company-document-number__form">
          <PrimaryInput
            placeholder="Digite o CNPJ"
            InputProps={{
              inputComponent: InputFormat,
              inputProps: {
                format: '##.###.###/####-##'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faCity} />
                </InputAdornment>
              )
            }}
            data-testid="create-company-document-number-input"
            errorMessage={
              (errors?.documentNumber?.message as string) ||
              (error?.location === 'createProposal' ? error?.message : '')
            }
            {...register('documentNumber')}
          />
          <div className="create-company-document-number__form--button">
            {groups?.length > 0 ? (
              <>
                <div className="create-company-document-number__button--container close">
                  <PrimaryButton
                    onClick={() => window.location.replace(redirectUrl)}
                    data-testid="create-company-document-number-redirect"
                    variant="outlined"
                  >
                    Fechar
                  </PrimaryButton>
                </div>
                <div className="create-company-document-number__button--container">
                  <PrimaryButton
                    type="submit"
                    disabled={!isValid}
                    data-testid="create-company-document-number-submit"
                    isLoading={isCreatingCompanyProposal}
                  >
                    Continuar
                  </PrimaryButton>
                </div>
              </>
            ) : (
              <div className="create-company-document-number__button--container">
                <PrimaryButton
                  onClick={goToNextStep}
                  disabled={!isValid}
                  data-testid="create-company-document-number-next-step"
                >
                  Continuar
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </CreateCompanyPageWrapper>
  )
}

export default WithHeader()(styled(CreateCompanyDocumentNumber)`
  .create-company-document-number__input--wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .create-company-document-number__input--label {
      font-size: 24px;
      color: ${({ theme }) => theme?.colors?.secondaryFont};
    }
  }

  .create-company-document-number__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 640px;

    gap: 24px;

    .create-company-document-number__form--button {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;

      .create-company-document-number__button--container {
        width: 240px;
      }
    }
  }

  @media (max-width: 700px) {
    .create-company-document-number__input--wrapper {
      gap: 18px;

      .create-company-document-number__input--label {
        font-size: 18px;
      }
    }

    .create-company-document-number__form {
      gap: 32px;

      .create-company-document-number__form--button {
        width: 100%;
        flex-direction: column;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        grid-template-areas:
          'top'
          'bottom';

        .create-company-document-number__button--container {
          width: 100%;
          grid-area: top;

          &.close {
            grid-area: bottom;
          }
        }
      }
    }
  }
`)
