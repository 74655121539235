import styled from 'styled-components'
import ModalWrapper from '../../commons/ModalWrapper'
import PrimaryButton from '../../buttons/PrimaryButton'
import { FormEvent } from 'react'

interface IProps {
  className?: string
  open: boolean
  onClose: () => void
  buttons: Array<{
    label: string
    onClick: () => void
    isLoading?: boolean
    isPrimary?: boolean
    isSubmit?: boolean
  }>
  type: AlertType
  texts: {
    title: string
    description: string | React.ReactNode
  }
}

export type AlertType = 'warning' | 'success' | 'error'

const AlertModal: React.FC<IProps> = ({ className, open, buttons, texts, type }: IProps) => {
  const handleSubmit = (e: FormEvent) => {
    e?.preventDefault()
    const submitButton = buttons.find((button) => button.isSubmit)
    submitButton?.onClick()
  }

  return (
    <ModalWrapper open={open} className={className}>
      <form className="modal__wrapper" onSubmit={handleSubmit} data-testid="modal-test">
        <div className="modal__text___wrapper">
          <h4>{texts.title}</h4>
          <p>{texts.description}</p>
        </div>
        <div className="modal__button___wrapper">
          {buttons.map((button, index) => (
            <PrimaryButton
              key={index}
              onClick={button.onClick}
              type={button.isSubmit ? 'submit' : 'button'}
              isLoading={button.isLoading}
              size="small"
              className={`button ${button.isPrimary ? 'primary-button' : 'secondary-button'} ${type}`}
              data-testid={`modal-button-${index}`}
            >
              {button.label}
            </PrimaryButton>
          ))}
        </div>
      </form>
      <div className="modal__side-color"></div>
    </ModalWrapper>
  )
}

export default styled(AlertModal)`
  margin-top: 100px;
  @media (max-width: 850px) {
    margin-top: 112px;
    padding: 12px;
    height: 70%;
    .modal {
      padding: 12px !important;
    }
    .modal__wrapper {
      max-width: 400px;
      border-radius: 8px;
      margin: 0 auto;
      .modal__button___wrapper {
        margin-top: 12px !important;
        button {
          max-width: 150px !important;
          font-size: 12px !important;
        }
      }
      .modal__text___wrapper {
        h4 {
          font-size: 14px !important;
          margin-bottom: 12px !important;
        }
        span {
          font-size: 14px !important;
        }
        p {
          font-size: 14px !important;
        }
        ul {
          li {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .modal__side-color {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: ${({ type, theme: { colors, styleGuide } }) => {
      switch (type) {
        case 'warning':
          return styleGuide?.support.yellow
        case 'error':
          return styleGuide?.support.red
        default:
          return colors?.primary
      }
    }};
  }

  .modal__wrapper {
    .modal__text___wrapper {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};

      h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 32px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .modal__button___wrapper {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      gap: 8px;

      button {
        width: 100%;
        max-width: 200px;
        display: block;
        background: ${({ type, theme: { colors, styleGuide } }) => {
          switch (type) {
            case 'warning':
              return styleGuide?.support.yellow
            case 'error':
              return styleGuide?.support.red
            default:
              return colors?.primary
          }
        }};
        border-color: ${({ type, theme: { colors, styleGuide } }) => {
          switch (type) {
            case 'warning':
              return styleGuide?.support.yellow
            case 'error':
              return styleGuide?.support.red
            default:
              return colors?.primary
          }
        }};
      }
    }
  }
`
