import { ReactNode } from 'react'
import styled from 'styled-components'

export interface IH5Props {
  className?: string
  children: ReactNode
}

const H5: React.FC<IH5Props> = ({ className, children }: IH5Props) => {
  return <div className={className}>{children}</div>
}

export default styled(H5)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
`
