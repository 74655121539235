import { CloseOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { FormEvent, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import WithHeader from '../../hocs/WithHeader'
import { getRedirectUrl } from '../../utils/string'

interface IProps {
  className?: string
}

interface IDefaultErrorState {
  state: {
    text: string[]
    title: string
    action?: string
    redirect?: boolean
  }
}

const DefaultError: React.FC<IProps> = ({ className }: IProps) => {
  const navigate = useNavigate()
  const { state } = useLocation() as IDefaultErrorState
  const redirectUrl = getRedirectUrl() as string

  useEffect(() => {
    if (!state) {
      navigate(-1)
    }
  }, [state, navigate])

  const handleSubmit = (e: FormEvent) => {
    e?.preventDefault()

    navigate('/create-company/document-number')
  }

  return (
    <div className={className}>
      <div className="default-error__container">
        <img
          alt="Pessoas investigando um símbolo de alerta"
          src="https://af-logo.s3.sa-east-1.amazonaws.com/SemOfertaTinyImg.png"
        />
        <form onSubmit={handleSubmit} className="default-error__form--wrapper">
          {state?.redirect && (
            <div className="default-error__form___back-button--wrapper">
              <IconButton
                className=""
                data-testid="default-error-back-button-test"
                onClick={() => window.location.replace(redirectUrl)}
              >
                <CloseOutlined />
              </IconButton>
            </div>
          )}
          <h1 data-testid="default-error-title-test">{state?.title}</h1>
          {state?.text?.map((paragraph, i) => (
            <p key={i} data-testid="default-error-text-test">
              {paragraph}
            </p>
          ))}
          {state?.action && <p data-testid="default-error-action-test">{state?.action}</p>}
          <div className="default-error__form___button--wrapper">
            <PrimaryButton data-testid="default-error-button-test" type="submit">
              Tentar outro CNPJ
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default WithHeader()(styled(DefaultError)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .default-error__container {
    padding: 64px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 120px;

    img {
      width: 400px;
    }

    .default-error__form--wrapper {
      width: 384px;
      h1 {
        font-size: 32px;
      }
      display: flex;
      flex-direction: column;
      gap: 32px;

      p {
        line-height: 24px;
      }
      .default-error__form___back-button--wrapper {
        width: 100%;
        margin-left: -16px;
      }

      .default-error__form___button--wrapper {
        width: 256px;
      }
    }
  }

  @media (max-width: 1080px) {
    .default-error__container {
      flex-direction: column;
      align-items: center;
      gap: 64px;

      .default-error__form--wrapper {
        h1 {
          text-align: center;
        }

        p {
          line-height: 24px;
          text-align: center;
        }

        .default-error__form___button--wrapper {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .default-error-page__container {
      img {
        width: 90vw;
      }

      .default-error-page__form--wrapper {
        width: 90vw;
      }
    }
  }
`)
