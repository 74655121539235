import { useContext, useEffect } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import List from '../../components/crud/SplitCrud/List'
import { CompanyContext } from '../../states/company/CompanyContext'
import { theme } from '../../styles/theme'
import { cnpjMask, phoneMask } from '../../utils/string'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CompanyCustomersAndSuppliers: React.FC<IProps> = ({ className }: IProps) => {
  const {
    companyCustomersAndSuppliers,
    defaultCompany,
    isGettingDefaultCompany,
    isGettingCompanyCustomersAndSuppliersByCompanyId,
    getCompanyCustomersAndSuppliersByCompanyId
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyCustomersAndSuppliersByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getCompanyCustomersAndSuppliersByCompanyId])

  const getTagColorAndStatus = (customerOrSupplier: '0' | undefined | null | string) => {
    if (customerOrSupplier === '0')
      return {
        status: 'Cliente',
        color: theme?.styleGuide?.support?.green
      }
    return {
      status: 'Fornecedor',
      color: theme?.styleGuide?.support?.blue
    }
  }

  const listData = {
    basePath: '/company-customers-and-suppliers',
    goToFormButtonLabel: 'Adicionar cliente ou fornecedor',
    presentation: {
      title: 'Aqui que você vai cadastrar seus clientes e fornecedores!',
      description: [
        'Você deve informar os principais clientes da sua empresa, com os quais deseja antecipar recebíveis, e também os fornecedores.',
        'Essas informações garantirão agilidade no seu cadastro e na antecipação das suas notas fiscais.'
      ]
    },
    list: companyCustomersAndSuppliers?.map((companyCustomerOrSupplier) => ({
      ...companyCustomerOrSupplier,
      id: companyCustomerOrSupplier?.customerId || companyCustomerOrSupplier?.supplierId,
      documentNumber: cnpjMask(companyCustomerOrSupplier?.documentNumber as string),
      tag: getTagColorAndStatus(companyCustomerOrSupplier?.isCustomer?.toString() as string),
      phone: phoneMask(companyCustomerOrSupplier?.phone as string),
      subtitle: companyCustomerOrSupplier?.companyName as string
    })),
    keysInCard: ['documentNumber', 'email', 'phone'],
    cardTitleKeys: ['fullName'],
    isLoading: isGettingCompanyCustomersAndSuppliersByCompanyId || isGettingDefaultCompany,
    isDisabled: (defaultCompany?.statusId as string) !== '1'
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <List data={listData} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyCustomersAndSuppliers)
