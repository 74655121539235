import { Maybe } from 'graphql/jsutils/Maybe'
import styled from 'styled-components'
import CompanyWelcomeForm from '../../../companies/CompanyWelcomeForm'
import { CircularProgress } from '@mui/material'
import { ellipsisInTheEndOfString } from '../../../../utils/string'
import RegisteredAssigneeCardDelete from '../../../companies/RegisteredAssigneeCardDelete'

export interface ICRUDListDeleteData {
  basePath: string
  goToFormButtonLabel?: string
  showWelcomeMessage?: boolean
  presentation: {
    title: string
    description: string[]
  }
  list: {
    tag?: {
      status: string
      color: string
    }
    imageSrc?: string
    isUnclickable?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: Maybe<any>
  }[]
  keysInCard: string[]
  cardTitleKeys: string[]
  isLoading?: boolean
  isDisabled?: boolean
  checkFunc?: (id: string) => void
}

interface IProps {
  className?: string
  data: ICRUDListDeleteData
}

const List: React.FC<IProps> = ({ className, data }: IProps) => {
  const handleCheck = (id: string) => data?.checkFunc && data?.checkFunc(id)

  return (
    <div className={className}>
      {data?.isLoading ? (
        <div className="list__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <>
          <CompanyWelcomeForm
            header={data?.presentation?.title}
            descriptionText={data?.presentation?.description}
            buttonNavigateUrl={`${data?.basePath}/form`}
            buttonText={data?.goToFormButtonLabel}
            buttonDisabled={data?.isDisabled}
            notShowWelcomeMessage={data?.list?.length > 0 && !data?.showWelcomeMessage}
          />
          <div data-testid="bank-accounts-list-test" className="list__card--wrapper">
            {data?.list?.map((item) => (
              <RegisteredAssigneeCardDelete
                isUnclickable={item?.isUnclickable}
                key={item?.id}
                infos={data?.keysInCard?.map((key) => ellipsisInTheEndOfString(item[key] as string, 25))}
                titles={data?.cardTitleKeys?.map((key) => item[key] as string)}
                tag={item?.tag?.status}
                tagColor={item?.tag?.color}
                imageSrc={item?.imageSrc}
                isDisabled={data?.isDisabled}
                isChecked={item?.isDefault}
                checkFunc={() => handleCheck(item?.id)}
                editData={{
                  accountAgency: item.accountAgency,
                  accountDigit: item.accountDigit,
                  accountNumber: item.accountNumber,
                  bankCode: item.bankCode,
                  bankName: item.bankName,
                  id: item.id,
                  isDefault: item.isDefault,
                  ispb: item.ispb
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default styled(List)`
  min-height: calc(100vh - 280px);
  .list__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 240px);
  }

  .list__card--wrapper {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`
