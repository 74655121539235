import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface IProps {
  className?: string
  title: string
  subtitle: string
  backButton?: boolean
}

const NormalFlowTitle: React.FC<IProps> = ({ className, title, subtitle, backButton }: IProps) => {
  const navigate = useNavigate()

  return (
    <div className={className}>
      {backButton && (
        <div className="normal-flow-title__back-button">
          <IconButton onClick={() => navigate(-1)} data-testid="normal-flow-title-back-button-test">
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        </div>
      )}
      <div className="normal-flow-title--wrapper">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}

export default styled(NormalFlowTitle)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .normal-flow-title__back-button {
    margin-left: -12px;
    margin-bottom: 32px;
  }

  svg {
    height: 24px;
    width: 24px;
    path {
      color: ${({ theme }) => theme?.colors?.tertiary};
    }
  }

  .normal-flow-title--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    h1 {
      color: ${({ theme }) => theme?.colors?.tertiary};
      font-size: 24px;
      font-weight: bold;
    }

    p {
      font-size: 18px;
    }
  }
`
