import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import rating from '../../../../apps/common/assets/rating.svg'
import { CompanyContext } from '../../../../states/company/CompanyContext'
import { CircularProgress } from '@mui/material'
import { SnackbarContext } from '../../../../apps/common/contexts/SnackBar/SnackBarContext'

interface IProps {
  className?: string
}

type FormData = {
  item1: boolean
  item2: boolean
  item3: boolean
  item4: boolean
  item5: boolean
  item6: boolean
  item7: boolean
  other: string
}

const itemTexts = [
  'Sócio ou empresa com liminar',
  'Excesso de pendências/restrições',
  'Informações insuficientes nos bureaus de crédito',
  'Score risco de fraude insatisfatório',
  'O perfil do cedente não se encaixa em nossa política interna',
  'Visita Virtual insatisfatória',
  'Os perfis dos sacados não se encaixam em nossa política interna'
]

const NegativeRatingReasons: React.FC<IProps> = ({ className }: IProps) => {
  const { register, handleSubmit, reset } = useForm<FormData>()
  const [showOtherTextArea, setShowOtherTextArea] = useState(false)
  const [otherText, setOtherText] = useState('')
  const { createRatingReasons, companyRating, isCreatingRatingReasons, clearCreateRatingReasons } =
    useContext(CompanyContext)
  const { showSnackbar } = useContext(SnackbarContext)

  useEffect(() => {
    clearCreateRatingReasons()
  }, [clearCreateRatingReasons])

  const onSubmit = (data: FormData) => {
    const descriptions = itemTexts?.filter((_, index) =>
      data[`item${index + 1}` as keyof FormData] ? itemTexts[index] : ''
    )
    if (showOtherTextArea && otherText.trim() !== '') {
      descriptions.push(otherText)
    }
    if (descriptions.length > 0 || otherText.trim() !== '') {
      createRatingReasons({ creditRatingsId: companyRating?.id, description: descriptions?.filter(Boolean) })
      reset({ ...data, other: '' })
      setShowOtherTextArea(false)
      setOtherText('')
    } else {
      showSnackbar('Selecione pelo menos uma opção', 'error')
    }
  }

  const handleOtherCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOtherTextArea(event.target.checked)
    if (!event.target.checked) {
      setOtherText('')
    }
  }

  return (
    <div className={className}>
      <div className="negative-rating-reasons__rating__container">
        <img src={rating} alt="Rating" />
        <div className="negative-rating-reasons__rating__info">
          <p>CLASSIFICAÇÃO</p>
          <p>Não exibiremos os títulos de cedentes com essa classificação na sua vitrine</p>
          <p>Qual é o motivo dessa classificação?</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="negative-rating-reasons__form__container">
            {itemTexts.map((itemText, index) => (
              <div key={index} className="negative-rating-reasons__form__item">
                <label>
                  <input type="checkbox" {...register(`item${index + 1}` as keyof FormData)} value={itemText} />
                  <p>{itemText}</p>
                </label>
              </div>
            ))}
            <div className="negative-rating-reasons__form__item">
              <label className="negative-rating-reasons__form__other__textarea">
                <div className="negative-rating-reasons__form__other__textarea--input">
                  <input type="checkbox" onChange={handleOtherCheckboxChange} />
                  <p onClick={() => setShowOtherTextArea(true)}>Outros: _____________________________</p>
                </div>
                {showOtherTextArea && (
                  <textarea
                    data-testId="other-test-id"
                    maxLength={200}
                    {...register('other')}
                    value={otherText}
                    onChange={(e) => setOtherText(e.target.value)}
                  />
                )}
              </label>
            </div>
          </div>
          {isCreatingRatingReasons ? (
            <CircularProgress size={26} data-testid="test-circular-progress" />
          ) : (
            <button onClick={() => clearCreateRatingReasons()} type="submit">
              Confirmar
            </button>
          )}
        </form>
      </div>
    </div>
  )
}

export default styled(NegativeRatingReasons)`
  width: 100%;
  background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .negative-rating-reasons__rating__container {
    display: flex;
    max-width: 400px;
    flex-direction: column;
    align-items: center;
    margin: 46px 0px;
  }

  p {
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
    font-size: 14px;
  }

  button {
    background-color: transparent;
    border-color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
    color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
    cursor: pointer;
    border-radius: 8px;
    margin: 0px;
    max-width: 294px;
    padding: 14px 0px;
    width: 100%;

    &:hover {
      background: ${({ theme: colors }) => colors?.colors?.buyer};
      color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
      font-weight: bold;
    }
  }

  .negative-rating-reasons__rating__info {
    p:first-child {
      color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
      font-size: 24px;
      font-weight: bold;
      padding: 24px 0px 12px;
    }

    p:nth-last-child(1) {
      font-weight: bold;
      padding: 18px 0px 26px;
    }
  }

  form {
    padding-bottom: 64px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    .negative-rating-reasons__form__container {
      padding: 0px 0px 44px 76px;
    }

    .negative-rating-reasons__form__item {
      padding: 0px 44px 24px 0px;

      label {
        display: flex;
        align-items: flex-start;

        input {
          border-color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
          cursor: pointer;
          min-height: 18px;
          margin-right: 8px;
          min-width: 18px;
        }

        input[type='checkbox'] {
          accent-color: ${({ theme: colors }) => colors?.colors?.buyer};
        }
      }
    }

    .negative-rating-reasons__form__other__textarea {
      display: flex;
      flex-direction: column;

      .negative-rating-reasons__form__other__textarea--input {
        display: flex;
      }

      textarea {
        color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
        font-size: 14px;
        margin-top: 8px;
        max-width: 400px;
        min-height: 100px;
        padding: 8px;
        width: 100%;
      }
    }
  }
`
