import { IAPIError } from '../../types/common'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleGraphQLError = (error: any, type: string, location: string): { type: any; payload: any } => {
  const extensions: IAPIError = error.graphQLErrors[0]?.extensions

  return {
    type,
    payload: {
      location,
      message: extensions?.response?.body?.message
    }
  }
}
