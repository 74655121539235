import styled from 'styled-components'

interface IProps {
  className?: string
}

const Trash: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
      <path
        id="da086273b974cb595139babd4da17772"
        d="M23.228,11.895l-.378,11.7a3.809,3.809,0,0,1-3.781,3.727H11.886A3.808,3.808,0,0,1,8.106,23.6l-.378-11.7a.946.946,0,1,1,1.89-.064L10,23.534a1.91,1.91,0,0,0,1.89,1.858h7.183a1.91,1.91,0,0,0,1.89-1.862l.378-11.7a.946.946,0,1,1,1.89.064ZM24.478,8a.956.956,0,0,1-.945.966H7.423a.967.967,0,0,1,0-1.933h2.93a1.216,1.216,0,0,0,1.2-1.109,2.851,2.851,0,0,1,2.82-2.6h2.2a2.851,2.851,0,0,1,2.82,2.6,1.216,1.216,0,0,0,1.2,1.109h2.93A.956.956,0,0,1,24.477,8ZM13.2,7.035H17.76a3.246,3.246,0,0,1-.241-.912.953.953,0,0,0-.939-.87h-2.2a.953.953,0,0,0-.939.87,3.246,3.246,0,0,1-.242.912Zm.952,14.642V13.448a.945.945,0,1,0-1.89,0V21.68a.945.945,0,1,0,1.89,0Zm4.55,0V13.448a.945.945,0,1,0-1.89,0V21.68a.945.945,0,1,0,1.89,0Z"
        transform="translate(-6.478 -3.322)"
        fill="#db2828"
      />
    </svg>
  )
}

export default styled(Trash)``
