import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const CustomerMainInformationSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div data-testid="customer-main-information-skeleton-test" className={className}>
      <Skeleton classes={{ root: 'title' }} width="300px" animation="wave" />
      <Skeleton classes={{ root: 'description' }} width="350px" animation="wave" />
      <Skeleton classes={{ root: 'description' }} width="200px" animation="wave" />
      <Skeleton classes={{ root: 'client-data' }} width="200px" animation="wave" />
      <Skeleton classes={{ root: 'client-data' }} width="150px" animation="wave" />
      <div className="social-links">
        {[...Array(4)].map((_, index) => (
          <Skeleton classes={{ root: 'social-link' }} key={index} width="30px" animation="wave" />
        ))}
      </div>
    </div>
  )
}

export default styled(CustomerMainInformationSkeleton)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;

  .title {
    height: 50px;
    margin-bottom: 0;
  }
  .description {
    height: 40px;
    margin: 0;
  }
  .client-data {
    height: 20px;
  }
  span {
    border-radius: 4px;
  }
  .social-links {
    display: flex;
    gap: 32px;
  }
  .social-link {
    height: 40px;
  }
`
