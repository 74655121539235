import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../states/company/CompanyContext'
import DocumentsSkeleton from './DocumentsSkeleton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import Save from '../../../apps/common/assets/Save'
import Plus from '../../../apps/common/assets/Plus'
import { CircularProgress } from '@mui/material'
import Tooltip from '../../../components/tooltip'

interface IProps {
  className?: string
  companyId: string
}

interface IDocument {
  name: string
  fullName: string
  id: string
  excludeBorder?: boolean
  showCircularProgress: boolean
}

const CustomerDocuments: React.FC<IProps> = ({ className, companyId }: IProps) => {
  const {
    getCustomerAllDocumentsNames,
    getCustomerGroupFilesByURL,
    getCustomerAllDocumentsFilesByURL,
    isGettingCustomerGroupFilesByURL,
    isGettingCustomerAllDocumentsFilesByURL,
    isGettingCustomerDataByCompanyId,
    allDocumentsNames,
    documentsGroupDownload,
    allDocumentsDownload,
    clearCustomerGroupFilesURL,
    clearAllFilesURL
  } = useContext(CompanyContext)

  const [clickedId, setClickedId] = useState<string>()

  const [documents, setDocuments] = useState<IDocument[]>([])

  const [isDownloading, setIsDownloading] = useState(false)

  const [currentDownloadId, setCurrentDownloadId] = useState<string | null>(null)

  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false)

  const [isButtonVisible, setIsButtonVisible] = useState(false)

  useEffect(() => {
    if (!isGettingCustomerGroupFilesByURL) {
      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) => (doc.id === clickedId ? { ...doc, showCircularProgress: false } : doc))
      )
    }
  }, [clickedId, isGettingCustomerGroupFilesByURL])

  useEffect(() => {
    if (!isGettingCustomerDataByCompanyId) {
      getCustomerAllDocumentsNames({ companyId: companyId, dossieId: 1 })
    }
  }, [companyId, isGettingCustomerDataByCompanyId, getCustomerAllDocumentsNames])

  useEffect(() => {
    if (!isGettingCustomerDataByCompanyId) {
      getCustomerAllDocumentsFilesByURL({ companyId: companyId, dossieId: 1, excludedTypesId: '[55, 0]' })
    }
  }, [companyId, isGettingCustomerDataByCompanyId, getCustomerAllDocumentsFilesByURL])

  useEffect(() => {
    const filteredDocuments = allDocumentsNames?.documentsIdsAndNames
      ?.filter((document) => document.name !== 'Vídeos' && document.name !== 'Análise e Relatório')
      ?.map((document) => {
        let name = document.name
        const id = String(document.id)
        if (name.length > 30) {
          name = name.slice(0, 25) + '...'
        }
        return {
          name,
          fullName: document.name,
          id,
          excludeBorder: false,
          showCircularProgress: false
        }
      })

    setDocuments(filteredDocuments || [])
  }, [allDocumentsNames])

  const handleDownloadAllDocumentsClick = async () => {
    try {
      if (allDocumentsDownload?.allDocumentsUrl) {
        setIsDownloading(true)
        const downloadUrl = allDocumentsDownload.allDocumentsUrl
        window.open(downloadUrl, '_self')
        clearAllFilesURL()
        setIsButtonVisible(true)
      }
    } finally {
      setTimeout(() => {
        setIsDownloading(false)
      }, 3000)
    }
  }

  useEffect(() => {
    if (!isGettingCustomerAllDocumentsFilesByURL && documentsGroupDownload?.url.length > 1) {
      const downloadUrl = documentsGroupDownload?.url
      window.open(downloadUrl, '_self')
      clearCustomerGroupFilesURL()
      clearAllFilesURL()
    }
  }, [
    documentsGroupDownload?.url,
    clearCustomerGroupFilesURL,
    clearAllFilesURL,
    isGettingCustomerAllDocumentsFilesByURL
  ])

  const handleDownloadDocumentGroupClick = async (documentId: string) => {
    if (isDownloadInProgress) {
      return
    }

    setClickedId(documentId)
    setCurrentDownloadId(documentId)
    setIsDownloadInProgress(true)

    getCustomerGroupFilesByURL({ companyId: companyId, dossieId: 1, typesId: documentId })

    setDocuments((prevDocuments) =>
      prevDocuments.map((doc) => (doc.id === documentId ? { ...doc, showCircularProgress: true } : doc))
    )
  }

  useEffect(() => {
    if (!isGettingCustomerGroupFilesByURL && currentDownloadId) {
      setIsDownloadInProgress(false)
      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) => (doc.id === currentDownloadId ? { ...doc, showCircularProgress: false } : doc))
      )
    }
  }, [currentDownloadId, isGettingCustomerGroupFilesByURL])

  return (
    <div className={className}>
      <div className="container">
        {isGettingCustomerDataByCompanyId ? (
          <DocumentsSkeleton />
        ) : (
          <div className="customer-documents">
            <h5 className="customer-documents__main-title">Documentos</h5>
            {allDocumentsNames?.documentsIdsAndNames?.length > 0 ? (
              <div className="customer-documents__list">
                <div className="customer-documents__list-wrapper">
                  {documents.map((document, index) => (
                    <div key={index} className={document.excludeBorder ? 'exclude-border' : ''}>
                      <div className="document-item">
                        <Tooltip content={document.fullName}>
                          <p>{document.name}</p>
                        </Tooltip>
                        {document.showCircularProgress ? (
                          <div className="loading-container">
                            <CircularProgress
                              size={22}
                              sx={{ marginRight: '16px', marginLeft: '16px' }}
                              data-testid="test-circular-progress"
                            />
                          </div>
                        ) : (
                          <Save
                            data-testid="save-button-test"
                            onClick={() => handleDownloadDocumentGroupClick(document.id)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="customer-documents__without-document">
                <p>Nenhum documento disponível</p>
              </div>
            )}
            <div className="customer-documents__download-button">
              <div data-testid="submit-test">
                <Plus />
                <p className="customer-documents__download-button__documents">
                  <a
                    rel="noreferrer"
                    href="https://antecipa.zeev.it/2.0/anonymous?c=qpvwiPDbjM670Aemf5QfWyZre2xD4fQDHX0axVv3BhAhhLxjgN6N3lDZ0aFPVy%2FKhYAjh%2B5%2B9Q0gG9P4%2B3MHM4l3FUw4F2tgAwKVsQYxIxI%3D#top"
                    target="_blank"
                  >
                    Solicitar documentos
                  </a>
                </p>
              </div>
              {(isButtonVisible || allDocumentsDownload?.allDocumentsUrl) &&
                allDocumentsNames.documentsIdsAndNames.length > 0 && (
                  <PrimaryButton
                    variant="outlined"
                    data-testid="submit-test"
                    type="submit"
                    className="customer-documents__button-download"
                    onClick={() => handleDownloadAllDocumentsClick()}
                  >
                    <p>Baixar documentos</p>
                    {isDownloading ? (
                      <CircularProgress
                        sx={{ position: 'absolute', right: '6%' }}
                        size={18}
                        data-testid="test-circular-progress"
                      />
                    ) : (
                      <Save />
                    )}
                  </PrimaryButton>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default styled(CustomerDocuments)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  border-radius: 8px;
  display: grid;
  grid-area: customer_documents;
  width: 100%;

  .container {
    padding: 16px;
  }

  .loading-container {
    border-left: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};
  }

  .customer-documents__main-title {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .customer-documents__list {
    margin-top: 24px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};

    .customer-documents__list-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      ${Save} {
        cursor: pointer;
        max-height: 22px;
        max-width: 22px;
        padding: 4px 16px;
        border-left: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};

        path {
          fill: ${({ theme: { colors } }) => colors?.primary};
        }
      }

      .document-item {
        display: flex;
        text-align: left;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};

        p {
          color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
          font-weight: bold;
          padding: 16px 0px 16px 16px;
        }
      }
    }

    .exclude-border {
      .document-item {
        border-bottom: none;
      }
    }
  }

  .customer-documents__without-document {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    width: 100%;
    margin-top: 34px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
    }
  }

  .customer-documents__download-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 34px;

    .customer-documents__download-button__documents {
      font-weight: normal;
    }

    div {
      display: flex;
      width: 100%;
      justify-content: center;
      cursor: pointer;
      align-items: center;

      p {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-weight: bold;
      }

      a {
        color: ${({ theme: { colors } }) => colors?.primary};
        text-decoration: none;
      }

      ${Save} {
        height: 22px;
        width: 22px;
        position: absolute;
        right: 6%;

        path {
          fill: ${({ theme: { colors } }) => colors?.primary};
        }
      }

      ${Plus} {
        max-width: 16px;
        margin-right: 12px;

        path {
          fill: ${({ theme: { colors } }) => colors?.primary};
        }
      }
    }

    ${PrimaryButton} {
      width: 100%;
    }

    .customer-documents__button-download {
      margin-top: 32px;
    }
  }
`
