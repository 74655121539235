import { StandardTextFieldProps, TextField } from '@mui/material'
import { ChangeEvent, Ref, forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

export interface IMaskInputProps extends Omit<StandardTextFieldProps, 'variant' | 'onChange'> {
  value: number
  onChange: (value: number | string) => void
  label?: string
  className?: string
  errorMessage?: string
  mask?: string
  ref?: Ref<HTMLInputElement>
  endAdornment?: React.ReactNode
}

export const DEFAULT_VALUE = '0,000'

const MaskInput = forwardRef<HTMLInputElement, IMaskInputProps>(
  ({ onChange, value, className, errorMessage, endAdornment, ...rest }: IMaskInputProps, ref) => {
    const [maskedValue, setMaskedValue] = useState<string>(DEFAULT_VALUE)

    useEffect(() => {
      if (!isNaN(value)) {
        const formattedValue = value.toFixed(3).replace('.', ',')
        setMaskedValue(formattedValue)
      }
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const sanitizedValue = event.target.value.replace(/[^\d]/g, '')
      const floatValue = parseFloat(sanitizedValue) / 1000
      if (isNaN(floatValue)) {
        setMaskedValue(DEFAULT_VALUE)
        onChange(0)
      } else {
        const integerPart = Math.floor(floatValue)
        const decimalPart = (floatValue - integerPart).toFixed(3).substring(2)
        const formatedInput = `${String(integerPart).replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${decimalPart}`

        setMaskedValue(formatedInput)
        onChange(floatValue)
      }
    }

    return (
      <div className={className}>
        <TextField
          ref={ref}
          variant="standard"
          error={!!errorMessage}
          helperText={errorMessage}
          FormHelperTextProps={{
            error: !!errorMessage
          }}
          fullWidth
          {...rest}
          value={maskedValue}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
            error: !!errorMessage
          }}
          InputProps={{
            error: !!errorMessage,
            endAdornment: endAdornment,
            ...rest?.InputProps
          }}
        />
      </div>
    )
  }
)

MaskInput.displayName = 'MaskInput'

export default styled(MaskInput)`
  min-height: ${({ label }) => (label ? '70px' : '50px')};

  .MuiInputAdornment-positionStart {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
  .MuiInputAdornment-positionEnd {
    width: 20px;
    margin-right: 12px;
    margin-bottom: 8px;
    background: red;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }

  .MuiInput-underline:before {
    border-bottom-width: 2px;
  }

  .MuiInputLabel-root {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
  }

  .MuiInputLabel-standard {
    font-size: 14px;
  }
`
