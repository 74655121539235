import { ReactNode, useCallback, useEffect, useReducer } from 'react'
import { useGetThemeLazyQuery } from '../../service/graphql/schema'
import { setAppTab } from '../../utils/screen'
import { getUpperLevelDomain } from '../../utils/string'
import { initialState, OriginContext } from './OriginContext'
import OriginReducer from './OriginReducer'
import { theme as scTheme } from '../../styles/theme'
import { Theme } from '../../types/override/styled'
import { modifyColors } from '../../utils/color'
interface IProps {
  children: ReactNode
}

const OriginState: React.FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(OriginReducer, initialState)

  const [getOriginInfosQuery] = useGetThemeLazyQuery({
    onCompleted: ({ getTheme }) => {
      dispatch({
        type: 'GET_ORIGIN_INFOS_SUCCESS',
        payload: getTheme
      })
    },
    onError: (error) => {
      dispatch({
        type: 'GET_ORIGIN_INFOS_ERROR',
        payload: {
          location: 'getOriginInfos',
          message: error?.graphQLErrors[0]?.message
        }
      })
    }
  })

  const getOriginInfos = useCallback(() => {
    dispatch({
      type: 'GET_ORIGIN_INFOS'
    })

    getOriginInfosQuery({
      variables: {
        hostname: getUpperLevelDomain(window.location.hostname)
      }
    })
  }, [getOriginInfosQuery])

  const setTheme = useCallback(
    (theme: Partial<Theme>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const colors = state.dynamicTheme.colors as any

      const [dark, light, gray] = modifyColors(theme?.styleGuide?.dynamic?.primary?.main as string)

      const newDynamicColorObjects = {
        primary: {
          main: theme?.styleGuide?.dynamic?.primary?.main
        },
        secondary: {
          dark,
          light,
          gray
        }
      }

      const newTheme = {
        ...state?.dynamicTheme,
        ...theme,
        styleGuide: {
          ...state?.dynamicTheme?.styleGuide,
          ...theme?.styleGuide,
          dynamic: newDynamicColorObjects
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newColors = theme as any

      Object.keys(newColors).forEach((color) => {
        if (newColors[color] !== colors[color]) {
          dispatch({
            type: 'SET_THEME',
            payload: newTheme
          })
        }
      })
    },
    [state?.dynamicTheme]
  )

  useEffect(() => {
    if (!state?.originInfos) {
      getOriginInfos()
    }
  }, [getOriginInfos, state?.originInfos])

  useEffect(() => {
    if (state?.originInfos) {
      setAppTab(state?.originInfos?.storeName, state?.originInfos?.favicon)

      const dynamicColors = state?.originInfos?.colors?.find(({ group }) => group === 'Default')

      const [dark, light, gray] = modifyColors(dynamicColors?.primary as string)

      const newColorObjects = {
        primary: dynamicColors?.primary,
        secondary: dynamicColors?.secondary,
        dynamic: {
          primary: {
            main: dynamicColors?.primary
          },
          secondary: {
            dark,
            light,
            gray
          }
        }
      }

      dispatch({
        type: 'SET_THEME',
        payload: {
          ...scTheme,
          colors: { ...scTheme.colors, primary: newColorObjects?.primary },
          styleGuide: {
            ...scTheme?.styleGuide,
            dynamic: newColorObjects?.dynamic
          }
        }
      })
    }
  }, [state?.originInfos])

  const contextValues = {
    ...state,
    setTheme
  }

  return <OriginContext.Provider value={contextValues}>{children}</OriginContext.Provider>
}

export default OriginState
