import { useContext, useEffect } from 'react'
import SecondaryOnePageCrud, { ISecondaryOnePageCrudFormData } from '../../components/crud/SecondaryOnePageCrud'
import { CompanyContext } from '../../states/company/CompanyContext'
import WithHeader from '../../hocs/WithHeader'

const CompanyTaxes: React.FC = () => {
  const {
    companyTaxes,
    getCompanyTaxesByCompanyId,
    isGettingCompanyTaxesByCompanyId,
    defaultCompany,
    isGettingDefaultCompany,
    registerCompanyTaxes,
    isRegistratingCompanyTaxes,
    companyTaxesModal,
    closeCompanyTaxesModal
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getCompanyTaxesByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getCompanyTaxesByCompanyId])

  const formData: ISecondaryOnePageCrudFormData = {
    isLoading: isGettingCompanyTaxesByCompanyId || isGettingDefaultCompany,
    title: 'Aqui você configura as taxa operacionais',
    subtitle: ['Os custos operacionais devem ser definidos aqui. Não se preocupe, você pode alterar-los depois. =]'],
    currentData: companyTaxes,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveData: (data: any) =>
      registerCompanyTaxes({
        ...data,
        companyId: defaultCompany?.businessId
      }),
    closeSuccessModal: closeCompanyTaxesModal,
    isSavingData: isRegistratingCompanyTaxes,
    openSuccessModal: companyTaxesModal,
    isDisabled: (defaultCompany?.statusId as string) !== '1' && (defaultCompany?.statusId as string) !== '4',
    inputs: [
      {
        label: 'Ad Valorem',
        text: ['Essa porcentagem é mensal e incide sobre o valor total dos vencimentos.', 'Valor sugerido: 0,3% - 1%'],
        name: 'adValorem',
        required: true,
        align: 'right',
        type: 'percentage'
      },
      {
        label: 'Prazo de Liquidação/Floating',
        text: [
          'Dias a serem somados no prazo de vencimento, incluindo na cobrança o período entre o pagamento e recebimento. Valor sugerido: 0 - 5 dias'
        ],
        name: 'settlementTerm',
        required: true,
        align: 'right',
        type: 'number'
      },
      {
        label: 'IOF do Valor',
        text: [
          'Imposto sobre Operações Financeiras, incide sobre o valor total dos vencimentos.',
          'Valor sugerido: 0,38%'
        ],
        name: 'iofAmount',
        required: true,
        align: 'right',
        type: 'percentage'
      },
      {
        label: 'IOF do Prazo',
        text: [
          'Incide sobre cada vencimento e é ponderada com o prazo médio (somado com floating).',
          'Valor sugerido: 0,0041%'
        ],
        name: 'iofTerm',
        required: true,
        align: 'right',
        type: 'percentage'
      },
      {
        label: 'Taxa Cobrança',
        text: [
          'Se refere ao custo que se tem por vencimento vindo das instituições bancárias (emissão de boleto). Valor sugerido: R$ 0'
        ],
        name: 'chargeTax',
        required: true,
        align: 'right',
        type: 'currency'
      },
      {
        label: 'Taxa de Operação',
        text: [
          'Quaisquer despesa e impostos que incidem sobre a sua operação, calculado sobre o valor total dos vencimentos. Valor sugerido: 0% - 1%'
        ],
        name: 'operationTax',
        required: true,
        align: 'right',
        type: 'percentage'
      },
      {
        label: 'Taxa de análise',
        text: [
          'Custo relacionado a sua análise de crédito para cada título. Incide sobre o valor total dos vencimentos. Valor sugerido: Até R$30,00'
        ],
        name: 'analysisTax',
        required: true,
        align: 'right',
        type: 'currency'
      }
    ]
  }

  return <SecondaryOnePageCrud formData={formData} />
}

export default WithHeader()(CompanyTaxes)
