/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAuthState } from '../../types/auth'

interface AuthActions {
  type:
    | 'LOGIN'
    | 'LOGIN_SUCCESS'
    | 'LOGIN_ERROR'
    | 'FORGOT_PASSWORD'
    | 'FORGOT_PASSWORD_SUCCESS'
    | 'FORGOT_PASSWORD_ERROR'
    | 'SAVE_TEMPORARY_USER_INFO'
    | 'NEW_PASSWORD'
    | 'NEW_PASSWORD_SUCCESS'
    | 'NEW_PASSWORD_ERROR'
    | 'RESET_PASSWORD'
    | 'RESET_PASSWORD_SUCCESS'
    | 'RESET_PASSWORD_ERROR'
    | 'RESEND_CREATE_USER_EMAIL'
    | 'RESEND_CREATE_USER_EMAIL_SUCCESS'
    | 'RESEND_CREATE_USER_EMAIL_ERROR'
    | 'LOGOUT'
    | 'LOGOUT_SUCCESS'
    | 'LOGOUT_ERROR'

  payload?: any
}

const actionHandler = (payload: any): { [key: string]: Partial<IAuthState> } => ({
  LOGIN: {
    isLoggingIn: true
  },
  LOGIN_SUCCESS: {
    isLoggingIn: false
  },
  LOGIN_ERROR: {
    isLoggingIn: false,
    authError: payload
  },
  FORGOT_PASSWORD: {
    isSendingEmailForgotPassword: true
  },
  FORGOT_PASSWORD_SUCCESS: {
    isSendingEmailForgotPassword: false
  },
  FORGOT_PASSWORD_ERROR: {
    isSendingEmailForgotPassword: false,
    authError: payload
  },
  SAVE_TEMPORARY_USER_INFO: {
    temporaryUser: payload,
    isLoggingIn: false
  },
  NEW_PASSWORD: {
    isSavingNewPassword: true
  },
  NEW_PASSWORD_SUCCESS: {
    isSavingNewPassword: false
  },
  NEW_PASSWORD_ERROR: {
    isSavingNewPassword: true,
    authError: payload
  },
  RESET_PASSWORD: {
    isResetingPassword: true
  },
  RESET_PASSWORD_SUCCESS: {
    resetSuccess: true,
    isResetingPassword: false
  },
  RESET_PASSWORD_ERROR: {
    isResetingPassword: false,
    authError: payload
  },
  RESEND_CREATE_USER_EMAIL: {
    isResetingCreateUserEmail: true
  },
  RESEND_CREATE_USER_EMAIL_SUCCESS: {
    isResetingCreateUserEmail: false,
    isResentCreateUserEmail: true
  },
  RESEND_CREATE_USER_EMAIL_ERROR: {
    isResetingCreateUserEmail: false,
    isResentCreateUserEmail: false,
    authError: payload
  },
  LOGOUT: {
    isLoggingOut: true
  },
  LOGOUT_SUCCESS: {
    isLoggingOut: false
  },
  LOGOUT_ERROR: {
    isLoggingOut: false
  }
})

const AuthReducer = (state: IAuthState, action: AuthActions): IAuthState => {
  const updatedProperties = actionHandler(action.payload)[action.type]
  return {
    ...state,
    ...updatedProperties
  }
}

export default AuthReducer
