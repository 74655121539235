import { useContext, useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../hooks/useAuth'
import { CompanyContext } from '../../../states/company/CompanyContext'
import { translateGroup } from '../../../utils/auth'
import PrimaryCard from '../../cards/PrimaryCard'
import PrimaryTab from '../../tabs/PrimaryTab'
import CompanyContracts from '../CompanyContracts'

interface IProps {
  className?: string
  children: ReactNode
}

const DefaultRegisterCompanyPage: React.FC<IProps> = ({ className, children }: IProps) => {
  const { groups } = useAuth()
  const { getContractsByCompanyId, contracts, defaultCompany } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getContractsByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getContractsByCompanyId])

  useEffect(() => {
    const awaitingSigners = contracts.find((contract) => contract.status === 'Aguardando Signatário')

    if (defaultCompany?.businessId && awaitingSigners) {
      const func = setInterval(() => getContractsByCompanyId(defaultCompany?.businessId), 10 * 1000)

      return () => clearInterval(func)
    }
  }, [defaultCompany?.businessId, getContractsByCompanyId, contracts])

  const group = groups?.[0]

  const tabsItems = [
    {
      group: 'Customer',
      items: [
        { title: 'Dados da empresa', url: '/register-company', id: 0, testId: 'tabs-first-tab' },
        { title: 'Sócios / Procuradores', url: '/company-partners', id: 1 },
        { title: 'Informações comerciais', url: '/company-customers-and-suppliers', id: 2 },
        { title: 'Dados bancários', url: '/company-bank-account-info', id: 3 },
        { title: 'Documentos', url: '/company-documents', id: 4 }
      ]
    },
    {
      group: 'Buyer',
      items: [
        { title: 'Dados comerciais', url: '/register-company', id: 0 },
        { title: 'Representantes', url: '/company-partners', id: 1 },
        { title: 'Testemunhas', url: '/company-witnesses', id: 2 },
        { title: 'Taxas', url: '/company-taxes', id: 3 },
        { title: 'Usuários', url: '/company-users', id: 4 },
        { title: 'Dados bancários', url: '/company-bank-account-info', id: 5 },
        { title: 'Documentos', url: '/company-documents', id: 6 }
      ]
    }
  ]

  const tabs = tabsItems?.find((item) => item?.group === translateGroup(group))?.items

  return (
    <div className={className} data-testid="default-register-company-page-test">
      {contracts?.length > 0 && <CompanyContracts data-testid="contracts-test" contracts={contracts} />}
      <PrimaryCard>
        {tabs && (
          <>
            <PrimaryTab items={tabs} />
            {children}
          </>
        )}
      </PrimaryCard>
    </div>
  )
}

export default styled(DefaultRegisterCompanyPage)`
  background: ${({ theme: { colors } }) => colors?.secondaryBackground};
  padding: 24px;
  width: calc(100% - 48px);
  min-height: calc(100vh - 152px);

  h2 {
    font-size: 32px;
    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    margin-bottom: 24px;
    user-select: none;
  }
`
