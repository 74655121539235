import { ReactNode, useCallback, useContext, useMemo, useReducer } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  CreateCompanyOutput,
  CreateCompanyProposalInput,
  CreateUserInput,
  useAnswerQuizMutation,
  useCreateCompanyProposalMutation,
  useCreateUserMutation,
  useGetCompanyProposalByIdLazyQuery
} from '../../../service/graphql/schema'
import { OriginContext } from '../../../states/origin/OriginContext'
import { IAPIError } from '../../../types/common'
import { removeAllLettersAndSpecialCharacters, removeCurrencyFromString } from '../../../utils/string'
import { IAnswerQuizInput } from '../types'
import { initialState, OnboardingContext } from './OnboardingContext'
import OnboardingReducer from './OnboardingReducer'
import Onboarding from '../services/index'
import { useAuth } from '../../../hooks/useAuth'
interface IProps {
  children: ReactNode
}

const OnboardingState: React.FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(OnboardingReducer, initialState)
  const { originInfos } = useContext(OriginContext)
  const navigate = useNavigate()
  const onboarding = useMemo(() => new Onboarding(), [])
  const { groups } = useAuth()
  const [searchParams] = useSearchParams()

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: async ({ createUser }) => {
      dispatch({
        type: 'CREATE_USER_SUCCESS',
        payload: createUser
      })

      if (createUser?.companyId) {
        return navigate(`/onboarding/created-user?companyId=${createUser?.companyId}`, {
          state: {
            email: createUser?.email
          }
        })
      }

      navigate('/onboarding/created-user', {
        state: {
          email: createUser?.email
        }
      })
    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({
          type: 'CREATE_USER_ERROR',
          payload: {
            location: 'createUser',
            message: error?.message
          }
        })
        return navigate('/onboarding/denied-user')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const extensions: IAPIError = error?.graphQLErrors[0]?.extensions as any
      const {
        response: { status }
      } = extensions
      dispatch({
        type: 'CREATE_USER_ERROR',
        payload: {
          location: 'createUser',
          message: extensions?.response?.body?.message
        }
      })

      if (status === 409) {
        return navigate('/onboarding/user-already-exists')
      }
      if (status === 422 || status === 406) {
        return navigate('/onboarding/denied-user')
      }
      return navigate('/onboarding/denied-user')
    }
  })
  const createUser = useCallback(
    (params: Omit<CreateUserInput, 'whiteLabel'>) => {
      dispatch({
        type: 'CREATE_USER'
      })
      createUserMutation({
        variables: {
          params: {
            ...params,
            whiteLabel: originInfos?.storeId || '',
            documentNumber: removeAllLettersAndSpecialCharacters(params.documentNumber),
            mobile: removeAllLettersAndSpecialCharacters(params.mobile)
          }
        }
      })
    },
    [createUserMutation, originInfos?.storeId]
  )

  const [createCompanyProposalMutation] = useCreateCompanyProposalMutation({
    onCompleted: async ({ createCompanyProposal }) => {
      dispatch({
        type: 'CREATE_COMPANY_PROPOSAL_SUCCESS',
        payload: createCompanyProposal
      })

      navigate(`/onboarding/analyzing-company/${createCompanyProposal?.id}`)
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const extensions: IAPIError = error?.graphQLErrors[0]?.extensions as any
      dispatch({
        type: 'CREATE_COMPANY_PROPOSAL_ERROR',
        payload: {
          location: 'createCompanyProposal',
          message: extensions?.response?.body?.message
        }
      })
      navigate(`/onboarding/onboarding-error`)
    }
  })
  const createCompanyProposal = useCallback(
    (params: Omit<CreateCompanyProposalInput, 'whiteLabel'>) => {
      const partnerId = searchParams.get('partnerId')
      dispatch({
        type: 'CREATE_COMPANY_PROPOSAL'
      })
      createCompanyProposalMutation({
        variables: {
          params: {
            ...params,
            whiteLabel: originInfos?.storeId as string,
            partnerId
          }
        }
      })
    },
    [createCompanyProposalMutation, originInfos?.storeId, searchParams]
  )

  const [answerQuizMutation] = useAnswerQuizMutation({
    onCompleted: async ({ answerQuiz }) => {
      dispatch({
        type: 'ANSWER_QUIZ_SUCCESS',
        payload: answerQuiz
      })

      if (answerQuiz?.status === 'DENIED') {
        return navigate('/onboarding/denied-company')
      }

      if (answerQuiz?.status === 'NO_OFFER') {
        return navigate('/sem-oferta')
      }

      navigate(`/onboarding/analyzed-company?companyId=${answerQuiz.companyId}`, {
        state: {
          buyers: answerQuiz?.buyers,
          averageTax: answerQuiz?.averageTax,
          minTax: answerQuiz?.minTax
        }
      })
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const extensions: IAPIError = error?.graphQLErrors[0]?.extensions as any

      dispatch({
        type: 'ANSWER_QUIZ_ERROR',
        payload: {
          location: 'answerQuiz',
          message: extensions?.response?.body?.message
        }
      })

      navigate('/onboarding/onboarding-error')
    }
  })

  const answerQuiz = useCallback(
    async (params: IAnswerQuizInput) => {
      dispatch({
        type: 'ANSWER_QUIZ'
      })

      const result = await answerQuizMutation({
        variables: {
          params: {
            ...params,
            hasReceipt: params?.hasReceipt === 'true',
            howMuchByMonth: removeCurrencyFromString(params?.howMuchByMonth)
          }
        }
      })
      if (result.errors) {
        dispatch({
          type: 'ANSWER_QUIZ_ERROR',
          payload: {
            location: 'answerQuiz'
          }
        })
        return navigate('/onboarding/onboarding-error')
      } else {
        dispatch({
          type: 'ANSWER_QUIZ_SUCCESS'
        })
      }
    },
    [answerQuizMutation, navigate]
  )

  const [getCompanyProposalByIdQuery] = useGetCompanyProposalByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async ({ getCompanyProposalById: { status, dataStatus, documentNumber, id } }) => {
      dispatch({
        type: 'GET_COMPANY_PROPOSAL_BY_ID_SUCCESS'
      })

      try {
        if (status === 'created') {
          dispatch({
            type: 'GET_COMPANY_PROPOSAL_BY_ID_SUCCESS'
          })

          return
        }

        const createdCompany = (await onboarding.createCompany(id)) as CreateCompanyOutput

        if (status === 'no_offer') {
          dispatch({
            type: 'GET_COMPANY_PROPOSAL_BY_ID_SUCCESS'
          })

          return navigate('/sem-oferta')
        }

        if (status === 'denied') {
          dispatch({
            type: 'GET_COMPANY_PROPOSAL_BY_ID_ERROR'
          })

          return navigate('/onboarding/denied-company')
        }

        if (dataStatus === 'COMPLETED') {
          dispatch({
            type: 'GET_COMPANY_PROPOSAL_BY_ID_SUCCESS'
          })

          return navigate(`/onboarding/pre-approved-company?companyId=${createdCompany.id}`)
        }

        dispatch({
          type: 'GET_COMPANY_PROPOSAL_BY_ID_SUCCESS'
        })

        return navigate(`/onboarding/quiz/step/1/${documentNumber}?companyId=${createdCompany?.id}`)
      } catch (err) {
        if (!err) return navigate('/onboarding/onboarding-error')

        const { error } = err as {
          error: string
        }

        dispatch({
          type: 'GET_COMPANY_PROPOSAL_BY_ID_ERROR',
          payload: {
            location: 'getCompanyProposalById',
            message: error
          }
        })

        if (error === 'User Conflict') {
          return navigate('/error-page', {
            state: {
              title: 'Nós já nos conhecemos!',
              text: ['Você já cadastrou essa empresa anteriormente.'],
              action: 'O que acha de tentar outro CNPJ?',
              redirect: groups?.length > 0
            }
          })
        }

        if (error === 'Conflict') {
          return navigate('/onboarding/existing-company')
        }

        navigate('/onboarding/onboarding-error')
      }
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const extensions: IAPIError = error?.graphQLErrors[0]?.extensions as any

      dispatch({
        type: 'GET_COMPANY_PROPOSAL_BY_ID_ERROR',
        payload: {
          location: 'getCompanyProposalById',
          message: extensions?.response?.body?.message
        }
      })
      return navigate('/onboarding/onboarding-error')
    }
  })

  const getCompanyProposalById = useCallback(
    (companyProposalId: string) => {
      dispatch({
        type: 'GET_COMPANY_PROPOSAL_BY_ID'
      })

      getCompanyProposalByIdQuery({
        variables: {
          companyProposalId
        }
      })
    },
    [getCompanyProposalByIdQuery]
  )

  const contextValues = {
    ...state,
    createUser,
    createCompanyProposal,
    answerQuiz,
    getCompanyProposalById
  }
  return <OnboardingContext.Provider value={contextValues}>{children}</OnboardingContext.Provider>
}
export default OnboardingState
