import { RadioGroup } from '@mui/material'
import { useEffect, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { OnboardingContext } from '../../apps/onboarding/states/OnboardingContext'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import CreateCompanyPageWrapper from '../../components/companies/CreateCompanyPageWrapper'
import ProfileCard from '../../components/companies/ProfileCard'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CreateCompanyProfile: React.FC<IProps> = ({ className }: IProps) => {
  const { isCreatingCompanyProposal } = useContext(OnboardingContext)
  const { control, getValues } = useFormContext()
  const navigate = useNavigate()

  const documentNumber = getValues('documentNumber')

  useEffect(() => {
    if (!documentNumber) {
      navigate('/create-company/document-number')
    }
  }, [documentNumber, navigate])

  return (
    <CreateCompanyPageWrapper title="Qual é o perfil da sua empresa?" className={className}>
      <Controller
        control={control}
        name="profile"
        render={({ field: { onChange, value: result } }) => (
          <RadioGroup
            defaultValue="C"
            name="profile"
            value={result}
            onChange={({ target: { value } }) => onChange(value)}
          >
            <section className="create-company-profile__wrapper">
              <ProfileCard
                checked={result === 'C'}
                value="C"
                label="Fornecedor"
                explanation="Antecipar pagamentos"
                dataTestid="provider-card-test"
              />
              <ProfileCard checked={result === 'F'} value="F" label="Financiador" explanation="Comprar recebíveis" />
              <ProfileCard checked={result === 'P'} value="P" label="Parceiro" explanation="Conectar empresas" />
            </section>
          </RadioGroup>
        )}
      />
      <div className="create-company-profile__button___wrapper">
        <div className="create-company-profile__button___container">
          <PrimaryButton
            isLoading={isCreatingCompanyProposal}
            type="submit"
            data-testid="create-company-profile-button"
          >
            Continuar
          </PrimaryButton>
        </div>
      </div>
    </CreateCompanyPageWrapper>
  )
}

export default WithHeader()(styled(CreateCompanyProfile)`
  .create-company-profile__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    gap: 24px;

    .create-company-profile__card {
      padding: 12px 24px 24px 24px;
      border: 1px solid ${({ theme }) => theme?.colors?.gray?.lighter};
      border-radius: 10px;
      width: 180px;

      span {
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.tertiaryFont};
      }
    }
  }

  .create-company-profile__button___wrapper {
    margin-top: 24px;
    width: 228px;

    .create-company-profile__button___container {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .create-company-profile__wrapper {
      gap: 18px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .create-company-profile__button___wrapper {
      margin-top: 18px;
      width: 100%;
      display: flex;
      justify-content: center;

      .create-company-profile__button___container {
        width: 100%;
      }
    }
  }
`)
