import LoginForm from '../../components/login/LoginForm'
import NormalFlowPageWrapper from '../../components/auth/NormalFlowPageWrapper'

const Login: React.FC = () => {
  return (
    <NormalFlowPageWrapper>
      <LoginForm />
    </NormalFlowPageWrapper>
  )
}

export default Login
