import { useContext } from 'react'
import styled from 'styled-components'
import { OriginContext } from '../../../../states/origin/OriginContext'

interface IProps {
  className?: string
}

const Header: React.FC<IProps> = ({ className }: IProps) => {
  const { originInfos } = useContext(OriginContext)
  return (
    <header className={className}>
      <img alt={`${originInfos?.storeName} Logo`} src={originInfos?.logoMain} data-testid="header-image-test" />
    </header>
  )
}

export default styled(Header)`
  margin-bottom: 48px;
  height: 64px;

  img {
    max-height: 64px;
  }
`
