import { useContext } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import Input, { IInputs } from '../../components/crud/PrimaryFileSplitCrud/Input'
import { CompanyContext } from '../../states/company/CompanyContext'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CompanyRevenues: React.FC<IProps> = ({ className }: IProps) => {
  const { defaultCompany, isGettingCompanyRevenue, isSendingCompanyRevenue } = useContext(CompanyContext)
  const isDisabled =
    defaultCompany?.statusId === '2' || defaultCompany?.statusId === '11' || defaultCompany?.statusId === '5'

  const monthsInputs: IInputs = {
    basePath: '/company-revenues',
    presentation: {
      title: 'Agora, o faturamento da sua empresa.',
      description: ['Aqui você deve informar o faturamento dos últimos 12 meses da sua empresa.']
    },
    isLoading: isGettingCompanyRevenue || isSendingCompanyRevenue,
    isSubmitting: isSendingCompanyRevenue,
    isDisabled
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <Input data={monthsInputs} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyRevenues)
