import Box from '@mui/material/Box'
import List from '@mui/material/List'
import styled from 'styled-components'
import { translateGroup } from '../../../../../../utils/auth'
import { useAuth } from '../../../../../../hooks/useAuth'
import MenuItem from '../MenuItem'
import { CircularProgress } from '@mui/material'
import useTabs from '../../Tabs'

interface IMenuListProps {
  className?: string
  handleClick: (path: string, redirectToV1?: boolean, blocked?: boolean) => void
  selectedTab?: string
}

const MenuList = ({ className, handleClick, selectedTab }: IMenuListProps) => {
  const { groups, loading } = useAuth()
  const group = translateGroup(groups?.[0])
  const [customerTabs, buyerTabs] = useTabs()

  const items = group === 'Buyer' ? buyerTabs : customerTabs

  const filteredMenuItems = items.filter((menuItem) => menuItem.label !== selectedTab)

  return (
    <Box data-testid="menu-list-test" className={className}>
      {loading ? (
        <Box data-testid="menu-list-loading-test" className="loading-content">
          <CircularProgress className="loading-content" data-testid="test-circular-progress" />
        </Box>
      ) : (
        <List data-testid="list-test">
          {filteredMenuItems.map((item, index) => (
            <div
              data-testid="link-test"
              onClick={() => handleClick(item?.path as string, item?.redirectToV1, item.blockedTab)}
              key={item?.path as string}
              className={item?.blockedTab ? 'blocked' : 'notBlocked'}
            >
              <MenuItem text={item.label} isLastItem={index === items.length - 1} />
            </div>
          ))}
        </List>
      )}
    </Box>
  )
}

export default styled(MenuList)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};

  .loading-content {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }

  .blocked {
    color: ${({ theme }) => theme?.colors?.disabled};
  }
  .notBlocked {
    color: ${({ theme }) => theme?.colors?.quarternaryFont};
  }

  ul {
    padding: 0px;

    li {
      position: relative;
      text-align: center;

      &:hover {
        background-color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.lighter?.primary};
      }

      span {
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        padding: 16px;
      }

      &:first-child {
      }

      &::before {
        background-color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.lighter?.primary};
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 16px;
        position: absolute;
        right: 16px;
      }

      &.last-item::before {
        display: none;
      }
    }
  }
`
