import styled from 'styled-components'
import { ellipsisInTheEndOfString } from '../../../utils/string'
import notFav from '../../../assets/icons/not-fav.svg'
import fav from '../../../assets/icons/fav.svg'
import Trash from '../../../assets/icons/Trash'
import { useNavigate } from 'react-router-dom'
import Edit from '../../../assets/icons/Edit'
interface IProps {
  className?: string
  tag?: string
  tagColor?: string
  titles: string[]
  infos: string[]
  isUnclickable?: boolean
  imageSrc?: string
  isDisabled?: boolean
  isChecked?: boolean
  checkFunc?: () => void
  editData: {
    accountAgency: string
    accountDigit?: string
    accountNumber: string
    bankCode?: string
    bankName: string
    id: string
    isDefault: boolean
    ispb?: string
  }
}

const RegisteredAssigneeCardDelete: React.FC<IProps> = ({
  className,
  tag,
  titles,
  infos,
  imageSrc,
  isDisabled,
  isChecked,
  checkFunc,
  editData
}: IProps) => {
  const navigate = useNavigate()

  return (
    <>
      <button className={className} data-testid="registered-assignee-card-test" disabled={isDisabled}>
        <div className="registered-assignee-card__header">
          {tag && (
            <div className="registered-assignee-card__status--wrapper" data-testid="registered-assignee-card-tag-test">
              <div className="registered-assignee-card__status___dot" />
              <p className="registered-assignee-card__status___tag">{tag?.toUpperCase()}</p>
            </div>
          )}
          {isChecked ? (
            <div data-testid="default-account-test" className="registered-assignee-card__fav fav">
              <p>Conta padrão</p>
              <img src={fav} />
            </div>
          ) : (
            <div
              data-testid="default-account-test"
              className="registered-assignee-card__fav not-fav"
              onClick={checkFunc}
            >
              <p>Conta padrão</p>
              <img src={notFav} />
            </div>
          )}
        </div>
        <div className="registered-assignee-card__left--wrapper-container">
          <div className="registered-assignee-card__left--wrapper">
            <div className="registered-assignee-card__left--infos">
              {imageSrc && (
                <div className="registered-assignee-card__image--wrapper">
                  <img className="registered-assignee-card__image" src={imageSrc} />
                </div>
              )}
              <div className="registered-assignee-card__texts--wrapper">
                {titles?.map((title) => (
                  <h4 key={title} data-testid="registered-assignee-card-title-test">
                    {ellipsisInTheEndOfString(title, 25)}
                  </h4>
                ))}
                {infos?.map((info, i) => (
                  <p key={i} data-testid="registered-assignee-card-info-test">
                    {info}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              navigate(`/company-bank-account-info/form`, { state: editData })
            }}
            className="registered-assignee-card__right-side"
          >
            <div className="registered-assignee-card__box--divisor"></div>
            <Edit></Edit>
          </div>
        </div>
      </button>
    </>
  )
}

export default styled(RegisteredAssigneeCardDelete)`
  border: 1px solid ${({ theme }) => theme?.colors?.borderColor};
  border-radius: 8px;
  padding: 16px;
  width: 500px;
  background: transparent;
  align-items: stretch;

  .registered-assignee-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .registered-assignee-card__status--wrapper {
      display: flex;
      letter-spacing: 0.36px;
      display: flex;
      gap: 8px;
      align-items: center;

      .registered-assignee-card__status___dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
        background: ${({ tagColor, theme }) => {
          if (tagColor) {
            return tagColor
          } else {
            return theme?.colors?.primary
          }
        }};
      }

      .registered-assignee-card__status___tag {
        font-size: 12px;
        font-weight: bold;
        color: ${({ tagColor, theme }) => {
          if (tagColor) {
            return tagColor
          } else {
            return theme?.colors?.primary
          }
        }};
      }
    }
  }

  .registered-assignee-card__right-side {
    display: flex;
    gap: 16px;
    align-items: center;

    .registered-assignee-card__box--divisor {
      width: 1px;
      height: 100%;
      background: ${({ theme }) => theme?.colors?.borderColor};
    }

    ${Edit} {
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme?.colors?.primary};
      }
    }
  }

  .registered-assignee-card__fav {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-size: 14px;
    }
  }
  .fav {
    p {
      color: ${({ theme: { styleGuide } }) => styleGuide?.support?.green};
      font-weight: 600;
    }
  }

  .not-fav {
    cursor: pointer;
  }

  .registered-assignee-card__left--wrapper-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .registered-assignee-card__left--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;
      gap: 8px;

      .registered-assignee-card__left--infos {
        display: flex;
        gap: 16px;

        .registered-assignee-card__image--wrapper {
          display: flex;
          height: 100%;

          .registered-assignee-card__image {
            height: 48px;
            width: 48px;
          }
        }

        .registered-assignee-card__texts--wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;

          h4 {
            font-size: 14px;
            font-weight: bold;
            color: #383838;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .tag__name {
    font-size: 12px;
    margin-left: 8px;
    margin-top: -1px;
    font-weight: bold;
  }

  ${Trash} {
    path {
      fill: ${({ theme }) => theme?.colors?.disabled};
    }
  }
`
