import styled from 'styled-components'

export interface ICompanyItemProps {
  companyName: string
  companyCnpj: string
  isLastItem: boolean
  className: string
}

const CompanyItem = ({ companyName, companyCnpj, isLastItem, className }: ICompanyItemProps) => {
  return (
    <div className={className}>
      <li data-testid="company-item-test" className={isLastItem ? 'last-item' : ''}>
        <span data-testid="company-item-name-test" className="company-name">
          {companyName}
        </span>
        <br />
        <span data-testid="company-item-cnpj-test" className="company-cnpj">
          {companyCnpj}
        </span>
      </li>
    </div>
  )
}

export default styled(CompanyItem)`
  cursor: pointer;

  .company-name {
    color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
    font-size: 14px;
  }

  .company-cnpj {
    color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
    font-size: 10px;
    text-decoration: none !important;
  }
`
