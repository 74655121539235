import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyContext } from '../../states/company/CompanyContext'
import * as validations from 'validations-br'
import CreateUpdateDelete, { IFormData } from '../../components/crud/SplitCrud/CreateUpdateDelete'
import WithHeader from '../../hocs/WithHeader'

const CompanyUsersForm: React.FC = () => {
  const {
    defaultCompany,
    isGettingDefaultCompany,
    isGettingCompanyUsersByCompanyId,
    getCompanyUsersByCompanyId,
    companyUsers,
    registerCompanyUser,
    isRegistratingCompanyUser,
    updateCompanyUser,
    isUpdatingCompanyUser,
    error
  } = useContext(CompanyContext)

  const { id } = useParams()

  useEffect(() => {
    if (defaultCompany?.businessId && id) {
      getCompanyUsersByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, id, getCompanyUsersByCompanyId])

  const showStatusRow = () => {
    if (id) {
      return {
        rows: [
          {
            inputs: [
              {
                name: 'status',
                required: true,
                type: 'radio',
                options: [
                  {
                    name: 'Ativo',
                    value: 'ACTIVATED'
                  },
                  {
                    name: 'Inativo',
                    value: 'DISABLED'
                  }
                ]
              }
            ],
            grid: [1]
          }
        ]
      }
    }

    return { rows: [] }
  }

  const formData: IFormData = {
    breadCrumb: 'Dados cadastrais / Usuários',
    isLoading: isGettingDefaultCompany || isGettingCompanyUsersByCompanyId,
    title: id ? 'Editar usuário' : 'Adicionar novo usuário',
    subtitle: id
      ? 'Edite as informações, altere o status ou exclua o usuário selecionado.'
      : 'Preencha os campos abaixo para adicionar um novo usuário.',
    currentData: companyUsers?.find((r) => r?.id === id),
    backTo: '/company-users',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveData: (data: any) =>
      registerCompanyUser({
        companyId: defaultCompany?.businessId,
        ...data
      }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editData: (data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { lastAccess, ...rest } = data

      updateCompanyUser({
        companyId: defaultCompany?.businessId,
        ...rest
      })
    },
    isSavingData: isRegistratingCompanyUser,
    isEditingData: isUpdatingCompanyUser,
    groups: [
      showStatusRow(),
      {
        rows: [
          {
            inputs: [
              {
                label: 'Nome',
                name: 'fullName',
                required: true
              },
              {
                label: 'CPF',
                name: 'documentNumber',
                required: true,
                mask: '###.###.###-##',
                test: {
                  type: 'is-cpf',
                  message: 'Confira se seu CPF está correto',
                  callback: (value?: string) => !!(value && validations.validateCPF(value))
                }
              }
            ],
            grid: [3, 1]
          }
        ]
      },
      {
        rows: [
          {
            inputs: [
              {
                label: 'E-mail',
                name: 'email',
                required: true,
                disabled: !!id,
                type: 'email',
                error: error?.location === 'registerCompanyUser' ? 'Usuário já cadastrado na plataforma' : ''
              },

              {
                label: 'Celular',
                name: 'mobile',
                required: true,
                test: {
                  type: 'is-phone',
                  message: 'Confira se seu celular está correto',
                  callback: (value?: string) => !!(value && validations.validatePhone(value))
                },
                mask: '(##) #####-####'
              }
            ],
            grid: [1, 1]
          }
        ]
      }
    ]
  }

  return <CreateUpdateDelete formData={formData} />
}

export default WithHeader()(CompanyUsersForm)
