/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { GRAPHQL_ENDPOINT, WEB_SOCKET_ENDPOINT } from '../utils/envs'
import { setContext } from '@apollo/client/link/context'
import ApolloLinkTimeout from 'apollo-link-timeout'
import Auth from '../service/auth'

const subClient = new SubscriptionClient(WEB_SOCKET_ENDPOINT, { lazy: true, reconnect: true }, null, [])
const wsLink = new WebSocketLink(subClient) as any
const httpLink = new HttpLink({
  uri: (operation) => `${GRAPHQL_ENDPOINT}?${operation.operationName}`
})

const timeoutLink = new ApolloLinkTimeout(120000)

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const authLink = setContext(async (_, { headers }) => {
  const auth = new Auth()
  let token

  try {
    const { accessTokenGraphql } = await auth.currentAuthenticatedUser()
    token = accessTokenGraphql
  } catch {
    token = undefined
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Basic ${token}` : undefined
    }
  }
})

const exportLink = ApolloLink.from([timeoutLink, authLink.concat(link)])

export const client = new ApolloClient({
  link: exportLink,
  cache: new InMemoryCache()
})
