import styled from 'styled-components'
import PrimaryButton from '../../../buttons/PrimaryButton'
import CompanyFormWrapper from '../../../companies/CompanyFormWrapper'
import { ChangeEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, IconButton } from '@mui/material'
import SecondaryButton from '../../../buttons/SecondaryButton'
import CrudDeleteModal from '../../CrudDeleteModal'
import CompanyWelcomeForm from '../../../companies/CompanyWelcomeForm'
import { WatchLaterOutlined } from '@mui/icons-material'
import { ellipsisInTheEndOfString } from '../../../../utils/string'
import Upload from '../../../../assets/icons/Upload'
import Download from '../../../../assets/icons/Download'
import Trash from '../../../../assets/icons/Trash'

export interface IDocument {
  id: string
  fileName: string
  downloadUrl: string
  createdAt: string
}

export interface ICreateDeleteData {
  breadCrumb: string
  isLoading?: boolean
  title: string
  backTo: string
  formDisabled?: boolean
  documentTypes: {
    title?: string
    description: string[]
    link?: {
      href: string
      text: string
    }
  }[]
  documentList: IDocument[]
  deleteDocument: (id: string) => void
  isDeletingDocument: boolean
  uploadDocument: (file: File) => void
}

interface IProps {
  className?: string
  formData: ICreateDeleteData
}

const CreateDelete: React.FC<IProps> = ({ className, formData }: IProps) => {
  const uploadInputRef = useRef<HTMLInputElement | null>(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const navigate = useNavigate()

  const onDownload = (downloadUrl: string, fileName: string) => {
    const link = document.createElement('a')
    link.download = fileName
    link.href = downloadUrl
    link.click()
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      formData.uploadDocument(e?.target?.files[0])
    }
  }

  return (
    <CompanyFormWrapper className={className} title={formData?.breadCrumb}>
      {formData?.isLoading ? (
        <div className="create-delete__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <div className="create-delete__wrapper">
          <div className="create-delete__header--wrapper">
            <h3 className="create-delete__title" data-testid="create-update-delete-form-title">
              {formData?.title}
            </h3>
          </div>
          <div className="create-delete__document-types__wrapper">
            {formData?.documentTypes?.map(({ title, description, link }, index) => (
              <CompanyWelcomeForm key={index} header={title && `${title}:`} descriptionText={description} link={link} />
            ))}
          </div>
          <div className="create-delete__documents-list___wrapper">
            {formData?.documentList?.map((document) => (
              <div key={document?.id} className="create-delete__documents-list___document-infos">
                <p>
                  <WatchLaterOutlined /> {document?.createdAt}
                </p>
                <h5>{ellipsisInTheEndOfString(document?.fileName, 25)}</h5>
                <div className="create-delete__documents-list___document-infos____buttons--wrapper">
                  <IconButton onClick={() => onDownload(document?.downloadUrl, document?.fileName)}>
                    <Download />
                  </IconButton>
                  <IconButton onClick={() => setDeleteModal(true)} disabled={!!formData?.formDisabled}>
                    <div className="create-delete__documents-list___document-infos____buttons_____delete">
                      <Trash />
                    </div>
                  </IconButton>
                </div>
                <CrudDeleteModal
                  open={deleteModal}
                  onClose={() => setDeleteModal(false)}
                  submit={() => formData?.deleteDocument(document?.id)}
                  isLoading={!!formData?.isDeletingDocument}
                />
              </div>
            ))}
            <div className="create-delete__documents-list___button--new">
              <input id="raised-button-file" type="file" hidden ref={uploadInputRef} onChange={onChange} />
              <SecondaryButton
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                startIcon={<Upload />}
                disabled={!!formData?.formDisabled}
              >
                Enviar novo documento
              </SecondaryButton>
            </div>
          </div>
          <div className="create-delete__footer">
            <div className="create-delete__button--wrapper">
              <PrimaryButton
                data-testid="create-update-delete-form-button-cancel-test"
                variant="outlined"
                onClick={() => navigate(formData?.backTo)}
              >
                Fechar
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </CompanyFormWrapper>
  )
}

export default styled(CreateDelete)`
  .create-delete__loading {
    height: 536px;
    width: 784px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .create-delete__wrapper {
    width: 608px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .create-delete__header--wrapper {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .create-delete__title {
        font-size: 24px;
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      }
    }

    .create-delete__document-types__wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .create-delete__documents-list___wrapper {
      display: flex;
      flex-direction: column;
      margin: 32px 0 16px 0;
      gap: 32px;
      .create-delete__documents-list___button--new {
        margin-left: -4px;

        ${Upload} {
          height: 18px;
          width: 18px;
          path {
            fill: ${({ theme }) => theme?.colors?.primary};
          }
        }
      }

      .create-delete__documents-list___document-infos {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          font-size: 12px;
          color: ${({ theme: { colors } }) => colors?.tertiaryFont};
          display: flex;
          align-items: center;
          gap: 4px;
          svg {
            height: 16px;
            width: 16px;
          }
        }

        h5 {
          font-size: 16px;
          color: ${({ theme: { colors } }) => colors?.tertiaryFont};
          font-weight: bold;
        }

        .create-delete__documents-list___document-infos____buttons--wrapper {
          display: flex;
          margin-left: -8px;

          ${Download} {
            height: 18px;
            width: 18px;

            path {
              fill: ${({ theme: { colors } }) => colors?.primary};
            }
          }

          button:disabled,
          button[disabled] {
            svg,
            path {
              fill: ${({ theme }) => theme?.colors?.disabled} !important;
              color: ${({ theme }) => theme?.colors?.disabled} !important;
            }
          }

          .create-delete__documents-list___document-infos____buttons_____delete {
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            ${Trash} {
              height: 20px;
              width: 20px;
            }
          }

          svg {
            color: ${({ theme: { colors } }) => colors?.primary};
          }
        }
      }
    }

    .create-delete__footer {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .create-delete__button--wrapper {
        width: 184px;
      }
    }
  }

  @media (max-width: 900px) {
    .create-delete__loading {
      height: calc(100vh - 152px);
      width: calc(100vw - 96px);
    }
    .create-delete__wrapper {
      width: calc(100vw - 96px);
      .create-delete__footer {
        width: 100%;

        .create-delete__button--wrapper {
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }
`
