import { client } from '../../../config/Apollo'
import { CreateCompanyDocument, CreateCompanyOutput } from '../../../service/graphql/schema'
import { IAPIError } from '../../../types/common'

export default class Onboarding {
  async createCompany(companyProposalId: string): Promise<CreateCompanyOutput | { error: string; message: string }> {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: CreateCompanyDocument,
          variables: {
            companyProposalId
          }
        })
        .then(({ data: { createCompany: createCompanyData } }) => {
          return resolve(createCompanyData as CreateCompanyOutput)
        })
        .catch((error) => {
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          const extensions: IAPIError = error?.graphQLErrors[0]?.extensions as any
          return reject(extensions?.response?.body as { error: string; message: string })
        })
    })
  }
}
