import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'
import PrimaryButton from '../../../buttons/PrimaryButton'
import CompanyFormWrapper from '../../../companies/CompanyFormWrapper'
import Row from '../Row'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { createSchema } from '../../../../utils/input'
import SecondaryButton from '../../../buttons/SecondaryButton'
import CrudDeleteModal from '../../CrudDeleteModal'
import Trash from '../../../../assets/icons/Trash'

export interface IInput {
  label?: string
  name: string
  required?: boolean
  notRequired?: boolean
  type?: string
  placeholder?: string
  maxDate?: Date
  disabled?: boolean
  maxLength?: number
  test?: {
    type: string
    message: string
    callback: (value?: string) => boolean
  }
  mask?: string
  onlyNumber?: boolean
  options?: {
    name: string
    value: string | number
  }[]
  selectOptions?: Array<{
    value: number | string
    item: string
  }>
  selectType?: string
  selectDefaultValue?: number
  error?: string
  addressFields?: IAddressFields
}

export interface IRow {
  inputs: IInput[]
  grid: number[]
}

interface IGroup {
  title?: string
  rows: IRow[]
}

export interface IFormData {
  formDisabled?: boolean
  formWidth?: number
  breadCrumb: string
  isLoading: boolean
  title: string
  subtitle: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentData?: any
  backTo: string
  groups: IGroup[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveData: (data: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editData?: (data: any) => void
  deleteData?: () => void
  isSavingData: boolean
  isEditingData?: boolean
  isDeletingData?: boolean
}

interface IProps {
  className?: string
  formData: IFormData
  additionalButton?: JSX.Element
}

export interface IAddressFields {
  city: string
  streetName: string
  neighborhood: string
  state: string
}

const CreateUpdateDelete: React.FC<IProps> = ({ className, formData, additionalButton }: IProps) => {
  const [deleteModal, setDeleteModal] = useState(false)

  const navigate = useNavigate()

  const formattedInputs: IInput[] = formData?.groups?.reduce((acc: IInput[], group) => {
    acc.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...group.rows.reduce((ac: any[], o) => {
        ac.push(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...o.inputs.reduce((a: any[], t) => {
            a.push(t)

            return a
          }, [])
        )

        return ac
      }, [])
    )
    return acc
  }, [])

  const methods = useForm({
    resolver: yupResolver(createSchema(formattedInputs))
  })

  const { handleSubmit, reset } = methods

  useEffect(() => {
    if (formData?.currentData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...rest } = formData.currentData

      reset({ ...rest })
    }
  }, [formData?.currentData, reset])

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      if (formData?.currentData && formData?.editData) {
        return formData?.editData(data)
      }
      return formData?.saveData(data)
    },
    [formData]
  )

  return (
    <CompanyFormWrapper className={className} title={formData?.breadCrumb}>
      {formData?.deleteData && (
        <CrudDeleteModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          submit={formData?.deleteData}
          isLoading={!!formData?.isDeletingData}
        />
      )}
      {formData?.isLoading ? (
        <div className="create-update-delete-form__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form className="create-update-delete-form--wrapper" onSubmit={handleSubmit(onSubmit)}>
            <div className="create-update-delete-form__header--container">
              <div className="create-update-delete-form__header--wrapper">
                <h3 className="create-update-delete-form__title" data-testid="create-update-delete-form-title">
                  {formData?.title}
                </h3>
                <p data-testid="create-update-delete-form-subtitle" className="create-update-delete-form__subtitle">
                  {formData?.subtitle}
                </p>
              </div>
              {additionalButton}
            </div>
            {formData?.groups?.map((group, i) => (
              <Fragment key={i}>
                {group?.rows?.length > 0 && (
                  <div className="create-update-delete-form--input__container">
                    {group?.title && <p className="create-update-delete-form__container___title">{group?.title}</p>}
                    {group?.rows?.map((row, index) => (
                      <Row data={row} key={index} isDisabled={formData?.formDisabled} />
                    ))}
                  </div>
                )}
              </Fragment>
            ))}
            <div className="create-update-delete-form__footer">
              {formData?.deleteData && formData?.currentData ? (
                <div className="create-update-delete-form__delete-button--wrapper">
                  <SecondaryButton
                    color="error"
                    onClick={() => setDeleteModal(true)}
                    startIcon={<Trash />}
                    data-testid="create-update-delete-form-button-delete-test"
                    disabled={!!formData?.formDisabled}
                  >
                    Excluir
                  </SecondaryButton>
                </div>
              ) : (
                <div />
              )}
              <div className="create-update-delete-form__button--wrapper">
                <div className="create-update-delete-form__button--container close">
                  <PrimaryButton
                    data-testid="create-update-delete-form-button-cancel-test"
                    variant="outlined"
                    onClick={() => navigate(formData?.backTo)}
                  >
                    Cancelar
                  </PrimaryButton>
                </div>
                <PrimaryButton
                  isLoading={formData?.isSavingData || formData?.isEditingData}
                  className="create-update-delete-form__button--container"
                  type="submit"
                  data-testid="create-update-delete-form-button-submit-test"
                  disabled={(formData?.currentData && !formData?.editData) || !!formData?.formDisabled}
                >
                  Salvar
                </PrimaryButton>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </CompanyFormWrapper>
  )
}

export default styled(CreateUpdateDelete)`
  .create-update-delete-form__loading {
    height: 536px;
    width: 784px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .create-update-delete-form--wrapper {
    width: ${({ formData: { formWidth } }) => (formWidth ? `${formWidth}px` : '784px')};
    display: flex;
    flex-direction: column;
    gap: 40px;

    .create-update-delete-form__header--container {
      display: flex;
      justify-content: space-between;
    }

    .create-update-delete-form__header--wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 40px;

      .create-update-delete-form__title {
        font-size: 24px;
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      }

      .create-update-delete-form__subtitle {
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
      }
    }

    .create-update-delete-form--input__container {
      .create-update-delete-form__container___title {
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
      }
    }

    .create-update-delete-form__footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      .create-update-delete-form__delete-button--wrapper {
        display: flex;
        align-items: flex-end;
      }

      .create-update-delete-form__button--wrapper {
        display: grid;
        grid-template-columns: 184px 184px;
        grid-column-gap: 16px;
        grid-template-areas: 'left right';
        justify-content: flex-end;

        .create-update-delete-form__button--container {
          width: 100%;
          grid-area: right;

          &.close {
            grid-area: left;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .create-update-delete-form__loading {
      height: calc(100vh - 152px);
      width: calc(100vw - 96px);
    }
    .create-update-delete-form--wrapper {
      width: calc(100vw - 96px);
      gap: 0px;
      .create-update-delete-form__footer {
        grid-template-columns: 1fr;
        grid-row-gap: 16px;

        .create-update-delete-form__button--wrapper {
          margin-top: 40px;
          grid-template-columns: 1fr;
          grid-template-areas:
            'top'
            'bottom';
          grid-row-gap: 16px;

          .create-update-delete-form__button--container {
            grid-area: top;

            &.close {
              grid-area: bottom;
            }
          }
        }
      }
    }
  }
`
