export const setCookie = (name: string, value: string, expireDays: number): void => {
  const d = new Date()

  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  const domain = window.location.hostname.replace(/^[^.]+\./, '.')
  document.cookie = name + '=' + value + ';' + expires + ';path=/' + ';domain=' + domain
}

export const getCookie = (cname: string): string => {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (const value of ca) {
    let c = value

    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export const deleteCookie = (name: string) => {
  const domain = window.location.hostname.replace(/^[^.]+\./, '.')
  document.cookie = name + '=; Max-Age=-99999999; domain=' + domain + '; path=/'
}

export const deleteAllSessionCookies = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    if (name === 'ApplicationToken' || name === 'ApplicationTokenGraphql' || name.includes('CognitoIdentityService')) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  }
}
