import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: ReactNode
  title: string
  grid?: number
  errorMessage?: string
}

const QuizQuestion: React.FC<IProps> = ({ className, children, title, errorMessage }: IProps) => {
  return (
    <div className={className}>
      <h6 className="quiz-question___title">{title}</h6>
      <div>
        <div className="quiz-question__alternatives___wrapper">{children}</div>
        <div className="quiz-question__error-message">{errorMessage}</div>
      </div>
    </div>
  )
}

export default styled(QuizQuestion)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .quiz-question___title {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.48px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
  }

  .quiz-question__alternatives___wrapper {
    display: grid;
    width: 100%;
    grid-column-gap: 16px;
    grid-template-columns: ${({ grid }) => {
      if (!grid) {
        return '1fr 1fr'
      }

      let templateColumns = ''

      Array.from(Array(grid).keys()).forEach(() => {
        templateColumns = templateColumns + '1fr '
      })

      return templateColumns
    }};
  }
  .quiz-question__error-message {
    font-size: 12px;
    margin: '0 14px';
    color: ${({ theme }) => theme?.styleGuide?.support?.red};
  }

  @media (max-width: 400px) {
    .quiz-question__alternatives___wrapper {
      grid-column-gap: 0px;
    }
  }
`
