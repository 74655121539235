import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const ChevronRight: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" className={className} {...rest}>
      <path
        id="Icon_ionic-ios-arrow-back"
        data-name="Icon ionic-ios-arrow-back"
        d="M9.912,12.127l5.295-6.049a1.257,1.257,0,0,0,0-1.615.918.918,0,0,0-1.416,0l-6,6.854a1.262,1.262,0,0,0-.029,1.577l6.024,6.9a.919.919,0,0,0,1.416,0,1.257,1.257,0,0,0,0-1.615Z"
        transform="translate(15.5 20.129) rotate(180)"
      />
    </svg>
  )
}

export default styled(ChevronRight)`
  fill: ${({ theme: { colors } }) => colors?.primary};
`
