import { Button, ButtonProps } from '@mui/material'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps extends ButtonProps {
  className?: string
  children: ReactNode
}

const SecondaryButton: React.FC<IProps> = ({ className, children, ...rest }: IProps) => {
  return (
    <div className={className}>
      <Button variant="outlined" disableElevation {...rest}>
        {children}
      </Button>
    </div>
  )
}

export default styled(SecondaryButton)`
  .MuiButton-root {
    font-size: 10px;
    color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
    border: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
    border-radius: 16px;
    padding: 2px 8px;

    &:hover {
      color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
      border: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
    }
  }
`
