import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../states/company/CompanyContext'
import ReputationSkeleton from './CustomerReputationSkeleton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import Reputation from '../../../apps/common/assets/Reputation'

interface IProps {
  className?: string
  companyId: string
}

const CustomerReputation: React.FC<IProps> = ({ className, companyId }: IProps) => {
  const { getReputationByCompanyId, customerReputation, isGettingCustomerDataByCompanyId } = useContext(CompanyContext)

  useEffect(() => {
    if (companyId) {
      getReputationByCompanyId({ companyId: companyId })
    }
  }, [companyId, getReputationByCompanyId])

  return (
    <div className={className}>
      <div className="customer-reputation__container" data-testid="customer-profile-customer-reputation-test">
        {isGettingCustomerDataByCompanyId ? (
          <ReputationSkeleton />
        ) : (
          <div className="customer-reputation__wrapper">
            <h5 className="customer-reputation__main-title">Reputação do cedente</h5>
            {customerReputation ? (
              <div className="customer-reputation__inner-info-container">
                <div className="customer-reputation__inner-info">
                  <div className="customer-reputation__inner-info-left">
                    <p>{customerReputation?.totalOperation ?? 0}</p>
                    <p>Total de operações</p>
                  </div>
                  <div>
                    <div className="customer-reputation__inner-info-term">
                      <p>{Math.round(customerReputation.mediumTerm ?? 0)} </p>
                      <p>dias</p>
                    </div>
                    <p>Prazo médio</p>
                  </div>
                </div>
                <PrimaryButton
                  variant="outlined"
                  data-testid="metabase-button-test"
                  type="submit"
                  className="customer-reputation-button"
                  onClick={() => window.open(`/customer-reputation/${companyId}`, '_blank')}
                >
                  <p>Reputação completa</p> <Reputation />
                </PrimaryButton>
              </div>
            ) : (
              <div className="customer-reputation__without-reputation___wrapper">
                <p>Nenhum dado disponível</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default styled(CustomerReputation)`
  background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  border-radius: 8px;
  display: grid;
  grid-area: customer_reputation;
  width: 100%;

  .customer-reputation__container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .customer-reputation__wrapper {
      .customer-reputation__main-title {
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
      }

      .customer-reputation__inner-info-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .customer-reputation__inner-info {
          display: flex;
          justify-content: center;
          padding-bottom: 28px;

          .customer-reputation__inner-info-term {
            align-items: flex-end;
            display: flex;
            justify-content: center;

            p {
              font-size: 16px;
              font-weight: 700;
              padding: 0px 0px 12px 6px;
              color: ${({ theme: colors }) => colors?.colors?.buyer};
            }
          }

          p:first-child {
            color: ${({ theme: colors }) => colors?.colors?.buyer};
            font-weight: bold;
            font-size: 32px;
            padding: 16px 0px 8px;
          }

          p {
            color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
            font-size: 14px;
          }

          .customer-reputation__inner-info-left {
            padding-right: 50px;
          }
        }
      }

      ${PrimaryButton} {
        button {
          color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
          p {
            color: ${({ theme: colors }) => colors?.colors?.buyer};
            font-weight: bold;
          }
        }
      }

      ${Reputation} {
        cursor: pointer;
        max-height: 20px;
        max-width: 20px;
        padding: 2px 0px 0px 6px;

        path {
          fill: ${({ theme: { colors } }) => colors?.primary};
        }
      }
    }

    .customer-reputation__without-reputation___wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;
      justify-content: center;
      width: 100%;
      margin: 34px 0;
      p {
        font-size: 12px;
        font-weight: 400;
        color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
      }
    }
  }
`
