import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const FavoriteIcon: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg
      id="Componente_50_22_Toggle_State_"
      data-name="Componente 50 – 22 (Toggle State)"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.002"
      height="24"
      viewBox="0 0 24.002 24"
      {...rest}
    >
      <defs>
        <clipPath id="clip-path-favorite">
          <path id="Caminho_6117" data-name="Caminho 6117" d="M2.03,0V12a12,12,0,0,0,24,0V0Z" fill="none" />
        </clipPath>
      </defs>
      <path
        id="Caminho_6101"
        data-name="Caminho 6101"
        d="M26.032,12a12,12,0,1,1-12-12,12,12,0,0,1,12,12"
        transform="translate(-2.03)"
        fill="#A4AEB0"
        className={className}
      />
      <g id="Grupo_10262" data-name="Grupo 10262" transform="translate(-2.03)">
        <g id="Grupo_10261" data-name="Grupo 10261" clipPath="url(#clip-path-favorite)">
          <path
            id="Caminho_6102"
            data-name="Caminho 6102"
            d="M.268,28.554c.353-2.423,1.976-12.818,2.1-13.521S2.2,13.142,2.22,12.27,3.5,9.547,3.292,7.334s.177-2.281.742-2.318c.637-.041,1.49.985,1.714,2.371s-.5,3.672.957,3.395a14.862,14.862,0,0,1,3.72-.49c.736.086,1.188.843.928,1.8.761.1,1.449.807.693,2.065a1.279,1.279,0,0,1,.573.821,1.3,1.3,0,0,1-.5,1.238,1.721,1.721,0,0,1-.69,2.065,8.048,8.048,0,0,1-3.406.907c-.836.06-1.352,8.785-1.173,10.155S.063,29.96.268,28.554"
            fill="#fff"
          />
          <path
            id="Caminho_6103"
            data-name="Caminho 6103"
            d="M3.687,30.276A7.852,7.852,0,0,1,.543,29.41c-.5-.327-.569-.667-.536-.894.358-2.456,1.986-12.88,2.1-13.527a6,6,0,0,0-.059-1.428,9.894,9.894,0,0,1-.089-1.3,4.718,4.718,0,0,1,.38-1.34,8.018,8.018,0,0,0,.693-3.564c-.122-1.305-.054-1.975.233-2.312a.994.994,0,0,1,.755-.294C4.855,4.7,5.772,5.884,6.008,7.344A7.706,7.706,0,0,1,6.04,8.818c-.027.671-.057,1.43.2,1.65.031.028.128.11.418.055l.283-.054a13.407,13.407,0,0,1,3.516-.439,1.332,1.332,0,0,1,.963.585,1.763,1.763,0,0,1,.247,1.278,1.369,1.369,0,0,1,.846.7,1.63,1.63,0,0,1-.123,1.5,1.6,1.6,0,0,1,.488.834A1.448,1.448,0,0,1,12.41,16.3a1.881,1.881,0,0,1-.843,2.2,7.911,7.911,0,0,1-3.481.941c-.592.635-1.172,8.331-.973,9.861a.667.667,0,0,1-.357.672,5.868,5.868,0,0,1-3.069.293M.529,28.592c-.021.143.18.3.3.376,1.174.763,4.761,1.125,5.645.568.125-.079.118-.131.115-.157-.148-1.14.246-9.229,1.094-10.267a.456.456,0,0,1,.322-.185,7.55,7.55,0,0,0,3.288-.87,1.4,1.4,0,0,0,.585-1.713l-.017-.072a.264.264,0,0,1,.08-.249.969.969,0,0,0-.022-1.632.271.271,0,0,1-.128-.163.268.268,0,0,1,.028-.2,1.3,1.3,0,0,0,.216-1.2.9.9,0,0,0-.718-.462.264.264,0,0,1-.22-.331,1.361,1.361,0,0,0-.117-1.114.8.8,0,0,0-.587-.357,13.323,13.323,0,0,0-3.357.434l-.283.054a.979.979,0,0,1-.861-.175c-.447-.385-.415-1.2-.38-2.07a7.383,7.383,0,0,0-.026-1.368c-.216-1.334-1-2.175-1.436-2.15-.219.015-.322.033-.388.11A3.5,3.5,0,0,0,3.554,7.31a8.441,8.441,0,0,1-.724,3.8,4.671,4.671,0,0,0-.347,1.169A9.751,9.751,0,0,0,2.57,13.5a6.187,6.187,0,0,1,.054,1.575c-.111.646-1.738,11.06-2.1,13.514"
            fill="#383838"
          />
          <path
            id="Caminho_6104"
            data-name="Caminho 6104"
            d="M11.1,12.423a.264.264,0,0,1-.068-.509l.24-.091a.264.264,0,0,1,.187.493l-.24.091a.259.259,0,0,1-.119.016"
            fill="#383838"
          />
          <path
            id="Caminho_6105"
            data-name="Caminho 6105"
            d="M11.681,14.479a.264.264,0,0,1-.018-.523L12,13.9a.264.264,0,1,1,.088.52l-.339.058a.275.275,0,0,1-.07,0"
            fill="#383838"
          />
          <path
            id="Caminho_6106"
            data-name="Caminho 6106"
            d="M12.1,16.48a.26.26,0,0,1-.047-.009l-.254-.073a.264.264,0,0,1,.146-.507l.253.073a.264.264,0,0,1-.1.516"
            fill="#383838"
          />
          <path
            id="Caminho_6107"
            data-name="Caminho 6107"
            d="M18.415,33.68c.086-2.552.516-22.055.523-22.8s.522-1.909.66-2.809-.808-3.031-.186-5.263.24-2.374-.333-2.516c-.646-.161-1.711.734-2.2,2.115S16.717,6.27,15.27,5.716a15.484,15.484,0,0,0-3.726-1.192c-.771-.048-1.375.645-1.286,1.678-.8-.038-1.636.56-1.094,1.99a1.339,1.339,0,0,0-.74.736,1.354,1.354,0,0,0,.283,1.363,1.794,1.794,0,0,0,.325,2.247A8.4,8.4,0,0,0,12.359,14.1c.846.216-1.666,17.191-2.1,18.564s8.109,2.5,8.159,1.017"
            fill="#fff"
          />
          <path
            id="Caminho_6108"
            data-name="Caminho 6108"
            d="M14.4,34.544a9.186,9.186,0,0,1-4.147-1.216.7.7,0,0,1-.258-.748c.492-1.545,2.661-16.865,2.211-18.236a8.2,8.2,0,0,1-3.354-1.6,1.962,1.962,0,0,1-.457-2.419A1.511,1.511,0,0,1,8.171,8.82a1.661,1.661,0,0,1,.656-.766,1.7,1.7,0,0,1,.15-1.559,1.429,1.429,0,0,1,1-.557,1.839,1.839,0,0,1,.489-1.266,1.389,1.389,0,0,1,1.1-.422,13.859,13.859,0,0,1,3.526,1.1l.282.107c.287.111.4.043.439.021.3-.178.411-.963.508-1.656a8.043,8.043,0,0,1,.306-1.506C17.134.863,18.29-.181,19.145.027a1.034,1.034,0,0,1,.72.441c.233.4.178,1.1-.189,2.416a8.376,8.376,0,0,0,.051,3.786,4.942,4.942,0,0,1,.142,1.445,10.355,10.355,0,0,1-.332,1.316,6.209,6.209,0,0,0-.324,1.453c-.006.7-.438,20.289-.523,22.806-.008.208-.116.5-.591.7a8.883,8.883,0,0,1-3.7.156M11.662,4.811,11.527,4.8a.838.838,0,0,0-.669.257,1.421,1.421,0,0,0-.326,1.123.275.275,0,0,1-.287.3.941.941,0,0,0-.822.342,1.354,1.354,0,0,0,0,1.276.275.275,0,0,1-.173.36,1.01,1.01,0,0,0-.323,1.67.273.273,0,0,1,.036.27l-.03.07a1.463,1.463,0,0,0,.283,1.867,7.887,7.887,0,0,0,3.213,1.5.378.378,0,0,1,.238.195c.75,1.352-1.835,17.735-2.147,18.719-.018.053.058.133.1.175.985.9,5.872,1.545,7.264.959.075-.031.251-.115.254-.21.085-2.515.516-22.1.523-22.791a6.39,6.39,0,0,1,.347-1.606,9.89,9.89,0,0,0,.315-1.242,4.867,4.867,0,0,0-.14-1.264,8.824,8.824,0,0,1-.039-4.032A3.646,3.646,0,0,0,19.39.745c-.054-.091-.155-.129-.378-.184-.434-.108-1.4.61-1.871,1.939a7.633,7.633,0,0,0-.28,1.4c-.125.895-.243,1.741-.774,2.054a1.021,1.021,0,0,1-.915.019l-.281-.107a16.03,16.03,0,0,0-3.229-1.055"
            fill="#383838"
          />
          <path
            id="Caminho_6109"
            data-name="Caminho 6109"
            d="M10.453,6.586a.263.263,0,0,1-.11-.038l-.229-.137A.275.275,0,1,1,10.4,5.94l.229.137a.275.275,0,0,1-.173.509"
            fill="#383838"
          />
          <path
            id="Caminho_6110"
            data-name="Caminho 6110"
            d="M9.47,8.588a.241.241,0,0,1-.063-.015L9.07,8.451a.275.275,0,1,1,.187-.517l.337.122a.275.275,0,0,1-.124.532"
            fill="#383838"
          />
          <path
            id="Caminho_6111"
            data-name="Caminho 6111"
            d="M8.675,10.565a.275.275,0,0,1,0-.547l.274-.028a.275.275,0,1,1,.056.547l-.274.028a.291.291,0,0,1-.059,0"
            fill="#383838"
          />
          <path
            id="Caminho_6112"
            data-name="Caminho 6112"
            d="M29.021,28c-.617-2.37-3.371-12.524-3.568-13.209s-.041-1.9-.16-2.762-1.57-2.566-1.608-4.788-.427-2.248-.993-2.223c-.637.029-1.372,1.143-1.442,2.544s.9,3.595-.579,3.481a14.8,14.8,0,0,0-3.751-.079c-.722.166-1.088.968-.724,1.893-.746.183-1.352.961-.463,2.129a1.283,1.283,0,0,0-.48.878,1.3,1.3,0,0,0,.632,1.176,1.721,1.721,0,0,0,.914,1.977,8.054,8.054,0,0,0,3.483.528c.838-.033,2.309,8.583,2.282,9.964s6.815-.134,6.457-1.509"
            fill="#fff"
          />
          <path
            id="Caminho_6113"
            data-name="Caminho 6113"
            d="M25.595,30.13a5.515,5.515,0,0,1-2.866,0A.671.671,0,0,1,22.3,29.5c.03-1.543-1.392-9.129-2.049-9.694a7.942,7.942,0,0,1-3.564-.553,1.883,1.883,0,0,1-1.08-2.1,1.448,1.448,0,0,1-.615-1.325,1.6,1.6,0,0,1,.393-.883,1.635,1.635,0,0,1-.287-1.474,1.374,1.374,0,0,1,.765-.785,1.765,1.765,0,0,1,.105-1.3,1.328,1.328,0,0,1,.893-.687,13.3,13.3,0,0,1,3.539.05l.292.023c.294.022.38-.071.409-.1.229-.246.115-1,.015-1.662a7.743,7.743,0,0,1-.13-1.468c.075-1.477.849-2.759,1.695-2.795a.989.989,0,0,1,.782.21c.323.3.464.961.486,2.272a8.031,8.031,0,0,0,1.08,3.467,4.713,4.713,0,0,1,.525,1.289,9.944,9.944,0,0,1,.054,1.3,5.927,5.927,0,0,0,.1,1.425c.181.632,2.945,10.816,3.57,13.215.057.223.029.568-.435.948a8.15,8.15,0,0,1-3.247,1.249M17.068,11.2l-.089.019a.8.8,0,0,0-.544.419,1.362,1.362,0,0,0,.006,1.12.264.264,0,0,1-.182.353.9.9,0,0,0-.663.539,1.3,1.3,0,0,0,.347,1.174.263.263,0,0,1-.061.378.969.969,0,0,0,.159,1.623.263.263,0,0,1,.107.238l-.008.074a1.4,1.4,0,0,0,.769,1.639,7.558,7.558,0,0,0,3.363.5.458.458,0,0,1,.341.148c.956.938,2.237,8.935,2.215,10.085,0,.026,0,.079.131.144.94.455,4.466-.3,5.548-1.185.113-.092.3-.267.259-.407-.625-2.4-3.385-12.571-3.566-13.2a6.138,6.138,0,0,1-.12-1.571,9.391,9.391,0,0,0-.049-1.228,4.631,4.631,0,0,0-.473-1.124,8.465,8.465,0,0,1-1.137-3.7C23.4,6.168,23.3,5.53,23.1,5.348c-.074-.069-.178-.076-.4-.067-.428.022-1.123.945-1.191,2.294a7.432,7.432,0,0,0,.125,1.363c.129.858.252,1.668-.151,2.1a.978.978,0,0,1-.836.268l-.291-.023a14.651,14.651,0,0,0-3.292-.081"
            fill="#383838"
          />
          <path
            id="Caminho_6114"
            data-name="Caminho 6114"
            d="M16.484,13.156a.268.268,0,0,1-.116,0l-.247-.064a.263.263,0,1,1,.132-.51l.247.064a.263.263,0,0,1-.016.514"
            fill="#383838"
          />
          <path
            id="Caminho_6115"
            data-name="Caminho 6115"
            d="M16.127,15.265a.235.235,0,0,1-.065,0l-.344-.02a.263.263,0,1,1,.03-.526l.344.02a.264.264,0,0,1,.035.522"
            fill="#383838"
          />
          <path
            id="Caminho_6116"
            data-name="Caminho 6116"
            d="M15.935,17.3a.264.264,0,0,1-.15-.5l.244-.1a.263.263,0,0,1,.2.487l-.244.1a.246.246,0,0,1-.051.015"
            fill="#383838"
          />
        </g>
      </g>
    </svg>
  )
}

export default styled(FavoriteIcon)``
