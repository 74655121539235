import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../../states/company/CompanyContext'
import LoadingPage from '../../../../components/commons/LoadingPage'
import { useParams } from 'react-router-dom'

interface IProps {
  className?: string
}

const CustomerReputationMetabase: React.FC<IProps> = ({ className }: IProps) => {
  const { getCustomerReputationFromMetabase, isGettingCustomerReputationFromMetabase, customerReputationMetabase } =
    useContext(CompanyContext)

  const { id } = useParams()
  const Iframe: React.FC<{ source?: string }> = ({ source }) => {
    const src = source
    return <iframe height={800} src={src} style={{ width: '100%' }} />
  }

  useEffect(() => {
    getCustomerReputationFromMetabase({ companyId: id as string })
  }, [getCustomerReputationFromMetabase, id])

  return isGettingCustomerReputationFromMetabase ? (
    <LoadingPage />
  ) : (
    <div className={className} data-testid="customer-reputation-metabase-test">
      <Iframe source={customerReputationMetabase?.customerReputationURL ?? ''} />
    </div>
  )
}

export default styled(CustomerReputationMetabase)``
