import styled from 'styled-components'
import { CircularProgress } from '@mui/material'

interface IProps {
  className?: string
}

const PrimaryLoading: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <div className={className} {...rest}>
      <p>Enviando...</p>
      <CircularProgress size={12} />
    </div>
  )
}

export default styled(PrimaryLoading)`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 14px;
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
  }

  .MuiCircularProgress-root {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
  }
`
