import { useContext } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../../states/company/CompanyContext'
import ReactPlayer from 'react-player'

interface IProps {
  className?: string
}

const VirtualSection: React.FC<IProps> = ({ className }: IProps) => {
  const { customerData } = useContext(CompanyContext)

  return (
    <div data-testid="virtual-section-test" className={className}>
      <p className="virtual-section__main-title">Visita Virtual</p>
      <div className="virtual-section__video-container">
        {customerData?.videos?.map((video) => (
          <div className="virtual-section__video-item-wrapper" key={video?.id}>
            <div data-testid="video-item-test" className="virtual-section__video-item">
              <ReactPlayer
                className="virtual-section__react-player"
                width="100%"
                height="100%"
                url={video?.url as string}
                controls
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default styled(VirtualSection)`
  display: flex;
  flex-direction: column;

  .virtual-section__main-title {
    padding: 36px 0px 18px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  .virtual-section__video-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  .virtual-section__video-item-wrapper {
    max-width: 360px;
    width: 100%;
  }

  .virtual-section__video-item {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
  }

  .virtual-section__react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 810px) {
    .virtual-section__video-item-wrapper {
      max-width: unset;
      width: 100%;
    }
  }
`
