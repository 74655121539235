import styled from 'styled-components'
import Header from '../../components/Header'
import H3 from '../../../common/components/Typography/H3'
import PageWrapper from '../../components/PageWrapper'
import alert from '../../../../assets/icons/alert.svg'
import SsnCard from '../../../../assets/images/SsnCard.png'

interface IProps {
  className?: string
}

const PendingSecurityNumber = ({ className }: IProps) => {
  return (
    <PageWrapper className={className}>
      <Header />
      <div className="pending-social-number__wrapper">
        <img src={alert} style={{ height: 88, width: 88 }} />
        <H3>O seu CPF está suspenso.</H3>
        <div className="pending-social-number___info">
          <p>Não se preocupe, normalmente esse é um problema simples de resolver.</p>
          <p>Preparamos um artigo que pode te ajudar com isso.</p>
        </div>
        <div className="pending-social-number____ssn-card-wrapper">
          <img src={SsnCard} style={{ height: 88, width: 88 }} />
          <div
            data-testid="pending-security-number-container-test"
            onClick={() => (window.location.href = 'https://antecipafacil.com.br/resolver-cpf-inativo-ou-suspenso/')}
          >
            <p>CPF Inativo</p>
            <p>4 passos para resolver CPF inativo</p>
          </div>
        </div>
        <p className="pending-social-number_____bottom-info">
          Tente se cadastrar novamente após solucionar <br /> esse probleminha.
        </p>
        <p className="pending-social-number______greeting-message">Nos vemos em breve!</p>
      </div>
    </PageWrapper>
  )
}

export default styled(PendingSecurityNumber)`
  .pending-social-number__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 400px;

    @media (max-width: 400px) {
      div {
        p {
          max-width: 300px;
        }
      }
    }

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      padding-top: 32px;
      text-align: center;
    }

    .pending-social-number___info {
      p {
        text-align: center;
      }

      p:first-child {
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
        font-weight: bold;
        padding: 8px 0px 40px;
      }

      p:nth-child(2) {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 14px;
        padding-bottom: 32px;
      }
    }

    .pending-social-number____ssn-card-wrapper {
      background-color: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
      border-radius: 8px;
      box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
      cursor: pointer;
      display: flex;
      max-width: 400px;
      width: 100%;

      div {
        p:first-child {
          color: ${({ theme: { colors } }) => colors?.tertiaryFont};
          font-weight: bold;
          padding: 24px 0px 8px 8px;
        }

        p:nth-child(2) {
          color: ${({ theme: { colors } }) => colors?.quarternaryFont};
          font-size: 14px;
          padding-left: 8px;
        }
      }

      @media (max-width: 400px) {
        div {
          max-width: 200px;

          p {
            padding-top: 4px !important;
            text-align: center;
          }
        }
      }
    }

    .pending-social-number_____bottom-info {
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
      font-size: 14px;
      padding: 48px 0px 24px;
      text-align: center;
    }

    .pending-social-number______greeting-message {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      font-size: 14px;
      font-weight: bold;
    }
  }
`
