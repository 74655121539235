import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    font: 400 16px 'Inter', sans-serif;
    font-variant-numeric: tabular-nums;
  }

  p, h1, h2, h3, h4, h5, h6, a {
    color: ${({ theme }) => theme?.colors?.gray?.main};
  }
`
