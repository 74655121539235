import { Pagination, PaginationItem } from '@mui/material'
import styled from 'styled-components'
import ChevronLeft from '../../../icons/ChevronLeft'
import ChevronLeftDouble from '../../../icons/ChevronLeftDouble'
import ChevronRight from '../../../icons/ChevronRight'
import ChevronRightDouble from '../../../icons/ChevronRightDouble'

interface IProps {
  className?: string
  page: number
  onPageChange: (newPage: number) => void
  count: number
}

const SecondaryPagination: React.FC<IProps> = ({ className, onPageChange, page, count }: IProps) => {
  return (
    <Pagination
      className={className}
      count={count}
      page={page}
      onChange={(event: unknown, newPage: number) => onPageChange(newPage)}
      siblingCount={0}
      showFirstButton={true}
      showLastButton={true}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          components={{
            first: () => <ChevronLeftDouble data-testid="secondary-pagination-first-button" />,
            last: () => <ChevronRightDouble data-testid="secondary-pagination-last-button" />,
            next: () => <ChevronRight data-testid="secondary-pagination-next-button" />,
            previous: () => <ChevronLeft data-testid="secondary-pagination-previous-button" />
          }}
        />
      )}
    />
  )
}

export default styled(SecondaryPagination)`
  .MuiPaginationItem-root {
    margin: 0;
  }

  .MuiPaginationItem-root {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .Mui-selected {
    background: transparent !important;
    font-weight: bold;
    color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
  }
`
