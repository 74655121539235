import styled from 'styled-components'
import contractsIcon from '../../../../src/assets/icons/contracts.svg'
import awaitingDot from '../../../../src/assets/icons/awaitingDot.svg'
import successDot from '../../../../src/assets/icons/successDot.svg'
import { D4_SIGN_BLOB_URL } from '../../../utils/envs'
import { GetContractsByCompanyIdOutput } from '../../../service/graphql/schema'
import { useContext } from 'react'
import { CompanyContext } from '../../../states/company/CompanyContext'
import SecondaryLoading from '../../../apps/common/components/Loading/SecondaryLoading'
import Download from '../../../assets/icons/Download'

interface IProps {
  className?: string
  contracts: GetContractsByCompanyIdOutput[]
}

const Contracts: React.FC<IProps> = ({ className, contracts, ...rest }: IProps) => {
  const { getContractDownloadUrl, isGettingContractDownloadUrl } = useContext(CompanyContext)

  const getRightContract = (contract: GetContractsByCompanyIdOutput) => {
    if (contract.status === 'Finalizado') {
      return (
        <>
          <div className="contracts__item">
            <div className="contracts__left-info">
              <div className="contracts__img-and-text">
                <img width={12} src={successDot} />
                <p className="contracts__status signed">CONTRATO ASSINADO</p>
              </div>
              <p>{contract.name}</p>
            </div>
          </div>
          {isGettingContractDownloadUrl ? (
            <div className="contracts__icon-wrapper loading" data-testid="contracts-loading-test">
              <hr />
              <SecondaryLoading size={24} />
            </div>
          ) : (
            <div
              data-testid="contracts-finished-status-test"
              className="contracts__icon-wrapper"
              onClick={() => getContractDownloadUrl(contract.contractId)}
            >
              <hr />
              <Download width={24} height={24} />
            </div>
          )}
        </>
      )
    }

    return (
      <>
        <div className="contracts__item">
          <div className="contracts__left-info">
            <div className="contracts__img-and-text">
              <img width={12} src={awaitingDot} />
              <p className="contracts__status">CONTRATO AGUARDANDO ASSINATURA</p>
            </div>
            <p>{contract.name}</p>
          </div>
        </div>
        <div
          data-testid="contracts-not-finished-status-test"
          className="contracts__icon-wrapper"
          onClick={() => window.open(`${D4_SIGN_BLOB_URL}/${contract.contractId}`, '_blank')}
        >
          <hr />
          <img width={24} src={contractsIcon} />
        </div>
      </>
    )
  }

  return (
    <div className={className} {...rest}>
      <p className="contracts__main-title">Contratos</p>
      <div className="contracts__grid">
        {contracts?.map((contract) => (
          <div className="contracts__wrapper" key={contract.id}>
            {getRightContract(contract)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default styled(Contracts)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
  border-radius: 8px;
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  margin-bottom: 24px;
  padding: 24px;

  .contracts__main-title {
    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    font-size: 16px;
    font-weight: bold;
  }

  .contracts__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 16px;
    margin-top: 8px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .contracts__wrapper {
    border: 1px solid ${({ theme }) => theme?.colors?.borderColor};
    border-radius: 8px;
    display: flex;

    .contracts__item {
      width: 100%;

      .contracts__left-info {
        display: flex;
        flex-direction: column;
        padding: 16px 0px 16px 16px;

        p {
          color: ${({ theme: { colors } }) => colors?.tertiaryFont};
          font-size: 14px;
          font-weight: bold;
          @media (max-width: 500px) {
            font-size: 12px;
          }
        }

        .contracts__img-and-text {
          display: flex;
          padding-bottom: 16px;

          img {
            @media (max-width: 500px) {
              width: 10px;
            }
          }

          .contracts__status {
            color: ${({ theme: { styleGuide } }) => styleGuide?.support?.yellow};
            font-size: 12px;
            font-weight: bold;

            @media (max-width: 500px) {
              font-size: 10px !important;
            }
          }

          .signed {
            color: ${({ theme: { styleGuide } }) => styleGuide?.support?.green};
          }

          img {
            padding-right: 8px;
          }
        }
      }
    }

    .contracts__icon-wrapper {
      cursor: pointer;
      display: flex;
      height: 60%;
      height: 100%;
      align-items: center;
      justify-items: center;
      gap: 16px;
      padding-right: 16px;

      hr {
        border: 1px solid ${({ theme: { colors } }) => colors?.gray?.lighter};
        height: 80%;
      }

      img {
        height: 100%;
        object-fit: contain;
      }

      ${Download} {
        path {
          fill: ${({ theme: { colors } }) => colors?.primary};
        }
      }
    }

    .loading {
      cursor: auto;
    }
  }
`
