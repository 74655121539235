import { useContext } from 'react'
import styled from 'styled-components'
import { CompanyContext } from '../../../../states/company/CompanyContext'

interface IProps {
  className?: string
}

const BankAccountsSection: React.FC<IProps> = ({ className }: IProps) => {
  const { customerData } = useContext(CompanyContext)

  return (
    <div data-testid="bank-accounts-section-test" className={className}>
      <p className="bank-accounts-section__main-title">Dados bancários</p>
      {customerData.bankAccounts && customerData.bankAccounts.length === 0 ? (
        <div className="bank-accounts-section__without-bank-accounts-container">Nenhum dado disponível</div>
      ) : (
        <>
          <div className="bank-accounts-section__header">
            <div>Agência</div>
            <div>Conta</div>
          </div>
          <div className="bank-accounts-section__bank-accounts-content">
            {customerData.bankAccounts?.map(
              (bankAccount, index) =>
                bankAccount && (
                  <div
                    data-testid="bank-account-item-test"
                    className="bank-accounts-section__bank-account-item-wrapper"
                    key={index}
                  >
                    <div className="bank-accounts-section__bank-name-wrapper">{bankAccount.bankName}</div>
                    <div className="bank-accounts-section__bank-agency-wrapper">{bankAccount.accountAgency}</div>
                    <div className="bank-accounts-section__bank-account-number-wrapper">
                      {bankAccount.accountNumber}
                      {bankAccount?.accountDigit ? `-${bankAccount?.accountDigit}` : ''}
                    </div>
                    <div
                      data-testid="bank-account-item-default-badge"
                      className="bank-accounts-section__default-bank-account-wrapper"
                    >
                      {bankAccount.isDefault && <div className="web">Conta padrão</div>}
                      {bankAccount.isDefault && <div className="mob">Padrão</div>}
                    </div>
                  </div>
                )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default styled(BankAccountsSection)`
  text-align: left !important;

  .bank-accounts-section__main-title {
    padding: 36px 0px 18px;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  .bank-accounts-section__bank-account-item-wrapper {
    display: flex;
    align-items: center;
  }

  .bank-accounts-section__bank-name-wrapper {
    width: 150px;
    font-weight: 700 !important;
    color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 550px) {
      width: 100px;
    }
  }
  .bank-accounts-section__header {
    padding-left: 150px;
    margin-bottom: 14px;
    display: flex;
    div {
      font-weight: 700;
      color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
    }
    div:first-child {
      width: 100px;
    }
    @media (max-width: 550px) {
      padding-left: 100px;
    }
  }

  .bank-accounts-section__without-bank-accounts-container {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
  }

  .bank-accounts-section__bank-accounts-content > div:not(:last-child) {
    margin-bottom: 15px;
  }

  .bank-accounts-section__bank-agency-wrapper {
    width: 100px;
    color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
    font-size: 14px;
    display: flex;
  }

  .bank-accounts-section__bank-account-number-wrapper {
    color: ${({ theme }) => theme?.styleGuide?.fixed?.gray?.light?.primary};
    font-size: 14px;
    width: 100px;
  }
  .bank-accounts-section__default-bank-account-wrapper {
    div {
      padding: 2px 6px;
      border-radius: 100px;
      display: inline-block;
      font-size: 12px;
      background: ${({ theme: { styleGuide } }) => styleGuide?.support.blue};
      color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
      white-space: nowrap;
    }
    div & .web {
      @media (max-width: 550px) {
        display: none;
      }
    }
    div & .mob {
      @media (min-width: 550px) {
        display: none;
      }
    }
  }
`
