import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CreateUpdateDelete, { IFormData } from '../../components/crud/SplitCrud/CreateUpdateDelete'
import { CompanyContext } from '../../states/company/CompanyContext'
import * as validations from 'validations-br'
import WithHeader from '../../hocs/WithHeader'

const CompanyWitnessForm: React.FC = () => {
  const {
    defaultCompany,
    isGettingDefaultCompany,
    isRegistratingWitness,
    registerWitness,
    getWitnessesByCompanyId,
    isGettingWitnessessByCompanyId,
    witnesses,
    error
  } = useContext(CompanyContext)

  const { id } = useParams()

  useEffect(() => {
    if (defaultCompany?.businessId && id) {
      getWitnessesByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, id, getWitnessesByCompanyId])

  const formData: IFormData = {
    breadCrumb: 'Dados cadastrais / Testemunhas',
    isLoading: isGettingDefaultCompany || isGettingWitnessessByCompanyId,
    title: 'Adicionar testemunhas',
    subtitle: 'Preencha os campos abaixo para adicionar um sócio ou procurador.',
    currentData: witnesses?.find((r) => r?.id === id),
    backTo: '/company-witnesses',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveData: (data: any) =>
      registerWitness({
        companyId: defaultCompany?.businessId,
        ...data
      }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editData: (data: any) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      registerWitness({
        companyId: defaultCompany?.businessId,
        ...data
      }),
    isSavingData: isRegistratingWitness,
    isEditingData: isRegistratingWitness,
    formDisabled: (defaultCompany?.statusId as string) !== '1',
    groups: [
      {
        rows: [
          {
            inputs: [
              {
                label: 'Nome',
                name: 'fullName',
                required: true
              },
              {
                label: 'CPF',
                name: 'documentNumber',
                required: true,
                mask: '###.###.###-##',
                test: {
                  type: 'is-cpf',
                  message: 'Confira se seu CPF está correto',
                  callback: (value?: string) => !!(value && validations.validateCPF(value))
                }
              },
              {
                label: 'Número do RG',
                name: 'personalDocument',
                required: true,
                onlyNumber: true
              }
            ],
            grid: [2, 1, 1]
          }
        ]
      },
      {
        rows: [
          {
            inputs: [
              {
                label: 'E-mail',
                name: 'email',
                required: true,
                type: 'email',
                error: error?.location === 'registerCompanyUser' ? 'Usuário já cadastrado na plataforma' : ''
              }
            ],
            grid: [3, 1]
          }
        ]
      }
    ]
  }

  return <CreateUpdateDelete formData={formData} />
}

export default WithHeader()(CompanyWitnessForm)
