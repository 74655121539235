import { getValuesInsideParentheses } from '../string'

export const percentToHex = (percentage: number) => {
  const intValue = Math.round((percentage / 100) * 255)
  const hexValue = intValue.toString(16)
  return hexValue.padStart(2, '0').toUpperCase()
}

export const addOpacityToDynamicColors = (color: string, percentage: number) => {
  const isHex = color?.includes('#')
  const isRGB = color?.includes('rgb')
  const percentageToDecimal = percentage / 100

  if (isHex) {
    return `rgba(${[...hexToRgb(color), percentageToDecimal].join(',')})`
  } else if (isRGB) {
    const rgb = getValuesInsideParentheses(color)

    const splitRedBlueGreen = rgb.split(',')

    return `rgba(${[...splitRedBlueGreen, percentageToDecimal].join(',')})`
  }

  return color
}

export const hexToRgb = (hexColor: string) => {
  // Convert the hex color to RGB
  const red = parseInt(hexColor.slice(1, 3), 16)
  const green = parseInt(hexColor.slice(3, 5), 16)
  const blue = parseInt(hexColor.slice(5, 7), 16)

  return [red, green, blue]
}

export const rgbToHsb = (red: number, green: number, blue: number) => {
  // Convert the RGB values to HSB
  const max = Math.max(red, green, blue)
  const min = Math.min(red, green, blue)
  const delta = max - min

  let hue = 0
  if (delta !== 0) {
    if (max === red) {
      hue = ((green - blue) / delta) % 6
    } else if (max === green) {
      hue = (blue - red) / delta + 2
    } else if (max === blue) {
      hue = (red - green) / delta + 4
    }
    hue *= 60
    if (hue < 0) hue += 360
  }

  const saturation = max === 0 ? 0 : Math.round((delta / max) * 100)

  const brightness = Math.round((max / 255) * 100)

  // Return the HSB values as integers
  return [Math.round(hue), saturation, brightness]
}

export const modifyHsb = (hue: number, saturation: number, brightness: number) => {
  // Increase saturation and brightness by 20
  const newSaturation1 = Math.min(saturation + 20, 100)
  const newBrightness1 = Math.max(brightness - 20, 0)
  const modified1 = [hue, newSaturation1, newBrightness1]

  // Decrease saturation by 30 and increase brightness by 20
  const newSaturation2 = Math.max(saturation - 30, 0)
  const newBrightness2 = Math.min(brightness + 20, 100)
  const modified2 = [hue, newSaturation2, newBrightness2]

  // Set saturation to 7
  const modified3 = [hue, 7, brightness]

  return [modified1, modified2, modified3]
}

export const hsbToHex = (hue: number, saturation: number, brightness: number) => {
  // Convert HSB to RGB
  const chroma = (brightness / 100) * (saturation / 100)
  const x = chroma * (1 - Math.abs(((hue / 60) % 2) - 1))
  const m = brightness / 100 - chroma
  let red, green, blue
  if (hue >= 0 && hue < 60) {
    red = chroma
    green = x
    blue = 0
  } else if (hue >= 60 && hue < 120) {
    red = x
    green = chroma
    blue = 0
  } else if (hue >= 120 && hue < 180) {
    red = 0
    green = chroma
    blue = x
  } else if (hue >= 180 && hue < 240) {
    red = 0
    green = x
    blue = chroma
  } else if (hue >= 240 && hue < 300) {
    red = x
    green = 0
    blue = chroma
  } else {
    red = chroma
    green = 0
    blue = x
  }
  red = Math.round((red + m) * 255)
  green = Math.round((green + m) * 255)
  blue = Math.round((blue + m) * 255)

  // Convert RGB to hex
  const hexRed = red.toString(16).padStart(2, '0')
  const hexGreen = green.toString(16).padStart(2, '0')
  const hexBlue = blue.toString(16).padStart(2, '0')
  return `#${hexRed}${hexGreen}${hexBlue}`.toUpperCase()
}

export const checkIfColorIsRGB = (color: string) => {
  const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/

  return rgbRegex.test(color)
}

export const getRGBValues = (rgbColor: string) => {
  const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/
  const match = rgbColor.match(rgbRegex) as RegExpMatchArray
  return [parseInt(match[1]), parseInt(match[2]), parseInt(match[3])]
}

export const modifyColors = (color: string) => {
  const isRGB = checkIfColorIsRGB(color)

  if (isRGB) {
    // get RGB VAlues
    const [red, green, blue] = getRGBValues(color)
    //  Convert rgb color to HSB
    const hsbColor = rgbToHsb(red, green, blue)
    // Modify HSB colors
    const modifiedHsb = modifyHsb(hsbColor[0], hsbColor[1], hsbColor[2])
    // Convert modified HSB colors to hex
    const newHex1 = hsbToHex(modifiedHsb[0][0], modifiedHsb[0][1], modifiedHsb[0][2])
    const newHex2 = hsbToHex(modifiedHsb[1][0], modifiedHsb[1][1], modifiedHsb[1][2])
    const newHex3 = hsbToHex(modifiedHsb[2][0], modifiedHsb[2][1], modifiedHsb[2][2])
    return [newHex1, newHex2, newHex3]
  }

  const [red, green, blue] = hexToRgb(color)

  //  Convert rgb color to HSB
  const hsbColor = rgbToHsb(red, green, blue)
  // Modify HSB colors
  const modifiedHsb = modifyHsb(hsbColor[0], hsbColor[1], hsbColor[2])
  // Convert modified HSB colors to hex
  const newHex1 = hsbToHex(modifiedHsb[0][0], modifiedHsb[0][1], modifiedHsb[0][2])
  const newHex2 = hsbToHex(modifiedHsb[1][0], modifiedHsb[1][1], modifiedHsb[1][2])
  const newHex3 = hsbToHex(modifiedHsb[2][0], modifiedHsb[2][1], modifiedHsb[2][2])
  return [newHex1, newHex2, newHex3]
}
