import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { ComponentType } from 'react'
import styled from 'styled-components'

interface IProps extends ButtonProps {
  className?: string
  isLoading?: boolean
  text: string
  Image: ComponentType
}

const ImageButton: React.FC<IProps> = ({ className, isLoading, text, Image, ...rest }: IProps) => {
  return (
    <div className={className}>
      <Button
        variant="outlined"
        data-testid="image-button-test"
        disableElevation
        {...rest}
        disabled={isLoading || rest?.disabled}
      >
        {isLoading ? <CircularProgress size={26} data-testid="test-circular-progress" /> : <Image />}
        {text}
      </Button>
    </div>
  )
}

export default styled(ImageButton)`
  .MuiButton-root {
    font-size: 12px;
    font-weight: 900;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    img {
      height: 28px;
    }
  }
`
