/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAddressState } from '../../types/address'

interface AddressActions {
  type: 'GET_ADDRESS_BY_ZIP_CODE' | 'GET_ADDRESS_BY_ZIP_CODE_SUCCESS' | 'GET_ADDRESS_BY_ZIP_CODE_ERROR'
  payload?: any
}

const actionHandler = (
  payload: any
): {
  [key: string]: Partial<IAddressState>
} => ({
  GET_ADDRESS_BY_ZIP_CODE: { isGettingAddressByZipCode: true },
  GET_ADDRESS_BY_ZIP_CODE_SUCCESS: { isGettingAddressByZipCode: false },
  GET_ADDRESS_BY_ZIP_CODE_ERROR: { isGettingAddressByZipCode: false, error: payload }
})

const AddressReducer = (state: IAddressState, action: AddressActions) => {
  const updateProperties = actionHandler(action.payload)[action.type]
  return { ...state, ...updateProperties }
}

export default AddressReducer
