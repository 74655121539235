import { ReactNode } from 'react'
import styled from 'styled-components'

export interface IBodyProps {
  className?: string
  children: ReactNode
}

const Body: React.FC<IBodyProps> = ({ className, children }: IBodyProps) => {
  return <p className={className}>{children}</p>
}

export default styled(Body)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
`
