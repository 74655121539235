import styled from 'styled-components'
import H5 from '../../../common/components/Typography/H5'
import PrimaryPagination from '../../../common/components/Pagination/PrimaryPagination'
import { useFormContext, Controller } from 'react-hook-form'
import QuizQuestion from '../../components/QuizQuestion'
import PrimaryCheckbox from '../../../common/components/Input/PrimaryCheckbox'
import PrimaryRadio from '../../../common/components/Input/PrimaryRadio'
import RadioGroup from '../../../common/components/Input/RadioGroup'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { handleCheckboxChange } from '../../../../utils/input'
interface IProps {
  className?: string
}

const QuizFirstStep: React.FC<IProps> = ({ className }: IProps) => {
  const { documentNumber } = useParams()
  const {
    control,
    getValues,
    formState: { errors },
    setError,
    clearErrors
  } = useFormContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const companyId = searchParams.get('companyId') as string

  const isValid = () => {
    let valid = true
    if (!getValues('clientTypes') || getValues('clientTypes').length < 1) {
      valid = false
      setError('clientTypes', { message: 'Selecione pelo menos uma opção' })
    }

    if (!getValues('radialIncome') || getValues('radialIncome').length < 1) {
      valid = false
      setError('radialIncome', { message: 'Selecione pelo menos uma opção' })
    }

    if (!getValues('paymentTypes') || getValues('paymentTypes').length < 1) {
      valid = false
      setError('paymentTypes', { message: 'Selecione pelo menos uma opção' })
    }

    return valid
  }

  return (
    <div className={className}>
      <Controller
        control={control}
        name="clientTypes"
        defaultValue={[]}
        render={({ field }) => (
          <QuizQuestion title="Pra quem você vende?" errorMessage={errors['clientTypes']?.message as string}>
            <PrimaryCheckbox
              label="Pessoa física"
              value="pf"
              onChange={(event, checked) => {
                clearErrors()
                handleCheckboxChange(event, checked, field)
              }}
              data-testid="checkbox-pf-test"
              checked={getValues('clientTypes') ? getValues('clientTypes').includes('pf') : false}
            />
            <PrimaryCheckbox
              label="Pessoa jurídica"
              value="pj"
              data-testid="checkbox-pj-test"
              onChange={(event, checked) => {
                clearErrors()
                handleCheckboxChange(event, checked, field)
              }}
              checked={getValues('clientTypes') ? getValues('clientTypes').includes('pj') : false}
            />
          </QuizQuestion>
        )}
      />

      <Controller
        control={control}
        name="radialIncome"
        render={({ field: { onChange } }) => (
          <RadioGroup
            name="radialIncome"
            value={getValues('radialIncome') || ''}
            onChange={({ target: { value } }) => {
              clearErrors()
              onChange(value)
            }}
          >
            <QuizQuestion
              title="Qual o seu faturamento mensal?"
              errorMessage={errors['radialIncome']?.message as string}
            >
              <PrimaryRadio label="Até R$ 80 mil" value="Até R$ 80 mil" />
              <PrimaryRadio label="Até R$ 200 mil" value="Até R$ 200 mil" />
              <PrimaryRadio label="Até R$ 400 mil" value="Até R$ 400 mil" />
              <PrimaryRadio label="Até R$ 800 mil" value="Até R$ 800 mil" />
              <PrimaryRadio label="Até R$ 1,6 milhão" value="Até R$ 1,6 milhão" />
              <PrimaryRadio
                data-testid="radio-test"
                value="Maior que R$ 1,6 milhão"
                label={
                  <H5>
                    Maior que <br /> R$ 1,6 milhão
                  </H5>
                }
              />
            </QuizQuestion>
          </RadioGroup>
        )}
      />

      <Controller
        control={control}
        name="paymentTypes"
        defaultValue={[]}
        render={({ field }) => (
          <QuizQuestion
            title="Como você recebe os pagamentos?"
            errorMessage={errors['paymentTypes']?.message as string}
          >
            <PrimaryCheckbox
              label="Pix / TED"
              value="pix"
              onChange={(event, checked) => {
                clearErrors()
                handleCheckboxChange(event, checked, field)
              }}
              data-testid="checkbox-pix-test"
              checked={getValues('paymentTypes') ? getValues('paymentTypes').includes('pix') : false}
            />
            <PrimaryCheckbox
              label="Boleto"
              value="ticket"
              onChange={(event, checked) => {
                clearErrors()
                handleCheckboxChange(event, checked, field)
              }}
              data-testid="checkbox-ticket-test"
              checked={getValues('paymentTypes') ? getValues('paymentTypes').includes('ticket') : false}
            />
            <PrimaryCheckbox
              value="checkbook"
              label="Cheque"
              data-testid="checkbox-checkbook-test"
              onChange={(event, checked) => {
                clearErrors()
                handleCheckboxChange(event, checked, field)
              }}
              checked={getValues('paymentTypes') ? getValues('paymentTypes').includes('checkbook') : false}
            />
            <PrimaryCheckbox
              value="creditCard"
              label="Cartão de crédito"
              onChange={(event, checked) => {
                clearErrors()
                handleCheckboxChange(event, checked, field)
              }}
              checked={getValues('paymentTypes') ? getValues('paymentTypes').includes('creditCard') : false}
              data-testid="checkbox-credit-card-test"
            />
          </QuizQuestion>
        )}
      />
      <PrimaryPagination
        totalPages={3}
        currentPage={1}
        handleNextPage={(number) => {
          if (isValid()) {
            navigate(`/onboarding/quiz/step/${number}/${documentNumber}?companyId=${companyId}`)
          }
        }}
      />
    </div>
  )
}

export default styled(QuizFirstStep)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`
