import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const Add: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        id="_510c44a574f3765bd8912da01a80fbd4"
        data-name="510c44a574f3765bd8912da01a80fbd4"
        d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
        transform="translate(-4 -4)"
      />
    </svg>
  )
}

export default styled(Add)``
