import { Route } from 'react-router-dom'
import ExampleBuyer from '../../../pages/ExampleBuyer'
import ExampleCustomer from '../../../pages/ExampleCustomer'
import CommonRoute from '../../Rules/CommonRoute'
import MaintenancePage from '../../../utils/MaintenancePage'

export const CommonRoutes = (
  <Route path="/">
    <Route
      path="example"
      element={<CommonRoute BuyerComponent={() => <ExampleBuyer />} CustomerComponent={() => <ExampleCustomer />} />}
    />
    <Route path="/maintenance-page" element={<MaintenancePage />} />
  </Route>
)
