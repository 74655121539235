import { ReactNode, useCallback, useReducer, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import {
  CompanyLegalRepresentativeInput,
  CompanyPartnerInput,
  CompanyCustomerOrSupplierInput,
  CompanyUserInput,
  RegisterCompanyInput,
  CompanyTaxesInput,
  UpdateCompanyUserInput,
  useDeleteCompanyLegalRepresentativeMutation,
  useDeleteCompanyPartnerMutation,
  useGetCompanyInfosLazyQuery,
  useGetCompanyLegalRepresentativesByCompanyIdLazyQuery,
  useGetCompanyPartnersByCompanyIdLazyQuery,
  useGetCompanyUsersByCompanyIdLazyQuery,
  useGetDefaultCompanyLazyQuery,
  useGetWitnessesByCompanyIdLazyQuery,
  useRegisterCompanyLegalRepresentativeMutation,
  useRegisterCompanyMutation,
  useRegisterCompanyPartnerMutation,
  useRegisterCompanyUserMutation,
  useRegisterWitnessMutation,
  useUpdateCompanyUserMutation,
  useDeleteCompanyCustomerMutation,
  WitnessInput,
  useRegisterCompanyTaxesMutation,
  useGetCompanyTaxesByCompanyIdLazyQuery,
  useGetCompanyDocumentsByCompanyIdLazyQuery,
  useGetCompanyCustomersAndSuppliersByCompanyIdLazyQuery,
  useRegisterCompanyCustomerOrSupplierMutation,
  useDeleteCompanySupplierMutation,
  useDeleteCompanyDocumentMutation,
  useGetCompanyBankAccountInfoByCompanyIdLazyQuery,
  useDeleteCompanyBankAccountInfoMutation,
  useRegisterCompanyBankAccountInfoMutation,
  BankAccountInfoInput,
  useSendToAnalysisMutation,
  useGetCompanyRevenueLazyQuery,
  useSendCompanyRevenueMutation,
  SendCompanyRevenueInput,
  useGetContractsByCompanyIdLazyQuery,
  useGetContractDownloadUrlLazyQuery,
  useUpdateCompanyBankAccountInfoMutation,
  UpdateBankAccountInfoInput,
  useGetCompaniesFromUserLazyQuery,
  useUpdateDefaultCompanyMutation,
  useGetCustomersLazyQuery,
  GetCustomersInput,
  useGetCustomerProfileLazyQuery,
  GetCustomerProfileInput,
  useGetCompanyRatingByCompanyIdLazyQuery,
  useGetCustomerDataByCompanyIdLazyQuery,
  GetCustomerDataByCompanyIdInput,
  useMutateCompanyRatingsMutation,
  MutateCompanyRatingsInput,
  useGetCreditAnalysisReportByCompanyIdLazyQuery,
  GetCreditAnalysisReportByCompanyIdInput,
  useInDossieDocumentsGroupedByDocumentTypeLazyQuery,
  useGetZipDownloadUrlFromAllDocumentsLazyQuery,
  useGetZipDownloadUrlByDocumentGroupLazyQuery,
  useCreateRatingReasonsMutation,
  CreateRatingReasonsInput,
  useGetReputationByCompanyIdLazyQuery,
  GetReputationByCompanyIdInput,
  useGetCustomerReputationFromMetabaseLazyQuery,
  GetCustomerReputationFromMetabaseInput,
  useGetCustomerAllDocumentsNamesLazyQuery,
  GetCustomerAllDocumentsNamesInput,
  useGetCustomerGroupFilesByUrlLazyQuery,
  GetCustomerGroupFilesByUrlInput,
  useGetCustomerAllDocumentsFilesByUrlLazyQuery,
  GetCustomerAllDocumentsFilesByUrlInput,
  useGetBankAccountsLazyQuery,
  useGetBankAccountsPermissionsLazyQuery,
  GetBankAccountsPermissionsInput
} from '../../service/graphql/schema'
import { OriginContext } from '../origin/OriginContext'
import { CompanyContext, initialState } from './CompanyContext'
import CompanyReducer from './CompanyReducer'
import { translateGroup } from '../../utils/auth'
import { IUploadCompanyDocumentInput } from '../../types/company'
import { V1_URL } from '../../utils/envs'
import { api } from '../../config/Axios'
import { handleGraphQLError } from '../../utils/error'
import { downloadFile } from '../../utils/file'
import { SnackbarContext } from '../../apps/common/contexts/SnackBar/SnackBarContext'

interface IProps {
  children: ReactNode
}

const CompanyState: React.FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(CompanyReducer, initialState)
  const navigate = useNavigate()
  const { originInfos } = useContext(OriginContext)
  const { groups } = useAuth()
  const { showSnackbar } = useContext(SnackbarContext)

  const [registerCompanyMutation] = useRegisterCompanyMutation({
    onCompleted: async ({ registerCompany: registerCompanyCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_SUCCESS',
        payload: registerCompanyCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'REGISTER_COMPANY_ERROR', 'registerCompany')
      dispatch(action)
    }
  })

  const registerCompany = useCallback(
    (params: RegisterCompanyInput) => {
      dispatch({
        type: 'REGISTER_COMPANY'
      })

      registerCompanyMutation({
        variables: {
          params
        }
      })
    },
    [registerCompanyMutation]
  )

  const [getCompanyInfosQuery] = useGetCompanyInfosLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getCompanyInfos: getCompanyInfosCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_INFOS_SUCCESS',
        payload: getCompanyInfosCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_COMPANY_INFOS_ERROR', 'registerCompany')

      dispatch(action)
    }
  })

  const getCompanyInfos = useCallback(
    (businessId: string) => {
      dispatch({
        type: 'GET_COMPANY_INFOS'
      })

      getCompanyInfosQuery({
        variables: {
          businessId
        }
      })
    },
    [getCompanyInfosQuery]
  )

  const [getDefaultCompanyQuery] = useGetDefaultCompanyLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getDefaultCompany: getDefaultCompanyCompleted }) => {
      dispatch({
        type: 'GET_DEFAULT_COMPANY_SUCCESS',
        payload: getDefaultCompanyCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_DEFAULT_COMPANY_ERROR', 'registerCompany')
      dispatch(action)
    }
  })

  const getDefaultCompany = useCallback(() => {
    dispatch({
      type: 'GET_DEFAULT_COMPANY'
    })

    getDefaultCompanyQuery()
  }, [getDefaultCompanyQuery])

  useEffect(() => {
    if (!state.defaultCompany) {
      getDefaultCompany()
    }
  }, [getDefaultCompany, state.defaultCompany])

  const [registerCompanyLegalRepresentativeMutation] = useRegisterCompanyLegalRepresentativeMutation({
    onCompleted: async ({ registerCompanyLegalRepresentative: registerCompanyLegalRepresentativeCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_LEGAL_REPRESENTATIVE_SUCCESS',
        payload: registerCompanyLegalRepresentativeCompleted
      })

      navigate('/company-legal-representatives')
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'REGISTER_COMPANY_LEGAL_REPRESENTATIVE_ERROR',
        'registerCompanyLegalRepresentative'
      )
      dispatch(action)
    }
  })

  const registerCompanyLegalRepresentative = useCallback(
    (params: CompanyLegalRepresentativeInput) => {
      dispatch({
        type: 'REGISTER_COMPANY_LEGAL_REPRESENTATIVE'
      })

      registerCompanyLegalRepresentativeMutation({
        variables: {
          params
        }
      })
    },
    [registerCompanyLegalRepresentativeMutation]
  )

  const [deleteCompanyLegalRepresentativeMutation] = useDeleteCompanyLegalRepresentativeMutation({
    onCompleted: async ({ deleteCompanyLegalRepresentative: deleteCompanyLegalRepresentativeCompleted }) => {
      dispatch({
        type: 'DELETE_COMPANY_LEGAL_REPRESENTATIVE_SUCCESS',
        payload: deleteCompanyLegalRepresentativeCompleted
      })

      navigate('/company-legal-representatives')
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'DELETE_COMPANY_LEGAL_REPRESENTATIVE_ERROR',
        'deleteCompanyLegalRepresentative'
      )
      dispatch(action)
    }
  })

  const deleteCompanyLegalRepresentative = useCallback(
    (companyLegalRepresentativeId: string) => {
      dispatch({
        type: 'DELETE_COMPANY_LEGAL_REPRESENTATIVE'
      })

      deleteCompanyLegalRepresentativeMutation({
        variables: {
          companyLegalRepresentativeId
        }
      })
    },
    [deleteCompanyLegalRepresentativeMutation]
  )

  const [getCompanyLegalRepresentativesByCompanyIdQuery] = useGetCompanyLegalRepresentativesByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({
      getCompanyLegalRepresentativesByCompanyId: getCompanyLegalRepresentativesByCompanyIdCompleted
    }) => {
      dispatch({
        type: 'GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyLegalRepresentativesByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID_ERROR',
        'getCompanyLegalRepresentativesByCompanyId'
      )
      dispatch(action)
    }
  })

  const getCompanyLegalRepresentativesByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_LEGAL_REPRESENTATIVES_BY_COMPANY_ID'
      })

      getCompanyLegalRepresentativesByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyLegalRepresentativesByCompanyIdQuery]
  )

  const [registerCompanyPartnerMutation] = useRegisterCompanyPartnerMutation({
    onCompleted: async ({ registerCompanyPartner: registerCompanyPartnerCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_PARTNER_SUCCESS',
        payload: registerCompanyPartnerCompleted
      })

      navigate('/company-partners')
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'REGISTER_COMPANY_LEGAL_REPRESENTATIVE_ERROR',
        'registerCompanyLegalRepresentative'
      )
      dispatch(action)
    }
  })

  const registerCompanyPartner = useCallback(
    (params: CompanyPartnerInput) => {
      dispatch({
        type: 'REGISTER_COMPANY_PARTNER'
      })

      registerCompanyPartnerMutation({
        variables: {
          params
        }
      })
    },
    [registerCompanyPartnerMutation]
  )

  const [deleteCompanyPartnerMutation] = useDeleteCompanyPartnerMutation({
    onCompleted: async ({ deleteCompanyPartner: deleteCompanyPartnerCompleted }) => {
      dispatch({
        type: 'DELETE_COMPANY_PARTNER_SUCCESS',
        payload: deleteCompanyPartnerCompleted
      })

      navigate('/company-partners')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'DELETE_COMPANY_PARTNER_ERROR', 'deleteCompanyPartner')
      dispatch(action)
    }
  })

  const deleteCompanyPartner = useCallback(
    (companyPartnerId: string) => {
      dispatch({
        type: 'DELETE_COMPANY_PARTNER'
      })

      deleteCompanyPartnerMutation({
        variables: {
          companyPartnerId
        }
      })
    },
    [deleteCompanyPartnerMutation]
  )

  const [getCompanyPartnersByCompanyIdQuery] = useGetCompanyPartnersByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getCompanyPartnersByCompanyId: getCompanyPartnersByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_PARTNERS_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyPartnersByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_COMPANY_PARTNERS_BY_COMPANY_ID_ERROR',
        'getCompanyPartnersCompanyId'
      )
      dispatch(action)
    }
  })

  const getCompanyPartnersByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_PARTNERS_BY_COMPANY_ID'
      })

      getCompanyPartnersByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyPartnersByCompanyIdQuery]
  )

  const [deleteCompanyCustomerMutation] = useDeleteCompanyCustomerMutation({
    onCompleted: async ({ deleteCompanyCustomer: deleteCompanyCustomerCompleted }) => {
      dispatch({
        type: 'DELETE_COMPANY_CUSTOMER_SUCCESS',
        payload: deleteCompanyCustomerCompleted
      })

      navigate('/company-customers-and-suppliers')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'DELETE_COMPANY_CUSTOMER_ERROR', 'deleteCompanyCustomer')
      dispatch(action)
    }
  })

  const deleteCompanyCustomer = useCallback(
    (companyCustomerId: string) => {
      dispatch({
        type: 'DELETE_COMPANY_CUSTOMER'
      })

      deleteCompanyCustomerMutation({
        variables: {
          companyCustomerId
        }
      })
    },
    [deleteCompanyCustomerMutation]
  )

  const [deleteCompanySupplierMutation] = useDeleteCompanySupplierMutation({
    onCompleted: async ({ deleteCompanySupplier: deleteCompanySupplierCompleted }) => {
      dispatch({
        type: 'DELETE_COMPANY_SUPPLIER_SUCCESS',
        payload: deleteCompanySupplierCompleted
      })

      navigate('/company-customers-and-suppliers')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'DELETE_COMPANY_SUPPLIER_ERROR', 'deleteCompanySupplier')
      dispatch(action)
    }
  })

  const deleteCompanySupplier = useCallback(
    (companySupplierId: string) => {
      dispatch({
        type: 'DELETE_COMPANY_SUPPLIER'
      })

      deleteCompanySupplierMutation({
        variables: {
          companySupplierId
        }
      })
    },
    [deleteCompanySupplierMutation]
  )

  const [getCompanyCustomersAndSuppliersByCompanyIdQuery] = useGetCompanyCustomersAndSuppliersByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({
      getCompanyCustomersAndSuppliersByCompanyId: getCompanyCustomersAndSuppliersByCompanyIdCompleted
    }) => {
      dispatch({
        type: 'GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyCustomersAndSuppliersByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID_ERROR',
        'getCompanyCustomersAndSuppliersByCompanyId'
      )
      dispatch(action)
    }
  })

  const getCompanyCustomersAndSuppliersByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_CUSTOMERS_AND_SUPPLIERS_BY_COMPANY_ID'
      })

      getCompanyCustomersAndSuppliersByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyCustomersAndSuppliersByCompanyIdQuery]
  )

  const [registerCompanyCustomerOrSupplierMutation] = useRegisterCompanyCustomerOrSupplierMutation({
    onCompleted: async ({ registerCompanyCustomerOrSupplier: registerCompanyCustomerOrSupplierCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER_SUCCESS',
        payload: registerCompanyCustomerOrSupplierCompleted
      })

      navigate('/company-customers-and-suppliers')
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER_ERROR',
        'registerCompanyCustomerOrSupplier'
      )
      dispatch(action)
    }
  })

  const registerCompanyCustomerOrSupplier = useCallback(
    (params: CompanyCustomerOrSupplierInput) => {
      dispatch({
        type: 'REGISTER_COMPANY_CUSTOMER_OR_SUPPLIER'
      })

      registerCompanyCustomerOrSupplierMutation({
        variables: {
          params
        }
      })
    },
    [registerCompanyCustomerOrSupplierMutation]
  )

  const [getWitnessesByCompanyIdQuery] = useGetWitnessesByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getWitnessesByCompanyId: getWitnessesByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_WITNESSES_BY_COMPANY_ID_SUCCESS',
        payload: getWitnessesByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_WITNESSES_BY_COMPANY_ID_ERROR', 'getWitnessesByCompanyId')
      dispatch(action)
    }
  })

  const getWitnessesByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_WITNESSES_BY_COMPANY_ID'
      })

      getWitnessesByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getWitnessesByCompanyIdQuery]
  )

  const [registerWitnessMutation] = useRegisterWitnessMutation({
    onCompleted: async ({ registerWitness: registerWitnessCompleted }) => {
      dispatch({
        type: 'REGISTER_WITNESS_SUCCESS',
        payload: registerWitnessCompleted
      })

      navigate('/company-witnesses')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'REGISTER_WITNESS_ERROR', 'registerWitness')
      dispatch(action)
    }
  })

  const registerWitness = useCallback(
    (params: WitnessInput) => {
      dispatch({
        type: 'REGISTER_WITNESS'
      })

      registerWitnessMutation({
        variables: {
          params
        }
      })
    },
    [registerWitnessMutation]
  )

  const [registerCompanyTaxesMutation] = useRegisterCompanyTaxesMutation({
    onCompleted: async ({ registerCompanyTaxes: registerCompanyTaxesCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_TAXES_SUCCESS',
        payload: registerCompanyTaxesCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'REGISTER_COMPANY_TAXES_ERROR', 'registerCompanyTaxes')
      dispatch(action)
    }
  })

  const registerCompanyTaxes = useCallback(
    (params: CompanyTaxesInput) => {
      dispatch({
        type: 'REGISTER_COMPANY_TAXES'
      })

      registerCompanyTaxesMutation({
        variables: {
          params
        }
      })
    },
    [registerCompanyTaxesMutation]
  )

  const [getCompanyTaxesByCompanyIdQuery] = useGetCompanyTaxesByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getCompanyTaxesByCompanyId: getCompanyTaxesByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_TAXES_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyTaxesByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_COMPANY_TAXES_BY_COMPANY_ID_ERROR', 'getCompanyTaxesByCompanyId')
      dispatch(action)
    }
  })

  const getCompanyTaxesByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_TAXES_BY_COMPANY_ID'
      })

      getCompanyTaxesByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyTaxesByCompanyIdQuery]
  )

  const [getCompanyUsersByCompanyIdQuery] = useGetCompanyUsersByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getCompanyUsersByCompanyId: getCompanyUsersByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_USERS_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyUsersByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_COMPANY_USERS_BY_COMPANY_ID_ERROR', 'getCompanyUsersByCompanyId')
      dispatch(action)
    }
  })

  const getCompanyUsersByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_USERS_BY_COMPANY_ID'
      })

      getCompanyUsersByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyUsersByCompanyIdQuery]
  )

  const [updateCompanyUserMutation] = useUpdateCompanyUserMutation({
    onCompleted: async ({ updateCompanyUser: updateCompanyUserCompleted }) => {
      dispatch({
        type: 'UPDATE_COMPANY_USER_SUCCESS',
        payload: updateCompanyUserCompleted
      })

      navigate('/company-users')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'UPDATE_COMPANY_USER_ERROR', 'UpdateCompanyUser')
      dispatch(action)
    }
  })

  const updateCompanyUser = useCallback(
    (params: Omit<UpdateCompanyUserInput, 'whiteLabelShortName' | 'lastAccess'>) => {
      dispatch({
        type: 'UPDATE_COMPANY_USER'
      })

      updateCompanyUserMutation({
        variables: {
          params: {
            whiteLabelShortName: originInfos?.storeShortName || '',
            ...params
          }
        }
      })
    },
    [updateCompanyUserMutation, originInfos?.storeShortName]
  )

  const [registerCompanyUserMutation] = useRegisterCompanyUserMutation({
    onCompleted: async ({ registerCompanyUser: registerCompanyUserCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_USER_SUCCESS',
        payload: registerCompanyUserCompleted
      })

      navigate('/company-users')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'REGISTER_COMPANY_USER_ERROR', 'registerCompanyUser')
      dispatch(action)
    }
  })

  const registerCompanyUser = useCallback(
    (params: Omit<CompanyUserInput, 'whiteLabelShortName'>) => {
      dispatch({
        type: 'REGISTER_COMPANY_USER'
      })

      registerCompanyUserMutation({
        variables: {
          params: {
            whiteLabelShortName: originInfos?.storeShortName || '',
            ...params,
            group: translateGroup(groups?.[0])
          }
        }
      })
    },
    [registerCompanyUserMutation, originInfos?.storeShortName, groups]
  )

  const [getCompanyDocumentsByCompanyIdQuery] = useGetCompanyDocumentsByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getCompanyDocumentsByCompanyId: getCompanyDocumentsByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyDocumentsByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_ERROR',
        'getCompanyDocumentsByCompanyId'
      )
      dispatch(action)
    }
  })

  const getCompanyDocumentsByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID'
      })

      getCompanyDocumentsByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyDocumentsByCompanyIdQuery]
  )

  const [deleteCompanyDocumentMutation] = useDeleteCompanyDocumentMutation({
    onCompleted: async ({ deleteCompanyDocument: deleteCompanyDocumentCompleted }) => {
      dispatch({
        type: 'DELETE_COMPANY_DOCUMENT_SUCCESS',
        payload: deleteCompanyDocumentCompleted
      })

      navigate(`/company-documents`)
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'DELETE_COMPANY_DOCUMENT_ERROR', 'deleteCompanyDocument')
      dispatch(action)
    }
  })

  const deleteCompanyDocument = useCallback(
    (companyDocumentId: string) => {
      dispatch({
        type: 'DELETE_COMPANY_DOCUMENT'
      })

      deleteCompanyDocumentMutation({
        variables: {
          companyDocumentId
        }
      })
    },
    [deleteCompanyDocumentMutation]
  )

  const uploadCompanyDocument = useCallback(
    async (params: IUploadCompanyDocumentInput) => {
      dispatch({
        type: 'UPLOAD_COMPANY_DOCUMENT'
      })
      try {
        const formData = new FormData()

        formData.append('originId', params?.companyId)
        formData.append('documentTypeId', params?.documentTypeId)
        formData.append('documentTypeName', params?.documentTypeName)
        formData.append('files', params?.file)

        const response = await api?.post(`${V1_URL}/files/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        const formatResponse = {
          id: response?.data[0].fileId,
          originTypeId: response?.data[0].originTypeId,
          originTypeName: response?.data[0].originTypeName,
          companyId: response?.data[0].originId,
          documentTypeId: response?.data[0].documentTypeId,
          documentTypeName: response?.data[0].documentTypeName,
          statusId: response?.data[0].statusId,
          statusName: response?.data[0].statusName,
          name: response?.data[0].name,
          type: response?.data[0].type,
          size: response?.data[0].size,
          lastModifiedDate: response?.data[0].lastModifiedDate,
          key: response?.data[0].key,
          registerDate: response?.data[0].registerDate,
          uploadStatusId: response?.data[0].uploadStatusId,
          url: response?.data[0].url,
          description: response?.data[0].description,
          exists: response?.data[0].exists,
          validated: response?.data[0].validated,
          validUntil: response?.data[0].validUntil,
          docTypeId: response?.data[0].docTypeId,
          makeDocumentAvaliable: response?.data[0].makeDocumentAvaliable,
          pendingId: response?.data[0].pendingId,
          removePending: response?.data[0].removePending,
          inDossie: response?.data[0].inDossie,
          downloadUrl: response?.data[0].downloadUrl
        }

        dispatch({
          type: 'GET_COMPANY_DOCUMENTS_BY_COMPANY_ID_SUCCESS',
          payload: [...state.companyDocuments, formatResponse]
        })

        dispatch({
          type: 'UPLOAD_COMPANY_DOCUMENT_SUCCESS',
          payload: response
        })
      } catch (err) {
        const error = err as { message: string }

        dispatch({
          type: 'UPLOAD_COMPANY_DOCUMENT_ERROR',
          payload: {
            location: 'UploadCompanyDocument',
            message: error.message
          }
        })
      }
    },
    [state.companyDocuments]
  )

  const closeCompanyTaxesModal = () => {
    dispatch({
      type: 'CLOSE_COMPANY_TAXES_MODAL'
    })
  }

  const closeCompanyInfosModal = () => {
    dispatch({
      type: 'CLOSE_COMPANY_INFOS_MODAL'
    })
  }

  const [getCompanyBankAccountInfoByCompanyIdQuery] = useGetCompanyBankAccountInfoByCompanyIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getCompanyBankAccountInfoByCompanyId: getCompanyBankAccountInfoByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyBankAccountInfoByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID_ERROR',
        'getCompanyDocumentsByCompanyId'
      )
      dispatch(action)
    }
  })

  const getCompanyBankAccountInfoByCompanyId = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_BANK_ACCOUNT_INFO_BY_COMPANY_ID'
      })

      getCompanyBankAccountInfoByCompanyIdQuery({
        variables: {
          companyId
        }
      })
    },
    [getCompanyBankAccountInfoByCompanyIdQuery]
  )

  const [deleteCompanyBankAccountInfoMutation] = useDeleteCompanyBankAccountInfoMutation({
    onCompleted: async ({ deleteCompanyBankAccountInfo: deleteCompanyBankAccountInfoCompleted }) => {
      dispatch({
        type: 'DELETE_COMPANY_BANK_ACCOUNT_INFO_SUCCESS',
        payload: state.companyBankAccountInfos?.filter(
          (companyBankAccountInfo) => companyBankAccountInfo?.id !== deleteCompanyBankAccountInfoCompleted?.id
        )
      })
      navigate('/company-bank-account-info')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'DELETE_COMPANY_BANK_ACCOUNT_INFO_ERROR', 'deleteCompanyBankAccountInfo')
      showSnackbar('Erro ao deletar conta bancária. Por favor, tente novamente.', 'error')

      dispatch(action)
    }
  })

  const deleteCompanyBankAccountInfo = useCallback(
    (companyBankAccountInfoId: string, companyId: string) => {
      dispatch({
        type: 'DELETE_COMPANY_BANK_ACCOUNT_INFO'
      })

      deleteCompanyBankAccountInfoMutation({
        variables: {
          companyId,
          companyBankAccountInfoId
        }
      })
    },
    [deleteCompanyBankAccountInfoMutation]
  )

  const toggleCompanyBankAccountInfoModal = useCallback(
    (id: string) => {
      dispatch({
        type: 'TOGGLE_COMPANY_BANK_ACCOUNT_INFO_MODAL',
        payload: { open: !state?.deleteCompanyBankAccountInfoModal?.open, id }
      })
    },
    [state?.deleteCompanyBankAccountInfoModal]
  )

  const [registerCompanyBankAccountInfoMutation] = useRegisterCompanyBankAccountInfoMutation({
    onCompleted: async ({ registerCompanyBankAccountInfo: registerCompanyBankAccountInfoCompleted }) => {
      dispatch({
        type: 'REGISTER_COMPANY_BANK_ACCOUNT_INFO_SUCCESS',
        payload: registerCompanyBankAccountInfoCompleted
      })

      navigate('/company-bank-account-info')
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'REGISTER_COMPANY_BANK_ACCOUNT_INFO_ERROR',
        'registerCompanyBankAccountInfo'
      )
      showSnackbar('Erro ao salvar dados. Por favor, tente novamente.', 'error')

      dispatch(action)
    }
  })

  const registerCompanyBankAccountInfo = useCallback(
    (params: BankAccountInfoInput) => {
      dispatch({
        type: 'REGISTER_COMPANY_BANK_ACCOUNT_INFO'
      })

      registerCompanyBankAccountInfoMutation({
        variables: {
          params
        }
      })
    },
    [registerCompanyBankAccountInfoMutation]
  )

  const [sendToAnalysisMutation] = useSendToAnalysisMutation({
    onCompleted: async ({ sendToAnalysis: sendToAnalysisCompleted }) => {
      dispatch({
        type: 'SEND_TO_ANALYSIS_SUCCESS',
        payload: sendToAnalysisCompleted
      })
      getDefaultCompany()
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'SEND_TO_ANALYSIS_ERROR', 'registerCompanyBankAccountInfo')
      dispatch(action)
    }
  })

  const sendToAnalysis = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'SEND_TO_ANALYSIS'
      })

      sendToAnalysisMutation({
        variables: {
          companyId
        }
      })
    },
    [sendToAnalysisMutation]
  )

  const closeSendToAnalysisModal = () => {
    dispatch({
      type: 'CLOSE_SEND_TO_ANALYSIS_MODAL'
    })
  }

  const [getCompanyRevenueQuery] = useGetCompanyRevenueLazyQuery({
    onCompleted: async ({ getCompanyRevenue: getCompanyRevenueCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_REVENUE_SUCCESS',
        payload: getCompanyRevenueCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_COMPANY_REVENUE_ERROR', 'getCompanyRevenue')
      dispatch(action)
    }
  })

  const getCompanyRevenue = useCallback(
    async (companyId: string) => {
      dispatch({
        type: 'GET_COMPANY_REVENUE'
      })

      await getCompanyRevenueQuery({
        fetchPolicy: 'no-cache',
        variables: {
          companyId
        }
      })
    },
    [getCompanyRevenueQuery]
  )

  const [sendCompanyRevenueMutation] = useSendCompanyRevenueMutation({
    onCompleted: async ({ sendCompanyRevenue: sendCompanyRevenueCompleted }) => {
      dispatch({
        type: 'SEND_COMPANY_REVENUE_SUCCESS',
        payload: sendCompanyRevenueCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'SEND_COMPANY_REVENUE_ERROR', 'sendCompanyRevenue')
      dispatch(action)
    }
  })

  const sendCompanyRevenue = useCallback(
    async (payload: SendCompanyRevenueInput) => {
      dispatch({
        type: 'SEND_COMPANY_REVENUE'
      })

      await sendCompanyRevenueMutation({
        variables: {
          payload
        }
      })
    },
    [sendCompanyRevenueMutation]
  )

  const [getContractsByCompanyIdQuery] = useGetContractsByCompanyIdLazyQuery({
    onCompleted: async ({ getContractsByCompanyId: getContractsByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_CONTRACTS_BY_COMPANY_ID_SUCCESS',
        payload: getContractsByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CONTRACTS_BY_COMPANY_ID_ERROR', 'getContractsByCompanyId')
      dispatch(action)
    }
  })

  const getContractsByCompanyId = useCallback(
    async (companyId: string) => {
      dispatch({
        type: 'GET_CONTRACTS_BY_COMPANY_ID'
      })

      await getContractsByCompanyIdQuery({
        fetchPolicy: 'no-cache',
        variables: {
          companyId
        }
      })
    },
    [getContractsByCompanyIdQuery]
  )

  const [getContractDownloadUrlQuery] = useGetContractDownloadUrlLazyQuery({
    onCompleted: async ({ getContractDownloadUrl: getContractDownloadUrlCompleted }) => {
      downloadFile(getContractDownloadUrlCompleted?.url)
      dispatch({
        type: 'GET_CONTRACT_DOWNLOAD_URL_SUCCESS',
        payload: getContractDownloadUrlCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CONTRACT_DOWNLOAD_URL_ERROR', 'getContractDownloadUrl')
      dispatch(action)
    }
  })

  const getContractDownloadUrl = useCallback(
    async (contractId: string) => {
      dispatch({
        type: 'GET_CONTRACT_DOWNLOAD_URL'
      })

      await getContractDownloadUrlQuery({
        fetchPolicy: 'no-cache',
        variables: {
          contractId
        }
      })
    },
    [getContractDownloadUrlQuery]
  )

  const [updateCompanyBankAccountInfoMutation] = useUpdateCompanyBankAccountInfoMutation({
    onCompleted: async ({ updateCompanyBankAccountInfo: updateCompanyBankAccountInfoCompleted }) => {
      dispatch({
        type: 'UPDATE_COMPANY_BANK_ACCOUNT_INFO_SUCCESS',
        payload: updateCompanyBankAccountInfoCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'UPDATE_COMPANY_BANK_ACCOUNT_INFO_ERROR', 'updateCompanyBankAccountInfo')
      dispatch(action)
    }
  })

  const updateCompanyBankAccountInfo = useCallback(
    (params: UpdateBankAccountInfoInput) => {
      dispatch({
        type: 'UPDATE_COMPANY_BANK_ACCOUNT_INFO'
      })

      updateCompanyBankAccountInfoMutation({
        variables: {
          params
        }
      })
    },
    [updateCompanyBankAccountInfoMutation]
  )

  const [getCompaniesFromUserQuery] = useGetCompaniesFromUserLazyQuery({
    onCompleted: async ({ getCompaniesFromUser: getCompaniesFromUserCompleted }) => {
      dispatch({
        type: 'GET_COMPANIES_FROM_USER_SUCCESS',
        payload: getCompaniesFromUserCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_COMPANIES_FROM_USER_ERROR', 'getCompaniesFromUser')
      dispatch(action)
    }
  })

  const getCompaniesFromUser = useCallback(() => {
    dispatch({
      type: 'GET_COMPANIES_FROM_USER'
    })

    getCompaniesFromUserQuery({
      fetchPolicy: 'no-cache'
    })
  }, [getCompaniesFromUserQuery])

  const [updateDefaultCompanyMutation] = useUpdateDefaultCompanyMutation({
    onCompleted: async ({ updateDefaultCompany: updateDefaultCompanyCompleted }) => {
      dispatch({
        type: 'UPDATE_DEFAULT_COMPANY_SUCCESS',
        payload: updateDefaultCompanyCompleted
      })

      getDefaultCompany()
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'UPDATE_DEFAULT_COMPANY_ERROR', 'updateDefaultCompany')
      dispatch(action)
    }
  })

  const updateDefaultCompany = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'UPDATE_DEFAULT_COMPANY'
      })

      updateDefaultCompanyMutation({
        fetchPolicy: 'no-cache',
        variables: {
          companyId
        }
      })
    },
    [updateDefaultCompanyMutation]
  )

  const [getCustomersQuery] = useGetCustomersLazyQuery({
    onCompleted: async ({ getCustomers: getCustomersCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMERS_SUCCESS',
        payload: getCustomersCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CUSTOMERS_ERROR', 'getCustomers')
      dispatch(action)
    }
  })

  const getCustomers = useCallback(
    (params: GetCustomersInput) => {
      dispatch({
        type: 'GET_CUSTOMERS'
      })

      getCustomersQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomersQuery]
  )

  const [getCustomerProfileQuery] = useGetCustomerProfileLazyQuery({
    onCompleted: async ({ getCustomerProfile: getCustomerProfileCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMER_PROFILE_SUCCESS',
        payload: getCustomerProfileCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CUSTOMER_PROFILE_ERROR', 'getCustomerProfile')
      dispatch(action)
    }
  })

  const getCustomerProfile = useCallback(
    (params: GetCustomerProfileInput) => {
      dispatch({
        type: 'GET_CUSTOMER_PROFILE'
      })

      getCustomerProfileQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomerProfileQuery]
  )

  const [getCompanyRatingByCompanyIdQuery] = useGetCompanyRatingByCompanyIdLazyQuery({
    onCompleted: async ({ getCompanyRatingByCompanyId: getCompanyRatingByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_COMPANY_RATING_BY_COMPANY_ID_SUCCESS',
        payload: getCompanyRatingByCompanyIdCompleted
      })
    },
    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_COMPANY_RATING_BY_COMPANY_ID_ERROR', 'getCompanyRatingByCompanyId')
      dispatch(action)
    }
  })

  const getCompanyRatingByCompanyId = useCallback(
    (companyId: string, buyerId: string) => {
      dispatch({
        type: 'GET_COMPANY_RATING_BY_COMPANY_ID'
      })

      getCompanyRatingByCompanyIdQuery({
        fetchPolicy: 'no-cache',
        variables: {
          companyId,
          buyerId
        }
      })
    },
    [getCompanyRatingByCompanyIdQuery]
  )

  const [getCustomerDataByCompanyIdQuery] = useGetCustomerDataByCompanyIdLazyQuery({
    onCompleted: async ({ getCustomerDataByCompanyId: getCustomerDataByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMER_DATA_BY_COMPANY_ID_SUCCESS',
        payload: getCustomerDataByCompanyIdCompleted
      })
    },
    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CUSTOMER_DATA_BY_COMPANY_ID_ERROR', 'getCustomerDataByCompanyId')
      dispatch(action)
    }
  })

  const getCustomerDataByCompanyId = useCallback(
    (params: GetCustomerDataByCompanyIdInput) => {
      dispatch({
        type: 'GET_CUSTOMER_DATA_BY_COMPANY_ID'
      })

      getCustomerDataByCompanyIdQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomerDataByCompanyIdQuery]
  )

  const [mutateCompanyRatingsMutation] = useMutateCompanyRatingsMutation({
    onCompleted: async ({ mutateCompanyRatings: mutateCompanyRatingsCompleted }) => {
      dispatch({
        type: 'MUTATE_COMPANY_RATINGS_SUCCESS',
        payload: mutateCompanyRatingsCompleted
      })

      showSnackbar('Avaliação enviada', 'success')

      if (mutateCompanyRatingsCompleted.companyRating === 'disapproved') {
        navigate('/negative-rating-reasons')
        showSnackbar('Selecione um motivo', 'info')
      }
    },

    onError: (error) => {
      showSnackbar('Erro ao enviar avaliação', 'error')
      const action = handleGraphQLError(error, 'MUTATE_COMPANY_RATINGS_ERROR', 'mutateCompanyRatings')
      dispatch(action)
    }
  })

  const clearCompanyRatings = useCallback(() => {
    dispatch({
      type: 'CLEAR_COMPANY_RATING'
    })
  }, [])

  const mutateCompanyRatings = useCallback(
    (params: MutateCompanyRatingsInput) => {
      dispatch({
        type: 'MUTATE_COMPANY_RATINGS'
      })

      mutateCompanyRatingsMutation({
        variables: {
          params
        }
      })
    },
    [mutateCompanyRatingsMutation]
  )

  const [getCreditAnalysisReportByCompanyIdQuery] = useGetCreditAnalysisReportByCompanyIdLazyQuery({
    onCompleted: async ({ getCreditAnalysisReportByCompanyId: getCreditAnalysisReportByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID_SUCCESS',
        payload: getCreditAnalysisReportByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID_ERROR',
        'getCreditAnalysisReportByCompanyId'
      )
      dispatch(action)
    }
  })

  const getCreditAnalysisReportByCompanyId = useCallback(
    (params: GetCreditAnalysisReportByCompanyIdInput) => {
      dispatch({
        type: 'GET_CREDIT_ANALYSIS_REPORT_BY_COMPANY_ID'
      })

      getCreditAnalysisReportByCompanyIdQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCreditAnalysisReportByCompanyIdQuery]
  )

  const [inDossieDocumentsGroupedByDocumentTypeQuery] = useInDossieDocumentsGroupedByDocumentTypeLazyQuery({
    onCompleted: async ({
      inDossieDocumentsGroupedByDocumentType: inDossieDocumentsGroupedByDocumentTypeCompleted
    }) => {
      dispatch({
        type: 'IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE_SUCCESS',
        payload: inDossieDocumentsGroupedByDocumentTypeCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE_ERROR',
        'inDossieDocumentsGroupedByDocumentType'
      )
      dispatch(action)
    }
  })

  const inDossieDocumentsGroupedByDocumentType = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'IN_DOSSIE_DOCUMENTS_GROUPED_BY_DOCUMENT_TYPE'
      })

      inDossieDocumentsGroupedByDocumentTypeQuery({
        fetchPolicy: 'no-cache',
        variables: {
          companyId
        }
      })
    },
    [inDossieDocumentsGroupedByDocumentTypeQuery]
  )

  const [getZipDownloadUrlFromAllDocumentsQuery] = useGetZipDownloadUrlFromAllDocumentsLazyQuery({
    onCompleted: async ({ getZipDownloadUrlFromAllDocuments: getZipDownloadUrlFromAllDocumentsCompleted }) => {
      dispatch({
        type: 'GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS_SUCCESS',
        payload: getZipDownloadUrlFromAllDocumentsCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS_ERROR',
        'getZipDownloadUrlFromAllDocuments'
      )
      dispatch(action)
    }
  })

  const clearZipDownloadUrl = useCallback(() => {
    dispatch({
      type: 'CLEAR_ZIP_DOWNLOAD_URL'
    })
  }, [])

  const getZipDownloadUrlFromAllDocuments = useCallback(
    (companyId: string) => {
      dispatch({
        type: 'GET_ZIP_DOWNLOAD_URL_FROM_ALL_DOCUMENTS'
      })

      getZipDownloadUrlFromAllDocumentsQuery({
        fetchPolicy: 'no-cache',
        variables: {
          companyId
        }
      })
    },
    [getZipDownloadUrlFromAllDocumentsQuery]
  )

  const [getZipDownloadUrlByDocumentGroupQuery] = useGetZipDownloadUrlByDocumentGroupLazyQuery({
    onCompleted: async ({ getZipDownloadUrlByDocumentGroup: getZipDownloadUrlByDocumentGroupCompleted }) => {
      dispatch({
        type: 'GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP_SUCCESS',
        payload: getZipDownloadUrlByDocumentGroupCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP_ERROR',
        'getZipDownloadUrlByDocumentGroup'
      )
      dispatch(action)
    }
  })

  const clearZipDownloadUrlByDocumentGroup = useCallback(() => {
    dispatch({
      type: 'CLEAR_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP'
    })
  }, [])

  const getZipDownloadUrlByDocumentGroup = useCallback(
    (companyId: string, documentGroupId: number) => {
      dispatch({
        type: 'GET_ZIP_DOWNLOAD_URL_BY_DOCUMENT_GROUP'
      })

      getZipDownloadUrlByDocumentGroupQuery({
        fetchPolicy: 'no-cache',
        variables: {
          companyId,
          documentGroupId
        }
      })
    },
    [getZipDownloadUrlByDocumentGroupQuery]
  )

  const [createRatingReasonsMutation] = useCreateRatingReasonsMutation({
    onCompleted: async ({ createRatingReasons: createRatingReasonsCompleted }) => {
      dispatch({
        type: 'CREATE_RATING_REASONS_SUCCESS',
        payload: createRatingReasonsCompleted
      })
      showSnackbar('Avaliação enviada', 'success')
      navigate('/customers-list')
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'CREATE_RATING_REASONS_ERROR', 'createRatingReasons')
      dispatch(action)
      showSnackbar('Avaliação apresentou erro, tente novamente mais tarde', 'error')
      navigate('/customers-list')
    }
  })

  const clearCreateRatingReasons = useCallback(() => {
    dispatch({
      type: 'CLEAR_CREATE_RATING_REASONS'
    })
  }, [])

  const createRatingReasons = useCallback(
    (params: CreateRatingReasonsInput) => {
      dispatch({
        type: 'CREATE_RATING_REASONS'
      })

      createRatingReasonsMutation({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [createRatingReasonsMutation]
  )

  const [getReputationByCompanyIdQuery] = useGetReputationByCompanyIdLazyQuery({
    onCompleted: async ({ getReputationByCompanyId: getReputationByCompanyIdCompleted }) => {
      dispatch({
        type: 'GET_REPUTATION_BY_COMPANY_ID_SUCCESS',
        payload: getReputationByCompanyIdCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_REPUTATION_BY_COMPANY_ID_ERROR', 'getReputationByCompanyId')
      dispatch(action)
    }
  })

  const getReputationByCompanyId = useCallback(
    (params: GetReputationByCompanyIdInput) => {
      dispatch({
        type: 'GET_REPUTATION_BY_COMPANY_ID'
      })

      getReputationByCompanyIdQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getReputationByCompanyIdQuery]
  )

  const [getCustomerReputationFromMetabaseQuery] = useGetCustomerReputationFromMetabaseLazyQuery({
    onCompleted: async ({ getCustomerReputationFromMetabase: getCustomerReputationFromMetabaseCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMER_REPUTATION_FROM_METABASE_SUCCESS',
        payload: getCustomerReputationFromMetabaseCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_CUSTOMER_REPUTATION_FROM_METABASE_ERROR',
        'getCustomerReputationFromMetabase'
      )
      dispatch(action)
    }
  })

  const getCustomerReputationFromMetabase = useCallback(
    (params: GetCustomerReputationFromMetabaseInput) => {
      dispatch({
        type: 'GET_CUSTOMER_REPUTATION_FROM_METABASE'
      })

      getCustomerReputationFromMetabaseQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomerReputationFromMetabaseQuery]
  )

  const [getCustomerAllDocumentsNamesQuery] = useGetCustomerAllDocumentsNamesLazyQuery({
    onCompleted: async ({ getCustomerAllDocumentsNames: getCustomerAllDocumentsNamesCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMER_ALL_DOCUMENTS_NAMES_SUCCESS',
        payload: getCustomerAllDocumentsNamesCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CUSTOMER_ALL_DOCUMENTS_NAMES_ERROR', 'getCustomerAllDocumentsNames')
      dispatch(action)
    }
  })

  const getCustomerAllDocumentsNames = useCallback(
    (params: GetCustomerAllDocumentsNamesInput) => {
      dispatch({
        type: 'GET_CUSTOMER_ALL_DOCUMENTS_NAMES'
      })

      getCustomerAllDocumentsNamesQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomerAllDocumentsNamesQuery]
  )

  const [getCustomerGroupFilesByURLQuery] = useGetCustomerGroupFilesByUrlLazyQuery({
    onCompleted: async ({ getCustomerGroupFilesByURL: getCustomerGroupFilesByURLCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMER_GROUP_FILES_BY_URL_SUCCESS',
        payload: getCustomerGroupFilesByURLCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_CUSTOMER_GROUP_FILES_BY_URL_ERROR', 'getCustomerGroupFilesByURL')
      dispatch(action)
    }
  })

  const clearCustomerGroupFilesURL = useCallback(() => {
    dispatch({
      type: 'CLEAR_CUSTOMER_GROUP_FILES_URL'
    })
  }, [])

  const getCustomerGroupFilesByURL = useCallback(
    (params: GetCustomerGroupFilesByUrlInput) => {
      dispatch({
        type: 'GET_CUSTOMER_GROUP_FILES_BY_URL'
      })

      getCustomerGroupFilesByURLQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomerGroupFilesByURLQuery]
  )

  const [getCustomerAllDocumentsFilesByURLQuery] = useGetCustomerAllDocumentsFilesByUrlLazyQuery({
    onCompleted: async ({ getCustomerAllDocumentsFilesByURL: getCustomerAllDocumentsFilesByURLCompleted }) => {
      dispatch({
        type: 'GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL_SUCCESS',
        payload: getCustomerAllDocumentsFilesByURLCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(
        error,
        'GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL_ERROR',
        'getCustomerAllDocumentsFilesByURL'
      )
      dispatch(action)
    }
  })

  const clearAllFilesURL = useCallback(() => {
    dispatch({
      type: 'CLEAR_ALL_FILES_URL'
    })
  }, [])

  const getCustomerAllDocumentsFilesByURL = useCallback(
    (params: GetCustomerAllDocumentsFilesByUrlInput) => {
      dispatch({
        type: 'GET_CUSTOMER_ALL_DOCUMENTS_FILES_BY_URL'
      })

      getCustomerAllDocumentsFilesByURLQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getCustomerAllDocumentsFilesByURLQuery]
  )

  const [getBankAccountsQuery] = useGetBankAccountsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getBankAccounts: getBankAccountsCompleted }) => {
      dispatch({
        type: 'GET_BANK_ACCOUNTS_SUCCESS',
        payload: getBankAccountsCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_BANK_ACCOUNTS_ERROR', 'getBankAccounts')

      dispatch(action)
    }
  })

  const getBankAccounts = useCallback(() => {
    dispatch({
      type: 'GET_BANK_ACCOUNTS'
    })

    getBankAccountsQuery({
      fetchPolicy: 'no-cache'
    })
  }, [getBankAccountsQuery])

  const [getBankAccountsPermissionsQuery] = useGetBankAccountsPermissionsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getBankAccountsPermissions: getBankAccountsPermissionsCompleted }) => {
      dispatch({
        type: 'GET_BANK_ACCOUNTS_PERMISSIONS_SUCCESS',
        payload: getBankAccountsPermissionsCompleted
      })
    },

    onError: (error) => {
      const action = handleGraphQLError(error, 'GET_BANK_ACCOUNTS_PERMISSIONS_ERROR', 'getBankAccountsPermissions')

      dispatch(action)
    }
  })

  const getBankAccountsPermissions = useCallback(
    (params: GetBankAccountsPermissionsInput) => {
      dispatch({
        type: 'GET_BANK_ACCOUNTS_PERMISSIONS'
      })

      getBankAccountsPermissionsQuery({
        fetchPolicy: 'no-cache',
        variables: {
          params
        }
      })
    },
    [getBankAccountsPermissionsQuery]
  )

  const contextValues = {
    ...state,
    registerCompany,
    getCompanyInfos,
    registerCompanyLegalRepresentative,
    getCompanyLegalRepresentativesByCompanyId,
    registerCompanyPartner,
    getCompanyPartnersByCompanyId,
    getCompanyCustomersAndSuppliersByCompanyId,
    getWitnessesByCompanyId,
    registerWitness,
    registerCompanyTaxes,
    registerCompanyCustomerOrSupplier,
    getCompanyTaxesByCompanyId,
    getCompanyUsersByCompanyId,
    registerCompanyUser,
    deleteCompanyLegalRepresentative,
    updateCompanyUser,
    getCompanyDocumentsByCompanyId,
    deleteCompanyPartner,
    deleteCompanyCustomer,
    deleteCompanySupplier,
    deleteCompanyDocument,
    uploadCompanyDocument,
    closeCompanyTaxesModal,
    closeCompanyInfosModal,
    getCompanyBankAccountInfoByCompanyId,
    deleteCompanyBankAccountInfo,
    toggleCompanyBankAccountInfoModal,
    registerCompanyBankAccountInfo,
    sendToAnalysis,
    closeSendToAnalysisModal,
    getCompanyRevenue,
    sendCompanyRevenue,
    getContractsByCompanyId,
    getContractDownloadUrl,
    updateCompanyBankAccountInfo,
    getCompaniesFromUser,
    updateDefaultCompany,
    getCustomers,
    getCustomerProfile,
    getCompanyRatingByCompanyId,
    getCustomerDataByCompanyId,
    mutateCompanyRatings,
    clearCompanyRatings,
    getCreditAnalysisReportByCompanyId,
    inDossieDocumentsGroupedByDocumentType,
    getZipDownloadUrlFromAllDocuments,
    clearZipDownloadUrl,
    getZipDownloadUrlByDocumentGroup,
    clearZipDownloadUrlByDocumentGroup,
    createRatingReasons,
    clearCreateRatingReasons,
    getReputationByCompanyId,
    getCustomerReputationFromMetabase,
    getCustomerAllDocumentsNames,
    getCustomerGroupFilesByURL,
    getCustomerAllDocumentsFilesByURL,
    clearCustomerGroupFilesURL,
    clearAllFilesURL,
    getBankAccounts,
    getBankAccountsPermissions
  }

  return <CompanyContext.Provider value={contextValues}>{children}</CompanyContext.Provider>
}

export default CompanyState
