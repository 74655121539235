import { Outlet, Route } from 'react-router-dom'
import DefinitivePassword from '../../../pages/DefinitivePassword'
import EmailSendedError from '../../../pages/EmailSendedError'
import EmailSendedSuccess from '../../../pages/EmailSendedSuccess'
import ForgotPassword from '../../../pages/ForgotPassword'
import Login from '../../../pages/Login'
import NoOffer from '../../../pages/NoOffer'
import PasswordCode from '../../../pages/PasswordCode'
import AnonymousRoute from '../../Rules/AnonymousRoute'

export const AnonymousRoutesWrapper = () => (
  <AnonymousRoute>
    <Outlet />
  </AnonymousRoute>
)

export const AnonymousRoutes = (
  <Route path="/" element={<AnonymousRoutesWrapper />}>
    <Route path="sem-oferta" element={<NoOffer />} />
    <Route path="password-recovery/:token" element={<PasswordCode />} />
    <Route path="" element={<Login />} />
    <Route path="forgot-password" element={<ForgotPassword />} />
    <Route path="definitive-password" element={<DefinitivePassword />} />
    <Route path="email-sended-success" element={<EmailSendedSuccess />} />
    <Route path="email-sended-error" element={<EmailSendedError />} />
  </Route>
)
