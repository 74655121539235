import { InputBaseComponentProps } from '@mui/material'
import React from 'react'
import NumberFormat from 'react-number-format'

export interface IInputFormatProps extends InputBaseComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any
}

const InputFormat: React.FC<IInputFormatProps> = React.forwardRef(
  ({ onChange, name, ...others }: IInputFormatProps, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const func = onChange as any

    return (
      <NumberFormat
        name={name}
        getInputRef={ref}
        allowNegative={false}
        onValueChange={({ value }) => {
          func({
            target: {
              name,
              value
            }
          })
        }}
        isNumericString
        decimalSeparator=","
        {...others}
      />
    )
  }
)

InputFormat.displayName = 'InputFormat'

export default InputFormat
