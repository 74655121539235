import { createContext } from 'react'
import { IOnboardingState } from '../types'

const initialState: IOnboardingState = {
  createUser: () => undefined,
  createCompanyProposal: () => undefined,
  answerQuiz: () => undefined,
  getCompanyProposalById: () => undefined,
  isCreatingUser: false,
  isCreatingCompanyProposal: false,
  isAnsweringQuiz: false,
  isGettingCompanyProposalById: false,
  createdUser: { id: '', email: '' },
  answeredQuiz: {
    companyId: '',
    status: ''
  },
  createdCompanyProposal: {
    id: '',
    status: ''
  },
  error: { location: '', message: '' }
}

const OnboardingContext = createContext(initialState)

export { OnboardingContext, initialState }
