import styled from 'styled-components'
import BouncingLoadingBar from '../../../../components/bouncing-loading-bar'
import Carousel from '../../../../components/carousel'
import Header from '../../components/Header'
import PageWrapper from '../../components/PageWrapper'

interface IProps {
  className?: string
}

const Loading: React.FC<IProps> = ({ className }: IProps) => {
  const carouselTexts = [
    <div key={1}>
      <p className="loading-text__carousel___question">Para fornecedores de grandes empresas</p>
      Somos especialistas em antecipação de NF Produto ou Serviço para quem fornece para grandes empresas e só recebe em
      conta corrente.
    </div>,
    <div key={2}>
      <p className="loading-text__carousel___question">Único cadastro para todas as operações</p>
      Validamos e aprovamos os documentos da empresa e dos sócios (kit banco).
    </div>,
    <div key={3}>
      <p className="loading-text__carousel___question">Antecipação não é empréstimo</p>
      Antecipe todas NF de produtos entregues ou serviços prestados.
    </div>,
    <div key={4}>
      <p className="loading-text__carousel___question">Transação 100% online</p>
      Antecipe quando e quanto quiser sem sair do escritório, todos os contratos são assinados com eCPF.
    </div>,
    <div key={5}>
      <p className="loading-text__carousel___question">Operação confidencial</p>
      Fique tranquilo! Não entramos em contato com o seu cliente.
    </div>
  ]

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="loading-container">
        <BouncingLoadingBar />
        <div className="loading-data__analysis">
          <h3>Analisando os dados enviados</h3>
          <h5>Aguarde...</h5>
        </div>
        <div className="loading-text__carousel">
          <div className="loading-text__carousel___animation">
            <Carousel list={carouselTexts} timeOut={4000} width="100%" maxWidth="500px" height="100%" />
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default styled(Loading)`
  .loading-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 80px;
    max-width: 500px;
    height: 100%;
    width: 100%;

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 14px;
      text-align: center;

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }

    h5 {
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
      font-size: 16px;
      font-weight: 400;
    }

    .loading-data__analysis {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 14px;
      padding: 0px 24px 20px;
    }
  }

  .loading-text__carousel {
    align-self: flex-end;
    justify-self: flex-end;
    height: 100%;
    max-width: 500px;
    width: 100%;

    .loading-text__carousel___question {
      color: ${({ theme: { colors } }) => colors?.primary};
      font-weight: bold;
      text-align: center;
      height: 100%;
      width: 100%;
    }

    .loading-text__carousel___animation {
      display: block;
      height: 250px;
      overflow: hidden;
      position: relative;
      padding: 0px 16px;
      width: auto;

      div {
        font-size: 14px;
        text-align: center;

        p {
          font-size: 16px !important;
          margin-bottom: 4px;
        }
      }
    }
  }
`
