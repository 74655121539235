import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import denied from '../../../../assets/icons/denied.svg'
import { useNavigate } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper'
import Header from '../../components/Header'

interface IProps {
  className?: string
}

const DeniedUser = ({ className }: IProps) => {
  const navigate = useNavigate()

  return (
    <PageWrapper className={className}>
      <Header />
      <div className={'denied-user__wrapper'}>
        <img src={denied} style={{ width: 88 }} />
        <p className="denied-user___error-message">Ops… Algo deu errado.</p>
        <p className="denied-user____info-message">Não foi possível concluir o seu cadastro.</p>
        <div className="denied-user_____middle-container">
          <p>Encontramos alguma incompatibilidade nos dados fornecidos e não conseguimos prosseguir.</p>
          <p>Se achar que houve algum engano, você pode tentar novamente.</p>
          <PrimaryButton variant="outlined" data-testid="denied-user-button-test" onClick={() => navigate(-1)}>
            Tentar novamente
          </PrimaryButton>
        </div>
      </div>
    </PageWrapper>
  )
}

export default styled(DeniedUser)`
  .denied-user__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 336px;
    text-align: center;

    @media (max-width: 400px) {
      max-width: 300px;
    }
  }

  .denied-user___error-message {
    color: ${({ theme }) => theme?.styleGuide?.support?.red};
    font-size: 24px;
    font-weight: bold;
    padding: 32px 0px 8px;
  }

  .denied-user____info-message {
    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    font-size: 16px;
    font-weight: bold;
  }

  .denied-user_____middle-container {
    padding-top: 40px;

    p {
      font-size: 14px;
    }

    p:first-child {
      padding-bottom: 28px;
    }
  }

  button {
    margin-top: 72px;
    max-width: 296px;

    @media (max-width: 500px) {
      margin: 36px 0px;
    }
  }
`
