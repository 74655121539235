import { createContext } from 'react'
import { ICompanyState } from '../../types/company'

const initialState: ICompanyState = {
  registerCompany: () => undefined,
  getCompanyInfos: () => undefined,
  getCompanyLegalRepresentativesByCompanyId: () => undefined,
  registerCompanyLegalRepresentative: () => undefined,
  getCompanyPartnersByCompanyId: () => undefined,
  registerCompanyCustomerOrSupplier: () => undefined,
  registerCompanyPartner: () => undefined,
  getCompanyCustomersAndSuppliersByCompanyId: () => undefined,
  getWitnessesByCompanyId: () => undefined,
  registerWitness: () => undefined,
  registerCompanyTaxes: () => undefined,
  getCompanyTaxesByCompanyId: () => undefined,
  getCompanyUsersByCompanyId: () => undefined,
  registerCompanyUser: () => undefined,
  deleteCompanyLegalRepresentative: () => undefined,
  deleteCompanyPartner: () => undefined,
  deleteCompanyCustomer: () => undefined,
  deleteCompanySupplier: () => undefined,
  updateCompanyUser: () => undefined,
  getCompanyDocumentsByCompanyId: () => undefined,
  deleteCompanyDocument: () => undefined,
  uploadCompanyDocument: () => Promise.resolve(),
  closeCompanyTaxesModal: () => undefined,
  closeCompanyInfosModal: () => undefined,
  getCompanyBankAccountInfoByCompanyId: () => undefined,
  deleteCompanyBankAccountInfo: () => undefined,
  toggleCompanyBankAccountInfoModal: () => undefined,
  registerCompanyBankAccountInfo: () => undefined,
  sendToAnalysis: () => undefined,
  closeSendToAnalysisModal: () => undefined,
  getCompanyRevenue: () => undefined,
  sendCompanyRevenue: () => undefined,
  getContractsByCompanyId: () => undefined,
  getContractDownloadUrl: () => undefined,
  updateCompanyBankAccountInfo: () => undefined,
  getCompaniesFromUser: () => undefined,
  updateDefaultCompany: () => undefined,
  getCustomers: () => undefined,
  getCustomerProfile: () => undefined,
  getCompanyRatingByCompanyId: () => undefined,
  getCustomerDataByCompanyId: () => undefined,
  mutateCompanyRatings: () => undefined,
  clearCompanyRatings: () => undefined,
  getCreditAnalysisReportByCompanyId: () => undefined,
  inDossieDocumentsGroupedByDocumentType: () => undefined,
  getZipDownloadUrlFromAllDocuments: () => undefined,
  clearZipDownloadUrl: () => undefined,
  getZipDownloadUrlByDocumentGroup: () => undefined,
  clearZipDownloadUrlByDocumentGroup: () => undefined,
  createRatingReasons: () => undefined,
  clearCreateRatingReasons: () => undefined,
  getReputationByCompanyId: () => undefined,
  getCustomerReputationFromMetabase: () => undefined,
  getCustomerAllDocumentsNames: () => undefined,
  getCustomerGroupFilesByURL: () => undefined,
  getCustomerAllDocumentsFilesByURL: () => undefined,
  clearCustomerGroupFilesURL: () => undefined,
  clearAllFilesURL: () => undefined,
  getBankAccounts: () => undefined,
  getBankAccountsPermissions: () => undefined,
  isRegistratingCompany: false,
  isGettingCompanyInfos: false,
  isGettingDefaultCompany: false,
  isGettingCompanyLegalRepresentativesByCompanyId: false,
  isRegistratingCompanyLegalRepresentatives: false,
  isGettingCompanyPartnersByCompanyId: false,
  isGettingCompanyCustomersAndSuppliersByCompanyId: false,
  isRegistratingCompanyPartner: false,
  isRegistratingCompanyCustomerOrSupplier: false,
  isRegistratingWitness: false,
  isGettingWitnessessByCompanyId: false,
  isRegistratingCompanyTaxes: false,
  isGettingCompanyTaxesByCompanyId: false,
  isGettingCompanyUsersByCompanyId: false,
  isRegistratingCompanyUser: false,
  isUpdatingCompanyUser: false,
  isGettingCompanyDocumentsByCompanyId: false,
  isGettingCompanyBankAccountInfoByCompanyId: false,
  isDeletingCompanyLegalRepresentatives: false,
  isDeletingCompanyPartner: false,
  isDeletingCompanyCustomer: false,
  isDeletingCompanySupplier: false,
  isDeletingCompanyDocument: false,
  isUploadingCompanyDocument: false,
  isDeletingCompanyBankAccountInfo: false,
  isRegistratingBankAccountInfo: false,
  isSendingToAnalysis: false,
  isGettingCompanyRevenue: false,
  isSendingCompanyRevenue: false,
  isGettingContractsByCompanyId: false,
  isGettingContractDownloadUrl: false,
  isUpdatingBankAccountInfo: false,
  isGettingCompaniesFromUser: false,
  isUpdatingDefaultCompany: false,
  isGettingCustomers: false,
  isGettingCustomerProfile: false,
  isGettingCompanyRatingByCompanyId: false,
  isGettingCustomerDataByCompanyId: false,
  isMutatingCompanyRatings: false,
  isGettingCreditAnalysisReportByCompanyId: false,
  isGettingInDossieDocumentsGroupedByDocumentType: false,
  isGettingZipDownloadUrlFromAllDocuments: false,
  isGettingZipDownloadUrlByDocumentGroup: false,
  isCreatingRatingReasons: false,
  isGettingReputationByCompanyId: false,
  isGettingCustomerReputationFromMetabase: false,
  isGettingCustomerAllDocumentsNames: false,
  isGettingCustomerGroupFilesByURL: false,
  isGettingCustomerAllDocumentsFilesByURL: false,
  isGettingBankAccounts: false,
  isGettingBankAccountsPermissions: false,
  companyUsers: [],
  witnesses: [],
  registratedCompany: {
    updatedAt: ''
  },
  registratedWitness: [],
  companyInfos: {
    companySizes: [],
    constitutions: [],
    taxRules: [],
    buyerTypes: []
  },
  companyLegalRepresentatives: [],
  registratedCompanyLegalRepresentative: {},
  companyPartners: [],
  companyCustomersAndSuppliers: [],
  registratedCompanyPartner: {},
  registratedCompanyCustomerOrSupplier: {},
  companyBankAccountInfos: [],
  registratedCompanyUser: {
    id: '',
    companyId: '',
    documentNumber: '',
    email: '',
    fullName: '',
    group: '',
    mobile: '',
    status: ''
  },
  updatedCompanyUser: {
    id: '',
    companyId: '',
    documentNumber: '',
    email: '',
    fullName: '',
    group: '',
    mobile: '',
    status: ''
  },
  uploadedCompanyDocument: {},
  defaultCompany: undefined,
  registratedCompanyTaxes: {},
  companyTaxes: {},
  companyDocuments: [],
  error: {
    location: '',
    message: ''
  },
  companyTaxesModal: false,
  companyInfosModal: false,
  deleteCompanyBankAccountInfoModal: {
    open: false,
    id: ''
  },
  registratedBankAccountInfo: {
    accountAgency: '',
    accountNumber: '',
    accountDigit: '',
    bankName: '',
    companyName: '',
    documentNumber: '',
    id: '',
    kind: '',
    isDefault: false
  },
  sendedToAnalysis: {
    id: ''
  },
  sendToAnalysisModal: false,
  companyRevenue: {
    revenues: [],
    totalCompanyRevenues: 0
  },
  contracts: [],
  updatedBankAccountInfo: {
    accountAgency: '',
    accountNumber: '',
    accountDigit: '',
    bankName: '',
    companyName: '',
    documentNumber: '',
    id: '',
    kind: '',
    isDefault: false
  },
  companiesFromUser: [],
  updatedCompany: { id: '' },
  customers: {
    data: [],
    pagination: {
      totalItems: 0,
      totalPages: 0
    }
  },
  customerProfile: {
    id: '',
    tradeName: '',
    companyName: '',
    cnpj: '',
    email: '',
    phone: '',
    registerDate: ''
  },
  companyRating: {
    companyId: '',
    id: '',
    companyRating: '',
    buyerId: ''
  },

  customerData: {
    business: {
      activity: '',
      codeActivity: '',
      companySize: '',
      constitutionName: '',
      registerAddressCity: '',
      registerAddressComplement: '',
      registerAddressNeighborhood: '',
      registerAddressNumber: '',
      registerAddressState: '',
      registerAddressStreet: '',
      registerAddressZipCode: '',
      registerDate: '',
      birthDate: '',
      activityDescription: ''
    },
    videos: [
      {
        id: '',
        name: '',
        key: '',
        url: ''
      }
    ],
    responsibles: [
      {
        hasEcpf: false,
        id: '',
        name: '',
        type: '',
        document: ''
      }
    ],
    customers: [
      {
        id: '',
        name: ''
      }
    ],
    bankAccounts: [
      {
        accountAgency: '',
        accountNumber: '',
        bankName: '',
        id: '',
        isDefault: false
      }
    ],
    revenues: {
      average: 0
    }
  },
  reports: [
    {
      id: 0,
      groupDocumentName: '',
      url: ''
    }
  ],
  inDossieDocuments: [
    {
      id: '',
      name: ''
    }
  ],
  zipDownloadUrlFromAllDocuments: '',
  zipDownloadUrlByDocumentGroup: '',
  ratingReasons: { id: '' },
  customerReputation: { mediumTerm: 0, totalOperation: 0 },
  customerReputationMetabase: { customerReputationURL: '' },
  allDocumentsNames: { documentsIdsAndNames: [{ id: 0, name: '' }] },
  documentsGroupDownload: { url: '' },
  allDocumentsDownload: { allDocumentsUrl: '' },
  bankAccounts: [],
  bankAccountsPermissions: {
    allowCreate: false,
    allowDelete: false,
    allowRead: false,
    allowUpdate: false
  }
}

const CompanyContext = createContext(initialState)

export { CompanyContext, initialState }
