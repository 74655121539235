import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: ReactNode
}

const PageWrapper: React.FC<IProps> = ({ className, children }: IProps) => {
  return <div className={className}>{children}</div>
}

export default styled(PageWrapper)`
  background: ${({ theme: { colors } }) => colors?.tertiaryBackground};
  min-height: calc(100vh - 96px);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 48px 0;
`
