import { useContext, useEffect } from 'react'
import DefaultRegisterCompanyPage from '../../components/companies/DefaultRegisterCompanyPage'
import List from '../../components/crud/SplitCrud/List'
import { CompanyContext } from '../../states/company/CompanyContext'
import { theme } from '../../styles/theme'
import WithHeader from '../../hocs/WithHeader'

interface IProps {
  className?: string
}

const CompanyWitnesses: React.FC<IProps> = ({ className }: IProps) => {
  const {
    witnesses,
    defaultCompany,
    isGettingDefaultCompany,
    isGettingWitnessessByCompanyId,
    getWitnessesByCompanyId
  } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany?.businessId) {
      getWitnessesByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, getWitnessesByCompanyId])

  const listData = {
    basePath: '/company-witness',
    presentation: {
      title: 'Aqui que você vai cadastrar suas testemunhas.',
      description: ['Estes serão os dados usados para atender as necessidades dos nossos contratos.']
    },
    list: [
      {
        ...witnesses[0],
        id: witnesses[0]?.id || '0',
        personalDocument: witnesses[0]?.personalDocument ? witnesses[0]?.personalDocument : 'Número do RG',
        documentNumber: witnesses[0]?.documentNumber ? witnesses[0]?.documentNumber : 'CPF',
        email: witnesses[0]?.email ? witnesses[0]?.email : 'email',
        tag: {
          status: 'Primeira Testemunha',
          color: theme?.styleGuide?.support?.green
        }
      },
      {
        ...witnesses[1],
        id: witnesses[1]?.id || '1',
        personalDocument: witnesses[1]?.personalDocument ? witnesses[1]?.personalDocument : 'Número do RG',
        documentNumber: witnesses[1]?.documentNumber ? witnesses[1]?.documentNumber : 'CPF',
        email: witnesses[1]?.email ? witnesses[1]?.email : 'email',
        tag: {
          status: 'Segunda Testemunha',
          color: theme?.styleGuide?.support?.blue
        }
      }
    ],
    keysInCard: ['personalDocument', 'documentNumber', 'email'],
    cardTitleKeys: ['fullName'],
    isLoading: isGettingDefaultCompany || isGettingWitnessessByCompanyId,
    showWelcomeMessage: true
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <List data={listData} />
    </DefaultRegisterCompanyPage>
  )
}

export default WithHeader()(CompanyWitnesses)
