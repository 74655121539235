import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Upload from '../../../assets/icons/Upload'

interface IProps {
  className?: string
  tagName: string
  tagColor?: string
  title: string
  redirectTo: string
}

const FileCard: React.FC<IProps> = ({ className, tagName, title, redirectTo }: IProps) => {
  const navigate = useNavigate()

  return (
    <div className={className} data-testid="file-card-test" onClick={() => navigate(redirectTo)}>
      <div className="file-card__left--wrapper">
        <div className="file-card__status--wrapper" data-testid="file-card-tag-test">
          <div className="file-card__status___dot" data-testid="file-card-tag-dot-test" />
          <p className="file-card__status___tag" data-testid="file-card-tag-name-test">
            {tagName?.toUpperCase()}
          </p>
        </div>
        <h4 data-testid="file-card-title-test">{title}</h4>
      </div>
      <div className="file-card__right-side">
        <div className="file-card__box--divisor" />
        <Upload />
      </div>
    </div>
  )
}

export default styled(FileCard)`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme?.colors?.borderColor};
  border-radius: 8px;
  padding: 16px;
  width: 352px;
  cursor: pointer;

  .file-card__right-side {
    display: flex;
    gap: 16px;
    align-items: center;
    .file-card__box--divisor {
      width: 1px;
      height: 100%;
      background: ${({ theme }) => theme?.colors?.borderColor};
    }

    ${Upload} {
      path {
        fill: ${({ theme }) => theme?.colors?.primary};
      }
    }
  }

  .file-card__left--wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #383838;
    }

    .file-card__status--wrapper {
      display: flex;
      letter-spacing: 0.36px;
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;

      p {
        font-size: 12px;
      }

      .file-card__status___dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
        background: ${({ tagColor, theme }) => {
          if (tagColor) {
            return tagColor
          } else {
            return theme?.colors?.primary
          }
        }};
      }

      .file-card__status___tag {
        font-weight: bold;
        color: ${({ tagColor, theme }) => {
          if (tagColor) {
            return tagColor
          } else {
            return theme?.colors?.primary
          }
        }};
      }
    }
  }

  .tag__name {
    font-size: 12px;
    margin-left: 8px;
    margin-top: -1px;
    font-weight: bold;
  }

  @media (max-width: 500px) {
    width: calc(100% - 32px);
  }
`
