import { ReactNode, useMemo, useReducer, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Auth from '../../service/auth'
import { INewPasswordResponse, ISignInResponse } from '../../types/auth'
import { AuthContext, initialState } from './AuthContext'
import AuthReducer from './AuthReducer'
import { getRedirectUrl } from '../../utils/string'
import { deleteAllSessionCookies } from '../../utils/cookies'
import { SnackbarContext } from '../../apps/common/contexts/SnackBar/SnackBarContext'

interface Props {
  children: ReactNode
}

const AuthState: React.FC<Props> = ({ children }: Props) => {
  const auth = useMemo(() => new Auth(), [])

  const [state, dispatch] = useReducer(AuthReducer, initialState)
  const navigate = useNavigate()
  const { showSnackbar } = useContext(SnackbarContext)

  const redirectUrl = getRedirectUrl() as string
  const login = async (email: string, password: string) => {
    dispatch({
      type: 'LOGIN'
    })

    deleteAllSessionCookies()

    try {
      const result = (await auth?.signIn(email, password)) as ISignInResponse
      if (
        result?.userAttributes?.groups &&
        result?.userAttributes?.groups?.length > 0 &&
        result?.status !== 'NEW_PASSWORD_REQUIRED'
      ) {
        window.location.replace(redirectUrl)
        return
      }

      if (result?.status === 'NEW_PASSWORD_REQUIRED') {
        dispatch({
          type: 'SAVE_TEMPORARY_USER_INFO',
          payload: {
            username: email,
            oldPassword: password
          }
        })
        return navigate('/definitive-password')
      }

      dispatch({
        type: 'LOGIN_SUCCESS'
      })

      navigate('/create-company/document-number')
    } catch (error) {
      showSnackbar((error as Error)?.message, 'error')

      dispatch({
        type: 'LOGIN_ERROR',
        payload: error
      })
    }
  }

  const forgotPassword = async (email: string, resend: boolean) => {
    dispatch({
      type: 'FORGOT_PASSWORD'
    })

    try {
      const users = await auth?.getUserInfosByEmail(email)

      if (users && users?.UserStatus === 'FORCE_CHANGE_PASSWORD') {
        await auth?.resendCreateUserEmail(email)

        navigate('/email-sended-success', {
          state: {
            email
          }
        })

        dispatch({
          type: 'FORGOT_PASSWORD_SUCCESS'
        })
        return
      }

      await auth?.forgotPassword(email)

      navigate('/email-sended-success', {
        state: {
          email
        }
      })

      dispatch({
        type: 'FORGOT_PASSWORD_SUCCESS'
      })
    } catch (error) {
      dispatch({
        type: 'FORGOT_PASSWORD_ERROR',
        payload: error
      })

      if (resend) {
        navigate('/email-sended-error', {
          state: {
            email
          }
        })
      }
    }
  }

  const newPassword = async (password: string) => {
    dispatch({
      type: 'NEW_PASSWORD'
    })

    try {
      const {
        userAttributes: { noSqlId, groups }
      } = (await auth?.newPassword({
        username: state?.temporaryUser?.username ?? '',
        newPassword: password,
        oldPassword: state?.temporaryUser?.oldPassword ?? ''
      })) as INewPasswordResponse

      if (!noSqlId) {
        window.location.replace(redirectUrl)
        return
      }

      if (groups && groups?.length > 0) {
        navigate('/register-company')
        return
      }

      navigate('/create-company/document-number')

      dispatch({
        type: 'NEW_PASSWORD_SUCCESS'
      })
    } catch (error) {
      dispatch({
        type: 'NEW_PASSWORD_ERROR',
        payload: error
      })
    }
  }

  const resetPassword = async (email: string, code: string, password: string) => {
    dispatch({
      type: 'RESET_PASSWORD'
    })

    try {
      await auth?.resetPassword(email, code, password)
      dispatch({
        type: 'RESET_PASSWORD_SUCCESS'
      })
    } catch (error) {
      dispatch({
        type: 'RESET_PASSWORD_ERROR',
        payload: error
      })
    }
  }
  const resendCreateUserEmail = async (email: string) => {
    dispatch({
      type: 'RESEND_CREATE_USER_EMAIL'
    })

    try {
      await auth?.resendCreateUserEmail(email)

      dispatch({
        type: 'RESEND_CREATE_USER_EMAIL_SUCCESS'
      })
    } catch {
      dispatch({
        type: 'RESEND_CREATE_USER_EMAIL_ERROR',
        payload: {
          event: 'resendCreateUserEmail',
          message: 'error'
        }
      })
    }
  }

  const logout = async () => {
    dispatch({
      type: 'LOGOUT'
    })
    try {
      await auth.signOut()
      navigate('/')
      dispatch({
        type: 'LOGOUT_SUCCESS'
      })
    } catch (error) {
      dispatch({
        type: 'LOGOUT_ERROR'
      })
    }
  }

  const contextValue = {
    ...state,
    login,
    forgotPassword,
    newPassword,
    resetPassword,
    resendCreateUserEmail,
    logout
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthState
