import { forwardRef, InputHTMLAttributes } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import styled from 'styled-components'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SecondaryInput from '../SecondaryInput'
import ptBR from 'date-fns/locale/pt-BR'

export interface ISecondaryDateInputProps extends Omit<InputHTMLAttributes<HTMLElement>, 'onChange'> {
  className?: string
  errorMessage?: string
  label: string
  onChange: (value: Date) => void
  dataTestid?: string
  maxDate?: Date
  minDate?: Date
  variant?: 'standard' | 'outlined'
}

const SecondaryDateInput = forwardRef<HTMLInputElement, ISecondaryDateInputProps>(
  (
    { className, label, errorMessage, dataTestid, maxDate, minDate, variant, ...rest }: ISecondaryDateInputProps,
    ref
  ) => {
    return (
      <div className={className}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DatePicker
            disabled={rest?.disabled}
            ref={ref}
            label={label}
            inputFormat="dd/MM/yyyy"
            value={rest?.value}
            onChange={(newValue) => {
              newValue && rest?.onChange(newValue)
            }}
            maxDate={maxDate}
            minDate={minDate}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(params: any) => (
              <SecondaryInput
                variant={variant}
                data-testid={dataTestid}
                {...params}
                errorMessage={errorMessage}
                disabled={rest?.disabled}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    )
  }
)

SecondaryDateInput.displayName = 'SecondaryDateInput'

export default styled(SecondaryDateInput)`
  height: 80px;
  .MuiSvgIcon-root {
    path {
      fill: ${({ theme }) => theme?.colors?.secondaryFont};
    }
  }
`
