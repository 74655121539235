import React from 'react'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const ReputationSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className}>
      <div data-testid="customer-reputation-skeleton-test" className="customer-reputation__wrapper">
        <h5 className="customer-reputation__main-title">Reputação do cedente</h5>
        <div className="customer-reputation__skeleton">
          <div className="customer-reputation__skeleton-items">
            <div className="customer-reputation__skeleton-blocks">
              <Skeleton width="30%" height="84px" animation="wave" />
              <Skeleton width="30%" height="84px" animation="wave" />
            </div>
            <Skeleton width="90%" height="48px" animation="wave" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(ReputationSkeleton)`
   .customer-reputation__skeleton {
display: flex;
align-items: center;
flex-direction: column;
width: 100%;

.customer-reputation__skeleton-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-reputation__skeleton-blocks {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

  }
  }
}
  }
`
