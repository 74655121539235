import { VisibilityOff, VisibilityOutlined } from '@mui/icons-material'
import { IconButton, Skeleton } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'
import ImageButton from '../../buttons/ImageButton'
import { Accounts } from '../../../service/graphql/schema'
import BmpLogo from '../../../assets/images/BmpLogo.png'

interface IProps {
  className?: string
  loading: boolean
  balance: string
  accountsInfo: Array<Accounts>
  selectedAccount?: Accounts
}

const ClientExtract: React.FC<IProps> = ({ className, balance, selectedAccount, loading }: IProps) => {
  const [showBalance, setShowBalance] = useState(false)
  return (
    <header className={className}>
      <div className="client-extract-header__left-side">
        {loading ? (
          <Skeleton className="client-extract-header__account-infos__box--skeleton"></Skeleton>
        ) : (
          <div className="client-extract-header__account-infos__box--account">
            {selectedAccount?.bankPartnerNumber === '274' && <img alt="logo do banco BMP" src={BmpLogo} />}
            <div className="client-extract-header__account__box--right-side">
              <h6>{selectedAccount?.bankPartnerDescription ?? 'Sem conta aberta'}</h6>
              <div className="client-extract-header__account__box--bottom">
                <p>
                  CC: {selectedAccount?.accountNumber ?? '00000'}-{selectedAccount?.accountDigit ?? '0'}
                </p>
                <p data-testid="account-agency-test">Ag: {selectedAccount?.agencyNumber ?? '0000'}</p>
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <Skeleton className="client-extract-header__account-infos__box--skeleton"></Skeleton>
        ) : (
          <div className="client-extract-header__account-infos__box--balance">
            <h6>Saldo atual</h6>
            <div className="client-extract-header__account-infos--bottom">
              <h3 data-testid="client-extract-header-balance-test">{showBalance ? balance : '********'}</h3>
              <IconButton onClick={() => setShowBalance(!showBalance)} edge="end">
                {showBalance ? (
                  <VisibilityOutlined data-testid="visibility-icon" />
                ) : (
                  <VisibilityOff data-testid="visibility-off-icon" />
                )}
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default styled(ClientExtract)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};

  .client-extract-header__left-side {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;

    @media (max-width: 560px) {
      flex-direction: column;
    }

    h3 {
      color: ${({ theme }) => theme?.colors?.secondaryFont};
      font-size: 26px;
      font-weight: bold;
    }

    .client-extract-header__account-infos__box--balance {
      padding: 14px 16px;
      background: ${({ theme }) => theme?.colors?.quarternary};
      border-radius: 10px;
      gap: 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 560px) {
        box-sizing: border-box;
        width: 100%;
      }

      h6 {
        color: ${({ theme }) => theme?.colors?.tertiaryFont};
        font-size: 14px;
      }

      .client-extract-header__account-infos--bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -8px;

        path {
          color: ${({ theme }) => theme?.colors?.primary};
        }

        h3 {
          color: ${({ theme }) => theme?.colors?.tertiaryFont};
          font-weight: 800;
          font-size: 24px;
        }
      }
    }
  }

  .client-extract-header__account-infos__box--skeleton {
    height: 82px !important;
    padding: 0 !important;
    width: 200px;
    box-sizing: border-box;
    transform: none;
    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .client-extract-header__account-infos__box--account {
    padding: 14px 16px;
    background: ${({ theme }) => theme?.colors?.quarternary};
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 560px) {
      box-sizing: border-box;
      width: 100%;
      flex: 1;
    }

    img {
      max-height: 65px;
    }

    .client-extract-header__account__box--right-side {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h6 {
        color: ${({ theme }) => theme?.colors?.tertiaryFont};
        font-weight: bold;
      }

      .client-extract-header__account__box--bottom {
        display: flex;
        gap: 12px;
        white-space: nowrap;

        p {
          grid-area: bottom;
          color: ${({ theme }) => theme?.colors?.tertiaryFont};
        }
      }
    }
  }

  .client-extract-header__right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    gap: 12px;

    .client-extract-header__button--right {
      img {
        margin-left: 12px;
      }
    }

    ${ImageButton} {
      .MuiButton-root {
        border-color: ${({ theme }) => theme?.colors?.gray?.lighter};
      }
    }
  }
`
