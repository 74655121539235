import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AddressContext } from '../../../../states/address/AddressContext'
import { removeAllLettersAndSpecialCharacters } from '../../../../utils/string'
import InputFormat from '../../../inputs/InputFormat'
import SecondaryAutoCompleteInput from '../../../inputs/SecondaryAutoCompleteInput'
import SecondaryDateInput from '../../../inputs/SecondaryDateInput'
import SecondaryInput from '../../../inputs/SecondaryInput'
import SecondarySelectInput from '../../../inputs/SecondarySelectInput'
import { IInput } from '../CreateUpdateDelete'

interface IProps {
  input: IInput
  isDisabled?: boolean
}

const Inputs: React.FC<IProps> = ({ input, isDisabled }: IProps) => {
  const { getAddressByZipCode } = useContext(AddressContext)
  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
    control,
    clearErrors
  } = useFormContext()

  const handleZipCode = async (event: React.FocusEvent<HTMLInputElement>) => {
    const address = await getAddressByZipCode(event.target.value)
    if (input.addressFields) {
      setValue(input.addressFields?.city, address.city)
      setValue(input.addressFields?.streetName, address.streetName)
      setValue(input.addressFields?.neighborhood, address.neighboorhood)
      setValue(input.addressFields?.state, address.state)
    }
  }

  const formatProps = (inputData: IInput) => {
    if (inputData?.mask) {
      return {
        InputProps: {
          inputComponent: InputFormat,
          inputProps: {
            format: inputData?.mask
          }
        },
        defaultValue: removeAllLettersAndSpecialCharacters(watch(inputData?.name) as string) || '',
        value: removeAllLettersAndSpecialCharacters(getValues(inputData?.name) as string)
      }
    }

    if (inputData?.onlyNumber) {
      return {
        InputProps: {
          inputComponent: InputFormat
        },
        defaultValue: removeAllLettersAndSpecialCharacters(watch(inputData?.name) as string) || '',
        value: removeAllLettersAndSpecialCharacters(getValues(inputData?.name) as string)
      }
    }

    return {}
  }
  switch (input.type) {
    case 'date':
      return (
        <SecondaryDateInput
          label={`${input?.label}${input?.required ? '*' : ''}`}
          errorMessage={
            errors[input?.name] && !watch(input?.name) ? (errors[input?.name]?.message as string) : '' || input?.error
          }
          onChange={(value) => {
            setValue(input?.name, value?.toString())
          }}
          dataTestid={`input-${input?.name}-date-test`}
          value={watch(input?.name)}
          name={input?.name}
          maxDate={input?.maxDate}
          disabled={input?.disabled || isDisabled}
        />
      )

    case 'radio':
      return (
        <Controller
          control={control}
          name={input?.name}
          render={({ field: { onChange, value: result } }) => (
            <FormControl error={!!errors[input?.name]} disabled={input?.disabled || isDisabled}>
              <RadioGroup name={input?.name} value={result} onChange={({ target: { value } }) => onChange(value)}>
                <div className="row__input-radio--wrapper">
                  {input?.options?.map((option) => (
                    <FormControlLabel
                      key={option?.value}
                      value={option?.value}
                      control={<Radio data-testid={`input-${option?.name}-radio-test`} />}
                      label={option?.name}
                      checked={result === option?.value?.toString()}
                    />
                  ))}
                </div>
              </RadioGroup>
              {errors[input?.name] && <FormHelperText>{errors[input?.name]?.message as string}</FormHelperText>}
            </FormControl>
          )}
        />
      )

    case 'select':
      return (
        <SecondarySelectInput
          options={input?.selectOptions || []}
          label={`${input?.label}${input?.required ? '*' : ''}`}
          {...register(input?.name)}
          errorMessage={errors[input?.name] ? (errors[input?.name]?.message as string) : ''}
          disabled={input?.disabled || isDisabled}
          defaultValue={input?.selectDefaultValue}
        />
      )

    case 'zipCode':
      return (
        <SecondaryInput
          label={`${input?.label}${input?.required ? '*' : ''}`}
          errorMessage={errors[input?.name] ? (errors[input?.name]?.message as string) : '' || input?.error}
          data-testid={`input-${input?.name}-zip-code-text-test`}
          {...formatProps(input)}
          {...register(input?.name)}
          disabled={input?.disabled || isDisabled}
          onBlur={handleZipCode}
        />
      )

    case 'fakeDiv':
      return <div />

    case 'autoComplete':
      return (
        <SecondaryAutoCompleteInput
          placeholder={input?.placeholder}
          options={input?.selectOptions || []}
          label={`${input?.label}${input?.required ? '*' : ''}`}
          onChange={(value) => {
            clearErrors(input?.name)
            setValue(input?.name, value)
          }}
          disabled={isDisabled}
          value={
            input?.selectOptions?.find((option) => option?.value?.toString() === watch(input?.name)?.toString()) || {
              value: '',
              item: ''
            }
          }
          data-testid={`input-${input?.name}-auto-complete-test`}
          errorMessage={errors[input?.name] ? (errors[input?.name]?.message as string) : '' || input?.error}
        />
      )

    default:
      return (
        <SecondaryInput
          label={`${input?.label}${input?.required ? '*' : ''}`}
          errorMessage={errors[input?.name] ? (errors[input?.name]?.message as string) : '' || input?.error}
          data-testid={`input-${input?.name}-text-test`}
          {...formatProps(input)}
          {...register(input?.name)}
          disabled={input?.disabled || isDisabled}
          inputProps={{ maxLength: input?.maxLength }}
        />
      )
  }
}

export default Inputs
