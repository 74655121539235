import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
  status: 'SUCCESS' | 'ERROR'
  text: string[]
  resendFunction?: () => void
  isLoading?: boolean
}

const EmailStatus: React.FC<IProps> = ({ className, text, status, isLoading, resendFunction }: IProps) => {
  return (
    <div className={className}>
      <div className="email-status--wrapper">
        {isLoading ? (
          <CircularProgress size={24} data-testid="email-status__loading" />
        ) : (
          <>
            <div className="email-status__title--wrapper">
              <FontAwesomeIcon icon={faCheckCircle} />
              <h1>E-mail enviado</h1>
            </div>
            {text?.map((p, index) => <p key={index}>{p}</p>)}
            {status === 'SUCCESS' && (
              <p>
                Caso você não receba o e-mail nos próximos minutos,{' '}
                <b onClick={resendFunction} data-testid="email-status-button-test">
                  clique aqui para reenviar.
                </b>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default styled(EmailStatus)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .email-status--wrapper {
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    svg {
      height: 24px;
      width: 24px;
    }

    .email-status__title--wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      justify-content: flex-start;

      h1,
      path {
        color: ${({ theme }) => theme?.colors?.primary};
      }

      h1 {
        font-weight: bold;
        font-size: 42px;
      }
    }
    p {
      font-size: 20px;

      b {
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        color: ${({ theme }) => theme?.colors?.primary};
        transition: all 300ms;

        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
`
