const gray = {
  main: '#5C5C5C',
  secondaryGray: '#E7E7E7',
  light: '#727272',
  lighter: '#E0E0E0',
  smooth: '#FAFAFA',
  dark: '#484848',
  darker: '#333333'
}

const elements = {
  primaryBoxShadow: '0px 2px 6px #00000026;'
}

const colors = {
  background: '#F7F9FF',
  secondaryBackground: '#F4F4F4',
  tertiaryBackground: '#FAFAFA',
  primary: '#727272',
  secondaryDark: '#EBEBEB',
  tertiary: '#000000',
  tertiaryFont: '#383838',
  quarternary: '#F8F8F8',
  gray,
  border: '#888888',
  primaryFont: '#9A9A9A',
  secondaryFont: '#000000',
  quarternaryFont: '#6D6F71',
  disabled: '#DBDBDB',
  borderColor: '#E4E4E4',
  buyer: '#4CA0AF',
  inputBorder: '#979797',
  infoMain: '#0288D1',
  infoAlert: '#014361'
}

const styleGuide = {
  support: {
    red: '#DB2828',
    yellow: '#FBB208',
    purple: '#A333C8',
    blue: '#2185D0',
    green: '#21BA45',
    positive: '#a6ceec',
    negative: '#f2a9a9',
    favorite: '#a6e3b5'
  },
  dynamic: {
    primary: {
      main: '#4CAF4F'
    },
    secondary: {
      dark: '#1D7D20',
      light: '#A6E3AB',
      gray: '#A4B0A4'
    }
  },
  fixed: {
    gray: {
      lighter: {
        primary: '#0000001F'
      },
      light: {
        primary: '#6D6F71'
      },
      dark: {
        primary: '#383838'
      }
    },
    white: {
      light: {
        primary: '#FFFFFF'
      },
      dark: {
        primary: '#FAFAFA'
      }
    }
  },
  background: {
    light: {
      primary: '#F4F4F4'
    }
  },
  borderColor: '#E4E4E4'
}

export const theme = {
  colors,
  elements,
  styleGuide
}
