import { useEffect, useState, useCallback } from 'react'

export const useMobile = (maxWidth: number): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = useCallback(() => {
    const width = window.innerWidth
    setIsMobile(width < maxWidth)
  }, [maxWidth])

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])
  return isMobile
}
