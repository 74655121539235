import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { createSchema } from '../../../utils/input'
import PrimaryButton from '../../buttons/PrimaryButton'
import CompanyWelcomeForm from '../../companies/CompanyWelcomeForm'
import DefaultRegisterCompanyPage from '../../companies/DefaultRegisterCompanyPage'
import InputFormat from '../../inputs/InputFormat'
import SecondaryInput from '../../inputs/SecondaryInput'
import CrudCreateModal from '../CrudCreateModal'

interface IInput {
  label: string
  name: string
  required?: boolean
  text: string[]
  type?: string
  align?: 'right' | 'left'
}

export interface ISecondaryOnePageCrudFormData {
  isLoading: boolean
  title: string
  subtitle: string[]
  inputs: IInput[]
  openSuccessModal: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentData?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveData: (data: any) => void
  closeSuccessModal: () => void
  isSavingData: boolean
  isDisabled?: boolean
}

interface IProps {
  className?: string
  formData: ISecondaryOnePageCrudFormData
}

const SecondaryOnePageCrud: React.FC<IProps> = ({ className, formData }: IProps) => {
  const {
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(createSchema(formData?.inputs))
  })

  useEffect(() => {
    if (formData?.currentData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...rest } = formData.currentData
      reset({ ...rest })
    }
  }, [formData?.currentData, reset])

  const getInputProps = (type: string) => {
    if (type === 'currency') {
      return {
        prefix: 'R$ ',
        decimalScale: 2
      }
    }

    if (type === 'percentage') {
      return { suffix: '%' }
    }

    return {}
  }

  return (
    <DefaultRegisterCompanyPage className={className}>
      <CrudCreateModal open={formData?.openSuccessModal} onClose={formData?.closeSuccessModal} />
      {formData.isLoading ? (
        <div className="secondary-one-page-crud__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <>
          <CompanyWelcomeForm header={formData?.title} descriptionText={formData?.subtitle} />
          <form onSubmit={handleSubmit((data) => formData?.saveData(data))}>
            <div className="secondary-one-page-crud__input___container">
              {formData?.inputs?.map((input) => (
                <div className="secondary-one-page-crud__input___wrapper" key={input?.name}>
                  <div className="secondary-one-page-crud__input___left-side">
                    <b>{input?.label}</b>
                    {input?.text?.map((p, i) => <p key={i}>{p}</p>)}
                  </div>
                  <div className="secondary-one-page-crud__input___right-side">
                    <SecondaryInput
                      InputProps={{
                        inputComponent: InputFormat,
                        inputProps: {
                          style: { textAlign: input?.align ?? 'left' },
                          ...getInputProps(input?.type || 'text')
                        }
                      }}
                      disabled={!!formData?.isDisabled}
                      onChange={(e) => setValue(input?.name, e?.target?.value)}
                      defaultValue={watch(input?.name)}
                      value={getValues(input?.name)}
                      data-testid={`input-${input?.name}-test`}
                      errorMessage={errors[input?.name] ? (errors[input?.name]?.message as string) : ''}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="secondary-one-page-botton--wrapper">
              <div />
              <div className="secondary-one-page-crud__button--wrapper">
                <div className="secondary-one-page-crud__button--container">
                  <PrimaryButton
                    type="submit"
                    data-testid="secondary-one-page-crud-button-submit-test"
                    isLoading={formData?.isSavingData}
                    disabled={!!formData?.isDisabled}
                  >
                    Salvar
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </DefaultRegisterCompanyPage>
  )
}

export default styled(SecondaryOnePageCrud)`
  .secondary-one-page-crud__loading {
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .secondary-one-page-botton--wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .secondary-one-page-crud__button--wrapper {
    margin-left: 312px;
    margin-top: 64px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .secondary-one-page-crud__button--container {
      width: 184px;
    }
  }

  .secondary-one-page-crud__input___container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 40px;

    color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    .secondary-one-page-crud__input___wrapper {
      display: flex;
      gap: 16px;

      .secondary-one-page-crud__input___left-side {
        width: 284px;

        b {
          font-size: 12px;
          font-weight: 700;
        }

        p {
          letter-spacing: 0;
          font-size: 12px;
          line-height: 16px;

          &:nth-child(2) {
            margin-top: 8px;
          }
        }
      }

      .secondary-one-page-crud__input___right-side {
        width: 184px;
        display: flex;
        align-items: flex-end;
        margin-bottom: -16px;
      }
    }
  }

  @media (max-width: 1100px) {
    .secondary-one-page-crud__input___container {
      grid-template-columns: 1fr;
      .secondary-one-page-crud__input___wrapper {
        flex-direction: column;
        .secondary-one-page-crud__input___right-side {
          width: 100%;
          margin: 0;

          ${SecondaryInput} {
            width: 100%;
          }
        }
      }
    }

    .secondary-one-page-botton--wrapper {
      grid-template-columns: 1fr;
    }

    .secondary-one-page-crud__button--wrapper {
      margin-left: 0;
      .secondary-one-page-crud__button--container {
        width: 100%;
      }
    }
  }
`
