import { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

interface IProps extends HTMLAttributes<HTMLFormElement> {
  className?: string
  children: ReactNode
}

const NormalForm: React.FC<IProps> = ({ className, children, ...props }: IProps) => {
  return (
    <form className={className} {...props}>
      {children}
    </form>
  )
}

export default styled(NormalForm)`
  width: 360px;
  margin: 36px 0 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`
