import { Outlet, Route } from 'react-router-dom'
import ClientExtract from '../../../pages/ClientExtract'
import BankingState from '../../../states/banking/BankingState'
import RouteByGroup from '../../Rules/RouteByGroup'
import CompanyState from '../../../states/company/CompanyState'

export const CustomerRouteWrapper = () => (
  <RouteByGroup allowedGroup="Cedente">
    <Outlet />
  </RouteByGroup>
)

export const CustomerRoutes = (
  <Route path="/" element={<CustomerRouteWrapper />}>
    <Route
      path="client-extract"
      element={
        <CompanyState>
          <BankingState>
            <ClientExtract />
          </BankingState>
        </CompanyState>
      }
    />
  </Route>
)
