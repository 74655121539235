import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { forwardRef, Ref } from 'react'
import styled from 'styled-components'

export interface ISecondarySelectInputProps extends Omit<SelectProps, 'variant'> {
  className?: string
  errorMessage?: string
  label: string
  options: Array<{
    value: string | number
    item: string | number
  }>
  ref?: Ref<HTMLInputElement>
}

const SecondarySelectInput = forwardRef<HTMLInputElement, ISecondarySelectInputProps>(
  ({ className, errorMessage, label, options, ...rest }: ISecondarySelectInputProps, ref) => {
    return (
      <div className={className}>
        <FormControl variant="standard" fullWidth error={!!errorMessage}>
          <InputLabel id={label} shrink>
            {label}
          </InputLabel>
          <Select ref={ref} labelId={label} {...rest}>
            {options?.map(({ value, item }) => (
              <MenuItem key={value} value={value}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {!!errorMessage && (
            <FormHelperText data-testid="secondary-select-input-error-test">{errorMessage}</FormHelperText>
          )}
        </FormControl>
      </div>
    )
  }
)

SecondarySelectInput.displayName = 'SecondarySelectInput'

export default styled(SecondarySelectInput)`
  height: 70px;

  .MuiInputAdornment-positionStart {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }

  .MuiInputAdornment-positionEnd {
    width: 20px;
    margin-right: 12px;
    margin-bottom: 8px;

    path {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
`
