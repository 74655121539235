import { ReactNode, useCallback, useReducer } from 'react'
import { client } from '../../config/Apollo'
import { GetAddressByZipCodeDocument } from '../../service/graphql/schema'
import { initialState, AddressContext } from './AddressContext'
import AddressReducer from './AddressReducer'

interface IProps {
  children: ReactNode
}

const AddressState: React.FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(AddressReducer, initialState)

  const getAddressByZipCode = useCallback(async (zipCode: string) => {
    dispatch({
      type: 'GET_ADDRESS_BY_ZIP_CODE'
    })

    try {
      const {
        data: { getAddressByZipCode: response }
      } = await client.query({
        fetchPolicy: 'no-cache',
        query: GetAddressByZipCodeDocument,
        variables: {
          zipCode
        }
      })
      dispatch({
        type: 'GET_ADDRESS_BY_ZIP_CODE_SUCCESS'
      })

      return response
    } catch (error) {
      const err = error as { message: string }
      const message = err?.message

      dispatch({
        type: 'GET_ADDRESS_BY_ZIP_CODE_ERROR',
        payload: {
          location: 'getAddressByZipCode',
          message: message
        }
      })
      return { city: '', streetName: '', neighboorhood: '', state: '', zipCode: '' }
    }
  }, [])

  const contextValues = {
    ...state,
    getAddressByZipCode
  }

  return <AddressContext.Provider value={contextValues}>{children}</AddressContext.Provider>
}

export default AddressState
