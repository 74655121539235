import React from 'react'
import styled from 'styled-components'
import Menu from '../../../../assets/Menu'
import Close from '../../../../assets/Close'

interface MenuIconProps {
  className?: string
  showMenu: boolean
  toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void
  selectedTab: string
}

const MenuIcon = ({ className, toggleDrawer, showMenu, selectedTab }: MenuIconProps) => {
  return (
    <div className={className} data-testid="menu-icon-test">
      <button data-testid="menu-icon-button-test" onClick={toggleDrawer}>
        {showMenu ? (
          <Close data-testid="menu-icon-close-test" width={16} />
        ) : (
          <Menu width={16} data-testid="menu-icon-menu-test" />
        )}
      </button>
      <span>{selectedTab}</span>
    </div>
  )
}

export default styled(MenuIcon)`
  background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  display: flex;

  button {
    border: none;
    background-color: transparent;
    margin: 0;
    cursor: pointer;
    padding: 16px 0px 16px 16px;

    ${Menu} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    ${Close} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }
  }

  span {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
    font-weight: bold;
    padding: 16px 0px;
    text-align: center;
    width: 80%;
  }
`
