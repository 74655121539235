import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}
const FavoriteIcon: React.FC<IProps> = ({ className, ...rest }: IProps) => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path d="M7.11134 12H8.88934C9.13417 11.971 9.35985 11.8531 9.52361 11.6688C9.68736 11.4845 9.77781 11.2465 9.77781 11C9.77781 10.7535 9.68736 10.5155 9.52361 10.3312C9.35985 10.1469 9.13417 10.029 8.88934 10H7.11134C6.8665 10.029 6.64082 10.1469 6.47707 10.3312C6.31332 10.5155 6.22287 10.7535 6.22287 11C6.22287 11.2465 6.31332 11.4845 6.47707 11.6688C6.64082 11.8531 6.8665 11.971 7.11134 12ZM0.000338617 1C-0.0128159 1.25011 0.0731229 1.49533 0.23953 1.68252C0.405937 1.8697 0.639406 1.98377 0.889339 2H15.1113C15.3613 1.98377 15.5947 1.8697 15.7611 1.68252C15.9276 1.49533 16.0135 1.25011 16.0003 1C16.0135 0.749887 15.9276 0.504666 15.7611 0.317481C15.5947 0.130296 15.3613 0.0162288 15.1113 0H0.889339C0.639406 0.0162288 0.405937 0.130296 0.23953 0.317481C0.0731229 0.504666 -0.0128159 0.749887 0.000338617 1ZM3.55634 7H12.4453C12.6902 6.97098 12.9159 6.85315 13.0796 6.66884C13.2434 6.48453 13.3338 6.24655 13.3338 6C13.3338 5.75345 13.2434 5.51547 13.0796 5.33116C12.9159 5.14685 12.6902 5.02902 12.4453 5H3.55634C3.31151 5.02902 3.08582 5.14685 2.92207 5.33116C2.75832 5.51547 2.66787 5.75345 2.66787 6C2.66787 6.24655 2.75832 6.48453 2.92207 6.66884C3.08582 6.85315 3.31151 6.97098 3.55634 7Z" />
    </svg>
  )
}

export default styled(FavoriteIcon)`
  path {
    fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
  }
`
