interface IProps {
  className?: string
}

const ExampleCustomer: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className} data-testid="example-test">
      This is an Example of a Customer group
    </div>
  )
}

export default ExampleCustomer
