import TertiaryButton from '../../../../components/buttons/TertiaryButton'
import { GetCustomersData } from '../../../../service/graphql/schema'
import FavoriteIcon from '../../assets/FavoriteIcon'
import NegativeIcon from '../../assets/NegativeIcon'
import PositiveIcon from '../../assets/PositiveIcon'

interface IProps {
  companyRating: GetCustomersData['companyRating']
}

const CompanyRatingCell: React.FC<IProps> = ({ companyRating }: IProps) => {
  switch (companyRating) {
    case 'approved':
      return <PositiveIcon data-testid="company-rating-cell-positive-icon-test" />
    case 'disapproved':
      return <NegativeIcon data-testid="company-rating-cell-negative-icon-test" />
    case 'favorite':
      return <FavoriteIcon data-testid="company-rating-cell-favorite-icon-test" />
    default:
      return <TertiaryButton data-testid="company-rating-cell-default-icon-test">Classificar</TertiaryButton>
  }
}

export default CompanyRatingCell
