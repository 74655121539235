import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyContext } from '../../states/company/CompanyContext'
import * as validations from 'validations-br'
import CreateUpdateDelete, { IFormData } from '../../components/crud/SplitCrud/CreateUpdateDelete'
import { useAuth } from '../../hooks/useAuth'
import { translateGroup } from '../../utils/auth'
import WithHeader from '../../hocs/WithHeader'

const CompanyPartnersForm: React.FC = () => {
  const {
    defaultCompany,
    isGettingDefaultCompany,
    isGettingCompanyPartnersByCompanyId,
    getCompanyPartnersByCompanyId,
    companyPartners,
    registerCompanyPartner,
    deleteCompanyPartner,
    isDeletingCompanyPartner,
    isRegistratingCompanyPartner
  } = useContext(CompanyContext)

  const { id } = useParams()

  useEffect(() => {
    if (defaultCompany?.businessId && id) {
      getCompanyPartnersByCompanyId(defaultCompany?.businessId)
    }
  }, [defaultCompany?.businessId, id, getCompanyPartnersByCompanyId])

  const maritalStatus = [
    { value: 1, item: 'Solteiro' },
    { value: 2, item: 'Casado' },
    { value: 4, item: 'Divorciado' },
    { value: 5, item: 'Viúvo' }
  ]

  const hasEcpf = [
    { value: 1, item: 'Sim' },
    { value: 0, item: 'Não' }
  ]

  const signsForCompany = [
    { value: 1, item: 'Sim' },
    { value: 0, item: 'Não' }
  ]

  const findMaritalStatus = companyPartners?.find((r) => r?.id === id)?.maritalStatusId
  const findHasEcpf = companyPartners?.find((r) => r?.id === id)?.hasEcpf
  const findSignsForCompany = companyPartners?.find((r) => r?.id === id)?.signsForCompany

  const { groups } = useAuth()
  const group = translateGroup(groups?.[0])

  const formData: IFormData = {
    formWidth: 1232,
    breadCrumb: group === 'Buyer' ? 'Dados cadastrais / Representantes' : 'Dados cadastrais / Sócios e procuradores',
    isLoading: isGettingDefaultCompany || isGettingCompanyPartnersByCompanyId,
    title:
      id && group === 'Buyer'
        ? 'Editar representante'
        : !id && group === 'Buyer'
          ? 'Adicionar representante'
          : id
            ? 'Editar sócio ou procurador'
            : 'Adicionar sócio ou procurador',
    subtitle: id
      ? 'Faça a edição dos dados e não se esqueça de salvar as modificações.'
      : 'Preencha os campos abaixo para adicionar um sócio ou procurador.',
    currentData: companyPartners?.find((r) => r?.id === id),
    backTo: '/company-partners',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveData: (data: any) =>
      registerCompanyPartner({
        companyId: defaultCompany?.businessId,
        ...data
      }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editData: (data: any) =>
      registerCompanyPartner({
        companyId: defaultCompany?.businessId,
        id: data?.id,
        ...data
      }),
    deleteData: () => deleteCompanyPartner(id as string),
    isSavingData: isRegistratingCompanyPartner,
    isEditingData: isRegistratingCompanyPartner,
    isDeletingData: isDeletingCompanyPartner,
    formDisabled: (defaultCompany?.statusId as string) !== '1',
    groups: [
      {
        rows: [
          {
            inputs: [
              {
                name: 'typeId',
                required: true,
                type: 'radio',
                options: [
                  {
                    name: 'Sócio',
                    value: 1
                  },
                  {
                    name: 'Procurador',
                    value: 2
                  }
                ]
              }
            ],
            grid: [1]
          }
        ]
      },
      {
        rows: [
          {
            inputs: [
              {
                label: 'Nome',
                name: 'fullName',
                required: true
              },
              {
                label: 'Data de Nascimento',
                name: 'birthDate',
                required: true,
                type: 'date',
                maxDate: new Date()
              },
              {
                label: 'Estado Civil',
                name: 'maritalStatusId',
                required: true,
                type: 'select',
                selectOptions: maritalStatus,
                selectDefaultValue: findMaritalStatus ? parseInt(findMaritalStatus as string) : undefined
              },
              {
                label: 'CPF',
                name: 'documentNumber',
                required: true,
                mask: '###.###.###-##',
                test: {
                  type: 'is-cpf',
                  message: 'Confira se seu CPF está correto',
                  callback: (value?: string) => !!(value && validations.validateCPF(value))
                }
              },
              {
                label: 'Possui e-cpf?',
                name: 'hasEcpf',
                required: true,
                type: 'select',
                selectOptions: hasEcpf,
                selectDefaultValue: findHasEcpf ? parseInt(findHasEcpf as string) : undefined
              }
            ],
            grid: [2, 1, 1, 1, 1]
          }
        ]
      },
      {
        rows: [
          {
            inputs: [
              {
                label: 'Número do RG',
                name: 'personalDocument',
                required: true,
                mask: '##.###.###-##'
              },
              {
                label: 'Orgão Emissor do RG',
                name: 'personalDocumentEmitter',
                required: true
              },
              {
                label: 'UF do RG',
                name: 'personalDocumentState',
                required: true
              },
              {
                label: 'Participação na Empresa %',
                name: 'companyShareholding',
                required: true
              },
              {
                label: 'Assina pela Empresa?',
                name: 'signsForCompany',
                required: true,
                type: 'select',
                selectOptions: signsForCompany,
                selectDefaultValue: findSignsForCompany ? parseInt(findSignsForCompany as string) : undefined
              },
              {
                type: 'fakeDiv',
                name: 'fakeDiv'
              }
            ],
            grid: [1, 1, 1, 1, 1, 1]
          }
        ]
      },
      {
        title: 'Contato',
        rows: [
          {
            inputs: [
              {
                label: 'E-mail',
                name: 'email',
                required: true,
                test: {
                  type: 'email',
                  message: 'Confira se seu email está correto',
                  callback: (value?: string) => !!(value ? validations.validateEmail(value) : true)
                }
              },
              {
                label: 'Telefone',
                name: 'phone',
                mask: '(##) ####-####',
                test: {
                  type: 'phone',
                  message: 'Confira se seu telefone está correto',
                  callback: (value?: string) => !!(value ? validations.validatePhone(value) : true)
                }
              },
              {
                label: 'Celular',
                name: 'mobile',
                required: true,
                test: {
                  type: 'is-phone',
                  message: 'Confira se seu celular está correto',
                  callback: (value?: string) => !!(value && validations.validatePhone(value))
                },
                mask: '(##) #####-####'
              }
            ],
            grid: [1, 1, 1]
          }
        ]
      },
      {
        title: 'Endereço',
        rows: [
          {
            inputs: [
              {
                label: 'CEP',
                name: 'addressZipCode',
                required: true,
                type: 'zipCode',
                mask: '#####-###',
                addressFields: {
                  city: 'addressCity',
                  streetName: 'addressStreetName',
                  neighborhood: 'addressNeighborhood',
                  state: 'addressState'
                }
              },
              {
                label: 'Logradouro',
                name: 'addressStreetName',
                required: true
              },
              {
                label: 'Número',
                name: 'addressNumber',
                required: true
              },
              {
                label: 'Complemento',
                name: 'addressComplement',
                notRequired: true
              }
            ],
            grid: [1, 2, 1, 2]
          }
        ]
      },
      {
        rows: [
          {
            inputs: [
              {
                type: 'fakeDiv',
                name: 'fakeDiv2'
              },
              {
                label: 'Bairro',
                name: 'addressNeighborhood',
                required: true
              },
              {
                label: 'Cidade',
                name: 'addressCity',
                required: true
              },
              {
                label: 'Estado UF',
                name: 'addressState',
                required: true
              }
            ],
            grid: [1.4, 2, 3, 2]
          }
        ]
      }
    ]
  }

  return <CreateUpdateDelete formData={formData} />
}

export default WithHeader()(CompanyPartnersForm)
