import styled from 'styled-components'

interface IProps {
  className?: string
}

const Edit: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g
        id="_8fc02cfb0c252e439ae0caf0e61d1228"
        data-name="8fc02cfb0c252e439ae0caf0e61d1228"
        transform="translate(-0.5 -0.5)"
      >
        <path
          id="Caminho_5325"
          data-name="Caminho 5325"
          d="M12.389,10.146l-1.442,4.809a1.162,1.162,0,0,0,1.113,1.495,1.147,1.147,0,0,0,.333-.049l4.81-1.442a1.158,1.158,0,0,0,.488-.291L26.508,5.85a1.161,1.161,0,0,0,0-1.642L23.14.84A1.161,1.161,0,0,0,21.5.84L12.68,9.658A1.163,1.163,0,0,0,12.389,10.146Zm2.141.947L22.319,3.3l1.725,1.725-7.789,7.79-2.464.739Z"
          transform="translate(-2.348 0)"
        />
        <path
          id="Caminho_5326"
          data-name="Caminho 5326"
          d="M23.339,11.339A1.161,1.161,0,0,0,22.177,12.5v7.742a1.938,1.938,0,0,1-1.935,1.935H4.758a1.938,1.938,0,0,1-1.935-1.935V4.758A1.938,1.938,0,0,1,4.758,2.823H12.5A1.161,1.161,0,1,0,12.5.5H4.758A4.263,4.263,0,0,0,.5,4.758V20.242A4.263,4.263,0,0,0,4.758,24.5H20.242A4.263,4.263,0,0,0,24.5,20.242V12.5A1.161,1.161,0,0,0,23.339,11.339Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  )
}

export default styled(Edit)``
