export const downloadBase64File = (base64: string, fileNameWithExtension: string): void => {
  const linkSource = `data:application/octet-stream;base64,${base64}`
  const downloadLink = document.createElement('a')
  downloadLink.href = linkSource
  downloadLink.download = fileNameWithExtension
  downloadLink.click()
}
export const downloadFile = (url: string) => {
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = ''
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}
