import { useEffect, useState } from 'react'
import {
  validateHasLowercase,
  validateHasNumber,
  validateHasUppercase,
  validateIfPasswordIsLow,
  validateMinLength,
  validateSpecialCharacters
} from '../../utils/password/validator'

interface IUsePasswordValidator {
  upperCase: boolean
  lowerCase: boolean
  number: boolean
  length: boolean
  specialCharacter: boolean
  all: boolean
}

export const usePasswordValidator = (password: string, passwordSize: number): IUsePasswordValidator => {
  const [validator, setValidator] = useState({
    upperCase: false,
    lowerCase: false,
    number: false,
    length: false,
    specialCharacter: false,
    all: false
  })

  useEffect(() => {
    setValidator({
      upperCase: validateHasUppercase(password),
      lowerCase: validateHasLowercase(password),
      number: validateHasNumber(password),
      length: validateMinLength(password, passwordSize),
      specialCharacter: validateSpecialCharacters(password),
      all: validateIfPasswordIsLow(password, passwordSize)
    })
  }, [password, passwordSize])

  return validator
}
