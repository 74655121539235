import { FormControlLabel, Radio } from '@mui/material'
import styled from 'styled-components'
import { addOpacityToDynamicColors } from '../../../utils/color'

interface IProps {
  className?: string
  value: string
  label: string
  explanation: string
  checked: boolean
  dataTestid?: string
}

const ProfileCard: React.FC<IProps> = ({ className, value, label, explanation, dataTestid }: IProps) => {
  return (
    <div className={className} data-testid={dataTestid ?? 'profile-card-test'}>
      <FormControlLabel value={value} control={<Radio />} label={label} />
      <p>({explanation})</p>
    </div>
  )
}

export default styled(ProfileCard)`
  padding: 12px 24px 24px 24px;
  border: 1px solid ${({ theme, checked }) => (checked ? theme?.colors?.primary : theme?.colors?.gray?.lighter)};
  border-radius: 10px;
  width: 180px;

  background: ${({ theme, checked }) =>
    checked ? addOpacityToDynamicColors(theme?.colors?.primary, 15) : 'transparent'};

  span {
    font-weight: 600;
    color: ${({ theme }) => theme?.colors?.tertiaryFont};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`
