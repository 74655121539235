import styled from 'styled-components'
import { IRow } from '../CreateUpdateDelete'
import Inputs from '../Inputs'

interface IProps {
  className?: string
  data: IRow
  isDisabled?: boolean
}

const Row: React.FC<IProps> = ({ className, data, isDisabled }: IProps) => {
  return (
    <div className={className}>
      {data?.inputs?.map((input, index) => <Inputs key={index} input={input} isDisabled={isDisabled} />)}
    </div>
  )
}

export default styled(Row)`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ data: { grid } }) => `${grid?.join('fr ')}fr`};
  grid-column-gap: 16px;

  .row__input-radio--wrapper {
    display: flex;
  }

  @media (max-width: 900px) {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
    .row__input-radio--wrapper {
      padding: 16px 0;
    }
  }
`
