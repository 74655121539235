import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'
import { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

interface IProps extends CheckboxProps {
  className?: string
  label: ReactNode
}
const PrimaryCheckbox = forwardRef<HTMLInputElement, IProps>(({ className, label, ...rest }: IProps, ref) => {
  return <FormControlLabel ref={ref} className={className} control={<Checkbox {...rest} />} label={label} />
})

PrimaryCheckbox.displayName = 'PrimaryCheckbox'

export default styled(PrimaryCheckbox)`
  .MuiTypography-root {
    color: ${({ theme: { colors } }) => colors?.quarternaryFont};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20px;
  }
`
