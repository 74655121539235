/* eslint-disable react-hooks/exhaustive-deps */

import { Fragment, useContext, useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material'
import { BankingContext } from '../../states/banking/BankingContext'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { formatDateToDDMMYYYY, formatDateToDDMMYYYYHHMM } from '../../utils/datetime'
import { formatCurrencyToPtBR } from '../../utils/currency'
import ClientExtractHeader from '../../components/banking/ClientExtractHeader'
import WithHeader from '../../hocs/WithHeader'
import SecondaryDateInput from '../../components/inputs/SecondaryDateInput'
import styled from 'styled-components'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SearchIcon from '@mui/icons-material/Search'
import { CompanyContext } from '../../states/company/CompanyContext'
import { Accounts } from '../../service/graphql/schema'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import SecondaryPagination from '../../apps/common/components/Pagination/SecondaryPagination'
import PrimaryCard from '../../components/cards/PrimaryCard'

const ITEMS_PER_PAGE = '100'

const ClientExtract: React.FC = () => {
  const {
    isGettingExtract,
    extract,
    getExtract,
    isGettingBusinessBankAccounts,
    getBusinessBankAccounts,
    businessBankAccounts
  } = useContext(BankingContext)
  const { defaultCompany, isGettingDefaultCompany } = useContext(CompanyContext)
  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState<Accounts>()

  const currentBalance = businessBankAccounts?.accounts[0]?.availableBalance
    ? formatCurrencyToPtBR(Number(businessBankAccounts?.accounts[0]?.availableBalance))
    : '---'

  const [filter, setFilter] = useState({
    initialDate: new Date().toUTCString(),
    endDate: new Date().toISOString()
  })

  useEffect(() => {
    if (!isGettingDefaultCompany && defaultCompany) {
      getBusinessBankAccounts({ businessId: defaultCompany.businessId })
    }
  }, [isGettingDefaultCompany, getBusinessBankAccounts, defaultCompany])
  useEffect(() => {
    if (businessBankAccounts?.accounts[0]) {
      setSelectedAccount(businessBankAccounts?.accounts[0])
    }
  }, [businessBankAccounts])

  useEffect(() => {
    if (selectedAccount?.id)
      getExtract({
        accountId: selectedAccount?.id,
        endDate: new Date(filter.endDate).toISOString(),
        initDate: new Date(filter.initialDate).toISOString(),
        limit: ITEMS_PER_PAGE,
        offset: String(currentPage)
      })
  }, [selectedAccount, currentPage])

  return (
    <div data-testid="client-extract-test">
      <ClientExtractHeader
        loading={isGettingBusinessBankAccounts || isGettingDefaultCompany}
        balance={currentBalance}
        accountsInfo={businessBankAccounts?.accounts as Accounts[]}
        selectedAccount={selectedAccount}
      />
      <div style={{ background: '#F2F2F2', height: 30 }} />

      <div className="client-extract__wrapper">
        <PrimaryCard>
          <div className="client-extract__filter-container">
            <div className="client-extract__title">
              <b>Extrato</b>
            </div>

            <div className="client-extract__mobile-filter" onClick={() => setIsFilterVisible((prev) => !prev)}>
              <div className="client-extract__mobile-filter-title">
                <FilterListOutlinedIcon />

                <Typography fontSize={16} sx={{ color: 'primary.main' }}>
                  Filtro
                </Typography>
              </div>
              {!isFilterVisible && (
                <Typography fontSize={16} sx={{ flex: 1, color: 'primary.main' }}>
                  {formatDateToDDMMYYYY(new Date(filter.initialDate))}-{formatDateToDDMMYYYY(new Date(filter.endDate))}
                </Typography>
              )}
              <div className="client-extract__mobile-filter-expand">
                {isFilterVisible ? <ExpandMoreOutlinedIcon /> : <ExpandLessOutlinedIcon />}
              </div>
            </div>

            <div
              className={`client-extract__date-picker ${isFilterVisible ? 'client-extract__date-picker--visible' : ''}`}
            >
              <SecondaryDateInput
                disabled={
                  isGettingBusinessBankAccounts ||
                  isGettingExtract ||
                  isGettingDefaultCompany ||
                  businessBankAccounts?.accounts.length === 0
                }
                label={'Data inicial'}
                onChange={(value) => {
                  setFilter((prev) => ({ ...prev, initialDate: value.toString() }))
                }}
                dataTestid={`input-initial-date-test`}
                value={filter?.initialDate}
                name={'initialDate'}
                variant="outlined"
                maxDate={new Date(filter.endDate)}
                minDate={new Date(businessBankAccounts?.accounts[0]?.createdAt ?? '')}
              />
              <SecondaryDateInput
                disabled={
                  isGettingBusinessBankAccounts ||
                  isGettingExtract ||
                  isGettingDefaultCompany ||
                  businessBankAccounts?.accounts.length === 0
                }
                label={'Data final'}
                onChange={(value) => {
                  setFilter((prev) => ({ ...prev, endDate: value.toString() }))
                }}
                variant="outlined"
                dataTestid={`input-end-date-test`}
                value={filter?.endDate}
                name={'endDate'}
                maxDate={new Date()}
                minDate={new Date(filter.initialDate)}
              />
              <PrimaryButton
                disabled={
                  isGettingBusinessBankAccounts ||
                  isGettingExtract ||
                  isGettingDefaultCompany ||
                  businessBankAccounts?.accounts.length === 0
                }
                className="client-extract__button"
                data-testid="filter-button-test"
                startIcon={<SearchIcon />}
                onClick={() => {
                  if (selectedAccount?.id) {
                    setCurrentPage(0)
                    getExtract({
                      accountId: selectedAccount?.id,
                      endDate: new Date(filter.endDate).toISOString(),
                      initDate: new Date(filter.initialDate).toISOString(),
                      limit: ITEMS_PER_PAGE,
                      offset: '0'
                    })
                  }
                }}
              >
                Buscar
              </PrimaryButton>
            </div>
          </div>

          {isGettingBusinessBankAccounts || isGettingExtract || isGettingDefaultCompany ? (
            <Grid mt={10} sx={{ backGround: '#FFFFFF' }} container justifyContent="center">
              <CircularProgress size={40} color="primary" />
            </Grid>
          ) : businessBankAccounts?.accounts.length === 0 ? (
            <div style={{ padding: '0 20px' }}>
              <div
                style={{ background: '#FDEDED', display: 'flex', gap: '8px', padding: '8px 16px', borderRadius: '8px' }}
              >
                <div>
                  <InfoOutlinedIcon sx={{ color: '#D32F2F' }}></InfoOutlinedIcon>
                </div>
                <div>
                  <Typography fontSize={16} fontWeight={600}>
                    Você ainda não possui uma conta consignada
                  </Typography>
                  <Typography fontSize={16}>Fale com seu contato comercial para solicitar a abertura </Typography>
                </div>
              </div>
            </div>
          ) : extract?.transactions.length === 0 ? (
            <div style={{ padding: '0 20px' }}>
              <div
                style={{ background: '#E5F6FD', display: 'flex', gap: '8px', padding: '8px 16px', borderRadius: '8px' }}
              >
                <div>
                  <InfoOutlinedIcon sx={{ color: '#0288D1' }}></InfoOutlinedIcon>
                </div>
                <div>
                  <Typography fontSize={16} fontWeight={600}>
                    Sem movimentações no período selecionado
                  </Typography>
                  <Typography fontSize={16}>Altere a data inicial e final para buscar outro resultado</Typography>
                </div>
              </div>
            </div>
          ) : (
            <Grid mb={5} item xs={11.6}>
              <TableContainer sx={{ background: 'white' }}>
                <Table
                  sx={{
                    borderCollapse: 'separate',
                    borderSpacing: '0px 4px'
                  }}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="center">Data</TableCell>
                      <TableCell align="center">Contraparte</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Valor</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {extract?.transactions?.map((row) => (
                      <Fragment key={row?.id}>
                        <TableRow key={row.createdAt}>
                          <TableCell>
                            <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                              <Typography
                                fontWeight={600}
                                sx={{
                                  color: row.moveType == 'credit' ? 'primary.main' : 'gray.lighter'
                                }}
                                fontSize={20}
                              >
                                $
                              </Typography>
                              {row.moveType === 'credit' ? (
                                <ArrowDownward
                                  sx={{
                                    ml: 0.1,
                                    fontSize: 22,
                                    color: row.moveType === 'credit' ? 'primary.main' : 'gray.lighter'
                                  }}
                                />
                              ) : (
                                <ArrowUpward
                                  sx={{
                                    ml: 0.1,
                                    fontSize: 22,
                                    color: row.moveType === 'credit' ? 'primary.main' : 'gray.lighter'
                                  }}
                                />
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Typography>{formatDateToDDMMYYYYHHMM(row.date)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{row.counterpart}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{row.description}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              fontSize={20}
                              fontWeight={600}
                              sx={{
                                color: row.moveType === 'credit' ? '#04B74E' : 'black'
                              }}
                            >
                              {row.moveType === 'credit' ? '+ ' : '- '}
                              {formatCurrencyToPtBR(Number(row?.value))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent={'flex-end'}>
                <SecondaryPagination
                  count={Math.ceil(extract.totalCount / Number(ITEMS_PER_PAGE))}
                  page={currentPage + 1}
                  onPageChange={(newPage) => setCurrentPage(newPage - 1)}
                />
              </Grid>
            </Grid>
          )}
        </PrimaryCard>
      </div>
    </div>
  )
}

export default styled(WithHeader()(ClientExtract))`
  .client-extract__wrapper {
    background: ${({ theme: { colors } }) => colors?.secondaryBackground};
    padding: 0 24px 64px;
    width: calc(100% - 48px);
    min-height: calc(100vh - 300px);
  }
  .client-extract__filter-container {
    display: flex;
    justify-content: space-between;
    background: white;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 10px;
    @media (max-width: 550px) {
      flex-direction: column;
    }
  }
  .client-extract__title {
    margin-top: 13px;
    font-size: 20px;
    b {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .client-extract__date-picker {
    display: flex;
    gap: 10px;

    @media (max-width: 550px) {
      flex: 1;
      gap: 0px;
      flex-direction: column;
      display: none;
    }
  }
  .client-extract__mobile-filter {
    cursor: pointer;
    color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
    display: none;

    @media (max-width: 550px) {
      display: flex;
      gap: 10px;
    }
    .client-extract__mobile-filter-title {
      display: flex;
      gap: 10px;
      flex: 2;
    }
    .client-extract__mobile-filter-expand {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
  .client-extract__date-picker--visible {
    display: flex;
  }

  .client-extract__button {
    max-width: 150px;
    @media (max-width: 550px) {
      max-width: unset;
    }
  }
`
