import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps extends ButtonProps {
  className?: string
  children: ReactNode
  isLoading?: boolean
}

const SecondaryButton: React.FC<IProps> = ({ className, children, isLoading, ...rest }: IProps) => {
  return (
    <div className={className}>
      <Button
        variant="text"
        data-testid="secondary-button-test"
        disableElevation
        {...rest}
        disabled={isLoading || rest?.disabled}
      >
        {isLoading && (
          <CircularProgress size={18} className="secondary-button-loading" data-testid="test-circular-progress" />
        )}
        {children}
      </Button>
    </div>
  )
}

export default styled(SecondaryButton)`
  .MuiButton-root {
    font-size: 16px;

    .secondary-button-loading {
      margin-right: 12px;
    }

    path {
      color: ${({ theme: { colors } }) => colors?.primary};
    }
  }

  button:disabled,
  button[disabled] {
    svg,
    path {
      fill: ${({ theme }) => theme?.colors?.disabled} !important;
      color: ${({ theme }) => theme?.colors?.disabled} !important;
    }
  }

  .Mui-disabled {
    path {
      fill: ${({ theme }) => theme?.colors?.disabled};
      color: ${({ theme }) => theme?.colors?.disabled};
    }
  }
`
