import styled from 'styled-components'
import WithHeader from '../../hocs/WithHeader'
import CustomerCompanyDescription from './CustomerCompanyDescription'
import CustomerRating from './CustomerRating'
import CustomerDocuments from './CustomerDocuments'
import CustomerMainInformation from './CustomerMainInformation'
import { useParams } from 'react-router-dom'
import CustomerReports from './CustomerReports'
import { CompanyContext } from '../../states/company/CompanyContext'
import { useContext, useEffect } from 'react'
import posthog from 'posthog-js'
import CustomerReputation from './CustomerReputation'

interface IProps {
  className?: string
}

const CustomerProfile: React.FC<IProps> = ({ className }: IProps) => {
  const { id } = useParams()
  const { getCompanyInfos, defaultCompany, companyInfos } = useContext(CompanyContext)

  useEffect(() => {
    if (defaultCompany) {
      getCompanyInfos(defaultCompany?.businessId)
    }
  }, [getCompanyInfos, defaultCompany])

  return (
    <div className={className}>
      <CustomerMainInformation customerId={id as string} />
      <div className="customer-container">
        <CustomerCompanyDescription companyId={id as string} />
        <CustomerRating companyId={id as string} buyerId={companyInfos?.businessId as string} />
        {posthog.isFeatureEnabled('downloadCard') && <CustomerDocuments companyId={id as string} />}
        <CustomerReports companyId={id as string} />
        {posthog.isFeatureEnabled('reputationCard') && <CustomerReputation companyId={id as string} />}
      </div>
    </div>
  )
}

export default WithHeader()(styled(CustomerProfile)`
  align-items: center;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  padding: 16px;

  .customer-container {
    column-gap: 16px;
    row-gap: 16px;
    display: grid;
    max-width: 1300px;
    width: 90%;
    justify-content: center;
    grid-template-columns: 1fr 380px;

    grid-template-areas: ${() =>
      posthog.isFeatureEnabled('downloadCard')
        ? `
      'company_description customer_rating'
      'company_description customer_documents'
      'company_description customer_reports'
      'company_description customer_reputation'
      `
        : `
      'company_description customer_rating'
      'company_description customer_reports'
      `};

    @media (max-width: 900px) {
      grid-template-columns: 1fr 300px;
    }
    @media (max-width: 810px) {
      row-gap: 16px;
      grid-template-areas:
        'customer_rating'
        'company_description'
        'customer_documents'
        'customer_reports'
        'customer_reputation';
      grid-template-columns: 1fr;
      max-width: 500px;
      place-items: center;
    }
  }
`)
