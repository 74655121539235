import styled from 'styled-components'
import SecondaryButton from '../../../../../components/buttons/SecondaryButton'
import ChevronLeft from '../../../icons/ChevronLeft'
import ChevronRight from '../../../icons/ChevronRight'

export interface IPrimaryPaginationProps {
  className?: string
  totalPages: number
  currentPage: number
  nextButtonTextWhenIsLastPage?: string
  submit?: boolean
  handleNextPage?: (nextPage: number | undefined) => void
  handlePreviousPage?: (previousPage: number | undefined) => void
  loading?: boolean
}

const PrimaryPagination: React.FC<IPrimaryPaginationProps> = ({
  className,
  totalPages,
  nextButtonTextWhenIsLastPage,
  currentPage,
  submit,
  handleNextPage,
  handlePreviousPage,
  loading = false
}: IPrimaryPaginationProps) => {
  return (
    <div className={className}>
      {currentPage > 1 && (
        <SecondaryButton
          onClick={() => handlePreviousPage && handlePreviousPage(currentPage - 1)}
          data-testid="previous-button-test"
        >
          <div className="primary-pagination__button___container">
            <ChevronLeft />
            <p>Anterior</p>
          </div>
        </SecondaryButton>
      )}
      <div className="primary-pagination__pages___wrapper">
        {Array.from(Array(totalPages).keys()).map((page) => (
          <p key={page} className={`primary-pagination__pages___page ${page + 1 <= currentPage && 'checked'}`}>
            {page + 1}
          </p>
        ))}
      </div>
      {
        // prettier-ignore
        (currentPage !== totalPages || (currentPage === totalPages && !!nextButtonTextWhenIsLastPage)) && (
          <SecondaryButton
          isLoading={loading}
            type={submit ? 'submit' : 'button'}
            data-testid="next-button-test"
            onClick={() => handleNextPage && handleNextPage(currentPage + 1)}
          >
            <div className="primary-pagination__button___container">
              <p data-testid="next-button-text-test">{nextButtonTextWhenIsLastPage ?? 'Próximo'}</p>
              <ChevronRight />
            </div>
          </SecondaryButton>
        )
      }
    </div>
  )
}

export default styled(PrimaryPagination)`
  display: flex;
  align-items: center;
  gap: 16px;

  .primary-pagination__pages___wrapper {
    display: flex;
    gap: 16px;

    .primary-pagination__pages___page {
      font-size: 14px;
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
    }

    .checked {
      color: ${({ theme: { colors } }) => colors?.primary};
      font-weight: bold;
    }
  }

  .primary-pagination__button___container {
    display: flex;
    gap: 8px;
    align-items: center;

    p {
      color: ${({ theme: { colors } }) => colors?.primary};
    }
  }
`
