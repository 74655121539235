import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import H3 from '../../../common/components/Typography/H3'
import RefusedCompany from '../../../../assets/images/RefusedCompany.png'
import PageWrapper from '../../components/PageWrapper'
import { useAuth } from '../../../../hooks/useAuth'
interface IProps {
  className?: string
}

const DeniedCompany = ({ className }: IProps) => {
  const { loggedIn } = useAuth()
  const navigate = useNavigate()

  const handleClick = () => {
    if (loggedIn) {
      return navigate('/create-company/document-number')
    }

    return navigate('/onboarding/company-document-number-analysis')
  }
  return (
    <PageWrapper className={className}>
      <Header />
      <div className="denied-company__wrapper">
        <img alt="refused-company" src={RefusedCompany} />
        <H3>Empresa negada</H3>
        <div>
          <p>
            Desculpe, mas parece que a empresa que você <br /> informou foi negada na nossa plataforma.
          </p>
          <p>
            Por favor verifique se todos os dados informados estão corretos e tente novamente <strong>ou</strong> simule
            uma outra empresa.
          </p>
        </div>
        <div className="denied-company__button___wrapper">
          <PrimaryButton data-testid="denied-company-button-test" onClick={handleClick}>
            Tentar novamente
          </PrimaryButton>
        </div>
      </div>
    </PageWrapper>
  )
}

export default styled(DeniedCompany)`
  .denied-company__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 386px;

    img {
      height: 180px;
      width: 275px;
    }

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      padding-top: 24px;
    }

    div {
      p {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 16px;
        text-align: center;
      }

      p:first-child {
        padding: 8px 0px 16px;
      }

      strong {
        font-weight: bold;
      }
    }

    .denied-company__button___wrapper {
      margin-top: 64px;
      width: 296px;
    }
  }

  @media (max-width: 400px) {
    .denied-company__wrapper {
      div {
        p {
          width: 360px;
        }
      }
    }
  }
`
