import { SVGAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends SVGAttributes<SVGElement> {
  className?: string
}

const Logout: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="Group_10328" data-name="Group 10328" transform="translate(-0.875 -0.375)">
        <path
          id="Path_6118"
          data-name="Path 6118"
          d="M8.589,15.518a.9.9,0,0,1-.964.857H4.732A3.615,3.615,0,0,1,.875,12.946V3.8A3.638,3.638,0,0,1,4.732.375H7.625a.928.928,0,0,1,.964.857.9.9,0,0,1-.964.857H4.732A1.83,1.83,0,0,0,2.8,3.8v9.143a1.807,1.807,0,0,0,1.929,1.714H7.625A.928.928,0,0,1,8.589,15.518Z"
        />
        <path
          id="Path_6119"
          data-name="Path 6119"
          d="M18.613,7.826l-5.1-4.611a1.085,1.085,0,0,0-1.365-.034.724.724,0,0,0-.04,1.153l3.575,3.221H6.822a.872.872,0,0,0-.924.814.851.851,0,0,0,.924.814h8.819l-3.615,3.255a.724.724,0,0,0,.04,1.153,1.44,1.44,0,0,0,.683.2,1.037,1.037,0,0,0,.683-.237l5.1-4.611A.673.673,0,0,0,18.613,7.826Z"
          transform="translate(-2 0)"
        />
      </g>
    </svg>
  )
}

export default styled(Logout)``
