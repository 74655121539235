import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: ReactNode
}

const NormalFormWrapper: React.FC<IProps> = ({ className, children }: IProps) => {
  return (
    <section className={className}>
      <div className="normal-form-wrapper--container">{children}</div>
    </section>
  )
}

export default styled(NormalFormWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .normal-form-wrapper--container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      font-weight: 600;
      font-size: 32px;
    }
  }
`
