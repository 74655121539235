import styled from 'styled-components'
import { CompanyContext } from '../../../states/company/CompanyContext'
import { useContext, useEffect, useState } from 'react'
import Save from '../../../apps/common/assets/Save'
import ReportsSkeleton from './ReportsSkeleton'
import { downloadFile } from '../../../utils/file'
import { CircularProgress } from '@mui/material'

interface IProps {
  className?: string
  companyId: string
}

const CustomerReports: React.FC<IProps> = ({ className, companyId }: IProps) => {
  const { getCreditAnalysisReportByCompanyId, reports, isGettingCustomerDataByCompanyId } = useContext(CompanyContext)

  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    getCreditAnalysisReportByCompanyId({ companyId: companyId })
  }, [companyId, getCreditAnalysisReportByCompanyId])

  const handleDownloadAll = () => {
    setIsDownloading(true)

    reports.forEach((report, index) => {
      if (report.url) {
        setTimeout(() => {
          downloadFile(report.url as string)

          if (index === reports.length - 1) {
            setIsDownloading(false)
          }
        }, index * 3000)
      }
    })
  }

  return (
    <div className={className}>
      <div className="customer-reports__container">
        {isGettingCustomerDataByCompanyId ? (
          <ReportsSkeleton />
        ) : (
          <div className="customer-reports__wrapper">
            <h5 className="customer-reports__main-title">Relatórios</h5>
            {reports?.length > 0 ? (
              <div className="customer-reports__list">
                <div className="customer-reports__list-wrapper">
                  <div className="customer-reports__document-item">
                    <p>Relatórios e Análises de Crédito</p>
                    {isDownloading ? (
                      <div className="customer-reports__loading-container">
                        <CircularProgress
                          size={22}
                          sx={{ marginRight: '16px', marginLeft: '16px' }}
                          data-testid="test-circular-progress"
                        />
                      </div>
                    ) : (
                      <Save data-testid="download-all-test" onClick={handleDownloadAll} />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="customer-reports__without-reports___wrapper">
                <p>Nenhum relatório disponível</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default styled(CustomerReports)`
  background: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.white?.light?.primary};
  box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
  border-radius: 8px;
  display: grid;
  grid-area: customer_reports;
  width: 100%;

  .customer-reports__container {
    padding: 16px;
  }
  .customer-reports__loading-container {
    border-left: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};
  }

  .customer-reports__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .customer-reports__without-reports___wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    width: 100%;
    margin: 34px 0;
    p {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
    }
  }

  .customer-reports__main-title {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.dark?.primary};
  }

  .customer-reports__list {
    margin-top: 24px;
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};

    .customer-reports__list-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      ${Save} {
        max-height: 22px;
        max-width: 22px;
        padding: 4px 16px;
        border-left: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};

        path {
          fill: ${({ theme: { colors } }) => colors?.primary};
        }
      }

      .customer-reports__document-item {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${({ theme: { styleGuide } }) => styleGuide?.borderColor};

        p {
          color: ${({ theme: { styleGuide } }) => styleGuide?.fixed?.gray?.light?.primary};
          font-weight: bold;
          padding: 16px 0px 16px 16px;
          text-align: left;
        }
      }
    }
  }
`
