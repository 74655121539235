import { ComponentType } from 'react'
import HeaderAppBar from '../../apps/common/components/Header/HeaderAppBar'
import NavBar from '../../apps/common/components/NavBar'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const WithHeader =
  () =>
  (Component: ComponentType): React.FC => {
    const ComponentWithHeader: React.FC<IProps> = ({ className }: IProps) => {
      return (
        <div className={className}>
          <HeaderAppBar />
          <NavBar />
          <div data-testid="component-wrapper" className="component-with-header__component___wrapper">
            <Component />
          </div>
        </div>
      )
    }

    return styled(ComponentWithHeader)`
      display: flex;
      flex-direction: column;

      .component-with-header__component___wrapper {
        flex: 1;
        margin-top: 108px;
      }

      @media (min-width: 601px) {
        .component-with-header__component___wrapper {
          margin-top: 104px;
        }
      }
    `
  }

export default WithHeader
