import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const CompanyRatingSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className} data-testid="company-rating-skeleton-test">
      <form className="customer-rating__form">
        <h6 className="customer-rating__main-title">Avaliação</h6>
        <Skeleton width="100%" height="96px" animation="wave" />
        <div className="customer-rating__info-group--skeleton">
          <h6 className="customer-rating__info-title">Limite pré-aprovado</h6>
          <p className="customer-rating__info-subtitle">Valor máximo disponível para este cedente</p>
        </div>
        <div className="customer-rating__input-group">
          <Skeleton width="100%" height="64px" animation="wave" />
        </div>
        <div className="customer-rating__info-group">
          <h6 className="customer-rating__info-title">Taxas</h6>
          <p className="customer-rating__info-subtitle">A menor e maior taxa disponível para este cedente</p>
        </div>
        <div className="customer-rating__input-group">
          <Skeleton width="100%" height="64px" animation="wave" />
          <Skeleton width="100%" height="64px" animation="wave" />
        </div>
        <div className="customer-rating__button-submit___wrapper">
          <Skeleton width="calc(50% - 6px)" height="80px" animation="wave" />
        </div>
      </form>
    </div>
  )
}

export default styled(CompanyRatingSkeleton)`
  .customer-rating__info-group--skeleton {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`
