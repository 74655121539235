import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const DocumentsSkeleton: React.FC<IProps> = ({ className }: IProps) => {
  const items = Array(6).fill(null)
  const renderItems = items.map((item, index) => (
    <Skeleton key={index} width="90%" height="36px" animation="wave" className="document-item" />
  ))
  return (
    <div className={className} data-testid="documents-skeleton-test">
      <div className="customer-documents__wrapper">
        <h5 className="customer-documents__main-title">Documentos</h5>
        <div className="customer-documents__list">
          <div className="customer-documents__list-wrapper">{renderItems}</div>
        </div>
        <div className="customer-documents__download-button___wrapper">
          <Skeleton width="90%" height="36px" animation="wave" />
        </div>
      </div>
    </div>
  )
}

export default styled(DocumentsSkeleton)`
  .customer-documents__list-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .customer-documents__download-button___wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
